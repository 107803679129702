import styled from 'styled-components';

const StyledNotification = styled.div`
  flex: 1;
  min-height: 47px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 15px;
  width: 100%;
  max-width: 675px;
  padding: 5px 10px;
  @media screen and (max-width: 770px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 10px;
    width: auto;
    max-width: 100%;
  }
  .message {
    padding: 5px 0;
    display: block;
    margin-left: 7px;
    margin-right: auto;
    display: flex;
    align-items: center;
  }
  .action {
    margin: 0 10px;
    @media screen and (max-width: 770px) {
      margin: 4px 10px 0px;
    }
  }
  svg {
    margin-right: 15px;
    ]height: 14px;
    width: 14px;
    flex: 0 0 14px;
  }
  &.error {
    color: #fff;
    background-color: #b21f28;

    .action {
      button,
      link,
      a {
        background: transparent;
        border: none;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        position: relative;
        text-decoration: none;
        &:after {
          margin-left: 4px;
          content: '';
          display: inline-block;
          border-right: 3px solid #ffffff;
          border-bottom: 3px solid #ffffff;
          width: 7px;
          height: 7px;
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export default StyledNotification;
