import React, { useEffect, useContext } from 'react';
import { UserContext } from './components/User';
import TagManager from 'react-gtm-module';
import { isProd } from './utils';

const Analytics = ({ children }) => {
  const { activeLot } = useContext(UserContext);
  const ga4ProdMeasurementId = 'G-6V96VEB4QX';
  const ga4TestMeasurementId = 'G-CWMQZEYN60';

  // initialize google tag manager vis the react-gtm-module lib
  useEffect(() => {
    if (!activeLot?.LotNumber) return;

    TagManager.initialize({
      gtmId: 'GTM-MV2B9KX',
      dataLayer: {
        rtl_region: activeLot.Region,
        rtl_zone: activeLot.Zone,
        rtl_brand: activeLot.Brand,
        rtl_hc: activeLot.LotNumber,
      },
    });
    window.gaMeasurementId = isProd()
      ? ga4ProdMeasurementId
      : ga4TestMeasurementId;
  }, [activeLot]);

  return [children];
};

export default Analytics;
