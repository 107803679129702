import React from 'react';
import PropTypes from 'prop-types';
import DeleteAgentWrapper from './DeleteAgent.style';

const DeleteAgent = ({ IsCorporateOfficeUser, showDeleteModal }) => {
  return (
    <DeleteAgentWrapper>
      {IsCorporateOfficeUser && (
        <div>
          <button onClick={showDeleteModal}>Delete Agent</button>
        </div>
      )}
    </DeleteAgentWrapper>
  );
};

DeleteAgent.propTypes = {
  IsCorporateOfficeUser: PropTypes.bool.isRequired,
  showDeleteModal: PropTypes.func.isRequired,
};

DeleteAgent.defaultProps = {};

export default DeleteAgent;
