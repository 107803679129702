import styled from 'styled-components';

const StyledCardContainer = styled.div`
  width: 100%;

  .disabled {
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.available {
    .card-body {
      min-height: 100px;
    }
    .card-options {
      min-height: 0;
    }
  }
  .card {
    width: 88%;
    box-sizing: border-box;
    position: relative;
    color: #25292d;
    margin: auto auto 18px;
    &.updating {
      .card-body {
        align-items: center;
      }
    }

    .loader {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .card-body {
    min-height: 195px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: 9px 0;
    flex: 1;
    width: 100%;
  }
  .home-info {
    padding: 0 16px;
    flex: 1;
  }
  .error {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    span {
      color: #b21f28;
      margin-left: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .card-options {
    border-top: 1px solid #e4e5e7;
    flex: 1 100%;
    padding: 9px 20px 0;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    padding: 0;
    margin: 0 0 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 600;
    color: #25292d;
    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      display: block;
      margin-right: 8px;
    }
  }
  .tag {
    font: 600 12px/100% source-sans-pro, sans-serif;
    text-align: center;
    width: 48px;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    flex: 0 auto;
  }
  a {
    text-decoration: none;
    display: flex;
    flex: 1;
    flex-flow: row wrap;
  }
  .home-info-container {
    display: flex;
    flex-wrap: wrap;
    div {
      margin-right: 25px;
    }
    .available {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .home-errors {
    /* height: 16px; */
    margin: 10px 0px;
    display: flex;
    /* align-items: center; */
    min-height: 18px;
    min-width: 1px;
    flex-wrap: wrap;
    .error {
      flex-basis: 155px;
      margin-right: 10px;
      @media screen and (max-width: 580px) {
        flex-basis: 100%;
        margin-bottom: 4px;
        svg {
          transform: translateY(0px);
        }
      }
    }
    span {
      margin: 0;
      color: #b21f28;
      font-size: 12px;
      font-weight: normal;
    }
    svg {
      margin-right: 7px;
      transform: translateY(3px);
      height: 14px;
      width: 14px;
      flex: 0 0 14px;
    }
  }
  .arrow-position {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  p {
    color: #777b7e;
    font-size: 14px;
    padding: 0;
    margin: 0 0 5px;
  }
  span {
    margin-right: 7px;
    font-size: 12px;
    color: #4e5255;
    font-weight: 600;
  }
  .toggle-placeholder {
    height: 57px;
    width: 74px;
  }
  @media screen and (min-width: 1024px) {
    .toggle-placeholder {
      height: 40px;
      width: 144px;
    }
  }
  @media screen and (min-width: 1200px) {
    .card-body {
      min-height: 127px;
      padding: 20px 0 25px;
    }
    .home-info {
      padding: 0 16px;
      flex: 0 1 100%;
    }
    .card-options {
      border-top: none;
      border-left: 1px solid #e4e5e7;
      flex: 0 1 29%;
      padding: 0 0 0 20px;
      display: block;
    }
    .toggle-placeholder {
      width: 145px;
      height: 40px;
    }
  }
`;

export default StyledCardContainer;
