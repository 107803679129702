import styled from 'styled-components';

import StyledSettings from '../Settings.Style';

const StyledDescriptions = styled(StyledSettings)`
  .settings-item {
    flex: 0 0 100%;

    @media screen and (max-width: 767px) {
      display: block;
    }

    label {
      text-transform: capitalize;
    }

    input,
    textarea {
      border: 1px solid #e4e5e7;
      width: 100%;
      font: 400 15px/100% source-sans-pro;
      color: #a1a3a6;
      padding: 15px 5px;
      box-sizing: border-box;
      margin-bottom: 16px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .section-header {
      font: 400 15px/140% source-sans-pro;
      color: #4e5255;
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .page-header {
    margin-bottom: 20px;
  }

  .description-blurb {
    margin-bottom: 40px;

    font-weight: 600;
  }

  .meta-descriptions-container {
    display: flex;
    flex-wrap: wrap;

    .about-us {
      order: 1;
    }

    .homes {
      order: 2;
    }

    .energysmart {
      order: 3;
    }

    .index {
      order: 4;
    }

    .housesmart {
      order: 5;
    }

    .sale-homes {
      order: 6;
    }

    .special-offers {
      order: 7;
    }
  }
`;

export default StyledDescriptions;
