import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { getCachedToken, redirectToAuthUrl } from '../Api';
import { AuthLanding } from '../Auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        // token check
        if (!getCachedToken()) {
          // redirect, if needed
          redirectToAuthUrl(window.location.href);
          return <AuthLanding />; // show something instead of white page
        }

        // otherwise, render component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
