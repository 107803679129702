import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from './App.GlobalStyles';
import Navigation from './components/Navigation';
import PageHeader from './components/PageHeader';
import Home from './components/Home';
import Homepage from './components/Homepage/Homepage';
import AppProviders from './AppProviders';
import {
  AvailableList,
  OnDisplayList,
  SaleList,
  LandList,
} from './components/HomeList';
import SiteItemList from './components/SiteItems/SiteItemList';
import SiteItem from './components/SiteItems/SiteItem';
import ContentSiteItem from './components/SiteItems/ContentSiteItem';
import WelcomeHomeItem from './components/WelcomeHome/WelcomeHomeItem';
import SalesAgents from './components/SalesAgents/SalesAgents';
import InstallBanner from './components/InstallBanner/InstallBanner';
import Descriptions from './components/Settings/Descriptions/Descriptions';
import Hours from './components/Settings/Hours/Hours';
import Urls from './components/Settings/Urls/Urls';
import Other from './components/Settings/Other/Other';
import { Console, Hook, Unhook } from 'console-feed';
import Analytics from './Analytics';
import * as serviceWorker from './serviceWorker';
import AppError from './AppError';
import { UserContext, UserProvider } from './components/User';

toast.configure({ autoClose: 8000 });

const App = props => {
  const SHOW_INTERNAL_CONSOLE = false;
  const { location, history } = props;
  const [collapsed, setCollapsed] = useState(true);
  const [logs, setLogs] = useState([]);

  const {
    signout,
    user: { IsCorporateOfficeUser },
    activeLot,
    isLoading,
    isError,
  } = useContext(UserContext);

  // run once!
  useEffect(() => {
    const hookedConsole = Hook(
      window.console,
      log => setLogs(currLogs => [...currLogs, log]),
      false
    );
    return () => Unhook(hookedConsole);
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      //this is the time that the cache will clear. It will be set to 2 AM or 2, 0, 0 before being merged.
      //It is set to the current time for QA to test.
      const scheduleTime = new Date().setHours(2, 0, 0);
      const timeUntilNextClear = scheduleTime - Date.now();
      serviceWorker.unregister();
      if ('caches' in window) {
        const clearCache = () => {
          caches
            .keys()
            .then(names => {
              // Delete all the cache files
              names.forEach(name => {
                caches.delete(name);
              });
            })
            .then(() => {
              serviceWorker.register();
            });
        };

        const refreshWindow = () => {
          if (window.localStorage) {
            if (!localStorage.getItem('reload')) {
              localStorage['reload'] = true;
              window.location.reload();
            } else {
              localStorage.removeItem('reload');
            }
          }
        };

        setTimeout(() => {
          clearCache();
          refreshWindow();
          setInterval(clearCache, 24 * 60 * 60 * 1000); // Repeat daily
        }, timeUntilNextClear);

        const intervalId = setInterval(clearCache, 24 * 60 * 60 * 1000);

        return () => {
          // Cleanup the interval on unmount
          clearInterval(intervalId);
        };
      }
    }
  }, []);

  return (
    <AppProviders history={history}>
      {SHOW_INTERNAL_CONSOLE && <Console logs={logs} variant="dark" />}
      <div
        className={`app-container ${
          collapsed ? 'nav-collapsed' : 'nav-expanded'
        }`}
      >
        <ToastContainer pauseOnFocusLoss={false} />
        <GlobalStyles />
        <InstallBanner />
        <Analytics />
        <Navigation
          location={location}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />

        <main>
          {(isLoading || isError) && <AppError />}
          {!(isLoading || isError) && (
            <>
              <Switch>
                <Route
                  path="/homes"
                  render={() => (
                    <PageHeader
                      header="Home Inventory"
                      pathname={location.pathname}
                    />
                  )}
                />
                <Route
                  path="/home/:homeId/"
                  render={() => (
                    <PageHeader
                      header="Home Details"
                      history={history}
                      pathname={location.pathname}
                    />
                  )}
                />
                <Route
                  path="/land/:homeId/"
                  render={() => (
                    <PageHeader
                      header="Land Details"
                      history={history}
                      pathname={location.pathname}
                    />
                  )}
                />

                <Route
                  path="/content/welcome-home/:welcomeHomeItemId"
                  render={() => {
                    return (
                      <PageHeader
                        history={history}
                        pathname={location.pathname}
                      />
                    );
                  }}
                />
                <Route
                  path="/content/promotions/:siteItemId"
                  render={() => {
                    return (
                      <PageHeader
                        history={history}
                        pathname={location.pathname}
                      />
                    );
                  }}
                />
                <Route
                  path="/content/:type/:contentSiteItemId/bulk"
                  render={() => {
                    return (
                      <PageHeader
                        history={history}
                        pathname={location.pathname}
                      />
                    );
                  }}
                />
                <Route
                  path="/content/:type/:contentSiteItemId"
                  render={() => {
                    return (
                      <PageHeader
                        history={history}
                        pathname={location.pathname}
                      />
                    );
                  }}
                />
                <Route
                  path="/content/:type"
                  render={() => {
                    return (
                      <PageHeader
                        header="Manage Content"
                        pathname={location.pathname}
                      />
                    );
                  }}
                />

                <Route
                  path="/settings"
                  render={() => {
                    return (
                      <PageHeader
                        header="Settings"
                        pathname={location.pathname}
                      />
                    );
                  }}
                />
              </Switch>

              <div className="listing-container">
                <Switch>
                  <Route
                    exact
                    path="/homes/on-display"
                    component={OnDisplayList}
                  />
                  <Route
                    exact
                    path="/homes/available-floor-plans"
                    component={AvailableList}
                  />
                  <Route exact path="/homes/sale" component={SaleList} />
                  <Route exact path="/homes/land" component={LandList} />
                  <Route
                    exact
                    path="/homes/"
                    render={() => <Redirect to="/homes/on-display/" />}
                  />
                  <Route
                    path="/home/:homeId"
                    pathname={location.pathname}
                    component={Home}
                  />
                  <Route
                    path="/land/:homeId"
                    pathname={location.pathname}
                    component={Home}
                  />
                  {/* Avoid sending SiteItemList an invalid type */}

                  <Route exact path="/content/:type" component={SiteItemList} />
                  <Route
                    exact
                    path="/content/welcome-home/:id"
                    component={WelcomeHomeItem}
                  />
                  <Route
                    exact
                    path="/content/promotions/:id"
                    component={SiteItem}
                  />
                  <Route
                    exact
                    path="/content/:type/:id/:bulkMode"
                    component={ContentSiteItem}
                  />
                  <Route
                    exact
                    path="/content/:type/:id"
                    component={ContentSiteItem}
                  />

                  <Route exact path="/profile" component={SalesAgents} />
                  <Route
                    exact
                    path="/settings/descriptions"
                    component={Descriptions}
                  />
                  <Route exact path="/settings/hours" component={Hours} />
                  <Route exact path="/settings/urls" component={Urls} />
                  <Route exact path="/settings/other" component={Other} />
                  <Route
                    exact
                    path="/settings"
                    render={() => <Redirect to="/settings/descriptions/" />}
                  />
                  <Route exact path="/" component={Homepage} />
                </Switch>
              </div>
            </>
          )}
        </main>
      </div>
    </AppProviders>
  );
};

App.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

// wrap the app here so we can get the user data and monitor status in app (and children)
export default function AppWrapper(props) {
  return (
    <UserProvider>
      <App {...props} />
    </UserProvider>
  );
}
