import React, { useEffect } from 'react';
import PropTypes, { number } from 'prop-types';
import StyledPricing from './Pricing.Style';

export const RETAIL_PRICE_ID = 1;
export const DEALER_PRICE_ID = 2;
export const DEALER_RETAIL_PRICE_ID = 3;

const Pricing = ({
  HomePriceOptions,
  setLandPrice,
  Price,
  IsLand,
  IsAvailableFloorPlan,
  PublishedPriceStateId,
  PublishedPriceStateValue,
  setPublishedPriceStateId,
  setPublishedPriceStateValue,
  ModelPriceAdjustmentTypeId,
  setModelPriceAdjustmentTypeId,
  priceRef,
  focusRef,
  setHasFocus,
  setShowToast,
}) => {
  const priceOptions =
    HomePriceOptions &&
    HomePriceOptions.map(priceOption => (
      <option
        key={priceOption.Id}
        className="price-option"
        value={priceOption.Id}
      >
        {priceOption.Label.split('.00').join('')}
      </option>
    ));

  // useEffect(() => {
  //   if (PublishedPriceStateValue === undefined) {
  //     setPublishedPriceStateValue('');
  //   }
  // }, [PublishedPriceStateValue]);

  const formatterDecimal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currency: 'USD',
  });

  const formatCurrencyOnBlur = value => {
    if (value) {
      let cleanedValue =
        typeof value == 'string' && value.indexOf('$') > -1
          ? value
              .split(',')
              .join('')
              .split('$')
              .join('')
              .split('.')
              .join('')
          : value;

      // if cleaned number string is too big, treat it as an invalid input
      if (cleanedValue.length > 16) return '';

      const res = formatterDecimal.format(cleanedValue);

      return res === '$NaN' || res === '$0' || res === '' || res === undefined
        ? ''
        : `${res}.00`;
    }
  };

  const formatCurrency = value => {
    if (value) {
      let cleanedValue = value
        .split(',')
        .join('')
        .split('$')
        .join('');

      // if cleaned number string is too big, treat it as an invalid input
      if (cleanedValue.length > 16) return '';

      const res = formatterDecimal.format(cleanedValue);

      return res === '$NaN' || res === '$0' || res === '' || res === undefined
        ? ''
        : res;
    }
  };

  const removeFormatting = value => {
    let cleanedValue = value
      .split(',')
      .join('')
      .split('$')
      .join('')
      .split('.00')
      .join('');

    const res = formatterDecimal.format(cleanedValue);

    return res === '$NaN' || res === '$0' || res === '' || res === undefined
      ? ''
      : res;
  };

  const getRetailPrice = () => {
    if (IsLand || IsAvailableFloorPlan) {
      return null;
    }

    return `$${HomePriceOptions.find(
      opt => opt.Id === RETAIL_PRICE_ID
    ).Value.toLocaleString()}`;
  };

  return (
    <StyledPricing>
      <h2>Pricing</h2>
      <div className="card">
        <div className="price-wrapper">
          {/* This is a bug with the eslint rule */}
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="price-select">
            <span className="label">Display on website</span>
            {!IsAvailableFloorPlan && (
              <select
                id="price-select"
                value={PublishedPriceStateId}
                onChange={event => {
                  if (
                    event.target.value === DEALER_RETAIL_PRICE_ID ||
                    event.target.value === DEALER_PRICE_ID
                  ) {
                    setShowToast(false);
                    setPublishedPriceStateId(parseInt(event.target.value, 10));
                  } else {
                    setShowToast(true);
                    setPublishedPriceStateId(parseInt(event.target.value, 10));
                  }
                }}
              >
                {priceOptions}
              </select>
            )}
            {IsAvailableFloorPlan && !IsLand && (
              <select
                id="price-select"
                value={ModelPriceAdjustmentTypeId}
                onChange={event => {
                  setShowToast(true);
                  setModelPriceAdjustmentTypeId(
                    parseInt(event.target.value, 10)
                  );
                }}
              >
                {priceOptions}
              </select>
            )}
          </label>
          {(PublishedPriceStateId === DEALER_PRICE_ID ||
            PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) && (
            <input
              ref={focusRef}
              type="text"
              id="dealerPrice"
              onFocus={event => {
                setHasFocus(true);
                setPublishedPriceStateValue(
                  removeFormatting(event.target.value)
                );
              }}
              onBlur={event =>
                setPublishedPriceStateValue(
                  formatCurrencyOnBlur(event.target.value)
                )
              }
              onChange={event =>
                setPublishedPriceStateValue(formatCurrency(event.target.value))
              }
              // If no PublishedPriceStateValue show the dealer price option value instead
              value={
                (PublishedPriceStateValue !== undefined &&
                  PublishedPriceStateValue) ||
                (PublishedPriceStateValue === undefined &&
                  formatCurrencyOnBlur(
                    HomePriceOptions.find(opt => opt.Id === DEALER_PRICE_ID)
                      .Value
                  )) ||
                ''
              }
            />
          )}
          {!IsAvailableFloorPlan && !IsLand && (
            <p className="retail-pricing">Retail Pricing: {getRetailPrice()}</p>
          )}
        </div>
      </div>
    </StyledPricing>
  );
};

Pricing.propTypes = {
  HomePriceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      Label: PropTypes.string,
      Value: PropTypes.number,
    })
  ).isRequired,
  Price: number.isRequired,
  ModelPriceAdjustmentTypeId: PropTypes.number,
  setModelPriceAdjustmentTypeId: PropTypes.func.isRequired,
  PublishedPriceStateId: PropTypes.number,
  PublishedPriceStateValue: PropTypes.string,
  setPublishedPriceStateId: PropTypes.func.isRequired,
  setPublishedPriceStateValue: PropTypes.func.isRequired,
  IsAvailableFloorPlan: PropTypes.bool,
};

Pricing.defaultProps = {
  ModelPriceAdjustmentTypeId: undefined,
  PublishedPriceStateId: undefined,
  PublishedPriceStateValue: undefined,
  IsAvailableFloorPlan: true,
};

export default Pricing;
