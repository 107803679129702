import React, { useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import HomeSvg from '../HomeSvg';
import ModelImagesModal from './ModelImagesModal';
import StyledModelImagesCard from './ModelImagesCard.Style';

const cardSource = {
  beginDrag: props => ({
    id: props.id,
    index: props.index,
  }),
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const ModelImagesCard = React.forwardRef(
  (
    { images, isDragging, connectDragSource, connectDropTarget, 
      connectDragPreview, style, home },
    ref
  ) => {
    const elementRef = useRef(null);
    const previewRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(previewRef);
    connectDragPreview(previewRef);
    const opacity = isDragging ? 0.2 : 1;
    const transition = isDragging ? 'none' : 'inherit';
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
      setShowModal(!showModal);
    };

    // const [modelImages, setModelImages] = useState(home.ModelImages);

    const modelImages = home.ModelImages.map(modelImage => {
      return modelImage.Reference;
    });

    return (
      <div
        tabIndex={0}
        role="button"
        ref={previewRef}
        style={{ ...style, opacity, transition }}
        className="card"
        onClick={toggleModal}
        onKeyPress={event => {
          // trigger click on enter or space bar press
          if (event.key === ' ' || event.key === 'Enter') {
            event.currentTarget.click();
          }
        }}
      >
        <div ref={elementRef} className="draggable-bars">
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
        <StyledModelImagesCard>
          {images.length > 0 && images[0].Reference && (
            <img src={`${images[0].Reference}?width=300`} alt="" />
          )}
          {images.length > 0 && !images[0].Reference && <HomeSvg />}
          <div className="label">
            <span className="bold">Default Model Images</span>

            {home.ModelImageCount > 0 && (
              <span className="model-image-count">{home.ModelImageCount}</span>
            )}
          </div>
        </StyledModelImagesCard>
        <div className="background-card" />
        <div className="background-card" />
        <ModelImagesModal
          closeCopy="CLOSE"
          show={showModal}
          closeCallback={toggleModal}
          discardCallback={toggleModal}
          modelImages={modelImages}
        />
      </div>
    );
  }
);

ModelImagesCard.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      Reference: PropTypes.string.isRequired,
      ModelsImagesId: PropTypes.number.isRequired,
    })
  ).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
};

export default DropTarget(
  'image-card',
  {
    hover(props, monitor, component) {
      const { index, moveCard } = props;
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return null;
      }
      moveCard(dragIndex, hoverIndex);
      // (Mutating this object for performance enhancement)
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
      return null;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  })
)(DragSource('image-card', cardSource, collect)(ModelImagesCard));
