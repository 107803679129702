import React from 'react';
import PropTypes from 'prop-types';
import StyledSelectSalesAgent from './SelectSalesAgent.Style';

const SelectSalesAgent = ({
  activeSalesAgentId,
  handleSalesAgentChange,
  salesAgents,
}) => (
  <StyledSelectSalesAgent>
    <div>
      <p className="select-label">Select profile</p>
      <select
        id="sales-agent-select"
        value={activeSalesAgentId}
        onChange={handleSalesAgentChange}
      >
        {salesAgents &&
          salesAgents.map(agent => (
            <option
              key={agent.SalesAgentId}
              className="sale-agent-option"
              value={agent.SalesAgentId}
            >
              {agent.FirstName} {agent.LastName}
            </option>
          ))}
      </select>
    </div>
  </StyledSelectSalesAgent>
);

SelectSalesAgent.propTypes = {
  activeSalesAgentId: PropTypes.number.isRequired,
  handleSalesAgentChange: PropTypes.func.isRequired,
  salesAgents: PropTypes.arrayOf(
    PropTypes.shape({
      SalesAgentId: PropTypes.number,
      FirstName: PropTypes.string,
      LastName: PropTypes.string,
    })
  ).isRequired,
};

export default SelectSalesAgent;
