import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Check from '../icons/Check';

const ToastSave = ({ message, saveFunction }) => {
  const [closing, setClosing] = useState(false);
  function handleClick() {
    setClosing(true);
    setTimeout(() => {
      saveFunction();
    }, 800);
  }

  return (
    <>
      <button
        type="button"
        className={`save ${closing ? ' closing' : ''}`}
        onClick={handleClick}
      >
        {closing && <Check />}
        {!closing && <>{message}</>}
      </button>
    </>
  );
};

ToastSave.propTypes = {
  saveFunction: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ToastSave.defaultProps = {
  message: 'Save Changes',
};

export default ToastSave;
