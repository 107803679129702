import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import MultiBackend, {
  TouchTransition,
  MouseTransition,
} from 'react-dnd-multi-backend';
import { UserProvider } from './components/User';
import { HomesProvider } from './components/Homes';
import { SiteItemsProvider } from './components/SiteItems/SiteItemsProvider';
import { SalesAgentsProvider } from './components/SalesAgents/SalesAgentsProvider';
import { WelcomeHomeProvider } from './components/WelcomeHome/WelcomeHomeProvider';

const HTML5toTouch = {
  backends: [
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true }, // Note that you can call your backends with options
      preview: false,
      transition: TouchTransition,
    },
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
  ],
};

const AppProvider = ({ children, history, history: { push } }) => {
  return (
    <UserProvider push={push}>
      <SalesAgentsProvider history={history}>
        <SiteItemsProvider>
          <WelcomeHomeProvider history={history}>
            <HomesProvider push={push} history={history}>
              <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                {children}
              </DndProvider>
            </HomesProvider>
          </WelcomeHomeProvider>
        </SiteItemsProvider>
      </SalesAgentsProvider>
    </UserProvider>
  );
};

AppProvider.propTypes = {
  history: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default AppProvider;
