import React from 'react';

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#b21f28"
      d="M8.3,5.6c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S8.9,5.6,8.3,5.6z M7.1,12.7h2.3V6.4H7.1
        C7.1,6.4,7.1,12.7,7.1,12.7z M8.1,0.7C3.9,0.6,0.5,4,0.4,8.2s3.3,7.6,7.5,7.7c0.1,0,0.1,0,0.2,0c4.2,0,7.6-3.4,7.6-7.6
        C15.7,4.1,12.3,0.7,8.1,0.7L8.1,0.7z"
    />
  </svg>
);

export default InfoIcon;
