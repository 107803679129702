import React from 'react';
import PropTypes from 'prop-types';
import StyledMultiCheckBox from './MultiCheckBox.Style';
import CheckBox from '../CheckBox/CheckBox';

const MultiCheckBox = ({
  title,
  items,
  changedCallBack,
  asRadioGroup,
  ...props
}) => {
  const handleChange = e => {
    const newItems = [...items];

    const foundItem = newItems.find(function(item) {
      return item.id === e.target.name;
    });

    if (asRadioGroup) {
      newItems.forEach(item => {
        item.checked = false;
      });
    }

    foundItem.checked = e.target.checked;

    changedCallBack(foundItem, newItems);
  };

  const renderItems = () => {
    return items.map(item => (
      <div key={item.id} className="itemContainer">
        <label key={item.id}>
          <CheckBox
            name={item.id}
            checked={item.checked}
            onChange={handleChange}
            borderRadius={asRadioGroup ? '50%' : 'initial'}
            {...props}
          />
          <span>{item.label}</span>
        </label>
      </div>
    ));
  };

  return (
    <StyledMultiCheckBox>
      {title && <h2>{title}</h2>}
      <div className="grid">{renderItems()}</div>
    </StyledMultiCheckBox>
  );
};

MultiCheckBox.propTypes = {
  changedCallBack: PropTypes.func.isRequired,
  title: PropTypes.string,
};

MultiCheckBox.defaultProps = {
  title: null,
};

export default MultiCheckBox;
