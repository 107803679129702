import styled from 'styled-components';

const StyledStagedImages = styled.div`
  flex: 1 1 300px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  @media screen and (min-width: 850px) {
    max-width: 450px;
    margin: 0 30px;
  }
  .staged-image {
    flex: 1 1 100%;
    position: relative;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    border: solid 1px #e4e5e7;
    background-color: #fff;
    padding: 12px 20px;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;
    .image-preview {
      margin-right: 12px;
      margin-bottom: 15px;
      flex: 0 1 90px;
      figure {
        margin: 0;
        border: none;
        height: 75px;
        width: 90px;
        position: relative;
        overflow: hidden;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .edit {
      margin-top: auto;
      margin-left: auto;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.57;
      letter-spacing: 1px;
      color: #0075c9;
      background: none;
      border: none;
      cursor: pointer;
      @media screen and (min-width: 550px) {
        margin-top: 0;
      }
    }

    label {
      font-size: 12px;
      font-weight: 600;
      color: #4e5255;
      .label {
        margin-bottom: 5px;
        .sub {
          color: #777b7e;
        }
      }
      .select-wrapper {
        height: 27px;
        border: 1px solid #e4e5e7;
        border-radius: 2px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-top: 5px solid #0075c9;
          width: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          pointer-events: none;
        }
      }
      select {
        width: 100%;
        -webkit-appearance: none;
        border: none;
        height: 27px;
        padding: 0 12px 0 7px;
        background: #fff;
      }
      &.group {
        flex: 1 auto;
        margin-right: 6px;
        max-width: 35%;
      }
      &.room {
        flex: 1 auto;
        margin-left: 6px;
        margin-right: 5px;
        max-width: 35%;
      }
      &.caption {
        flex: 1 1 100%;
        textarea {
          width: 100%;
          height: 30px;
          border: 1px solid #e4e5e7;
          resize: none;
        }
      }
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px 7px 4px 4px;
      background: none;
      border: 0;
      cursor: pointer;
      svg {
        pointer-events: none;
      }
    }
  }
  .finish-button {
    flex: 1 1 100%;
    border-radius: 2px;
    background: #00b0ac;
    border: none;
    text-transform: uppercase;
    height: 45px;
    font-size: 12px;
    color: #fff;
    max-width: 450px;
    justify-self: center;
    transition: all 0.2s;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      background: #017f93;
    }
  }
`;

export default StyledStagedImages;
