import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import Auth from './Auth';
import PrivateRoute from './components/PrivateRoute';

export const history = createBrowserHistory();

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/auth" render={props => <Auth {...props} />} />
      <PrivateRoute path="/" component={App} />
    </Switch>
  </BrowserRouter>
);
