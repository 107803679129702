import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FeatureCard from './FeatureCard';

const Root = styled.div`
  /* flexbox */
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 8px;
  transition: background-color 0.2s ease;
  user-select: none;
  flex: 1 1 auto;
  background: ${props =>
    props.isDraggingFrom ? 'rgba(151,212,202,0.34)' : 'none'};
`;

const DropZone = styled.div`
  min-height: 45px;
`;

const FeatureList = ({
  features,
  addFeature,
  newFeatureId,
  setNewFeatureId,
  renameFeature,
  removeFeature,
  reorderFeatures,
}) => {
  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    reorderFeatures(result);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Root>
        <Droppable droppableId="drop-zone">
          {(dropProvided, dropSnapshot) => (
            <Wrapper
              isDraggingOver={dropSnapshot.isDraggingOver}
              isDraggingFrom={!!dropSnapshot.draggingFromThisWith}
              {...dropProvided.droppableProps}
            >
              <div>
                <DropZone ref={dropProvided.innerRef} className="feature-list">
                  {features.map(feature => (
                    <Draggable
                      key={feature.InventoryFeatureId}
                      draggableId={feature.InventoryFeatureId}
                      index={parseInt(feature.SortOrder, 10) - 1}
                    >
                      {draggableProvided => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <FeatureCard
                            id={feature.InventoryFeatureId}
                            key={feature.InventoryFeatureId}
                            name={feature.FeatureDescription}
                            removeFeature={() => {
                              removeFeature(feature);
                            }}
                            renameFeature={name => {
                              renameFeature(name, feature);
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </DropZone>
              </div>
            </Wrapper>
          )}
        </Droppable>
      </Root>
      {features.length < 10 && (
        <FeatureCard
          name="" // intentionally left blank
          draggable={false}
          addFeature={name => {
            addFeature({
              FeatureDescription: name,
              InventoryFeatureId: newFeatureId,
              SortOrder: 11, // ensure new feature is last, gets set correctly in reducer
            });
            setNewFeatureId(newFeatureId - 1);
          }}
        />
      )}
    </DragDropContext>
  );
};

FeatureList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape()),
  newFeatureId: PropTypes.number.isRequired,
  setNewFeatureId: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired,
  removeFeature: PropTypes.func.isRequired,
  renameFeature: PropTypes.func.isRequired,
  reorderFeatures: PropTypes.func.isRequired,
};

FeatureList.defaultProps = {
  features: [],
};

export default React.memo(FeatureList);
