import styled from 'styled-components';

const StyledTabs = styled.section`
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
  h1 {
    font-size: 25px;
    color: #25292d;
    font-weight: 600;
    padding-left: 16px;
    @media screen and (min-width: 1024px) {
      padding-left: 0px;
    }
  }
  .top-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-top: 20px;
    a {
      font-weight: 600;
      color: #9b9b9b;
      font-size: 12px;
      text-decoration: none;
      flex: 0 1 auto;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.57;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
    }
    .back-button {
      color: #777b7e;
      letter-spacing: 0.5px;
      text-decoration: none;
      margin-right: auto;
      svg {
        transform: rotate(180deg);
        margin-right: 15px;
      }
    }

    .view-link {
      height: 33px;
      border-radius: 2px;
      border: 1px solid #e4e5e7;
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: 0 10px 0 15px;
      box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
      svg {
        padding-left: 30px;
      }
    }
  }

  .view-live-site {
    margin-top: 25px;
    margin-right: 2%;
    margin-left: auto;
    font-size: 12px !important;
    max-width: 145px;
    border-radius: 2px;
    border: 1px solid #e4e5e7;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 3px !important;

    @media screen and (max-width: 767px) {
      display: none;
    }

    svg {
      padding-left: 30px;
      position: relative;
      top: 4px;
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    a {
      font-weight: 400;
      color: #9b9b9b;
      font-size: 14px;
      text-decoration: none;
      flex: 1;
      text-align: center;
      padding-bottom: 12px;
      text-transform: uppercase;
      line-height: 1.57;
      letter-spacing: 1px;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .nav-select-bar-container {
    flex: 1 1 100%;
  }
  .selected-bar {
    width: ${props =>
      props.links.length > 0 ? (1 / props.links.length) * 100 : 100}%;

    transform: ${props => {
      return `translateX(${Number(100 * props.pathIndex)}%)`;
    }};
    height: 2px;
    background-color: #0075c9;
    transition: transform ease-out 150ms, width ease-out 150ms;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 100px;
    h1 {
      margin: 0 0 20px;
    }
    h1 + .links {
      position: relative;
      width: auto;
      display: inline-flex;
      flex-wrap: wrap;
      a {
        flex: 0 1 141px;
        min-width: 141px;
        &:not(:first-child) {
          margin-left: 25px;
        }
      }
      .selected-bar {
        width: 141px;
        transform: ${props =>
          `translateX(${Number(
            141 * props.pathIndex + props.pathIndex * 25
          )}px)`};
      }
    }
    .top-links {
      max-width: 894px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export default StyledTabs;
