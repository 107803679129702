import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import InstallBannerIos from './InstallBannerIos';

const OnDisplayList = () => {
  useEffect(() => {
    if (!shouldShowInstallMessage()) return;

    toast.info(<InstallBannerIos />, {
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
      draggable: false,
      closeButton: true,
      closeOnClick: false,
      toastId: 'installBannerToast',
      className: 'install-banner',
    });
  }, []);

  const shouldShowInstallMessage = () => {
    // Detects if device is on iOS (currently android/chrome automatically shows a banner)
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    return isIos() && !isInStandaloneMode();
  };

  return null;
};

export default OnDisplayList;
