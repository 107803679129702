const editImageModalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_IMAGE':
      return {
        ...state,
        initialImage: action.payload,
      };
    case 'UPDATE_GROUP':
      return {
        ...state,
        stagedImage: {
          ...state.stagedImage,
          ImageTypeAcronym: action.payload,
        },
      };
    case 'UPDATE_ROOM':
      return {
        ...state,
        stagedImage: {
          ...state.stagedImage,
          RoomId: action.payload.Id,
          RoomDescription: action.payload.Description,
        },
      };
    case 'UPDATE_CAPTION':
      return {
        ...state,
        stagedImage: {
          ...state.stagedImage,
          Caption: action.payload,
        },
      };
    case 'RESET_STAGED_DETAILS':
      return {
        ...state,
        stagedImage: { ...state.initialImage },
      };
    case 'SET_SHOW_SAVE_MODAL':
      return {
        ...state,
        showSaveModal: action.payload,
      };
    default:
      return { ...state };
  }
};

export default editImageModalReducer;
