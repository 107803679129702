import React from 'react';
import StyledNoImagesAvailable from './NoImagesAvailable.Style';
import HomeSvg from '../HomeSvg';

const NoImagesAvailable = ({ message }) => (
  <StyledNoImagesAvailable>
    <div className="image-wrapper">
      <HomeSvg />
    </div>
    <p className="message">
      <strong>No images have been uploaded.</strong>
      {message}
    </p>
  </StyledNoImagesAvailable>
);

export default NoImagesAvailable;
