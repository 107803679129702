import styled from 'styled-components';

const DeleteAgentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  button {
    background: #9e1a1a;
    height: 45px;
    box-shadow: 0 9px 18px -12px #10a29f;
    border: solid 1px var(--kiddie-pool);
    color: white;
    border-radius: 2px;
    width: 300px;
    margin: 50px auto 0;
    font: 600 14px/100% source-sans-pro;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default DeleteAgentWrapper;
