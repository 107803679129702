import React from 'react';
import PropTypes from 'prop-types';
import StyledNotification from './Notification.Style';
import AlertIcon from '../icons/AlertIcon';

const Notification = ({ type, message, action }) => (
  <StyledNotification className={`${type}`}>
    <span className="message">
      {type === 'error' && <AlertIcon />}
      {message}
      {type === 'error' && ' 🙁'}
    </span>
    <div className="action">{action}</div>
  </StyledNotification>
);

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  action: PropTypes.node,
};

Notification.defaultProps = {
  type: 'error',
  action: <></>,
};

export default Notification;
