import React from 'react';
import StyledLoadingDots from './LoadingDots.Style';

const LoadingDots = ({ className }) => (
  <StyledLoadingDots className={className}>
    <div className="loader">
      <div className="loading-bubbles">
        <div className="bubble-container">
          <div className="bubble" />
        </div>
        <div className="bubble-container">
          <div className="bubble" />
        </div>
        <div className="bubble-container">
          <div className="bubble" />
        </div>
      </div>
    </div>
  </StyledLoadingDots>
);

export default LoadingDots;
