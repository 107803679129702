import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Hideable = ({ children, className = '', hidden = true }) => {
  return (
    <div className={className} style={{ display: hidden ? 'block' : 'none' }}>
      {children}
    </div>
  );
};

Hideable.propTypes = {
  children: PropTypes.node.isRequired,
};

Hideable.defaultProps = {};

export default Hideable;
