import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledCalendarModal from './CalendarModal.Style';
import DatePicker from '../DatePicker/DatePicker';

const CalendarModal = ({
  closeCallback,
  date,
  setDate,
  mode,
  allowNeverExpires = false,
}) => {
  const today = new Date();
  const expires = date;
  const neverExpires =
    date === null || expires.getFullYear() - today.getFullYear() > 50;
  const [showCalendar, setShowCalendar] = useState(
    allowNeverExpires ? false : true
  );
  const handleChange = newDate => {
    if (newDate.getFullYear() - today.getFullYear() > 50) {
      setDate(null);
    } else {
      setDate(newDate);
    }
  };

  useEffect(() => {
    setShowCalendar(allowNeverExpires === false || date !== null);
  }, [date]);

  return (
    <StyledCalendarModal>
      <div role="presentation" className="overlay" onClick={closeCallback} />
      <div className="modal">
        <p className="header">
          {mode === 'endDate'
            ? 'When should this post expire?'
            : 'When should this post be published?'}
        </p>
        <div className="radioButtonButtons">
          {mode === 'endDate' && allowNeverExpires && (
            <button
              type="button"
              onClick={() => {
                setDate(null);
                setShowCalendar(false);
              }}
              className={showCalendar ? '' : 'active'}
            >
              {mode === 'endDate' ? 'Never' : 'Immediately'}
            </button>
          )}
          {allowNeverExpires && (
            <button
              type="button"
              onClick={() => {
                if (neverExpires) {
                  setDate(today);
                }
                setShowCalendar(true);
              }}
              className={showCalendar ? 'active' : ''}
            >
              On a specific date
            </button>
          )}
        </div>
        {showCalendar && <DatePicker onChange={handleChange} value={expires} />}
        <button type="button" className="done" onClick={closeCallback}>
          Done
        </button>
      </div>
    </StyledCalendarModal>
  );
};

CalendarModal.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  date: PropTypes.shape(),
  setDate: PropTypes.func.isRequired,
};

CalendarModal.defaultProps = {
  date: null,
};

export default CalendarModal;
