import React from 'react';

const ShareIcon = props => (
  <svg
    enable-background="new 0 0 50 50"
    height="50px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    width="50px"
    {...props}
  >
    <polyline
      fill="none"
      points="17,10 25,2 33,10   "
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <line
      fill="none"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      x1="25"
      x2="25"
      y1="32"
      y2="2.333"
    />
    <rect fill="none" height="50" width="50" />
    <path
      d="M17,17H8v32h34V17h-9"
      fill="none"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
);

export default ShareIcon;
