import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserInfo from './UserInfo';
import NavIcon from '../icons/NavIcon';
import SideBarStyled from './Navigation.Style';
import PosedLinkBg from './NavigationLinkBg.Pose';
import PosedSideBarButton from './SideBarButton.Pose';
import { HELP_DOCUMENT_URL } from '../constants';
import { UserContext } from './User';
import { canViewLandRecords } from '../permissions';
import LoadingDots from './LoadingDots';

const Navigation = ({ location, collapsed, setCollapsed }) => {
  const [showToTopButton, setShowToTopButton] = useState(false);

  const {
    signout,
    user: { IsCorporateOfficeUser },
    activeLot,
    isLoading,
    isError,
  } = useContext(UserContext);

  const [ticking, setTicking] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollHandler = useCallback(() => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (window.scrollY > 1500) {
          setShowToTopButton(true);
        }
        if (window.scrollY < 1500) {
          setShowToTopButton(false);
        }
        setTicking(false);
      });

      setTicking(true);
    }
  }, [ticking]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SideBarStyled className="navigation" collapsed={collapsed}>
      <div
        role="presentation"
        className="nav-overlay"
        onClick={toggleSidebar}
      />
      <div className={'side-bar' + (isLoading || isError ? ' disabled' : '')}>
        <UserInfo />

        <h2 className="side-bar-header">Manage Inventory</h2>
        <Link
          to="/homes/on-display"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={
              location.pathname.includes('on-display') ? 'active' : 'inactive'
            }
          >
            <p className="link-text">On Display</p>
          </PosedLinkBg>
        </Link>
        <Link
          to="/homes/available-floor-plans"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={
              location.pathname.includes('available') ? 'active' : 'inactive'
            }
          >
            <p className="link-text">Available</p>
          </PosedLinkBg>
        </Link>
        <Link
          to="/homes/sale"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={location.pathname.includes('sale') ? 'active' : 'inactive'}
          >
            <p className="link-text">Sale</p>
          </PosedLinkBg>
        </Link>
        {activeLot && canViewLandRecords(activeLot.LotNumber) && (
          <Link
            to="/homes/land"
            onClick={() => {
              setCollapsed(true);
            }}
          >
            <PosedLinkBg
              className="bg-animate"
              pose={location.pathname.includes('land') ? 'active' : 'inactive'}
            >
              <p className="link-text">Land</p>
            </PosedLinkBg>
          </Link>
        )}
        <h2 className="side-bar-header">Manage Content</h2>

        <Link
          to="/content/welcome-home"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={
              location.pathname.includes('welcome-home') ? 'active' : 'inactive'
            }
          >
            <p className="link-text">Welcome Home</p>
          </PosedLinkBg>
        </Link>
        <Link
          to="/content/news"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={location.pathname.includes('news') ? 'active' : 'inactive'}
          >
            <p className="link-text">News</p>
          </PosedLinkBg>
        </Link>
        <Link
          to="/content/events"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={
              location.pathname.includes('events') &&
              !location.pathname.includes('bulk')
                ? 'active'
                : 'inactive'
            }
          >
            <p className="link-text">Events</p>
          </PosedLinkBg>
        </Link>
        {IsCorporateOfficeUser && (
          <Link
            to="/content/events/new/bulk"
            onClick={() => {
              setCollapsed(true);
            }}
          >
            <PosedLinkBg
              className="bg-animate"
              pose={location.pathname.includes('bulk') ? 'active' : 'inactive'}
            >
              <p className="link-text">Multi-Location Event</p>
            </PosedLinkBg>
          </Link>
        )}
        <Link
          to="/profile"
          onClick={() => {
            setCollapsed(true);
          }}
        >
          <PosedLinkBg
            className="bg-animate"
            pose={location.pathname.includes('profile') ? 'active' : 'inactive'}
          >
            <p className="link-text">
              Profile{IsCorporateOfficeUser ? 's' : ''}
            </p>
          </PosedLinkBg>
        </Link>
        <div className="bottom-links">
          {IsCorporateOfficeUser && (
            <Link
              to="/settings"
              onClick={() => {
                setCollapsed(true);
              }}
            >
              <PosedLinkBg
                className="bg-animate"
                pose={
                  location.pathname.includes('settings') ? 'active' : 'inactive'
                }
              >
                <p className="link-text">Settings</p>
              </PosedLinkBg>
            </Link>
          )}
          <a href={HELP_DOCUMENT_URL} target="_blank" rel="noopener noreferrer">
            <PosedLinkBg className="bg-animate" pose="inactive">
              <p className="link-text">Help</p>
            </PosedLinkBg>
          </a>
          <button type="button" onClick={signout} className="signoutLink">
            <PosedLinkBg className="bg-animate" pose="inactive">
              <p className="link-text">Logout</p>
            </PosedLinkBg>
          </button>
        </div>
      </div>
      <PosedSideBarButton
        onClick={scrollToTop}
        className={`side-bar-button side-bar-button-top${
          showToTopButton ? '' : ' hidden'
        }`}
      >
        <svg viewBox="0 0 100 100">
          <path
            d="M 30 70 L 50 50 M 50 50 L 70 70"
            stroke="#ffffff"
            strokeWidth="9"
            strokeLinecap="square"
          />
        </svg>
        <span>Top</span>
      </PosedSideBarButton>
      <PosedSideBarButton onClick={toggleSidebar} className="side-bar-button">
        <NavIcon open={!collapsed} />
      </PosedSideBarButton>
    </SideBarStyled>
  );
};

Navigation.propTypes = {
  location: PropTypes.shape().isRequired,
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default Navigation;
