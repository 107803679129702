import styled from 'styled-components';

const StyledSpecs = styled.div.attrs({ className: 'specs' })`
  .card {
    display: flex;
    flex-flow: row wrap;
    color: #4e5255;
    font-size: 14px;
    align-items: center;
    .info {
      flex: 1 1 55%;
      margin-right: auto;

      @media screen and (min-width: 320px) {
        min-width: auto;
      }

      @media screen and (min-width: 768px) {
        min-width: 250px;
      }
    }
  }
  .land-card {
    color: #4e5255;
    font-size: 14px;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    background: white;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    padding: 30px;
    flex: 1;
    margin-bottom: 50px;

    .land-info {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;

      @media screen and (min-width: 320px) {
        min-width: auto;
      }

      @media screen and (min-width: 768px) {
        min-width: 250px;
      }

      .name-wrapper {
        flex: 0 0 45%;

        @media screen and (min-width: 768px) {
          flex: 0 0 20%;
        }

        &:first-of-type {
          flex: 0 0 100%;

          @media screen and (min-width: 768px) {
            flex: 0 0 40%;
          }
        }

        &:nth-of-type(3n) {
          @media screen and (min-width: 768px) {
            flex: 0 0 10%;
          }
        }

        &:nth-of-type(n + 6) {
          flex: 0 0 100%;
          @media screen and (min-width: 768px) {
            flex: 0 0 25%;
          }
        }

        &:nth-of-type(8n) {
          @media screen and (min-width: 768px) {
            flex: 0 0 20%;
          }
        }
      }

      input {
        max-width: none;
      }
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.21;
      color: #4e5255;
    }

    .brokerage-disclaimer {
      padding: 20px 0;
    }
  }

  .name-wrapper {
    margin-bottom: 10px;
    label {
      display: flex;
      flex-flow: row wrap;

      &:nth-of-type(2n) {
        margin-top: 20px;
      }
      .label {
        flex: 1 1 100%;
      }
    }
    input {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 44px;
      width: 100%;
      max-width: 278px;
      padding: 0 10px;
      display: block;
      border-radius: 1px;
      border: solid 1px #e4e5e7;
      background-color: #ffffff;
    }
    .name {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 44px;
      display: block;
    }
  }

  .description-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    label {
      display: flex;
      flex-flow: row wrap;
      .label {
        flex: 1 1 100%;
      }
      .sub-label {
        font-size: 11px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.21;
        color: #4e5255;
        max-width: 278px;
        width: 100%;
      }
    }
    textarea {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 100px;
      width: 100%;
      max-width: 278px;
      padding: 0 10px;
      display: block;
      border-radius: 1px;
      border: solid 1px #e4e5e7;
      background-color: #ffffff;

      &:disabled:hover {
        cursor: not-allowed;
      }
    }
    .character-count {
      font-size: 13px;
      color: #4e5255;
      padding: 0;
      margin: 0;
    }
  }

  .description-box-container {
    margin-top: 5px;
    max-width: 160px;

    .description-label {
      margin-left: 5px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  .tags,
  .details {
    display: inline-block;
    margin: 10px 0;
  }
  .details {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    span {
      display: inline-block;
      margin: 0 4px;
      font-weight: 900;
      font-size: 18px;
    }
  }
  .tag {
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    padding: 5px 10px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    display: inline-block;
    margin-right: 10px;
  }
  .numbers {
    flex: 0 1 45%;
    min-width: 250px;
    margin-top: 30px;

    @media screen and (min-width: 320px) {
      min-width: 220px;
    }

    @media screen and (min-width: 768px) {
      min-width: 250px;
    }

    .number {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      color: #777b7e;
      .label {
        display: inline-block;
        margin-right: 10px;
        user-select: none;
      }
    }
  }
`;

export default StyledSpecs;
