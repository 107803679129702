// FormPrompt.js

import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

export const FormPrompt = ({ hasUnsavedChanges }) => {
  useEffect(() => {
    const onBeforeUnload = e => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <Prompt
      when={hasUnsavedChanges}
      message={'You have unsaved changes, are you sure you want to leave?'}
    />
  );
};
