import styled from 'styled-components';

const StyledSiteItemList = styled.div`
  padding: 0 20px 20px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    padding: 0 100px 50px;
  }

  .styled-select {
    border-radius: 1px;
    border: 1px solid #e4e5e7;
    font-size: 15px;
    line-height: 140%;
    font-family: source-sans-pro, sans-serif;
    flex: 0 1 180px;
    padding: 15px 25px 15px 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-top: 0px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-color: white;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    color: #4e5255;
    margin-left: 5px;
    @media screen and (max-width: 600px) {
      padding: 15px 15px 15px 3px;
      font-size: 12px;
    }
  }

  .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 15px;
    @media screen and (max-width: 600px) {
      left: 5px;
    }
  }

  .styled-input {
    border: 1px solid #e4e5e7;
    border-radius: 1px;
    font: 400 15px/140% source-sans-pro;
    color: #4e5255;
    padding: 15px 15px 15px 38px;
    /* width: 206px; */
    margin-top: 0px;
    box-sizing: border-box;
    background-color: white;
    &::placeholder {
      color: #4e5255;
    }
    @media screen and (max-width: 600px) {
      font-size: 12px;
      padding: 15px 5px 15px 20px;
    }
  }

  .filter-section {
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .search-wrap {
    flex: 1;
    min-width: 250px;
    position: relative;
    @media screen and (min-width: 1400px) {
      max-width: 400px;
    }
    @media screen and (max-width: 600px) {
      min-width: 140px;
    }
    input {
      width: 100%;
    }
  }

  .image-library-list {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(4, minmax(250px, 1fr));
    gap: 30px;
    @media screen and (max-width: 1570px) {
      grid-template-columns: repeat(3, minmax(250px, 1fr));
    }

    @media screen and (max-width: 1330px) {
      grid-template-columns: repeat(2, minmax(130px, 1fr));
      gap: 20px;
    }
  }

  .add-button {
    margin-bottom: 30px;
  }

  .error-wrap {
    height: 16px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    min-height: 18px;
    min-width: 1px;
    span {
      margin: 0;
      color: #b21f28;
      font-size: 12px;
      font-weight: normal;
    }
    svg {
      margin-right: 7px;
      height: 14px;
      width: 14px;
    }
  }

  .no-results {
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 1.2rem;
      color: #4e5255;
    }
  }

  .archived-images-intro {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 140px;
    flex-wrap: wrap;
    border-top: 1px solid #000;
    padding-top: 35px;
    .content {
      flex: 1 0 360px;
      max-width: 550px;
      padding-right: 20px;
      @media screen and (max-width: 600px) {
        max-width: 100%;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: normal;
      color: #25292d;
      margin-bottom: 15px;
    }
    .active-image-p {
      max-width: 560px;
    }
    p {
      font-size: 15px;
      line-height: 1.4;
      color: #4e5255;
      margin: 0 0 10px;
      padding: 0;
    }
  }
`;

export default StyledSiteItemList;
