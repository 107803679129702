import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledAutoComplete from './AutoComplete.Style';
import useFocus from '../hooks/useFocus';

const filterEntries = (query, entries) => {
  if (!Array.isArray(entries) || !query) {
    return [];
  }

  return entries.filter(val => {
    return (
      val.LotNumber === parseInt(query, 10) ||
      String(val.Name)
        .toLocaleLowerCase()
        .indexOf(query.toLocaleLowerCase()) >= 0
    );
  });
};

const AutoComplete = ({
  entries,
  onSelect,
  defaultValue,
  placeHolder = '',
  clearOnSelect = false,
}) => {
  const [query, setQuery] = useState(defaultValue);
  const [filteredEntries, setFilteredEntries] = useState(null);
  const [focusRef, setHasFocus, hasFocus] = useFocus();

  const handleChange = event => {
    setQuery(event.target.value);
    if (event.target.value !== '') {
      setFilteredEntries(filterEntries(event.target.value, entries));
    } else {
      setFilteredEntries([]);
    }
  };

  const handleSelect = index => {
    setFilteredEntries(null);
    onSelect(index);
    if (clearOnSelect) setQuery('');
  };

  return (
    <StyledAutoComplete ref={focusRef}>
      <input
        disabled={!entries.length}
        className="search"
        type="text"
        value={query}
        placeholder={placeHolder}
        onChange={handleChange}
        onFocus={({ currentTarget }) => {
          // TODO test this a lot
          if (!hasFocus) {
            setHasFocus(true);
            currentTarget.select();
          }
        }}
      />
      {hasFocus && query.length > 0 && filteredEntries && (
        <div className="suggestions">
          {filteredEntries.length === 0 && <div>No homes were found.</div>}
          {filteredEntries.map(entry => (
            <div
              role="menu"
              tabIndex="0"
              className="suggestion"
              key={entry.LotNumber}
              onClick={({ target }) => {
                setQuery(entry.Name);
                handleSelect(entry.LotNumber);
                target.blur();
              }}
              onKeyPress={({ charCode, target }) => {
                // Enter or Space
                if (charCode === 13 || charCode === 32) {
                  target.click();
                }
              }}
            >
              {entry.Name}
            </div>
          ))}
        </div>
      )}
    </StyledAutoComplete>
  );
};

AutoComplete.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      LotNumber: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Url: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

AutoComplete.defaultProps = {
  defaultValue: '',
};

export default AutoComplete;
