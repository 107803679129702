import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';
import welcomeHomeReducer from '../../reducers/welcomeHomeItems';
import StyledActiveImages from './ActiveImages.Style';
import ActiveImageCard from './ActiveImageCard';
import { reorderArray } from '../../utils';
import { reorderActiveImage } from '../../Api';
import { UserContext } from '../User';
import ToastSave from '../ToastSave';
import { SAVE_TOAST_OPTIONS, ERROR_TOAST_OPTIONS } from '../../constants';
import Modal from '../Modal';
import { WelcomeHomeContext } from '../WelcomeHome/WelcomeHomeProvider';
import ProgressBar from './../ProgressBar';

const ActiveImages = ({ aImages, orgOrder, history }) => {
  const [state, dispatch] = useReducer(welcomeHomeReducer, {
    activeImages: [],
    originalOrder: [],
    isLoading: true,
    isUpdating: false,
  });
  const { activeImages, originalOrder, isLoading, isUpdating } = state;

  const {
    reorderActiveImages,
    moveActiveImage,
    discardActiveImageChanges,
    saveActiveImageChanges,
    toggleActiveImage,
  } = useContext(WelcomeHomeContext);

  const { activeLot } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);

  useEffect(() => {
    dispatch({
      type: 'INIT_WELCOME_HOME_ACTIVE',
      payload: aImages,
    });
  }, [aImages]);

  useEffect(() => {
    dispatch({
      type: 'INIT_WELCOME_HOME_ACTIVE_ORIGINAL',
      payload: orgOrder,
    });
  }, [orgOrder]);

  const moveCard = (startIndex, endIndex) => {
    let rArr = reorderArray(activeImages, startIndex, endIndex);
    moveActiveImage(rArr);
  };
  const imagesChanged = useMemo(() => {
    let arr1;
    let arr2;
    let isEq;

    arr1 = activeImages.map(obj => obj.WelcomeHomeItemId);
    arr2 = originalOrder.map(obj => obj.WelcomeHomeItemId);
    isEq = isEqual(arr1, arr2);

    return !isEq;
  }, [moveCard]);

  const reorderSaveFunction = useCallback(() => {
    let reorderedArray;

    reorderedArray = activeImages.map(obj => obj.WelcomeHomeItemId);
    reorderActiveImages(reorderedArray);
  });

  useEffect(() => {
    if (!isLoading && imagesChanged && !showModal && !isUpdating) {
      if (!toast.isActive('saveToast')) {
        toast.info(
          <ToastSave saveFunction={reorderSaveFunction} />,
          SAVE_TOAST_OPTIONS
        );
      } else {
        toast.update('saveToast', {
          render: <ToastSave saveFunction={reorderSaveFunction} />,
        });
      }
    } else if (!imagesChanged && toast.isActive('saveToast')) {
      toast.dismiss('saveToast');
    }
    return () => {
      if (!imagesChanged && toast.isActive('saveToast')) {
        toast.dismiss('saveToast');
      }
    };
  }, [
    isLoading,
    imagesChanged,
    activeImages,
    showModal,
    isUpdating,
    reorderSaveFunction,
  ]);

  const toggleModal = useCallback(
    location => {
      toast.dismiss();
      setShowModal(!showModal);
      setLastLocation(location);
    },
    [showModal]
  );

  const handleConfirmNavigationClick = () => {
    // reorderSaveFunction();
    saveActiveImageChanges(activeImages);
    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 10);
  };

  const handleDiscardNavigationClick = () => {
    discardActiveImageChanges(originalOrder);

    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 0);
  };

  // Block navigation if changes have been made, and prompt users to save or discard
  useEffect(() => {
    const unblock = history.block(nextLocation => {
      toggleModal(nextLocation);
      return false;
    });
    if (!imagesChanged) {
      unblock();
    }
    return () => {
      unblock();
    };
  }, [imagesChanged, history, toggleModal]);

  return (
    <StyledActiveImages>
      <div className="images-wrapper">
        {activeImages.length > 0 && <h2>Home Page Images</h2>}
        <p className="active-image-p">
          Select 8 Welcome Home images to display on your website's home page.
          If 8 are not selected, your home page will continue to display the
          default Welcome Home images.
        </p>
        <ProgressBar max={8} current={activeImages.length} />
        {activeImages.length > 0 && (
          <>
            <div className="cards-wrapper">
              {activeImages.map((item, index) => {
                return (
                  <div className="card-wrap">
                    <ActiveImageCard
                      index={index}
                      toggleActiveImage={toggleActiveImage}
                      image={item.ImageReference}
                      firstName={item.FirstName}
                      lastName={item.LastName}
                      name={item.Name}
                      number={index + 1}
                      active={item.Active}
                      welcomeHomeItemId={item.WelcomeHomeItemId}
                      moveCard={moveCard}
                      key={item.WelcomeHomeItemId}
                      saveFunction={reorderSaveFunction}
                      isUpdating={isUpdating}
                      isLoading={isLoading}
                    />
                  </div>
                );
              })}
            </div>
            <p className="image-grid-intro">
              The grid below illustrates the layout of Welcome Home images on
              your website's home page.
            </p>
            <div className="image-grid">
              <div className="box box-1">
                <p>1</p>
              </div>
              <div className="box box-2">
                <p>2</p>
              </div>
              <div className="box box-3">
                <p>3</p>
              </div>
              <div className="box box-4">
                <p>4</p>
              </div>
              <div className="box box-5">
                <p>5</p>
              </div>
              <div className="box box-6">
                <p>6</p>
              </div>
              <div className="box box-7">
                <p>7</p>
              </div>
              <div className="box box-8">
                <p>8</p>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        modalHeadline="Save changes?"
        modalBody="If you don’t save your changes, any changes you’ve made to the order of your active images will be undone."
        closeCopy="DISCARD"
        saveCopy="SAVE"
        show={showModal}
        closeCallback={toggleModal}
        saveCallback={handleConfirmNavigationClick}
        discardCallback={handleDiscardNavigationClick}
      />
    </StyledActiveImages>
  );
};

ActiveImages.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ActiveImages;
