import React, { useReducer, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './imageUtils';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import StyledImageEditor from './ImageEditor.Style';

const ImageEditor = ({
  imageData,
  imageFileNameOverride,
  onDoneEditing,
  onCancelEditing,
  aspect,
  hideCancel,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log('imageeditor->oncropcomplete');
    setCroppedAreaPixels(croppedAreaPixels);
    // this triggers doneEditingImage via useCallback
  }, []);

  const cancelEditingImage = useCallback(() => {
    // close the dialog
    onCancelEditing();
  }, []);

  const doneEditingImage = useCallback(async () => {
    console.log('imageEditor -> doneEditingImage');
    try {
      const croppedImage = await getCroppedImg(
        imageData,
        imageFileNameOverride,
        croppedAreaPixels,
        rotation
      );

      onDoneEditing(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <StyledImageEditor>
      <div className="overlay" />
      <div className="edit-image-container">
        <div className="cropper-container">
          <Cropper
            image={imageData}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            minZoom={0.5}
            restrictPosition={false}
            aspect={aspect || 3 / 2}
            objectFit="contain"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            showGrid={true}
          />
        </div>

        <div className="btns-container">
          <div className="buttons">
            <button
              type="button"
              className={'cancel-button' + hideCancel ? 'hide' : ''}
              onClick={cancelEditingImage}
            >
              CANCEL
            </button>

            <div>
              <label htmlFor="rotation">Rotate</label>
              <Slider
                name="rotation"
                value={rotation}
                min={0}
                max={360}
                step={22.5}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <div>
              <label htmlFor="zoom">Zoom</label>
              <Slider
                name="zoom"
                value={zoom}
                min={0.5}
                max={3}
                step={0.01}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <button
              type="button"
              className={isSaving ? 'save-button loading-btn' : 'save-button'}
              onClick={e => {
                setIsSaving(true);
                doneEditingImage();
              }}
            >
              SAVE CHANGES
            </button>
          </div>
        </div>
      </div>
    </StyledImageEditor>
  );
};

ImageEditor.propTypes = {
  imageData: PropTypes.string,
  imageFile: PropTypes.any,
  onCancel: PropTypes.func,
  onDoneEditing: PropTypes.func.isRequired,
};

ImageEditor.defaultProps = {};

export default ImageEditor;
