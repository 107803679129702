import styled from 'styled-components';

const StyledLoadingDots = styled.div`
  min-width: 100%;
  flex: 1 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .loader {
    margin-top: -10%;
    animation-play-state: running;
  }
  .loading-bubbles {
    margin: auto;
  }
  .bubble-container {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    .bubble {
      border-radius: 50%;
      background-color: #0075c9;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      animation: bubble 1.2s -0.6s infinite ease-out;
    }
    &:nth-of-type(2n) {
      .bubble {
        animation-delay: -0.3s;
      }
    }
    &:nth-of-type(3n) {
      .bubble {
        animation-delay: 0s;
      }
    }
  }
  @keyframes bubble {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
`;

export default StyledLoadingDots;
