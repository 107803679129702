import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../../icons/CloseButton';
import { IMAGE_TYPES } from '../../constants';

export default function StagedLandImage(props) {
  const { image, removeImage, index, updateImage, handleEditImage } = props;

  const { ImageId } = image;

  return (
    <div className="staged-image" id={`image-${index}`}>
      <div className="image-preview">
        <figure className="image-wrapper">
          <img src={image.uri} alt={`preview-${index}`} />
        </figure>
        <button
          type="button"
          className="edit"
          onClick={() => handleEditImage(image)}
        >
          Adjust
        </button>
      </div>

      <label className="caption" htmlFor={`image-${index}-caption`}>
        <div className="label">
          Caption <span className="sub"> - Optional</span>
        </div>
        <textarea
          name={`image-${index}-caption`}
          id={`image-${index}-caption`}
          value={image.caption}
          onChange={event => {
            const newImage = { ...image, caption: event.target.value };
            updateImage(index, newImage);
          }}
        />
      </label>
      <button
        type="button"
        className="close-button"
        onClick={() => {
          removeImage(index);
        }}
      >
        <CloseButton />
      </button>
    </div>
  );
}

StagedLandImage.propTypes = {
  image: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  }).isRequired,
  removeImage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  updateImage: PropTypes.func.isRequired,
};
