import React, { useState } from 'react';
import Check from '../../icons/Check';
import ShareIcon from '../../icons/ShareIcon';
import StyledInstallBannerIos from './InstallBannerIos.Style';

const InstallBannerIos = () => {
  const [closing, setClosing] = useState(false);

  function handleClick() {
    setClosing(true);
  }

  return (
    <StyledInstallBannerIos>
      <button
        type="button"
        className={`save ${closing ? ' closing' : ''}`}
        onClick={handleClick}
      >
        {closing && <Check />}
        {!closing && (
          <>
            <div>Install this app on your device.</div>
            <p>
              Tap the share button
              <ShareIcon className="share-icon" /> and then
            </p>
            <div>Add to homescreen</div>
          </>
        )}
      </button>
    </StyledInstallBannerIos>
  );
};

export default InstallBannerIos;
