import styled from 'styled-components';

const StyledSaleHomeListingCard = styled.div`
  width: 100%;

  .card {
    box-sizing: border-box;
    position: relative;
    color: #25292d;
    margin-bottom: 18px;
  }

  .card-body {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    position: relative;
    min-height: 85px;
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
      min-height: 130px;
      padding: 20px 0 25px;
    }
  }

  .left-area {
    display: flex;
    flex: 0 1 100%;
    flex-wrap: wrap;
    padding: 0 16px;
    @media screen and (min-width: 768px) {
    }
  }

  .right-area {
    flex: 0 1 10%;
    padding: 0 16px;
    margin-left: auto;
  }

  .error {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    &:last-child {
      margin-bottom: 10px;
    }
    svg {
      flex: 0 0 14px;
    }
    span {
      color: #b21f28;
      margin-left: 10px;
    }
    display: block;
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #25292d;
    padding: 0;
    margin: 0 0 7px;
    flex: 1 1 100%;
    @media screen and (min-width: 768px) {
      margin-bottom: 18px;
    }
  }
  .tag {
    font: 600 12px/100% source-sans-pro, sans-serif;
    text-align: center;
    width: 48px;
    padding: 5px 0;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    position: absolute;
    top: 25px;
    left: 100px;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    flex: 1;
    @media screen and (min-width: 768px) {
      /* padding-top: 23px; */
    }
  }

  .home-info-container {
    display: flex;
    flex-wrap: wrap;

    div {
      display: flex;
      margin-right: 25px;
    }

    .available {
      display: flex;
    }

    .labels-container {
      div > p:last-child {
        margin: 0;
      }
      & > div:not(:first-child) {
        display: none;
      }
      @media screen and (min-width: 768px) {
        div {
          display: block;
        }
        & > div:not(:first-child) {
          display: block;
        }
      }
    }
  }

  .arrow-position {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  p {
    color: #777b7e;
    font-size: 14px;
    padding: 0;
    margin: 0 0 5px;
  }

  span {
    margin-right: 7px;
    font-size: 12px;
    color: #4e5255;
    font-weight: 600;
  }
`;

export default StyledSaleHomeListingCard;
