import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledAccordianToggle from './AccordianToggle.Style';
import Chevron from '../icons/ChevronThin';

const AccordianToggle = ({
  title,
  isOpen = false,
  onToggle,
  fillColor,
  className = '',
}) => {
  const handleToggle = () => {
    onToggle();
  };

  return (
    <StyledAccordianToggle className={className}>
      <a onClick={handleToggle}>
        <span className="label accordian-label">
          {title}{' '}
          <Chevron
            className={
              'accordian-icon ' +
              (isOpen ? 'accordian-icon-closed' : 'accordian-icon-open')
            }
            fill={fillColor ? fillColor : '#00B0AC'}
          />
        </span>
      </a>
    </StyledAccordianToggle>
  );
};

AccordianToggle.propTypes = {
  title: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  fillColor: PropTypes.string,
};

export default AccordianToggle;
