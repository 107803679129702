import React, { useContext, useState, useEffect, useRef } from 'react';
import StyledSettings from './Other.Style';
import { FormPrompt } from '../../FormPrompt';
import {
  getWebSiteTemplates,
  getDealer,
  updateContentSiteItem,
  updateDealer,
} from '../../../Api';
import LoadingDots from '../../LoadingDots';
import { UserContext } from '../../User';
import Toggle from '../../Toggle';

const Other = ({ history }) => {
  const [dealer, setDealer] = useState(null);
  const [dealerSnapshot, setDealerSnapshot] = useState(null);
  const [dealerHasChanged, setDealerHasChanged] = useState(false);
  const [templateTypes, setTemplateTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageError, setIsPageError] = useState(false);
  const { activeLot, user } = useContext(UserContext);
  const settingsFormRef = useRef(null);
  const [errors, setErrors] = useState({});

  // corp users only
  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser) {
      history.push('/');
    }
  }, [user]);

  // first time load available web site templates and dealer info
  useEffect(() => {
    if (!activeLot) return;
    (async () => {
      try {
        const dealer = await getDealer(activeLot.LotNumber);
        setDealer(dealer);
        setDealerSnapshot(dealer);
        const templateTypes = await getWebSiteTemplates();
        setTemplateTypes(templateTypes);
        setIsLoading(false);
        setDealerHasChanged(false);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
    })();
  }, [activeLot]);

  // on dealer change, validate
  useEffect(() => {
    if (!dealer) return;

    // enable save button on change
    if (dealerSnapshot != null) {
      setDealerHasChanged(true);
    }

    validateAll();
  }, [dealer]);

  const handleChange = (propName, newValue) => {
    setDealer({ ...dealer, [propName]: newValue });
  };

  const handleSave = () => {
    validateAll();
    if (hasErrors()) return;

    (async () => {
      setIsLoading(true);
      try {
        const response = await updateDealer(activeLot.LotNumber, dealer);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
      setDealerHasChanged(false);
    })();
  };

  const validateAll = () => {
    let newErrors = { ...errors };

    newErrors = {
      ...newErrors,
      ...validateLatLong('Latitude', dealer.Latitude),
    };

    newErrors = {
      ...newErrors,
      ...validateLatLong('Longitude', dealer.Longitude),
    };

    setErrors({ ...errors, ...newErrors });
  };

  const validateLatLong = (propName, val) => {
    const error = val && val != '' ? null : propName + ' is required.';

    return { [propName]: error };
  };

  const hasErrors = () => {
    if (Object.values(errors).length == 0) return false;

    let test = Object.values(errors).every(function(i) {
      return i === null;
    });

    return !test;
  };

  const getError = propName => {
    return errors.hasOwnProperty(propName) && errors[propName]
      ? errors[propName]
      : null;
  };

  return (
    <StyledSettings>
      <FormPrompt hasUnsavedChanges={dealerHasChanged} />

      <div>
        {isLoading && <LoadingDots />}
        {isPageError && (
          <div className="error">
            There was an error loading setttings, please try again later.
          </div>
        )}
        {!isPageError && (
          <form id="settings" ref={settingsFormRef}>
            <div className="settings-item">
              <div className="label">Website Template</div>

              <select
                name="website-template-id"
                value={
                  templateTypes && dealer
                    ? dealer.WebSiteTemplateId || templateTypes[0].Id
                    : ''
                }
                placeholder="Web Site Template"
                onChange={e =>
                  handleChange('WebSiteTemplateId', e.target.value)
                }
                className="styled-select sort-select"
                disabled={isLoading}
              >
                <option value="">Select</option>
                {templateTypes &&
                  templateTypes.map(templateType => {
                    return (
                      <option key={templateType.Id} value={templateType.Id}>
                        {templateType.Name}
                      </option>
                    );
                  })}
              </select>
              {getError('WebSiteTemplateId') && (
                <div className="error">{getError('WebSiteTemplateId')}</div>
              )}
            </div>

            <div className="settings-item">
              <Toggle
                className="short-toggle"
                label="Only display in stock inventory"
                name="is-inventory-only-for-website-homes"
                onToggle={event => {
                  handleChange(
                    'IsInventoryOnlyForWebsiteHomes',
                    !dealer.IsInventoryOnlyForWebsiteHomes
                  );
                }}
                inputProps={{
                  checked: dealer
                    ? dealer.IsInventoryOnlyForWebsiteHomes
                    : false,
                }}
              />
              {getError('IsInventoryOnlyForWebsiteHomes') && (
                <div className="error">
                  {getError('IsInventoryOnlyForWebsiteHomes')}
                </div>
              )}
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="latitude">
                Latitude
                <input
                  type="text"
                  name="latitude"
                  id="latitude"
                  value={dealer ? dealer.Latitude : ''}
                  disabled={isLoading}
                  onChange={e => handleChange('Latitude', e.target.value)}
                />
                {getError('Latitude') && (
                  <div className="error">{getError('Latitude')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="longitude">
                Longitude
                <input
                  type="text"
                  name="longitude"
                  id="longitude"
                  value={dealer ? dealer.Longitude : ''}
                  disabled={isLoading}
                  onChange={e => handleChange('Longitude', e.target.value)}
                />
                {getError('Longitude') && (
                  <div className="error">{getError('Longitude')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <button
                type="button"
                disabled={isLoading || hasErrors() || !dealerHasChanged}
                className="button"
                onClick={handleSave}
              >
                SAVE CHANGES
              </button>
            </div>
          </form>
        )}
      </div>
    </StyledSettings>
  );
};

export default Other;
