import styled from 'styled-components';

const StyledDetails = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  box-sizing: border-box;

  @media screen and (min-width: 320px) {
    max-width: 320px;
    padding: 0 10px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 0 20px;
    max-width: 1200px;
  }

  & > div {
    padding: 0 10px;
  }
  .errors,
  .specs,
  .downloads {
    flex: 1 1 100%;
  }
  .specs,
  .pricing,
  .options,
  .downloads {
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    width: 100%;
  }
  .pricing,
  .options {
    flex: 1 1 45%;
  }
  .errors {
    .label {
      font-size: 16px;
      font-weight: bold;
      color: #4e5255;
      margin-bottom: 5px;
      display: block;
    }
  }
  h2 {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #4e5255;
  }
  .card {
    background: white;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    padding: 30px;
    flex: 1;
    margin-bottom: 50px;
    .label {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.21;
      color: #4e5255;
    }
  }
  @media screen and (min-width: 680px) {
    padding: 0 40px;
  }
  @media screen and (min-width: 1300px) {
    padding: 0 80px;
  }
  @media screen and (min-width: 1440px) {
    .specs {
      flex: 1 1 100%;
    }
    .pricing {
      flex: 1 1 48%;
    }
    .options {
      flex: 1 1 48%;
    }
    .downloads {
      flex: 0 1 48%;
    }
  }

  @media screen and (min-width: 1500px) {
    padding: 0 100px;
  }
`;

export default StyledDetails;
