import styled from 'styled-components';

const StyledActiveImages = styled.div`
  flex: 1;
  .uploader-wrapper {
    margin-top: -32px;
    background: #ffffff;
    padding: 60px 25px 90px;
    @media screen and (min-width: 700px) {
      padding: 60px 100px 90px;
    }
  }
  .bar-wrapper {
    margin-bottom: 0px !important;
    div {
      margin-bottom: 0px !important;
    }
  }
  .images-wrapper {
    /* padding: 30px 25px;
    @media screen and (min-width: 700px) {
      padding: 30px 50px;
    } */
    padding-top: 20px;
    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: normal;
      color: #25292d;
      margin-bottom: 15px;
    }
    .active-image-p {
      max-width: 560px;
    }
    p {
      font-size: 15px;
      line-height: 1.4;
      color: #4e5255;
      margin: 0 0 10px;
      padding: 0;
    }
    .cards-wrapper {
      margin: 0 -10px !important;
    }
    .cards-wrapper,
    .images-list {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-flow: row wrap;
      position: relative;
      z-index: 1;
      flex-flow: column nowrap;
      @media screen and (min-width: 550px) {
        flex-flow: row wrap;
      }
      .card-wrap {
        position: relative;
        padding: 10px;
      }
      .card {
        transition: all 0.2s;
        position: relative;
        margin: 0px !important;
        flex: 1 0 auto;
        background: #ffffff;
        box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
        display: flex;
        flex-flow: row wrap;
        flex-wrap: nowrap;
        touch-action: none;
        @media screen and (min-width: 550px) {
          width: 228px;
        }
        @media screen and (min-width: 768px) {
          padding: 10px;
          min-height: auto;
          width: 300px;
        }

        .draggable-bars {
          width: 17px;
          border: 1px solid #e4e5e7;
          box-sizing: border-box;
          background: #f3f5f7;
          margin: 0px !important;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          &:hover {
            cursor: grab;
          }

          .dots-container {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 3.5px;

            .dot {
              background: #777b7e;
              width: 3.5px;
              height: 3.5px;
              border-radius: 2px;
            }
          }
        }
        .background-card {
          position: absolute;
          top: 5px;
          right: -5px;
          z-index: -1;
          height: 100%;
          width: 100%;
          background: #fff;
          box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
          &:nth-of-type(2) {
            top: 10px;
            right: -10px;
            z-index: -2;
          }
        }
        img {
          object-fit: contain;
          display: block;
          height: 100px;
          width: 145px;
          @media screen and (min-width: 550px) {
            background: #eeeeee;
          }
          @media screen and (max-width: 350px) {
            max-width: 30%;
            margin-right: 15px;
          }
        }
        svg {
          height: 85px;
          width: 120px;
          @media screen and (max-width: 350px) {
            max-width: 30%;
            margin-right: 15px;
          }
        }
        @media screen and (min-width: 550px) {
          /* display: block; */
          flex: 1 1 40%;
          max-width: 250px;
          margin: 0 15px 15px 0;
          /* padding: 15px 15px 0; */
          min-width: unset;
          display: flex;
          padding: 0;
          img {
            object-fit: contain;
            display: block;
            width: 100%;
            height: 175px;
            margin-right: 0;
          }
          svg {
            width: 100%;
            height: 170px;
          }
        }
        @media screen and (min-width: 700px) {
          max-width: 285px;
          flex: 1 1 30%;
          margin: 0 30px 30px 0;
        }
      }
    }
  }
  .image-grid-intro {
    margin: 40px 0px 10px !important;
    display: flex;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    max-width: 520px;
    aspect-ratio: 7 / 2;

    .box {
      background: #c2c2c2;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-weight: 700;
        font-size: 2rem;
        margin: 0px;
      }
    }
  }

  .box-1 {
    grid-area: 1 / 1 / 3 / 3;
  }
  .box-2 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .box-3 {
    grid-area: 1 / 4 / 2 / 5;
  }
  .box-4 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .box-5 {
    grid-area: 2 / 4 / 3 / 5;
  }
  .box-6 {
    grid-area: 1 / 5 / 3 / 7;
  }
  .box-7 {
    grid-area: 1 / 7 / 2 / 8;
  }
  .box-8 {
    grid-area: 2 / 7 / 3 / 8;
  }
`;

export default StyledActiveImages;
