import { toast } from 'react-toastify';

export const IMAGE_TYPES = {
  EXT: 'Exterior',
  INT: 'Interior',
  FEA: 'Feature',
  FLP: 'Floor Plan',
};

export const SAVE_TOAST_OPTIONS = {
  autoClose: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  draggable: false,
  closeButton: false,
  closeOnClick: false,
  toastId: 'saveToast',
  className: 'save-toast',
  progressClassName: 'save-progress-bar',
};

export const ERROR_TOAST_OPTIONS = {
  className: 'error-toast',
  progressClassName: 'error-progress-bar',
  closeButton: false,
  position: toast.POSITION.BOTTOM_RIGHT,
};

export const UNDO_TOAST_OPTIONS = {
  className: 'undo-toast',
  progressClassName: 'undo-progress-bar',
  closeButton: false,
  position: toast.POSITION.BOTTOM_RIGHT,
};

export const HOPE_SHEET_BASE_URL =
  'https://www.origin-clayton-media.com/pdf/retailInventoryinvoice.cfm';

export const MAX_SALE_HOMES_COUNT = 10;

export const STICKER_TYPES = {
  COMING_SOON: -6,
  SALE_HOME: -3,
  NEW_ARRIVAL: -2,
  SOLD: -1,
  BLANK: 0,
  MOVE_IN_READY: 1,
  UPGRADES_INCLUDED: 2,
  GREAT_VALUE: 3,
  QUICK_DELIVERY: 4,
  SALE_PENDING: 5,
  PRICE_DROP: 6,
};

export const HELP_DOCUMENT_URL =
  'https://p.widencdn.net/bslfoz/CMHWebAdmin-UserGuide';

export default {};
