import styled from 'styled-components';

const StyledUploadCard = styled.div`
  flex: 1 1 360px;
  margin: 0 auto 30px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
  border: solid 1px #e4e5e7;
  background-color: #fff;
  padding: 30px 20px;
  text-align: center;
  @media screen and (min-width: 850px) {
    max-width: 400px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 440px;
  }
  @media screen and (min-width: 1268px) {
    margin: 0 auto 0 0;
  }
  .loader .bubble {
    background-color: #0075c9 !important;
  }
  .header {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }
  .label {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #a1a3a6;
    margin-bottom: 15px;
  }
  .buttons {
    display: flex;
    align-items: center;
    label,
    button {
      flex: 1;
    }
  }
  .button {
    background-color: #0075c9;
    line-height: 45px;
    border-radius: 2px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: #003f8c;
    }
  }
  .footer,
  .footer-aspect-ratio-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    color: #777b7e;
    margin-top: 14px;
  }
  form {
    border-radius: 2px;
    @media screen and (min-width: 768px) {
      border: dashed 1px #cacccf;
      background-color: #f3f5f7;
      padding: 60px 85px 70px;
    }
    &.withLibrary {
      @media screen and (min-width: 768px) {
        padding: 60px 16px 70px;
      }

      .drag-image {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      .buttons {
        display: block;
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
        }
        label,
        button {
          border: none;
          padding: 0;
          display: block;
          font-family: source-sans-pro, sans-serif;
          width: 100%;
          margin-top: 12px;

          @media screen and (min-width: 768px) {
            max-width: 47%;
            width: auto;
            margin-top: none;
          }
        }
      }
    }
  }
  input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
`;

export default StyledUploadCard;
