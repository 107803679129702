import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../../icons/CloseButton';
import { IMAGE_TYPES } from '../../constants';

export default function StagedImage(props) {
  const {
    image,
    removeImage,
    index,
    updateImage,
    rooms,
    handleEditImage,
  } = props;

  const { ImageId } = image;

  const roomOptions = Object.keys(rooms).map(room => {
    const Description = rooms[room];
    const roomId = room;
    return (
      <option key={`${ImageId}-${room}`} value={roomId}>
        {Description}
      </option>
    );
  });

  return (
    <div className="staged-image" id={`image-${index}`}>
      <div className="image-preview">
        <figure className="image-wrapper">
          <img src={image.uri} alt={`preview-${index}`} />
        </figure>
        <button
          type="button"
          className="edit"
          onClick={() => handleEditImage(image)}
        >
          Adjust
        </button>
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className="group" htmlFor={`image-${index}-group`}>
        <div className="label">Group</div>
        <div className="select-wrapper">
          <select
            name={`image-${index}-group`}
            id={`image-${index}-group`}
            value={image.imageTypeAcronym}
            onChange={event => {
              const newImage = {
                ...image,
                imageTypeAcronym: event.target.value,
              };
              updateImage(index, newImage);
            }}
          >
            {Object.keys(IMAGE_TYPES).map(acronym => (
              <option key={acronym} value={acronym}>
                {IMAGE_TYPES[acronym]}
              </option>
            ))}
          </select>
        </div>
      </label>
      {/* show Room select only if group is interior */}
      {image.imageTypeAcronym === 'INT' && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className="room" htmlFor={`image-${index}-room`}>
          <div className="label">Room</div>
          <div className="select-wrapper">
            <select
              name={`image-${index}-room`}
              id={`image-${index}-room`}
              value={image.roomId}
              onChange={event => {
                const newImage = { ...image, roomId: event.target.value };
                updateImage(index, newImage);
              }}
            >
              {/* These options will need to be fetched */}
              {roomOptions}
            </select>
          </div>
        </label>
      )}
      <label className="caption" htmlFor={`image-${index}-caption`}>
        <div className="label">
          Caption <span className="sub"> - Optional</span>
        </div>
        <textarea
          name={`image-${index}-caption`}
          id={`image-${index}-caption`}
          value={image.caption}
          onChange={event => {
            const newImage = { ...image, caption: event.target.value };
            updateImage(index, newImage);
          }}
        />
      </label>
      <button
        type="button"
        className="close-button"
        onClick={() => {
          removeImage(index);
        }}
      >
        <CloseButton />
      </button>
    </div>
  );
}

StagedImage.propTypes = {
  image: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    imageTypeAcronym: PropTypes.string.isRequired,
    roomId: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  }).isRequired,
  removeImage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  updateImage: PropTypes.func.isRequired,
  rooms: PropTypes.shape().isRequired,
};
