import styled from 'styled-components';

const StyledPricing = styled.div.attrs({ className: 'pricing' })`
  .price-wrapper {
    margin-bottom: 10px;
    label {
      display: flex;
      flex-flow: row wrap;
      .label {
        flex: 1 1 100%;
      }

      select {
        border-radius: 1px;
        border: 1px solid #e4e5e7;
        font-size: 15px;
        line-height: 140%;
        font-family: source-sans-pro, sans-serif;
        flex: 0 1 396px;
        padding: 15px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin-top: 5px;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-color: transparent;
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
      }
    }
    input {
      border: 1px solid #e4e5e7;
      border-radius: 1px;
      font: 400 15px/140% source-sans-pro;
      color: #4e5255;
      padding: 10px 0 10px 11px;
      width: 206px;
      margin-top: 7px;
      box-sizing: border-box;
    }
    .retail-pricing {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-top: 32px;

      svg {
        margin-right: 7px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default StyledPricing;
