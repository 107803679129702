import React from 'react';
import PropTypes from 'prop-types';
import ContactInfoWrapper from './ContactInfo.Style';

const ContactInfo = ({ email }) => {
  return (
    <ContactInfoWrapper>
      <div className="form-wrap">
        <div className="field-value-container">
          <p className="label">Email Address</p>
          <p className="field-value">{email}</p>
        </div>
      </div>
    </ContactInfoWrapper>
  );
};

ContactInfo.propTypes = {
  email: PropTypes.string,
};

ContactInfo.defaultProps = {
  email: '',
};

export default ContactInfo;
