import posed from 'react-pose';

const PosedSideBarButton = posed.button({
  pressable: true,
  init: { scale: 1, boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.27)' },
  press: {
    scale: 0.85,
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 1)',
    transition: { duration: 50 },
  },
});

export default PosedSideBarButton;
