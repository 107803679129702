import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message, error }) => {
  return (
    <div className="error" style={{ padding: '20px 50px' }}>
      {message}
      <span role="img" aria-label="dizzy face">
        😵
      </span>
      <pre>{error.message}</pre>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

Error.defaultProps = {
  message: 'There was an error.',
  error: {},
};

export default Error;
