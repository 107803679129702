import styled from 'styled-components';

const ContactInfoWrapper = styled.div`
  max-width: 690px;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  display: flex;
  padding: 29px 35px;
  box-sizing: border-box;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    display: block;
  }

  .profile-avatar {
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-bottom: 37px;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0;
      font: 600 12px/100% source-sans-pro;
      color: #9b9b9b;
      letter-spacing: 0.5px;
      text-align: center;
      margin-top: 12px;
    }

    .upload-container {
      position: relative;
      overflow: hidden;

      a {
        margin-block-start: 0;
        margin-block-end: 0;
        padding: 0;
        font: 600 12px/100% source-sans-pro;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        text-align: center;
        margin-top: 12px;
        text-decoration: none;
        display: block;

        &:hover {
          cursor: pointer;
        }
      }

      .upload-image {
        position: absolute;
        left: 0;
        top: 5px;
        opacity: 0;
        max-width: 190px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .form-wrap {
    max-width: 620px;

    .label {
      display: block;
      font: 600 14px/121% source-sans-pro;
      color: #4e5255;
      margin-bottom: 6px;
    }

    .field-value-container {
      width: 100%;

      .field-value {
        width: 100%;
        font: 400 15px/100% source-sans-pro;
        color: #a1a3a6;
        padding: 15px 5px;
        box-sizing: border-box;
        margin-bottom: 16px;
      }
    }
  }

  #contactInfoForm {
    display: flex;
    flex-flow: row wrap;
    flex: 1 100%;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      display: block;
    }

    .spanish-speaker-container {
      margin-left: 20px;
      margin-top: 40px;
    }

    .spanish-label {
      font-weight: 400;
      color: #777b7e;
      padding-left: 13px;
    }

    .spanish-speaking {
      margin-right: 13px;
    }

    input {
      border: 1px solid #e4e5e7;
      width: 300px;
      font: 400 15px/100% source-sans-pro;
      color: #a1a3a6;
      padding: 15px 5px;
      box-sizing: border-box;
      margin-bottom: 16px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .contact-time {
      width: 100%;
      max-width: 620px;

      input {
        width: 100%;
      }
    }
  }
`;

export default ContactInfoWrapper;
