import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import StyledSiteItem from './SiteItem.Style';
import ImageUploader from '../ImageUploader/ImageUploader';
import Modal from '../Modal';
import ToastSave from '../ToastSave';
import LoadingDots from '../LoadingDots';
import Checkmark from '../../icons/Checkmark';
import { SiteItemsContext } from './SiteItemsProvider';
import { UserContext } from '../User';
import { SAVE_TOAST_OPTIONS } from '../../constants';
import CalendarModal from './CalendarModal';
import LibraryImagesModal from './LibraryImagesModal';
import { formatDate } from '../../utils';
import DealerPicker from './DealerPicker';

const SiteItem = ({
  match: {
    params: { type, id: siteItemId },
  },
  history,
}) => {
  const {
    activeItem,
    stagedItem,
    isLoading,
    setActiveItemId,
    updateStagedItem,
    updateItem,
    deleteItemById,
    stagedItemUpdated,
    isNavigationModalVisible,
    showNavigationModal,
    hideNavigationModal,
    isDeleteModalVisible,
    showDeleteModal,
    hideDeleteModal,
    isCalendarModalVisible,
    showCalendarModal,
    hideCalendarModal,
    isLibraryImagesModalVisible,
    showLibraryImagesModal,
    hideLibraryImagesModal,
    setConfirmNavigation,
    confirmedNavigation,
    targetLocation,
    isStagedItemValid,
    uploadItemImage,
    libraryImages,
    imageIsLoading,
  } = useContext(SiteItemsContext);

  const { activeLot, user } = useContext(UserContext);

  const [toolTip, setShowToolTip] = useState(false);

  const showTooltip = () => {
    setShowToolTip(!toolTip);
  };

  const handleChange = event => {
    updateStagedItem({
      ...stagedItem,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  const updateExpirationDate = newDate => {
    updateStagedItem({
      ...stagedItem,
      EndDate: newDate,
    });
  };

  const updateStatus = newStatus => {
    updateStagedItem(
      {
        ...stagedItem,
        IsPublished: newStatus,
      },
      setShowToolTip(!toolTip)
    );
  };

  const handleSave = useCallback(() => {
    updateItem({
      ...stagedItem,
      IsPublished: true,
    }).then(response => {
      // might add check for success
      if (stagedItem.RetailSiteItemId === 'new') {
        setConfirmNavigation(true);
        setTimeout(() => {
          hideNavigationModal();
          history.push(`/content/promotions`);
        }, 0);
      }
      if (targetLocation) {
        setConfirmNavigation(true);
        setTimeout(() => {
          hideNavigationModal();
          history.push(targetLocation);
        }, 0);
      }
    });
  }, [
    hideNavigationModal,
    history,
    setConfirmNavigation,
    stagedItem,
    targetLocation,
    updateItem,
  ]);

  const handleDiscard = () => {
    updateStagedItem({ ...activeItem });
    setConfirmNavigation(true);
    setTimeout(() => {
      hideNavigationModal();
      history.push(targetLocation);
    }, 0);
  };

  const handleDelete = () => {
    deleteItemById(siteItemId);
    hideDeleteModal();
    setTimeout(() => {
      history.push(`/content/promotions`);
    }, 0);
  };

  const handleRemoveImage = () => {
    updateStagedItem({
      ...stagedItem,
      ImageReference: null,
    });
  };

  const handleUpload = imageData => {
    uploadItemImage(imageData, stagedItem);
  };

  const handleLibraryImageSelect = url => {
    updateStagedItem({
      ...stagedItem,
      ImageReference: url,
    });
    hideLibraryImagesModal();
  };

  const handleUpdateDealers = dealerIds => {
    updateStagedItem({
      ...stagedItem,
      ['DealerIds']: dealerIds,
    });
  };

  const {
    ImageReference,
    Headline,
    LinkUrl,
    IsPublished,
    EndDate,
  } = stagedItem;

  const publishButtonText = () => {
    if (
      stagedItemUpdated &&
      !isDeleteModalVisible &&
      !isNavigationModalVisible &&
      !isCalendarModalVisible &&
      isStagedItemValid
    ) {
      return 'Save & Publish';
    }

    if (IsPublished) {
      return 'Published';
    }
    return 'Save & Publish';
  };

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      if (!confirmedNavigation && stagedItemUpdated) {
        showNavigationModal(nextLocation);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [stagedItemUpdated, confirmedNavigation, history, showNavigationModal]);

  useEffect(() => {
    if (siteItemId && activeLot) {
      if (siteItemId !== 'new') {
        setActiveItemId(siteItemId);
      } else {
        const newItem = {
          RetailSiteItemId: 'new',
          DealerIds: [activeLot.LotNumber],
        };
        newItem.RetailSiteItemType = 'SlideShow';
        updateStagedItem(newItem);
      }
    }
    return () => {
      setActiveItemId(null);
      updateStagedItem(null);
    };
  }, [siteItemId, activeLot, setActiveItemId, type, updateStagedItem]);

  // show loading dots until we have an actual object
  if (isLoading || (stagedItem.RetailSiteItemId !== 'new' && !activeItem)) {
    return <LoadingDots />;
  }

  const tooltipStyle = {
    display: toolTip ? 'block' : 'none',
  };

  const typeDescription = 'promotion';

  return (
    <StyledSiteItem>
      <div className="main-column">
        {!ImageReference && (
          <ImageUploader
            uploadFunction={handleUpload}
            stageImages={false}
            allowMultiple={false}
            headerText={`Upload ${typeDescription} image (required)`}
            showLibraryImagesModal={showLibraryImagesModal}
            minHeight={600}
            minWidth={900}
            isLoading={imageIsLoading}
          />
        )}

        {ImageReference && (
          <div className="image-field card">
            <div className="image-wrapper">
              <img src={ImageReference} alt={Headline} />
            </div>
            <button type="button" className="clean" onClick={handleRemoveImage}>
              Remove Image
            </button>
          </div>
        )}
        <div className="input-field">
          <label htmlFor="Headline">
            <span className="label">Header (required)</span>
            <input
              type="text"
              id="Headline"
              value={Headline || ''}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="LinkUrl">
            <span className="label">
              Link <span className="optional">(optional)</span>
            </span>
            <input
              type="text"
              id="LinkUrl"
              value={LinkUrl || ''}
              onChange={handleChange}
            />
          </label>
        </div>
        <DealerPicker
          authorizedDealers={user.AuthorizedRetailSites}
          selectedDealers={stagedItem.DealerIds}
          updateDealers={handleUpdateDealers}
        />
      </div>

      <div className="side-column">
        <div className="status-box card">
          <button
            type="button"
            className="publish"
            disabled={!isStagedItemValid || (IsPublished && !stagedItemUpdated)}
            onClick={handleSave}
          >
            {publishButtonText()}
          </button>
          <div className="status-line">
            <strong>Status</strong>
            <span>{IsPublished ? 'Published' : 'Unpublished'}</span>
            <div className="tooltip" style={tooltipStyle}>
              <div
                role="presentation"
                className="overlay"
                onClick={showTooltip}
              />
              <button
                type="button"
                onClick={() => {
                  updateStatus(true);
                }}
                className={IsPublished ? 'active' : ''}
              >
                Published
              </button>
              <button
                type="button"
                onClick={() => {
                  updateStatus(false);
                }}
                className={IsPublished ? '' : 'active'}
              >
                Unpublished
              </button>
            </div>
          </div>
          <div className="status-line">
            <strong>Expires</strong>
            <span>{!EndDate ? 'Never' : formatDate(EndDate)}</span>
            <button type="button" className="clean" onClick={showCalendarModal}>
              Edit
            </button>
          </div>
          <div className="bottom-button">
            <button
              type="button"
              className="clean"
              disabled={!IsPublished}
              onClick={() => {
                showDeleteModal();
              }}
            >
              Delete Promo Slide
            </button>
          </div>
        </div>
        <div className="message">
          <div className="checkmark" style={{ background: 'transparent' }}>
            <Checkmark />
          </div>
          Please note: changes won’t be visible on your site until published.
        </div>
      </div>
      <Modal
        modalHeadline={isStagedItemValid ? 'Save Changes?' : 'Hold On!'}
        modalBody={
          isStagedItemValid
            ? `If you do not save your changes, any changes you have made to your ${typeDescription} will be undone.`
            : 'This item has been changed, but is currently invalid and cannot be saved.  Would you like to continue editing?'
        }
        closeCopy="DISCARD"
        saveCopy={isStagedItemValid ? 'SAVE' : 'KEEP EDITING'}
        show={isNavigationModalVisible}
        closeCallback={hideNavigationModal}
        discardCallback={handleDiscard}
        saveCallback={isStagedItemValid ? handleSave : hideNavigationModal}
      />
      <Modal
        modalHeadline="Are you sure?"
        modalBody={`Are you sure you want to delete "${Headline}"?`}
        saveCopy="Delete"
        closeCopy="Cancel"
        saveCallback={handleDelete}
        show={isDeleteModalVisible}
        discardCallback={hideDeleteModal}
        closeCallback={hideDeleteModal}
      />
      {libraryImages && (
        <LibraryImagesModal
          closeCallback={hideLibraryImagesModal}
          images={libraryImages
            .filter(image => image.ItemTypeId === 4)
            .map(image => image.Reference)}
          selectCallback={handleLibraryImageSelect}
          show={isLibraryImagesModalVisible}
          user={user}
        />
      )}
      {isCalendarModalVisible && (
        <CalendarModal
          date={stagedItem.EndDate}
          setDate={date => {
            updateExpirationDate(date);
          }}
          closeCallback={hideCalendarModal}
        />
      )}
    </StyledSiteItem>
  );
};

SiteItem.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default SiteItem;
