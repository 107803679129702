import styled from 'styled-components';

const StyledEditImageModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .modal-content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-height: 360px; */
    overflow: auto;
    background: #fff;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    max-width: 578px;
    width: 40%;

    @media screen and (min-width: 1024px) {
      left: 61%;
    }
    @media screen and (max-width: 767px) {
      max-width: unset;
      width: 90%;
    }

    .spacer {
      width: 20px;
      flex-shrink: 0;
    }

    .content-container {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 41px 59px 26px;

      @media screen and (max-width: 767px) {
        justify-content: center;
        padding: 41px 20px 26px;
        flex-wrap: wrap;
      }
      .img-container {
        @media screen and (max-width: 767px) {
          text-align: center;
          margin-bottom: 18px;
          width: 100%;
        }
        img {
          max-width: 141px;
          height: auto !important;

          @media screen and (max-width: 767px) {
            display: unset;
            margin-right: auto;
          }
        }

        .uploaded-by {
          font: 400 11px/136% source-sans-pro, sans-serif;
          color: #777b7e;
          padding: 0;
          margin-bottom: 0;
        }

        .uploaded-by:first-of-type {
          margin-top: 8px;
        }
      }

      .input-group {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .inputs {
        display: flex;
        justify-content: space-between;

        &:nth-of-type(2n) {
          .input-container {
            margin-bottom: 0;
          }
        }

        .input-container {
          margin-right: 7px;
          margin-bottom: 13px;

          label {
            display: block;
            font: 600 12px/117% source-sans-pro, sans-serif;
            color: #4e5255;
            margin-bottom: 9px;
          }
          select {
            width: 144px;
            font: 400 14px/100% source-sans-pro, sans-serif;
            border: 1px solid #e4e5e7;
            border-radius: 2px;
            color: #777b7e;
            background: #fff;
          }
          textarea {
            width: 100%;
            font: 400 14px/100% source-sans-pro, sans-serif;
            border: 1px solid #e4e5e7;
            border-radius: 2px;
            color: #777b7e;
            padding: 7px;
            box-sizing: border-box;
            height: 60px;
            resize: none;
            outline: none;
          }
        }
        .full-width {
          flex: 1;
        }
      }
    }
  }

  .btns-container {
    border-top: 1px solid #e4e5e7;
    padding-top: 17px;
    padding-bottom: 26px;

    .loader {
      margin-top: 0px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;

      .cancel-button {
        background: #e4e5e7;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-left: 83px;

        @media screen and (max-width: 767px) {
          width: 142px;
          margin-left: 20px;
        }
      }

      .save-button {
        background: #00b0ac;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #fff;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-right: 83px;

        @media screen and (max-width: 767px) {
          width: 142px;
          margin-right: 20px;
        }
      }

      button {
        margin-block-start: 0;
        margin-block-end: 0;
        border: none;
        border-radius: 0;
        &:hover,
        &:focus {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .delete-image {
      font: 600 12px/100% source-sans-pro, sans-serif;
      color: #a1a3a6;
      text-align: center;
      display: block;
      margin: 0 auto;
      border: 0;
      background: none;

      &:hover {
        cursor: pointer;
      }

      svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 9px;
        vertical-align: text-bottom;
      }
    }
  }
`;

export default StyledEditImageModal;
