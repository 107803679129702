import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledFeatureCard from './FeatureCard.Style';
import DraggableDots from '../../icons/DraggableDots';
import CloseButton from '../../icons/CloseButton';
import { analyticsSendEvent } from '../../utils';

const FeatureCard = ({
  name,
  removeFeature,
  renameFeature,
  addFeature,
  draggable,
}) => {
  const [newName, setNewName] = useState(name);
  return (
    <StyledFeatureCard>
      <div className="card" draggable={draggable}>
        <div className="handle">
          <DraggableDots />
        </div>
        <input
          className="name"
          value={newName}
          onChange={event => setNewName(event.currentTarget.value)}
          maxLength={25}
          placeholder="Click here to enter text."
          onBlur={event => {
            if (newName && newName !== name) {
              if (typeof renameFeature === 'function') {
                analyticsSendEvent('home_features_add', { feature: newName });
                renameFeature(newName);
              } else if (typeof addFeature === 'function') {
                analyticsSendEvent('home_features_add', { feature: newName });
                addFeature(newName);
                setNewName('');
                event.currentTarget.focus();
              }
            } else {
              setNewName(name);
            }
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.currentTarget.blur();
            }
          }}
          onKeyDown={event => {
            // when a user enters a new feature and presses tab, give focus to the new feature input
            if (
              event.key === 'Tab' &&
              event.shiftKey === false &&
              typeof addFeature === 'function'
            ) {
              event.preventDefault();
              event.currentTarget.blur();
            }
          }}
        />
      </div>
      {typeof addFeature !== 'function' && (
        <button type="button" className="removeFeature" onClick={removeFeature}>
          <CloseButton />
        </button>
      )}
    </StyledFeatureCard>
  );
};

FeatureCard.propTypes = {
  name: PropTypes.string.isRequired,
  removeFeature: PropTypes.func,
  renameFeature: PropTypes.func,
  addFeature: PropTypes.func,
  draggable: PropTypes.bool,
};

FeatureCard.defaultProps = {
  removeFeature: undefined,
  renameFeature: undefined,
  addFeature: undefined,
  draggable: true,
};

export default React.memo(FeatureCard);
