import React, { useEffect, useState, useContext } from 'react';
import { StyledZoneSelector } from './ZoneSelector.Style';
import AccordianToggle from '../AccordianToggle';
import CheckBox from '../CheckBox/CheckBox';
import { UserContext } from '../User';

const ZoneSelector = ({ className, onChange, ...props }) => {
  const { user } = useContext(UserContext);
  // load zones
  const [zones, setZones] = useState(null);
  const [zoneToggles, setZoneToggles] = useState(null);

  useEffect(() => {
    if (!user) return;

    // loop thru all available dealers
    // create array containing all zones and regions available

    let zones = [];

    Object.values(user.AuthorizedRetailSites).forEach((site, index) => {
      // do we have this zone yet, if not create
      if (site.Zone == 0 || site.Region == null) {
        return;
      }
      let zone = zones.find(zone => zone.id === site.Zone);
      if (!zone) {
        zone = {
          id: site.Zone,
          title: 'Zone ' + site.Zone,
          isSelected: false,
          subZones: [],
        };
        zones.push(zone);
      }

      if (!zone.subZones.find(subZone => subZone.title === site.Region)) {
        zone.subZones = [
          ...zone.subZones,
          {
            title: site.Region,
            isSelected: false,
          },
        ];
      }
    });

    zones.sort((a, b) => a.id - b.id);
    setZones(zones);

    const zoneToggles = Array(zones.length).fill(false);
    setZoneToggles(zoneToggles);
  }, [user]);

  const bulkZoneChanged = zoneIndex => {
    const updatedZone = zones[zoneIndex];
    updatedZone.isSelected = !updatedZone.isSelected;
    // if zone selected, select all regions and show the sub zones
    if (updatedZone.isSelected) {
      let newSubZones = updatedZone.subZones.map(subZone => {
        var returnValue = { ...subZone };
        returnValue.isSelected = true;
        return returnValue;
      });
      updatedZone.subZones = newSubZones;

      // show the subzones for selected zone (open the accordian)
      const newZoneToggles = [
        ...zoneToggles.slice(0, zoneIndex),
        true,
        ...zoneToggles.slice(zoneIndex + 1),
      ];
      setZoneToggles(newZoneToggles);
    } else {
      // if zone deselcted, unselect all regions
      let newSubZones = updatedZone.subZones.map(subZone => {
        var returnValue = { ...subZone };
        returnValue.isSelected = false;
        return returnValue;
      });
      updatedZone.subZones = newSubZones;
    }
    const newZones = [
      ...zones.slice(0, zoneIndex),
      updatedZone,
      ...zones.slice(zoneIndex + 1),
    ];
    setZones(newZones);
    const dealerIds = convertRegionsToDealerIds(zones);

    onChange && onChange(newZones, dealerIds);
  };

  const bulkRegionChanged = (zoneIndex, subZoneIndex) => {
    const parentZone = zones[zoneIndex];
    const updatedSubZone = parentZone.subZones[subZoneIndex];
    updatedSubZone.isSelected = !updatedSubZone.isSelected;
    // if we are deselecting a subzone, then the zone should also be unselected
    if (!updatedSubZone.isSelected) {
      parentZone.isSelected = false;
    }

    // update the subzones array
    const newSubZones = [
      ...parentZone.subZones.slice(0, subZoneIndex),
      updatedSubZone,
      ...parentZone.subZones.slice(subZoneIndex + 1),
    ];
    // replace subzones in parent zone
    parentZone.subZones = newSubZones;

    // and finally update the zones with the changes
    const newZones = [
      ...zones.slice(0, zoneIndex),
      parentZone,
      ...zones.slice(zoneIndex + 1),
    ];
    setZones(newZones);
    const dealerIds = convertRegionsToDealerIds(zones);
    onChange && onChange(newZones, dealerIds);
  };

  const convertRegionsToDealerIds = zones => {
    let dealerIds = [];

    // loop thru each region and check the active dealer ids for match, adding matching dealers to result
    zones.forEach(zone => {
      zone.subZones.forEach(subZone => {
        const foundDealerIds = Object.values(user.AuthorizedRetailSites)
          .filter(site => subZone.isSelected && site.Region === subZone.title)
          .map(site => site.LotNumber);
        dealerIds = [...dealerIds, ...foundDealerIds];
      });
    });

    return dealerIds;
  };

  const accordianToggleChanged = zoneIndex => {
    const isOpen = zoneToggles[zoneIndex];
    const newZoneToggles = [
      ...zoneToggles.slice(0, zoneIndex),
      !isOpen,
      ...zoneToggles.slice(zoneIndex + 1),
    ];
    setZoneToggles(newZoneToggles);
  };
  return (
    <StyledZoneSelector>
      <div className={className + ' zone-selector-box'}>
        <div className="title">
          Multi-Location Event Zone Selection (required)
        </div>
        <div className="card content">
          {zones &&
            zones.map((zone, zoneIndex) => {
              return (
                <div key={zoneIndex}>
                  <AccordianToggle
                    key={'' + zoneIndex}
                    className="zone"
                    title={
                      <label htmlFor={zone + ' ' + zoneIndex}>
                        <CheckBox
                          id={zone + ' ' + zoneIndex}
                          checked={zone.isSelected}
                          onChange={() => {
                            bulkZoneChanged(zoneIndex);
                          }}
                          width="16px"
                          height="16px"
                        />
                        <span className="zone-checkbox-label">
                          {zone.title}
                        </span>
                      </label>
                    }
                    onToggle={() => {
                      accordianToggleChanged(zoneIndex);
                    }}
                    isOpen={zoneToggles[zoneIndex]}
                    fillColor="#25292D"
                  />
                  <div
                    className={
                      'section' + (zoneToggles[zoneIndex] ? '' : ' hidden')
                    }
                  >
                    {zone.subZones.map((subZone, subZoneIndex) => {
                      return (
                        <div
                          className="region"
                          key={subZone.title + ' ' + subZoneIndex}
                        >
                          <label htmlFor={subZone.title + ' ' + subZoneIndex}>
                            <CheckBox
                              id={subZone.title + ' ' + subZoneIndex}
                              checked={subZone.isSelected}
                              onChange={() => {
                                bulkRegionChanged(zoneIndex, subZoneIndex);
                              }}
                              width="16px"
                              height="16px"
                            />
                            <span className="zone-checkbox-label">
                              {subZone.title}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </StyledZoneSelector>
  );
};
export default ZoneSelector;
