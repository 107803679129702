import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledAutoComplete from './WelcomeHomeAutoComplete.style';
import useFocus from '../../hooks/useFocus';
import SearchIcon from '../../icons/SearchIcon';

const filterEntries = (query, entries) => {
  if (!Array.isArray(entries) || !query) {
    return [];
  }

  return entries.filter(val => {
    return (
      String(val.Email)
        .toLocaleLowerCase()
        .indexOf(query.toLocaleLowerCase()) >= 0 ||
      String(val.Name)
        .toLocaleLowerCase()
        .indexOf(query.toLocaleLowerCase()) >= 0 ||
      String(val.StockNumber)
        .toLocaleLowerCase()
        .indexOf(query.toLocaleLowerCase()) >= 0
    );
  });
};

const WelcomeHomeAutoComplete = ({
  entries,
  onSelect,
  defaultValue,
  clearOnSelect = false,
  setNoCustomerFound,
  setCustomer,
  customer,
}) => {
  const [query, setQuery] = useState(defaultValue);
  const [filteredEntries, setFilteredEntries] = useState(null);
  const [focusRef, setHasFocus, hasFocus] = useFocus();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleChange = event => {
    setQuery(event.target.value);

    if (event.target.value !== '') {
      setFilteredEntries(filterEntries(event.target.value, entries));
    } else {
      setFilteredEntries([]);
      setNoCustomerFound(false);
    }
  };

  const handleSelect = index => {
    setFilteredEntries(null);
    onSelect(index);
    if (clearOnSelect) setQuery('');
  };

  const onKeyDown = (ev, value) => {
    if (ev.keyCode === 13 && filteredEntries !== null) {
      let useIndex = selectedIndex !== -1 ? selectedIndex : 0;
      let selectedItem = filteredEntries !== null && filteredEntries[useIndex];

      if (selectedItem) {
        setQuery(`${selectedItem.Name} ${selectedItem.StockNumber}`);
      }

      handleSelect(selectedItem);
    }

    //Arrow Up
    if (ev.keyCode === 38) {
      moveIndex(-1);
    }

    //Arrow Down
    if (ev.keyCode === 40) {
      moveIndex(1);
    }
  };

  useEffect(() => {
    if (filteredEntries && filteredEntries.length === 0 && query !== '') {
      setNoCustomerFound(true);
      setCustomer(null);
    }
    if (filteredEntries && filteredEntries.length > 0 && query === '') {
      setNoCustomerFound(false);
    }
  }, [filteredEntries, query]);

  const moveIndex = i => {
    if (filteredEntries !== null) {
      let temp = selectedIndex + i;

      if (temp < 0) {
        temp = 0;
      } else if (temp >= filteredEntries.length) {
        let listIndex = filteredEntries.length;
        temp = listIndex - 1;
      }

      setSelectedIndex(temp);
    }
  };

  return (
    <StyledAutoComplete ref={focusRef}>
      <div className="search-container">
        <label htmlFor="search">
          <span className="label">Customer</span>
          <div className="search-input-container">
            <SearchIcon className="search-icon" />
            <input
              id="search"
              type="text"
              value={query}
              onChange={handleChange}
              placeholder="Search for Customer"
              autoComplete="off"
              onKeyDown={onKeyDown}
              onBlur={event => {
                if (filteredEntries && filteredEntries.length === 0) {
                  setQuery('');
                }
              }}
              onFocus={({ currentTarget }) => {
                if (!hasFocus) {
                  setHasFocus(true);
                  currentTarget.select();
                }
              }}
            />
          </div>
        </label>
      </div>
      {hasFocus && query.length > 0 && filteredEntries && (
        <div className="suggestions">
          {filteredEntries.length === 0 && (
            <div className="no-customers-found">No customers were found.</div>
          )}
          {filteredEntries.map((entry, i) => (
            <div
              role="menu"
              tabIndex="0"
              className={
                selectedIndex === i ? 'selected suggestion' : 'suggestion'
              }
              key={i}
              onClick={({ target }) => {
                setQuery(`${entry.Name}  (${entry.StockNumber})`);
                handleSelect(entry);
                target.blur();
              }}
              onKeyPress={({ charCode, target }) => {
                // Enter or Space
                if (charCode === 13 || charCode === 32) {
                  target.click();
                }
              }}
            >
              {entry.Name} <span>&nbsp;</span> {`(${entry.StockNumber})`}
            </div>
          ))}
        </div>
      )}
    </StyledAutoComplete>
  );
};

WelcomeHomeAutoComplete.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Url: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

WelcomeHomeAutoComplete.defaultProps = {
  defaultValue: '',
};

export default WelcomeHomeAutoComplete;
