import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../icons/SearchIcon';
import StyledSearchBar from './SearchBar.Style';

const SearchBar = ({ searchQuery, searchAction, searchText }) => {
  const handleChange = event => {
    searchAction(event.target.value);
  };

  return (
    <StyledSearchBar>
      <label htmlFor="search" className={searchQuery ? 'active' : ''}>
        <SearchIcon className="search-icon" />
        <span className={searchQuery ? 'placeholder hide' : 'placeholder'}>
          Search for {searchText}
        </span>
        <input
          onChange={handleChange}
          value={searchQuery}
          type="text"
          id="search"
        />
        <svg
          className="bar"
          xmlns="http://www.w3.org/2000/svg"
          width="325px"
          height="2px"
          viewBox="0 0 325 2"
        >
          <g fill="none" fillRule="evenodd" strokeWidth="2">
            <path stroke="#e6e6e6" d="M 0,0 L 325,0" />
            <path
              className="line"
              stroke="rgb(0, 117, 201)"
              d="M 0,0 L 325,0"
            />
          </g>
        </svg>
      </label>
    </StyledSearchBar>
  );
};

SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  searchAction: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  searchQuery: '',
};

export default SearchBar;
