import styled from 'styled-components';

const StyledUserInfo = styled.div`
  min-height: fit-content;
  width: 100%;
  background-color: #1b3453;
  .profile-link {
    display: flex;
    flex-direction: row;
    margin-left: 28px;
    margin-top: 25px;
    align-content: center;
    margin-bottom: 25px;
    .user-text {
      p {
        display: block;
        padding-left: 13px;
        margin: 0;
      }
      .user-name {
        margin-top: 10px;
      }
      .view-profile {
        color: #4c75a7;
        font-size: 14px;
      }
    }
    .user-image {
      border-radius: 50%;
      border: 1px solid #4c75a7;
      height: 56px;
      width: 56px;
      overflow: hidden;
      svg {
        width: 80px;
        height: 80px;
        transform: translateX(-12px) translateY(-10px);
      }
      img {
        max-width: 100%;
        object-fit: cover;
      }
    }
  }

  select {
    display: block;
    margin: 0 auto;
    width: 244px;
    height: 44px;
    background-color: #0c1e34;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 11px;
    border: inset 1px #29476c;
    font-family: source-sans-pro, sans-serif;
  }

  input.search {
    display: block;
    margin: 0 auto 15px;
    width: 230px;
    background-color: #0c1e34;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    padding-left: 14px;
    border: inset 1px #29476c;
    border-radius: 3px;
    font-family: source-sans-pro, sans-serif;
  }
  .dealer-info {
    margin: 0 15px 10px;
    table {
      color: #fff;
      text-align: left;
      width: 100%;
      td.mono {
        font-family: anonymous-pro, monospace;
      }
    }
  }
`;

export default StyledUserInfo;
