import styled from 'styled-components';

const StyledModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .modal-content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: ${props => (props.useCloseIcon ? 'none' : '240px')};
    overflow: ${props => (props.useCloseIcon ? 'inherit' : 'initial')};
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    width: ${props => (props.useCloseIcon ? '90%' : '300px')};
    max-width: ${props => (props.useCloseIcon ? '500px' : 'none')};
    @media screen and (min-width: 1024px) {
      left: 61%;
      width: ${props => (props.useCloseIcon ? '50%' : '300px')};
    }
  }

  .close-modal-icon {
    position: absolute;
    z-index: 9999;
    background: none;
    border: none;
    outline: none;
    top: -25px;
    left: 94%;

    &:hover {
      cursor: pointer;
    }
  }

  h2 {
    font: 600 18px/122% source-sans-pro;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 15px;
    text-align: ${props => (props.useCloseIcon ? 'center' : 'left')};
  }

  .modal-text {
    display: ${props => (props.useCloseIcon ? 'block' : 'flex')};
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  p {
    font: 400 15px/140% source-sans-pro;
    color: #7f8083;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: ${props => (props.useCloseIcon ? '20px' : '0')};
    margin-bottom: ${props => (props.useCloseIcon ? '0' : '50px')};
    text-align: ${props => (props.useCloseIcon ? 'center' : 'left')};
  }

  .btns-container {
    display: flex;
    justify-content: ${props => (props.useOkBtn ? 'center' : 'space-between')};
    border-top: ${props => (props.useOkBtn ? '1px solid #e4e5e7' : 'none')};
    padding-top: ${props => (props.useOkBtn ? '30px' : '0')};
    margin-top: ${props => (props.useOkBtn ? '30px' : '0')};

    .ok-btn {
      background: #00b0ac;
      font: 600 12px/ 100% source-sans-pro, sans-serif;
      color: #fff;
      letter-spacing: 0.5px;
      padding: 15px 0;
      width: 195px;
      margin: 0 auto;
      border-radius: 5px;

      &:hover {
        color: #fff;
        cursor: pointer;
        opacity: 0.9;
      }
    }

    button {
      font: 600 14px/100% source-sans-pro;
      color: #0075c9;
      letter-spacing: 1px;
      margin-block-start: 0;
      margin-block-end: 0;
      border: none;
      background: none;
      border-radius: 0;
      padding: 4px;
      transition: color 0.3s;
      &:hover,
      &:focus {
        color: #003f8c;
        cursor: pointer;
      }
    }
  }
`;

export default StyledModal;
