import styled from 'styled-components';

export const CheckBoxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckBox = styled.div`
  display: inline-block;
  /* width: 13px; */
  width: ${props => props.width || '13px'};
  /* height: 13px; */
  height: ${props => props.height || '13px'};
  background: ${props => (props.checked ? '#1370c8' : '')};
  transition: all 150ms;
  cursor: pointer;
  border: 1px solid #d6d7d9;
  border-radius: ${props => props.borderRadius || '0'};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
