import { useRef, useState, useEffect, useCallback } from 'react';

const useFocus = () => {
  const ref = useRef(null);
  const { current } = ref;
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = useCallback(
    event => {
      if (!event.relatedTarget) {
        setHasFocus(false);
        return;
      }

      if (
        current === event.relatedTarget ||
        current.contains(event.relatedTarget)
      ) {
        return;
      }
      setHasFocus(false);
    },
    [current]
  );

  useEffect(
    () => {
      const node = current;
      if (node) {
        node.addEventListener('focusin', handleFocus);
        node.addEventListener('focusout', handleBlur);

        return () => {
          node.removeEventListener('focusin', handleFocus);
          node.removeEventListener('focusout', handleBlur);
        };
      }
      return () => {};
    },
    [current, handleBlur] // Recall only if ref changes
  );

  return [ref, setHasFocus, hasFocus];
};

export default useFocus;
