import styled from 'styled-components';

const StyledHomepage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 0px 25px;
  margin-top: -40px;
  .headline {
    line-height: 1;
    margin: 0 0 0;
    font-size: 2.5rem;  
    text-align: center;
    margin-bottom: 40px;
    @media only screen and (max-width: 600px) {
      font-size: 1.5rem;  
      margin-bottom: 20px;
    }
  }
  .sub-headline {
    padding: 0px;
    max-width: 600px;
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width: 600px) {
      font-size: .9rem;  
      margin-bottom: 20px;
    }
  }
  .buttons {
    position: relative;
    display: flex;
    @media only screen and (max-width: 1130px) {
      flex-direction: column;
      max-width: 511px;
      margin: 0 auto;
    }
    a {
      flex: 1;
      padding: 0px 20px;
      text-decoration: none;
      width: 238px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        p {
          span {
            background: #0075C9;
            color: white;
          }
        }
      }
      .icon {
        @media only screen and (min-width: 1131px) {
          min-height: 112px;
        }
        @media only screen and (max-width: 600px) {
          max-width: 150px;
          svg {
            width: 100%;
            height: auto;
          }
        }
      }
      p {
        padding: 0px;
        text-align: center;
        width: 100%;
        position: relative;
        display: flex;
        span {
          padding: 3px 10px;
          border 1px solid #0075C9;
          border-radius: 4px;
          color: #0075C9;
          text-decoration: none;
          transition: all .15s;
          width: 100%;
          display: block;
        }
      }
      @media only screen and (max-width: 1130px) {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
`;

export default StyledHomepage;
