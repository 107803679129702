import React, { useContext, useState, useEffect, useRef } from 'react';
import StyledSettings from './Hours.Style';
import { FormPrompt } from '../../FormPrompt';
import { getDealer, updateDealer } from '../../../Api';
import LoadingDots from '../../LoadingDots';
import { UserContext } from '../../User';

const Hours = ({ history }) => {
  const [dealer, setDealer] = useState(null);
  const [dealerSnapshot, setDealerSnapshot] = useState(null);
  const [dealerHasChanged, setDealerHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageError, setIsPageError] = useState(false);
  const [errors, setErrors] = useState({});
  const { activeLot, user } = useContext(UserContext);

  const settingsFormRef = useRef(null);

  // corp users only
  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser) {
      history.push('/');
    }
  }, [user]);

  // first time load dealer info and whatever youn need...
  useEffect(() => {
    if (!activeLot) return;
    (async () => {
      try {
        const dealer = await getDealer(activeLot.LotNumber);
        setDealer(dealer);
        setDealerSnapshot(dealer);
        setIsLoading(false);
        setDealerHasChanged(false);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
    })();
  }, [activeLot]);

  // on dealer change, validate
  useEffect(() => {
    if (!dealer) return;

    // enable save button on change
    if (dealerSnapshot != null) {
      setDealerHasChanged(true);
    }

    validateAll();
  }, [dealer]);

  const handleChange = (propName, newValue) => {
    setDealer({ ...dealer, [propName]: newValue });
  };

  const handleSave = () => {
    validateAll();
    if (hasErrors()) return;

    (async () => {
      setIsLoading(true);
      try {
        const response = await updateDealer(activeLot.LotNumber, dealer);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
      setDealerHasChanged(false);
    })();
  };

  const validateAll = () => {
    let newErrors = { ...errors };

    newErrors = {
      ...newErrors,
      ...validateHours('WeekdayHours', dealer.WeekdayHours),
    };

    newErrors = {
      ...newErrors,
      ...validateHours('FridayHours', dealer.FridayHours),
    };

    newErrors = {
      ...newErrors,
      ...validateHours('SaturdayHours', dealer.SaturdayHours),
    };

    newErrors = {
      ...newErrors,
      ...validateHours('SundayHours', dealer.SundayHours),
    };

    newErrors = {
      ...newErrors,
      ...validateHours('HolidayHours', dealer.HolidayHours),
    };

    setErrors({ ...errors, ...newErrors });
  };

  const validateHours = (propName, val) => {
    const error = val && val != '' ? null : propName + ' is required.';

    return { [propName]: error };
  };

  const hasErrors = () => {
    if (Object.values(errors).length == 0) return false;

    if (Object.values(errors).some(element => element !== null)) {
      return true;
    }
    return false;
  };

  const getError = propName => {
    return errors.hasOwnProperty(propName) && errors[propName]
      ? errors[propName]
      : null;
  };

  return (
    <StyledSettings>
      <FormPrompt hasUnsavedChanges={dealerHasChanged} />
      <div>
        {isLoading && <LoadingDots />}
        {isPageError && (
          <div className="error">
            There was an error loading setttings, please try again later.
          </div>
        )}
        {!isPageError && !isLoading && (
          <>
            <div className="settings-item">
              <label className="label" htmlFor="WeekdayHours">
                <span className="label-text">Weekday Hours</span>
                <input
                  type="text"
                  id="WeekdayHours"
                  value={dealer ? dealer.WeekdayHours : ''}
                  onChange={e => {
                    handleChange('WeekdayHours', e.target.value);
                  }}
                  maxLength="8000"
                  disabled={isLoading}
                />
                {getError('WeekdayHours') && (
                  <div className="error">{getError('WeekdayHours')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="FridayHours">
                <span className="label-text">Friday Hours</span>
                <input
                  type="text"
                  id="FridayHours"
                  value={dealer ? dealer.FridayHours : ''}
                  onChange={e => {
                    handleChange('FridayHours', e.target.value);
                  }}
                  maxLength="8000"
                  disabled={isLoading}
                />
                {getError('FridayHours') && (
                  <div className="error">{getError('FridayHours')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="SaturdayHours">
                <span className="label-text">Saturday Hours</span>
                <input
                  type="text"
                  id="SaturdayHours"
                  value={dealer ? dealer.SaturdayHours : ''}
                  onChange={e => {
                    handleChange('SaturdayHours', e.target.value);
                  }}
                  maxLength="8000"
                  disabled={isLoading}
                />
                {getError('SaturdayHours') && (
                  <div className="error">{getError('SaturdayHours')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="SundayHours">
                <span className="label-text">Sunday Hours</span>
                <input
                  type="text"
                  id="SundayHours"
                  value={dealer ? dealer.SundayHours : ''}
                  onChange={e => {
                    handleChange('SundayHours', e.target.value);
                  }}
                  maxLength="8000"
                  disabled={isLoading}
                />
                {getError('SundayHours') && (
                  <div className="error">{getError('SundayHours')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="HolidayHours">
                <span className="label-text">Holiday Hours</span>
                <input
                  type="text"
                  id="HolidayHours"
                  value={dealer ? dealer.HolidayHours : ''}
                  onChange={e => {
                    handleChange('HolidayHours', e.target.value);
                  }}
                  maxLength="8000"
                  disabled={isLoading}
                />
                {getError('HolidayHours') && (
                  <div className="error">{getError('HolidayHours')}</div>
                )}
              </label>
            </div>

            <div className="settings-item">
              <button
                type="button"
                className="button"
                disabled={isLoading || hasErrors() || !dealerHasChanged}
                onClick={handleSave}
              >
                SAVE CHANGES
              </button>
            </div>
          </>
        )}
      </div>
    </StyledSettings>
  );
};

export default Hours;
