import React from 'react';
import PropTypes from 'prop-types';

const GrayCloseIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C2.2379 0 0 2.2379 0 5C0 7.7621 2.2379 10 5 10C7.7621 10 10 7.7621 10 5C10 2.2379 7.7621 0 5 0ZM7.45161 6.3125C7.54637 6.40726 7.54637 6.56048 7.45161 6.65524L6.65323 7.45161C6.55847 7.54637 6.40524 7.54637 6.31048 7.45161L5 6.12903L3.6875 7.45161C3.59274 7.54637 3.43952 7.54637 3.34476 7.45161L2.54839 6.65323C2.45363 6.55847 2.45363 6.40524 2.54839 6.31048L3.87097 5L2.54839 3.6875C2.45363 3.59274 2.45363 3.43952 2.54839 3.34476L3.34677 2.54637C3.44153 2.45161 3.59476 2.45161 3.68952 2.54637L5 3.87097L6.3125 2.54839C6.40726 2.45363 6.56048 2.45363 6.65524 2.54839L7.45363 3.34677C7.54839 3.44153 7.54839 3.59476 7.45363 3.68952L6.12903 5L7.45161 6.3125Z"
      fill={color}
    />
  </svg>
);

GrayCloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

GrayCloseIcon.defaultProps = {
  color: '#9B9B9B',
  width: '15',
  height: '15',
};

export default GrayCloseIcon;
