import React from 'react';
import PropTypes from 'prop-types';
import StyledStickerSelect from './StickerSelect.Style';
import TagManager from 'react-gtm-module';
import { analyticsSendEvent } from '../../utils';

function StickerSelect({
  stickerOptions,
  selectedStickerTypeId,
  updateStickerType,
  selectProps,
  selectProps: { disabled, id },
}) {
  return (
    <StyledStickerSelect
      htmlFor={id}
      onClick={event => {
        event.preventDefault();
      }}
      className={disabled ? 'disabled' : ''}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
      <span className="label">Sticker Text</span>
      <select
        name={id}
        value={disabled ? 0 : selectedStickerTypeId}
        onChange={event => {
          updateStickerType(event);
        }}
        {...selectProps}
      >
        {stickerOptions &&
          stickerOptions.map(({ StickerDisplayText, StickerTypeId }) => (
            <option
              key={StickerTypeId}
              value={StickerTypeId}
              onClick={event => {
                // event.preventDefault();
              }}
            >
              {StickerDisplayText}
            </option>
          ))}
      </select>
    </StyledStickerSelect>
  );
}

StickerSelect.propTypes = {
  stickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      StickerDisplayText: PropTypes.string,
      StickerTypeId: PropTypes.number,
    })
  ).isRequired,
  selectedStickerTypeId: PropTypes.number.isRequired,
  updateStickerType: PropTypes.func.isRequired,
  selectProps: PropTypes.shape(),
};

StickerSelect.defaultProps = {
  selectProps: { disabled: false },
};

export default StickerSelect;
