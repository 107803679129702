import styled from 'styled-components';

const StyledSiteItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 25px;
  padding-bottom: 100px;
  flex: 1;
  @media screen and (min-width: 1024px) {
    margin: 0 0 0 100px;
  }

  .main-column {
    flex: 1 1 100%;
    .image-uploader {
      margin-bottom: 20px;
    }
    @media screen and (min-width: 1024px) {
      flex: 0;
      min-width: 460px;
      margin-right: 100px;
    }
  }

  .content-field-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tag {
    background-color: #e2f7ff;
    border: 1px solid #0075c9;
    color: #0075c9;
    padding: 4px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
  }

  .card {
    width: auto !important;
  }

  .save-grid {
    display: grid;
    grid: auto / auto auto auto;
  }

  .side-column {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 1024px) {
      flex-flow: column nowrap;

      flex: 0;
      margin-right: auto;
      min-width: 460px;
      margin-right: 100px;
    }

    .status-box {
      flex: 1 1 100%;
      padding: 1em;
      margin-top: 20px;
      @media screen and (min-width: 1024px) {
        flex: 0;
        margin-top: 0px;
      }
      button.publish {
        background: #00b0ac;
        height: 45px;
        box-shadow: 0 9px 18px -12px #10a29f;
        border: solid 1px var(--kiddie-pool);
        color: white;
        border-radius: 2px;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 25px;
        &:disabled {
          background: #e4e5e7;
          box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
          cursor: not-allowed;
        }
      }

      .status-line {
        margin-bottom: 25px;
        font-size: 14px;
        position: relative;
        strong {
          font-weight: 600;
          color: #25292d;
        }
        span {
          color: #4e5255;
        }
        button.clean {
          margin: 0 0 0 4px;
          color: #0075c9;
        }
        .tooltip {
          background: #fff;
          box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
          border: 1px solid #e4e5e7;
          width: 134px;
          text-align: center;
          position: absolute;
          z-index: 9;
          top: 20px;
          right: -17px;

          .overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 995;
            background: rgba(0, 0, 0, 0);
            opacity: 0;
          }

          button {
            padding: 10px 0;
            background: none;
            outline: none;
            border: none;
            width: 100%;
            font: 400 14px/100% source-sans-pro;
            color: #777b7e;
            position: relative;
            z-index: 999;

            &:hover {
              cursor: pointer;
            }
          }

          .active {
            background: #f3f5f7;
          }
        }
      }
      .last-col {
        text-align: right;
      }
      .bottom-button {
        text-align: center;
        margin-left: -1em;
        margin-right: -1em;
        border-top: 1px solid #e4e5e7;
        button {
        }
      }
    }
    .message {
      font-size: 12px;
      font-weight: 600;
      color: #777b7e;
      line-height: 1.17;
      display: flex;
      .checkmark {
        background: #00b0ac;
        height: 14px;
        min-width: 14px;
        max-width: 14px;
        margin-right: 13px;
        border-radius: 50%;
      }
    }
  }

  .card {
    background: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    border: solid 1px #e4e5e7;
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .image-field {
    text-align: center;
    margin-bottom: 45px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 0 1rem 0;
    .image-wrapper {
      padding: 1rem;
      min-height: 200px;
    }

    img {
      border: solid 1px #e4e5e7;
    }
    button.clean {
      margin: 0;
    }
  }
  button.clean {
    cursor: pointer;
    margin: 1rem 0 0 0;
    background: none;
    border: none;
    text-transform: uppercase;
    color: #9b9b9b;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    &:disabled {
      cursor: not-allowed;
      color: #cbcbcb;
    }
  }
  .input-field {
    label {
      display: flex;
      flex-flow: row wrap;
    }

    .is-featured {
      input {
        margin-bottom: 10px;
      }
    }

    input,
    textarea {
      color: #4e5255;
      flex: 1 1 100%;
      padding: 1em;
      border: solid 1px #e4e5e7;
      margin-bottom: 20px;
      font-size: 15px;
      &#FullCopy {
        height: 90px;
        resize: none;
      }
      &#Summary {
        margin-bottom: 10px;
      }
    }
    #Content {
      min-height: 250px;
      overflow: auto;
      resize: none;
    }
    .character-count {
      font-size: 13px;
      color: #4e5255;
      padding: 0;
      margin: 0 0 5px;
      display: block;
      width: 100%;
    }

    .error {
      color: #ff0000;
    }

    .featured-error {
      margin-bottom: 5px;
    }
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 0;
    display: block;
  }
  .sub-label {
    font-size: 13px;
    color: #4e5255;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
  }
  .checkbox-display {
    display: initial !important;
    margin-right: 15px;
  }
  .is-featured-text {
    font-size: 13px;
    color: #4e5255;
    padding: 0;
    margin: 0 0 20px;
    display: block;
    width: 100%;
  }
  .input-area {
    background-color: white;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 1em;
    border: solid 1px #e4e5e7;
    margin-bottom: 20px;
    font-size: 15px;
  }
`;

export default StyledSiteItem;
