import React, { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getUserData, getUserDataFailTest, getSignoutUrl } from '../Api';
import face from '../images/face.png';

export const UserContext = React.createContext({
  user: {
    AuthorizedRetailSites: [],
    AvatarImageReference: face,
    loading: true,
    isError: false,
    error: null,
  },
});

export const UserProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [state, setState] = useState(user);

  const setActiveLotId = lotId => {
    if (lotId || lotId === 0) {
      localStorage.setItem('activeLotId', lotId);
      setState({
        ...state,
        activeLotId: lotId,
      });
    } else {
      setState({
        ...state,
        isError: true,
        error: new Error(`Unable to set lotId to ${lotId}`),
      });
    }
  };

  const signout = async () => {
    const signoutUrl = await getSignoutUrl();

    localStorage.removeItem('activeLotId');
    localStorage.removeItem('token');
    setTimeout(() => {
      window.location.href = signoutUrl;
    }, 0);
  };

  const activeLot = useMemo(() => {
    if (!state.AuthorizedRetailSites) {
      return undefined;
    }
    return Object.keys(state.AuthorizedRetailSites).length
      ? state.AuthorizedRetailSites[state.activeLotId]
      : undefined;
  }, [state.AuthorizedRetailSites, state.activeLotId]);

  const isLoading = useMemo(() => {
    return state.loading;
  }, [state.loading, state.error]);

  const isError = useMemo(() => {
    return state.isError;
  }, [state.isError, state.loading]);

  const error = useMemo(() => {
    return state.error;
  }, [state.error]);

  const loadUserData = async () => {
    try {
      const data = await getUserData();

      if (data?.status) {
        // we didnt get a json object, so we presume error
        throw data?.statusText === ''
          ? data.status + ' (no other details provided)'
          : data?.statusText || 'An unknown error occurred.';
      }

      if (!data.AuthorizedRetailSites) {
        setState({
          ...data,
          AvatarImageReference: face,
          loading: false,
          isError: true,
          error: new Error('User is not authorized for any lot number.'),
        });
      } else if (!state.activeLotId) {
        const authorizedSites = data.AuthorizedRetailSites.reduce(
          (prev, next) => {
            return { ...prev, [next.LotNumber]: { ...next } };
          },
          {}
        );
        setState({
          ...data,
          AvatarImageReference: data.AvatarImageReference || face,
          AuthorizedRetailSites: authorizedSites,
          activeLotId:
            localStorage.getItem('activeLotId') ||
            data.AuthorizedRetailSites[0].LotNumber,
          loading: false,
          isError: false,
        });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
        isError: true,
        error,
      });
    }
  };

  useEffect(() => {
    loadUserData();
  }, [state.activeLotId]);

  return (
    <UserContext.Provider
      value={{
        user: state,
        setActiveLotId,
        activeLot,
        signout,
        isLoading,
        isError,
        error,
        loadUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
