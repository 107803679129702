import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import StyledEditModal from './EditModal.Style';
import CloseIcon from '../../icons/CloseIcon';
import Toggle from './../Toggle';
import ImageUploader from '../ImageUploader/ImageUploader';
import LoadingDots from './../LoadingDots';
import { getOrientation, resetOrientation } from '../../utils';
import { WelcomeHomeContext } from './WelcomeHomeProvider';
import ImageEditor from '../ImageEditor/ImageEditor';

const EditModal = ({
  fullName,
  galleryCheck,
  homeCheck,
  show,
  closeCallback,
  saveCallback,
  discardCallback,
  useCloseIcon,
  useOkBtn,
  currentItem,
  title,
  description,
  closeCopy,
  imageData,
  alwaysAllowSave,
}) => {
  const { updateWelcomeHomeImage, isLoading, isSubmitting } = useContext(
    WelcomeHomeContext
  );
  const [galleryShown, setGalleryShown] = useState(galleryCheck);
  const [homeShown, setHomeShown] = useState(homeCheck);
  const [newSortOrder, setNewSortOrder] = useState(currentItem.SortOrder);
  const [replaceImage, setReplaceImage] = useState(false);
  const [allowSave, setAllowSave] = useState(false);
  const [rawImageData, setRawImageData] = useState(currentItem.ImageReference);
  const [imageFile, setImageFile] = useState(null);
  const [orgOrientation, setOrgOrientation] = useState(null);
  const [newImgObject, setNewImgObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const newItem = {
    ...currentItem,
    SortOrder: newSortOrder,
    IsShownInGallery: galleryShown,
    Active: homeShown,
  };

  const handleSave = useCallback(() => {
    setLoading(true);
    updateWelcomeHomeImage(
      {
        active: homeShown,
        WelcomeHomeItemId: newItem.WelcomeHomeItemId,
        welcomeHomeImage: replaceImage ? newItem.ImageReference : rawImageData,
        firstName: newItem.FirstName,
        lastName: newItem.LastName,
        email: newItem.Email,
        hasConsentedToMediaRelease: newItem.HasConsentedToMediaRelease,
        isShownInGallery: galleryShown,
        sortOrder: newSortOrder,
        file: newImgObject,
      },
      newItem
    ).then(response => {
      setLoading(false);
      closeClear();
    });
  }, [
    updateWelcomeHomeImage,
    rawImageData,
    galleryShown,
    homeShown,
    newSortOrder,
  ]);

  const handleUpload = imageData => {
    setLoading(true);
    getOrientation(imageData.file, orientation => {
      setOrgOrientation(orientation);
    });
    setImageFile(imageData.file);
    resetOrientation(
      URL.createObjectURL(imageData.file),
      orgOrientation,
      (resetResultImageUrl, resetResultImageBlob) => {
        var file = new File([resetResultImageBlob], imageData.file.name);
        setNewImgObject(file);
        setRawImageData(resetResultImageUrl);
        setReplaceImage(false);
        setLoading(false);
      }
    );
  };

  const isEqual = function(obj1, obj2) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    for (let objKey of obj1Keys) {
      if (obj1[objKey] !== obj2[objKey]) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (alwaysAllowSave) {
      setAllowSave(true);
      return;
    }
    let objectsAreEqual = isEqual(newItem, currentItem);
    if (objectsAreEqual && imageFile === null) {
      setAllowSave(false);
    } else {
      setAllowSave(true);
    }
  }, [newItem, currentItem]);

  useEffect(() => {
    if (homeShown && currentItem.SortOrder === null) {
      setNewSortOrder(8);
    } else if (homeShown == false) {
      setNewSortOrder(null);
    } else {
      setNewSortOrder(currentItem.SortOrder);
    }
  }, [homeShown]);

  useEffect(() => {
    document.documentElement.classList.add('modal-lock');
  }, []);

  const closeClear = () => {
    document.documentElement.classList.remove('modal-lock');
    closeCallback();
    setImageFile(null);
    setNewImgObject(null);
    setReplaceImage(false);
  };

  const doneEditingImage = editedImage => {
    setLoading(false);
    setImageFile(editedImage.file);
    setNewImgObject(editedImage.file);
    setRawImageData(URL.createObjectURL(editedImage.file));
    setShowImageEditModal(false);
  };

  const handleAdjustImage = () => {
    setLoading(true);
    setShowImageEditModal(true);
  };

  const cancelEditingImage = () => {
    setLoading(false);
    setShowImageEditModal(false);
  };

  return (
    <StyledEditModal
      useCloseIcon={useCloseIcon}
      useOkBtn={useOkBtn}
      className="modal"
      style={{ display: show ? 'flex' : 'none' }}
    >
      <div role="presentation" className="overlay" onClick={closeClear} />
      <div className="modal-content">
        <button type="button" className="close-modal-icon" onClick={closeClear}>
          <CloseIcon color="black" width="28px" height="28px" />
        </button>
        {title && <h2>{title}</h2>}
        {description && <p className="description">{description}</p>}
        {!title && (
          <h2>{fullName ? 'Edit Image for ' + fullName : 'Edit Image'}</h2>
        )}
        <div className="edit-modal-container">
          <div className="edit-modal-left">
            {!replaceImage && (
              <div className="current-image-box">
                <div className="current-image">
                  <img
                    src={
                      rawImageData +
                      (rawImageData.includes('blob:') ? '' : '?width=800')
                    }
                    onLoad={e => {
                      setIsImgLoaded(true);
                    }}
                  />

                  {!isImgLoaded && (
                    <div className="img-placeholder">
                      <LoadingDots />
                    </div>
                  )}
                </div>
                {isImgLoaded && (
                  <div className="current-image-options">
                    <p
                      onClick={() => {
                        setReplaceImage(true);
                      }}
                    >
                      Replace Image
                    </p>
                    <p onClick={handleAdjustImage}>Adjust Image</p>
                  </div>
                )}
              </div>
            )}
            {replaceImage && (
              <ImageUploader
                uploadFunction={handleUpload}
                stageImages={false}
                allowMultiple={false}
                headerText={`Upload Welcome Home image (required)`}
                minHeight={600}
                minWidth={600}
                isWelcomeHomeImage={true}
              />
            )}
          </div>

          <div className="edit-modal-right">
            <div className="toggle-box">
              <Toggle
                label="Display on Home Page"
                onToggle={event => {
                  if (!newItem.HasConsentedToMediaRelease) {
                    return;
                  }
                  setHomeShown(event.currentTarget.checked);
                }}
                inputProps={{
                  checked: homeShown,
                }}
              />
              <Toggle
                label="Display on Gallery Page"
                onToggle={event => {
                  if (!newItem.HasConsentedToMediaRelease) {
                    return;
                  }
                  setGalleryShown(event.currentTarget.checked);
                }}
                inputProps={{
                  checked: galleryShown,
                }}
              />
              {!newItem.HasConsentedToMediaRelease && (
                <div className="signed-consent-warning">
                  Images without consent will not be displayed on your website.
                </div>
              )}
            </div>
            <button
              className={
                !isImgLoaded || loading || !allowSave
                  ? 'save-btn loading-btn'
                  : 'save-btn'
              }
              type="button"
              onClick={() => {
                if (!loading && allowSave) {
                  handleSave();
                }
              }}
            >
              {loading ? (
                <LoadingDots className={'white-loading-dots'} />
              ) : (
                'Save'
              )}
            </button>
          </div>
          {showImageEditModal && (
            <ImageEditor
              imageData={rawImageData}
              onDoneEditing={doneEditingImage}
              onCancelEditing={cancelEditingImage}
              aspect={1}
            />
          )}
        </div>

        {closeCopy && (
          <div className="btns-container">
            <button
              type="button"
              className="ok-btn"
              onClick={e => {
                handleSave();
                closeCallback();
              }}
            >
              {closeCopy}
            </button>
          </div>
        )}

        {showImageEditModal && (
          <ImageEditor
            imageData={rawImageData}
            onDoneEditing={doneEditingImage}
            onCancelEditing={cancelEditingImage}
            aspect={1}
          />
        )}
      </div>
    </StyledEditModal>
  );
};

EditModal.propTypes = {
  fullName: PropTypes.string,
  show: PropTypes.bool,
  galleryCheck: PropTypes.bool,
  homeCheck: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  discardCallback: PropTypes.func.isRequired,
};

EditModal.defaultProps = {
  fullName: '',
  show: false,
};

export default EditModal;
