import styled from 'styled-components';

const StyledSearchBar = styled.div`
  margin: 0 auto 40px 6%;
  flex: 0;
  label {
    width: 325px;
    position: relative;
    display: block;
    height: 25px;
    /* border-bottom: 1px solid #e6e6e6; */
    font-size: 15px;

    /* &.focus {
      .placeholder {
        opacity: 0;
        transform: scale(0.2) translateY(-5px);
      }
    } */

    .search-icon {
      width: 5%;
      margin-left: 1%;
    }
    .placeholder {
      display: inline-block;
      opacity: 1;
      margin-left: 4%;
      transform: scale(1) translateY(0);
      transition: all 0.2s;
      color: #a1a3a6;
      &.hide {
        opacity: 0;
        transform: scale(0.5) translateY(-10px);
      }
    }
    input {
      font-family: 'source-sans-pro', sans-serif;
      position: absolute;
      top: 0;
      left: 16px;
      width: 91%;
      bottom: 5px;
      border: none;
      background: transparent;
      padding: 0 0 0 3%;
      outline: none;
      font-size: 15px;
      color: #a1a3a6;
    }
    .bar {
      position: absolute;
      left: 0;
      bottom: 1px;
      .line {
        stroke-dasharray: 325px;
        stroke-dashoffset: 325px;
        transition: all 0.2s ease-in-out;
      }
    }
    input:focus + .bar {
      .line {
        stroke-dashoffset: 0;
      }
    }
  }
`;

export default StyledSearchBar;
