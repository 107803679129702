import { useEffect, useReducer, useRef } from 'react';

/**
 * Custom hook for handling drag events
 */
const useDragging = () => {
  const ref = useRef(null);

  const draggingReducer = (state, action) => {
    switch (action.type) {
      case 'enter':
        return { level: state.level + 1 };
      case 'leave':
        return { level: state.level - 1 };
      case 'drop':
        return { level: 0 };
      default:
        throw new Error('missing action');
    }
  };

  // increment and decrement level with dragging events
  const [state, dispatch] = useReducer(draggingReducer, { level: 0 });

  /**
   * Prevent default on a given event
   *
   * @param {{}} event
   */
  const preventDefault = event => {
    event.preventDefault();
  };

  /**
   * Handler function for the drag enter event
   */
  const handleDragEnter = () => {
    dispatch({ type: 'enter' });
  };

  /**
   * Handler function for the drag leave event
   */
  const handleDragLeave = () => {
    dispatch({ type: 'leave' });
  };

  /**
   * Handler function for the drop event
   */
  const handleDrop = () => {
    dispatch({ type: 'drop' });
  };

  /**
   * On mount, add event listeners and remove them on unmount
   */
  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }
    const { addEventListener, removeEventListener } = ref.current;
    ['drag', 'dragend', 'dragover', 'dragleave', 'dragenter', 'drop'].forEach(
      event => {
        addEventListener(event, preventDefault);
      }
    );
    addEventListener('dragleave', handleDragLeave);
    addEventListener('dragenter', handleDragEnter);
    addEventListener('drop', handleDrop);

    return () => {
      ['drag', 'dragend', 'dragover', 'dragleave', 'dragenter', 'drop'].forEach(
        event => {
          removeEventListener(event, preventDefault);
        }
      );
      removeEventListener('dragenter', handleDragEnter);
      removeEventListener('dragleave', handleDragLeave);
      removeEventListener('drop', handleDrop);
    };
  }, []);

  return [ref, state.level > 0];
};

export default useDragging;
