import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledDealerTag from './DealerTag.Style';
import CloseButton from '../../icons/CloseButton';

const DealerTag = ({ dealer, removeDealer }) => {
  return (
    <StyledDealerTag>
      <span title={dealer.Name}>{dealer.LotNumber}</span>
      <button type="button" className="closeButton" onClick={removeDealer}>
        <CloseButton />
      </button>
    </StyledDealerTag>
  );
};

DealerTag.propTypes = {
  dealer: PropTypes.object.isRequired,
  removeDealer: PropTypes.func,
};

DealerTag.defaultProps = {};

export default React.memo(DealerTag);
