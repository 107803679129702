import { getUniqueWhItemId, findWhItemByWildCardId } from '../utils';

export const welcomeHomeReducer = (state, action) => {
  if (!action) return { ...state };

  switch (action.type) {
    case 'FETCH_WELCOME_HOME_ITEMS_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_WELCOME_HOME_ITEMS_SUCCESS':
      return {
        ...state,
        welcomeHomeItems: action.payload.welcomeHomePayload,
        activeImages: action.payload.activeImagesPayload,
        originalOrder: action.payload.activeImagesPayload,
        isLoading: false,
        isError: false,
      };
    case 'FETCH_WELCOME_HOME_ITEMS_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'SET_WELCOME_HOME_ORDER':
      return {
        ...state,
        originalOrder: action.payload,
        activeImages: action.payload,
        isLoading: false,
        isError: false,
      };
    case 'MOVE_ACTIVE_IMAGE':
      return {
        ...state,
        // welcomeHomeItems: action.payload,
        // originalOrder: [...state.originalOrder],
        activeImages: action.payload,
        isLoading: false,
        isError: false,
      };
    case 'UPDATE_WELCOME_HOME_ACTIVE_IMAGE_ORDER_INIT': {
      return { ...state, isUpdating: true };
    }
    case 'SEARCH':
      return {
        ...state,
        query: action.payload,
      };
    case 'DISCARD_WELCOME_HOME_ACTIVE_IMAGE_ORDER': {
      return {
        ...state,
        originalOrder: action.payload,
        activeImages: action.payload,
        isUpdating: false,
        isLoading: false,
        isError: false,
      };
    }
    case 'SAVE_WELCOME_HOME_ACTIVE_IMAGE_ORDER': {
      return {
        ...state,
        originalOrder: action.payload,
        activeImages: action.payload,
        isUpdating: false,
        isLoading: false,
        isError: false,
      };
    }
    case 'INIT_WELCOME_HOME_ACTIVE':
      return {
        ...state,
        // originalOrder: action.payload,
        activeImages: action.payload,
        isLoading: false,
        isError: false,
      };
    case 'INIT_WELCOME_HOME_ACTIVE_ORIGINAL':
      return {
        ...state,
        originalOrder: action.payload,
        isLoading: false,
        isError: false,
      };
    case 'TOGGLE_WELCOME_HOME_ACTIVE_IMAGE_INIT': {
      return { ...state, isUpdating: true };
    }
    case 'ADD_WELCOME_HOME_ITEM_INIT':
      return {
        ...state,
        welcomeHomeItems: [...state.welcomeHomeItems, action.payload],
        isSubmitting: true,
        isError: false,
      };
    case 'ADD_WELCOME_HOME_ITEM_SUCCESS':
      return {
        ...state,
        welcomeHomeItems: [...state.welcomeHomeItems, action.payload],
        isSubmitting: false,
        isError: false,
      };
    case 'ADD_WELCOME_HOME_ITEM_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'DELETE_WELCOME_HOME_ITEM_INIT':
      return {
        ...state,
        isSubmitting: true,
        isLoading: true,
        isError: false,
      };
    case 'DELETE_WELCOME_HOME_ITEM_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        isLoading: false,
        isError: false,
      };
    case 'DELETE_WELCOME_HOME_ITEM_FAILURE':
      return {
        ...state,
        isSubmitting: false,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'UPDATE_WELCOME_HOME_ITEM_INIT':
      return {
        ...state,
        welcomeHomeItems: [
          ...state.welcomeHomeItems.map(item =>
            item.WelcomeHomeItemId === action.payload.WelcomeHomeItemId
              ? { ...item, isUpdating: true }
              : item
          ),
          action.payload,
        ],
        isSubmitting: true,
        isLoading: true,
        isUpdating: true,
        isError: false,
      };
    case 'UPDATE_WELCOME_HOME_ITEM_SUCCESS':
      return {
        ...state,
        welcomeHomeItems: [
          ...state.welcomeHomeItems.map(item =>
            item.WelcomeHomeItemId === action.payload.WelcomeHomeItemId
              ? { ...item, isUpdating: false }
              : item
          ),
          action.payload,
        ],
        activeImages: action.payload.activeImagesPayload,
        isSubmitting: false,
        isLoading: false,
        isUpdating: false,
        isError: false,
      };
    case 'UPDATE_WELCOME_HOME_ITEM_FAILURE':
      return {
        ...state,
        welcomeHomeItems: [
          ...state.welcomeHomeItems.map(item =>
            item.WelcomeHomeItemId === action.payload.WelcomeHomeItemId
              ? { ...item, isUpdating: false }
              : item
          ),
          action.payload,
        ],
        isLoading: false,
        isUpdating: false,
        isError: true,
        error: action.payload.error,
      };

    case 'FETCH_BOOKED_OPPORTUNITIES_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_BOOKED_OPPORTUNITIES_SUCCESS':
      return {
        ...state,
        bookedOpportunities: action.payload.bookedOpportunities,
        isLoading: false,
        isError: false,
      };
    case 'FETCH_BOOKED_OPPORTUNITIES_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'SET_IS_NAVIGATION_MODAL_VISIBLE':
      return {
        ...state,
        isNavigationModalVisible: action.payload,
      };
    case 'SET_TARGET_LOCATION':
      return {
        ...state,
        targetLocation: action.payload,
      };
    case 'SET_CONFIRMED_NAVIGATION': {
      return {
        ...state,
        confirmedNavigation: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default welcomeHomeReducer;
