import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

const RotateLine1Posed = posed.line({
  open: { transform: 'translate(49 947.5) rotate(45, 2.4, 1)' },
  close: { transform: 'translate(47.5 946.5) rotate(0, 2.4, 1)' },
});

const HideLine2Posed = posed.line({
  open: { stroke: 'rgba(255,255,255,0)' },
  close: { stroke: '#fff' },
});

const RotateLine3Posed = posed.line({
  open: { transform: 'translate(49 956.5) rotate(-45, 2.4, 1)' },
  close: { transform: 'translate(47.5 956.5) rotate(0, 2.4, 1)' },
});

const NavIcon = ({ open }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 18 12"
      strokeWidth="2"
    >
      <g id="navIcon" transform="translate(-47.5 -945.5)">
        <RotateLine1Posed
          pose={open ? 'open' : 'close'}
          id="Line_4"
          data-name="Line 4"
          x2="18"
          transform="translate(47.5 946.5) rotate(0)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <HideLine2Posed
          pose={open ? 'open' : 'close'}
          id="Line_5"
          data-name="Line 5"
          x2="18"
          transform="translate(47.5 951.5) rotate(0)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <RotateLine3Posed
          pose={open ? 'open' : 'close'}
          id="Line_6"
          dataName="Line 6"
          x2="18"
          transform="translate(47.5 956.5) rotate(0)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

NavIcon.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default NavIcon;
