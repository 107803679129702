import React, { useContext, useState, useEffect, useRef } from 'react';
import StyledSettings from './Urls.Style';
import { isValidUrl, isValidDomain, insertTextAtCaret } from '../../../utils';
import {
  getWebSiteTemplates,
  getDealer,
  updateContentSiteItem,
  updateDealer,
} from '../../../Api';
import LoadingDots from '../../LoadingDots';
import { UserContext } from '../../User';
import { FormPrompt } from '../../FormPrompt';

const Urls = ({ history }) => {
  const [dealer, setDealer] = useState(null);
  const [dealerSnapshot, setDealerSnapshot] = useState(null);
  const [dealerHasChanged, setDealerHasChanged] = useState(false);
  const [templateTypes, setTemplateTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageError, setIsPageError] = useState(false);
  const { activeLot, user } = useContext(UserContext);
  const settingsFormRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [skipValidation, setSkipValidation] = useState(false);
  const fbTextRef = useRef(null);
  const googleTextRef = useRef(null);

  // corp users only
  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser) {
      history.push('/');
    }
  }, [user]);

  // first time load available web site templates and dealer info
  useEffect(() => {
    if (!activeLot) return;
    (async () => {
      try {
        const dealer = await getDealer(activeLot.LotNumber);
        setDealer(dealer);
        setDealerSnapshot(dealer);
        const templateTypes = await getWebSiteTemplates();
        setTemplateTypes(templateTypes);
        setIsLoading(false);
        setDealerHasChanged(false);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
    })();
  }, [activeLot]);

  // on dealer change, validate
  useEffect(() => {
    if (!dealer || !errors) return;

    // enable save button on change
    if (dealerSnapshot != null) {
      setDealerHasChanged(true);
    }

    if (!skipValidation) validateAll();

    setSkipValidation(false);
  }, [dealer]);

  const handleChange = (propName, newValue) => {
    setDealer({ ...dealer, [propName]: newValue });
  };

  const handleVanityUrlChange = (index, newValue) => {
    let newUrls = [...dealer.VanityUrls];
    newUrls[index] = newValue;
    setDealer({ ...dealer, ['VanityUrls']: newUrls });
  };

  const handleSave = () => {
    validateAll();
    if (hasErrors()) return;

    (async () => {
      setIsLoading(true);
      try {
        const response = await updateDealer(activeLot.LotNumber, dealer);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
      setDealerHasChanged(false);
    })();
  };

  const validateLatLong = (propName, val) => {
    const error = val && val != '' ? null : propName + ' is required.';

    return { [propName]: error };
  };

  const validateAll = () => {
    let newErrors = {
      ...errors,
      ['VanityUrls']: validateVanityUrls(),
      ['FacebookUrl']: validateUrl('FacebookUrl', dealer['FacebookUrl']),
      ['GoogleReviewUrl']: validateUrl(
        'GoogleReviewUrl',
        dealer['GoogleReviewUrl']
      ),
    };

    setErrors(newErrors);
  };

  const validateUrl = (propName, val) => {
    let error = null;

    if (!val || val === '') error = null;
    else if (!isValidUrl(val)) error = propName + ' must be a valid url.';

    return error;
  };

  const validateVanityUrls = () => {
    let newErrors = [];

    dealer.VanityUrls.forEach((url, index) => {
      if (!url || url === '') {
        newErrors[index] = 'Url is required.';
      } else if (!isValidDomain(url)) {
        newErrors[index] = 'Must be a valid domain name.';
      }
    });

    return newErrors;
  };

  const hasErrors = () => {
    if (Object.values(errors).length == 0) return false;

    return Object.values(errors).every(function(i) {
      if (i === null) return true;
      if (typeof i != 'object') return i !== null;
      else {
        if (i.length == 0) return false;
        return i.every(function(i) {
          return i !== null && i !== undefined;
        });
      }
    });
  };

  const getError = propName => {
    return errors.hasOwnProperty(propName) && errors[propName]
      ? errors[propName]
      : null;
  };

  const getErrorFromArray = (propName, index) => {
    return errors.hasOwnProperty(propName) && errors[propName][index]
      ? errors[propName][index]
      : null;
  };

  const handleAddVanityUrl = () => {
    let newUrls = [...dealer.VanityUrls];
    newUrls.push('');
    // update dealer, but let's skip validation
    // so user has a chance to enter before being shown any errors
    setSkipValidation(true);
    setDealer({ ...dealer, ['VanityUrls']: newUrls });
  };

  const handleDeleteVanityUrl = index => {
    let newUrls = [...dealer.VanityUrls];
    newUrls.splice(index, 1);
    setDealer({ ...dealer, ['VanityUrls']: newUrls });
  };

  const pasteText = (text, elementRef, dealerPropertyToUpdate) => {
    insertTextAtCaret(elementRef.current, text);
    handleChange(dealerPropertyToUpdate, elementRef.current.value);
  };

  return (
    <StyledSettings>
      <FormPrompt hasUnsavedChanges={dealerHasChanged} />
      <div>
        {isLoading && <LoadingDots />}
        {isPageError && (
          <div className="error">
            There was an error loading setttings, please try again later.
          </div>
        )}
        {!isPageError && (
          <form id="settings" ref={settingsFormRef}>
            <div className="settings-item">
              <label className="label" htmlFor="facebook-url">
                Facebook URL
                <div>
                  <button
                    type="button"
                    className="publish tag"
                    onClick={e =>
                      pasteText(
                        'https://www.facebook.com/',
                        fbTextRef,
                        'FacebookUrl'
                      )
                    }
                  >
                    {'Insert Facebook Base Url'}
                  </button>
                </div>
                <input
                  ref={fbTextRef}
                  type="text"
                  name="facebook-url"
                  id="facebook-url"
                  value={dealer ? dealer.FacebookUrl : ''}
                  disabled={isLoading}
                  onChange={e => handleChange('FacebookUrl', e.target.value)}
                />
                <div className="error">{getError('FacebookUrl')}</div>
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="google-review-url">
                Google Review URL
                <div>
                  <button
                    type="button"
                    className="publish tag"
                    onClick={e =>
                      pasteText(
                        'http://search.google.com/local/writereview?placeid=',
                        googleTextRef,
                        'GoogleReviewUrl'
                      )
                    }
                  >
                    {'Insert Google Base Url'}
                  </button>
                </div>
                <input
                  ref={googleTextRef}
                  type="text"
                  name="google-review-url"
                  id="google-review-url"
                  value={dealer ? dealer.GoogleReviewUrl : ''}
                  disabled={isLoading}
                  onChange={e =>
                    handleChange('GoogleReviewUrl', e.target.value)
                  }
                />
                <div className="error">{getError('GoogleReviewUrl')}</div>
              </label>
            </div>

            <div className="settings-item">
              <label className="label" htmlFor="latitude">
                Home Center Urls
                {dealer &&
                  dealer.VanityUrls.map((url, index) => (
                    <div className="row hc-url" key={index}>
                      <input
                        type="text"
                        name={'vanity-url-' + index}
                        id={'vanity-url-' + index}
                        value={url}
                        disabled={isLoading}
                        onChange={e =>
                          handleVanityUrlChange(index, e.target.value)
                        }
                      />

                      <div className="error">
                        {getErrorFromArray('VanityUrls', index)}
                      </div>

                      <span className="tool-column ">
                        <button
                          type="button"
                          className="delete-btn"
                          onClick={() => handleDeleteVanityUrl(index)}
                        >
                          <span className="delete-btn-content">DELETE</span>
                          <span className="delete-btn-content-mobile">X</span>
                        </button>
                      </span>
                    </div>
                  ))}
                <div className="settings-item">
                  <button
                    type="button"
                    className="button add-btn"
                    disabled={isLoading || hasErrors()}
                    onClick={handleAddVanityUrl}
                  >
                    ADD URL
                  </button>
                </div>
              </label>
            </div>

            <div className="settings-item">
              <button
                type="button"
                className="button"
                disabled={isLoading || hasErrors() || !dealerHasChanged}
                onClick={handleSave}
              >
                SAVE CHANGES
              </button>
            </div>
          </form>
        )}
      </div>
    </StyledSettings>
  );
};

export default Urls;
