import posed from 'react-pose';

const PosedLinkBg = posed.div({
  active: { backgroundColor: '#0075c9', transition: { duration: '150' } },
  inactive: { backgroundColor: '#0c1e34', transition: { duration: '150' } },
  pressable: true,
  press: {
    scale: 0.85,
    transition: { duration: 75 },
  },
  init: {
    scale: 1,
    transition: { duration: 75 },
  },
});

export default PosedLinkBg;
