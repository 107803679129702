import styled from 'styled-components';

const StyledOptions = styled.div.attrs({ className: 'options' })`
  label:not(:last-child) {
    margin-bottom: 10px;
  }
  .card {
    /* Override default toggle styles (they were made for home list pages) */
    label {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .toggle-label {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #4e5255;
      }
    }
  }
`;

export default StyledOptions;
