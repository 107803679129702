import React from 'react';

const CloseButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 100 100"
  >
    <circle cx="50" cy="50" r="50" fill="#e4e5e7" />
    <path
      d="M 32 32 L 68 68 M 68 32 L 32 68"
      fill="none"
      stroke="#777b7e"
      strokeWidth="8"
    />
  </svg>
);

export default CloseButton;
