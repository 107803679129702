import styled from 'styled-components';

const StyledAutoComplete = styled.div.attrs({ className: 'autocomplete' })`
  position: relative;
  z-index: 1;
  .suggestions {
    background-color: #29476c;
    position: absolute;
    top: 40px;
    left: 15px;
    right: 15px;
    padding: 10px 0;
    min-height: 140px;
    div {
      color: #fff;
      padding: 8px 15px;
      font-size: 15px;
    }
    .suggestion {
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: #0075c9;
      }
    }
  }
`;

export default StyledAutoComplete;
