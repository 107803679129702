import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Notification from '../Notification';

const Errors = ({ error }) =>
  error.length > 0 && (
    <div className="errors">
      <span className="label">
        Please fix the error(s) below to display this home on the website.
      </span>
      {error.map(err => (
        <Notification
          type="error"
          message={err.message}
          key={err.message}
          action={
            !Object.prototype.hasOwnProperty.call(err, 'onClickHandler') ? (
              <Link to={err.targetUrl}>{err.buttonText}</Link>
            ) : (
              <Link to={err.targetUrl} onClick={err.onClickHandler}>
                {err.buttonText}
              </Link>
            )
          }
        />
      ))}
    </div>
  );

Errors.propTypes = {
  error: PropTypes.arrayOf(PropTypes.shape()),
};

Errors.defaultProps = {
  error: [],
};

export default Errors;
