const permToReadAndWriteLandRecords = [
  192,
  696,
  208,
  384,
  184,
  320,
  702,
  166,
  825,
  123,
  291,
  759,
  1080,
  682,
  679,
  151,
  684,
  768,
  1076,
  269,
];

export const canViewLandRecords = lotNumber => {
  // return permToReadAndWriteLandRecords.indexOf(lotNumber) > -1;
  return true;
};

export const canViewHome = (home, activeLot) => {
  if (!home || !activeLot) return true;
  if (!home.IsLand) return true;
  return canViewLandRecords(activeLot.LotNumber);
};
