import styled from 'styled-components';

const StyledDetailsLandSpecs = styled.div.attrs({
  className: 'DetailsLandSpecs',
})`
  .card {
    display: flex;
    flex-flow: row wrap;
    color: #4e5255;
    font-size: 14px;
    align-items: center;
    .info {
      flex: 1 1 55%;
      margin-right: auto;

      @media screen and (min-width: 320px) {
        min-width: auto;
      }

      @media screen and (min-width: 768px) {
        min-width: 250px;
      }
    }
  }
  .land-card {
    color: #4e5255;
    font-size: 14px;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    background: white;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    padding: 30px;
    flex: 1;
    margin-bottom: 50px;

    .land-info {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;

      @media screen and (min-width: 320px) {
        min-width: auto;
      }

      @media screen and (min-width: 768px) {
        min-width: 250px;
      }

      .name-wrapper {
        flex: 0 0 45%;

        @media screen and (min-width: 768px) {
          flex: 0 0 20%;
        }

        &:first-of-type {
          flex: 0 0 100%;

          @media screen and (min-width: 768px) {
            flex: 0 0 40%;
          }
        }

        &:nth-of-type(2n) {
          @media screen and (min-width: 768px) {
            flex: 0 0 17%;
          }
        }

        &:nth-of-type(3n) {
          @media screen and (min-width: 768px) {
            flex: 0 0 14%;
          }
        }

        &:nth-of-type(n + 6) {
          flex: 0 0 100%;
          @media screen and (min-width: 768px) {
            flex: 0 0 25%;
          }
        }

        &:nth-of-type(8n) {
          @media screen and (min-width: 768px) {
            flex: 0 0 20%;
          }
        }
      }

      input {
        max-width: none;
      }
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.21;
      color: #4e5255;
    }

    .brokerage-disclaimer {
      padding: 20px 0;
    }
  }

  .name-wrapper {
    margin-bottom: 10px;
    label {
      display: flex;
      flex-flow: row wrap;

      &:nth-of-type(2n) {
        margin-top: 20px;
      }
      .label {
        flex: 1 1 100%;
      }
    }
    input {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 44px;
      width: 100%;
      max-width: 278px;
      padding: 0 10px;
      display: block;
      border-radius: 1px;
      border: solid 1px #e4e5e7;
      background-color: #ffffff;
    }
    .name {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 44px;
      display: block;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  select {
    border-radius: 1px;
    border: 1px solid #e4e5e7;
    font-size: 15px;
    font-family: source-sans-pro, sans-serif;
    flex: 0 1 396px;
    padding: 0 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-top: 5px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-color: transparent;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    height: 46px;
  }

  .tags,
  .details {
    display: inline-block;
    margin: 10px 0;
  }
  .details {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    span {
      display: inline-block;
      margin: 0 4px;
      font-weight: 900;
      font-size: 18px;
    }
  }
  .tag {
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    padding: 5px 10px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    display: inline-block;
    margin-right: 10px;
  }
  .description-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 450px;
    label {
      display: flex;
      flex-flow: row wrap;
      .label {
        flex: 1 1 100%;
      }
      .sub-label {
        font-size: 11px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.21;
        color: #4e5255;
        max-width: 450px;
        width: 100%;
      }
    }
    textarea {
      margin-top: 5px;
      font-size: 15px;
      color: #4e5255;
      height: 100px;
      width: 100%;
      max-width: 450px;
      padding: 0 10px;
      display: block;
      border-radius: 1px;
      border: solid 1px #e4e5e7;
      background-color: #ffffff;
    }
    .character-count {
      font-size: 13px;
      color: #4e5255;
      padding: 0;
      margin: 0;
    }

    .description-box-container {
      margin-top: 5px;
      max-width: 160px;

      .description-label {
        margin-left: 5px;
      }
    }
  }
  .numbers {
    flex: 0 1 55%;
    min-width: 250px;
    margin-top: 30px;

    @media screen and (min-width: 320px) {
      min-width: 220px;
    }

    @media screen and (min-width: 768px) {
      min-width: 250px;
    }

    .number {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      color: #777b7e;
      .label {
        display: inline-block;
        margin-right: 10px;
        user-select: none;
      }
    }
  }
`;

export default StyledDetailsLandSpecs;
