import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import isequal from 'lodash.isequal';
import {
  getWelcomeHomeItems,
  createWelcomeHomeItem,
  updateWelcomeHomeItem,
  reorderActiveImage,
  getBookedOpportunities,
  deleteWelcomeHomeItem,
} from '../../Api';
import { UserContext } from '../User';
import { welcomeHomeReducer } from '../../reducers/welcomeHomeItems';
import { qualifyUrl, sortByKey } from '../../utils';
import { ERROR_TOAST_OPTIONS, UNDO_TOAST_OPTIONS } from '../../constants';

const searchWelcomeHomeItems = (query, welcomeHomeItems) => {
  if (query === '') {
    return welcomeHomeItems;
  }
  if (welcomeHomeItems.length > 0) {
    return welcomeHomeItems.filter(item => {
      return (
        (item.FirstName &&
          item.FirstName.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (item.LastName &&
          item.LastName.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (item.Email &&
          item.Email.toLowerCase().indexOf(query.toLowerCase()) >= 0)
      );
    });
  }
  return [];
};

export const WelcomeHomeContext = React.createContext({
  welcomeHomeItems: null,
  bookedOpportunities: null,
  activeImages: null,
  originalOrder: null,
  currentHistory: null,
  isLoading: true,
  isSubmitting: false,
  isUpdating: false,
  imageIsLoading: false,
  isError: false,
  confirmedNavigation: false,
  setIsSuccessModalVisible: false,
  query: '',
});

export const WelcomeHomeProvider = ({ children, history }) => {
  const context = useContext(WelcomeHomeContext);
  const { activeLot, user } = useContext(UserContext);
  const [state, dispatch] = useReducer(welcomeHomeReducer, context);

  const filterWelcomeHomeItems = query => {
    dispatch({ type: 'SEARCH', payload: query });
  };

  const currentHistory = useMemo(() => {
    return state.currentHistory ? state.currentHistory : history;
  }, [state.currentHistory]);

  const welcomeHomeItems = useMemo(() => {
    return state.welcomeHomeItems ? state.welcomeHomeItems : [];
  }, [state.welcomeHomeItems]);

  const bookedOpportunities = useMemo(() => {
    return state.bookedOpportunities ? state.bookedOpportunities : [];
  }, [state.bookedOpportunities]);

  const activeImages = useMemo(() => {
    return state.activeImages ? state.activeImages : [];
  }, [state.activeImages]);

  const originalOrder = useMemo(() => {
    return state.originalOrder ? state.originalOrder : [];
  }, [state.originalOrder]);

  const setConfirmNavigation = useCallback(confirm => {
    dispatch({ type: 'SET_CONFIRMED_NAVIGATION', payload: confirm });
  }, []);

  const filteredWelcomeHomeItems = useMemo(
    () => searchWelcomeHomeItems(state.query, welcomeHomeItems),
    [state.query, welcomeHomeItems, activeLot]
  );

  /**
   * This function creates a FormData objects and sends it in a POST request to the API.
   * After a successful request, the image is added to the state
   *
   * @param {object} data the object containing the image data
   * @returns {Promise<void>}
   */
  const uploadWelcomeHomeImage = async data => {
    const {
      file,
      firstName,
      lastName,
      email,
      hasConsentedToMediaRelease,
      vantageId,
      isShownInGallery,
      welcomeHomeImage,
    } = data;
    const formData = new FormData();

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('isShownInGallery', isShownInGallery);
    formData.append('hasConsentedToMediaRelease', hasConsentedToMediaRelease);
    formData.append('vantageId', vantageId);
    formData.append('welcomeHomeImage', welcomeHomeImage);
    formData.append('file', file);
    dispatch({
      type: 'ADD_WELCOME_HOME_ITEM_INIT',
    });

    try {
      const response = await createWelcomeHomeItem(
        activeLot.LotNumber,
        formData
      ).then();
      if (response.status === 200) {
        getWelcomeHome();
        const newItem = await response.json();
        dispatch({
          type: 'ADD_WELCOME_HOME_ITEM_SUCCESS',
          payload: {
            ...newItem,
            Active: false,
            CreationDate: (date => {
              const creationDate = date;
              return creationDate;
            })(newItem.CreationDate),
          },
        });
        return newItem;
      }
      if (response.status === 400) {
        toast.error(
          `${file.name} could not be uploaded. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }
    } catch (error) {
      dispatch({
        type: 'ADD_WELCOME_HOME_ITEM_FAILURE',
        payload: {
          error: 'Failed to update item: invalid request',
        },
      });
      toast.error(
        `${file.name} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }

    dispatch({
      type: 'ADD_WELCOME_HOME_ITEM_SUCCESS',
    });
  };

  const deleteWelcomeHomeImage = async itemId => {
    dispatch({
      type: 'DELETE_WELCOME_HOME_ITEM_INIT',
    });

    try {
      const deleted = await deleteWelcomeHomeItem(
        activeLot.LotNumber,
        itemId
      ).then();

      if (deleted) {
        getWelcomeHome();

        dispatch({
          type: 'DELETE_WELCOME_HOME_ITEM_SUCCESS',
          payload: {},
        });
      } else {
        dispatch({
          type: 'DELETE_WELCOME_HOME_ITEM_FAILURE',
          payload: {
            error: 'Failed to delete item: invalid request',
          },
        });
        toast.error(
          `Item could not be deleted. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }
    } catch (error) {
      dispatch({
        type: 'DELETE_WELCOME_HOME_ITEM_FAILURE',
        payload: {
          error: 'Failed to delete item: invalid request',
        },
      });
      toast.error(
        `Item could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }

    dispatch({
      type: 'DELETE_WELCOME_HOME_ITEM_SUCCESS',
    });
  };

  const updateWelcomeHomeImage = async (data, item) => {
    const {
      firstName,
      lastName,
      email,
      hasConsentedToMediaRelease,
      isShownInGallery,
      WelcomeHomeItemId,
      sortOrder,
      welcomeHomeImage,
      active,
      file,
    } = data;
    const formData = new FormData();

    let currentActiveImages = activeImages.map(obj => obj.WelcomeHomeItemId);

    // if this item is not already an active image, add it
    if (active && !currentActiveImages.includes(WelcomeHomeItemId))
      currentActiveImages.push(item.WelcomeHomeItemId);

    if (currentActiveImages.length > 8) {
      toast.error(`You may not exceed 8 active images.`, ERROR_TOAST_OPTIONS);
      return false;
    }

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('isShownInGallery', isShownInGallery);
    formData.append('hasConsentedToMediaRelease', hasConsentedToMediaRelease);
    formData.append('WelcomeHomeItemId', WelcomeHomeItemId);
    formData.append('SortOrder', sortOrder);
    formData.append('welcomeHomeImage', welcomeHomeImage);
    formData.append('Active', active);
    formData.append('file', file);

    dispatch({
      type: 'UPDATE_WELCOME_HOME_ITEM_INIT',
      payload: {
        ...data,
      },
    });

    try {
      const updateWelcomeHomeItemResponse = await updateWelcomeHomeItem(
        activeLot.LotNumber,
        item.WelcomeHomeItemId,
        formData
      ).then();
      const updateActiveResponse = await reorderActiveImage(
        activeLot.LotNumber,
        currentActiveImages
      ).then();

      if (
        updateWelcomeHomeItemResponse.status === 200 &&
        updateActiveResponse.status === 200
      ) {
        const updatedItem = await updateWelcomeHomeItemResponse.json();
        const activeItems = await updateActiveResponse.json();
        dispatch({
          type: 'UPDATE_WELCOME_HOME_ITEM_SUCCESS',
          payload: {
            ...updatedItem,
          },
        });
        getWelcomeHome();

        return updatedItem, activeItems;
      }

      if (
        updateWelcomeHomeItemResponse.status >= 400 ||
        updateActiveResponse.status >= 400
      ) {
        dispatch({
          type: 'UPDATE_WELCOME_HOME_ITEM_FAILURE',
          payload: {
            error:
              'Failed to update item: ' +
                updateWelcomeHomeItemResponse.status >=
              400
                ? updateWelcomeHomeItemResponse.message
                : updateActiveResponse.message,
          },
        });
        toast.error(
          `${item.FirstName} could not be updated.`,
          ERROR_TOAST_OPTIONS
        );
        return;
      }
    } catch (error) {
      dispatch({
        type: 'UPDATE_WELCOME_HOME_ITEM_FAILURE',
        payload: {
          error: 'Failed to update item: invalid request',
        },
      });
      toast.error(
        `${item.FirstName} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }

    dispatch({
      type: 'UPDATE_WELCOME_HOME_ITEM_FAILURE',
      payload: {
        error: 'Failed to update item.',
      },
    });
    toast.error(`${item.FirstName} could not be updated.`, ERROR_TOAST_OPTIONS);
  };

  const moveActiveImage = arr => {
    dispatch({
      type: 'MOVE_ACTIVE_IMAGE',
      payload: arr,
    });
  };

  const discardActiveImageChanges = arr => {
    dispatch({
      type: 'DISCARD_WELCOME_HOME_ACTIVE_IMAGE_ORDER',
      payload: arr,
    });
  };

  const saveActiveImageChanges = arr => {
    dispatch({
      type: 'SAVE_WELCOME_HOME_ACTIVE_IMAGE_ORDER',
      payload: arr,
    });
  };

  const reorderActiveImages = reorderedArray => {
    try {
      const request = reorderActiveImage(
        activeLot.LotNumber,
        reorderedArray
      ).then(function(response) {
        dispatch({
          type: 'SET_WELCOME_HOME_ORDER',
          payload: activeImages,
        });
      });
      if (!request) {
        toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
      }
    } catch (error) {
      toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
    }
  };

  const toggleActiveImage = async (id, isChecked) => {
    let currentActiveImages = activeImages.map(obj => obj.WelcomeHomeItemId);
    let finalActiveImages;

    if (isChecked && currentActiveImages.length >= 8) {
      toast.error(`You may not exceed 8 active images.`, ERROR_TOAST_OPTIONS);
      return false;
    }
    if (isChecked) {
      currentActiveImages.push(id);
      finalActiveImages = currentActiveImages;
    } else {
      finalActiveImages = currentActiveImages.filter(function(item) {
        return item !== id;
      });
    }

    try {
      dispatch({
        type: 'TOGGLE_WELCOME_HOME_ACTIVE_IMAGE_INIT',
      });

      const request = reorderActiveImage(
        activeLot.LotNumber,
        finalActiveImages
      ).then(function(response) {
        getWelcomeHome();
      });
      if (!request) {
        toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
      }
    } catch (error) {
      toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
    }
  };

  const getWelcomeHome = useCallback(() => {
    if (activeLot) {
      (async () => {
        dispatch({ type: 'FETCH_WELCOME_HOME_ITEMS_INIT' });
        try {
          const whItems = await getWelcomeHomeItems(activeLot.LotNumber);
          dispatch({
            type: 'FETCH_WELCOME_HOME_ITEMS_SUCCESS',
            payload: {
              welcomeHomePayload: whItems.sort(function(a, b) {
                return new Date(b.CreationDate) - new Date(a.CreationDate);
              }),
              activeImagesPayload: whItems
                .filter(item => item.Active)
                .sort(function(a, b) {
                  return a.SortOrder - b.SortOrder;
                }),
            },
          });
        } catch (error) {
          dispatch({
            type: 'FETCH_WELCOME_HOME_ITEMS_FAILURE',
            payload: 'Failed to fetch from API',
          });
        }
      })();
    }
  }, [activeLot]);

  useEffect(() => {
    if (activeLot) {
      (async () => {
        dispatch({ type: 'FETCH_WELCOME_HOME_ITEMS_INIT' });
        try {
          const whItems = await getWelcomeHomeItems(activeLot.LotNumber);
          dispatch({
            type: 'FETCH_WELCOME_HOME_ITEMS_SUCCESS',
            payload: {
              welcomeHomePayload: whItems.sort(function(a, b) {
                return new Date(b.CreationDate) - new Date(a.CreationDate);
              }),
              activeImagesPayload: whItems
                .filter(item => item.Active)
                .sort(function(a, b) {
                  return a.SortOrder - b.SortOrder;
                }),
            },
          });
        } catch (error) {
          dispatch({
            type: 'FETCH_WELCOME_HOME_ITEMS_FAILURE',
            payload: 'Failed to fetch from API',
          });
        }
      })();
    }
  }, [activeLot]);

  useEffect(() => {
    if (activeLot) {
      (async () => {
        dispatch({ type: 'FETCH_BOOKED_OPPORTUNITIES_INIT' });
        try {
          const boItems = await getBookedOpportunities(activeLot.LotNumber);
          dispatch({
            type: 'FETCH_BOOKED_OPPORTUNITIES_SUCCESS',
            payload: {
              bookedOpportunities: boItems,
            },
          });
        } catch (error) {
          dispatch({
            type: 'FETCH_BOOKED_OPPORTUNITIES_FAILURE',
            payload: 'Failed to fetch from API',
          });
        }
      })();
    }
  }, [activeLot]);

  useEffect(() => {
    if (state.isError) {
      toast.error(state.error, ERROR_TOAST_OPTIONS);
    }
  }, [state.isError, state.error]);

  return (
    <WelcomeHomeContext.Provider
      value={{
        ...state,
        welcomeHomeItems,
        activeImages,
        originalOrder,
        uploadWelcomeHomeImage,
        updateWelcomeHomeImage,
        moveActiveImage,
        discardActiveImageChanges,
        saveActiveImageChanges,
        reorderActiveImages,
        toggleActiveImage,
        currentHistory,
        bookedOpportunities,
        setConfirmNavigation,
        filterWelcomeHomeItems,
        filteredWelcomeHomeItems,
        deleteWelcomeHomeImage,
      }}
    >
      {children}
    </WelcomeHomeContext.Provider>
  );
};

WelcomeHomeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
