import React, { useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import StyledSalesAgents from './SalesAgents.Style';
import { SalesAgentsContext } from './SalesAgentsProvider';
import { UserContext } from '../User';
import SelectSalesAgent from './SelectSalesAgent';
import PublishAgent from './PublishAgent';
import DeleteAgent from './DeleteAgent';
import BasicInfo from './BasicInfo';
import ContactInfo from './ContactInfo';
import LoadingDots from '../LoadingDots';
import { HomesContext } from '../Homes';
import { uploadSiteItemImage, updateImage } from '../../Api';
import { ERROR_TOAST_OPTIONS } from '../../constants';
import { history } from '../../Router';
import ImageEditor from '../ImageEditor/ImageEditor';
import { fileToBlob, imageUrlToBlob } from '../ImageEditor/imageUtils';

const SalesAgents = () => {
  const [imageToEdit, setImageToEdit] = useState(null);

  const {
    availableSalesAgents,
    isLoading,
    isError,
    setActiveSalesAgentId,
    stagedAgent,
    updateStagedAgent,
    publishAgent,
    unPublishAgent,
    deleteAgent,
    isDeleteModalVisible,
    showDeleteModal,
    hideDeleteModal,
    validateStagedAgent,
  } = useContext(SalesAgentsContext);
  const {
    user: { IsCorporateOfficeUser, loading },
  } = useContext(UserContext);
  const { inventoryHomes } = useContext(HomesContext);

  const handleSalesAgentChange = event => {
    setActiveSalesAgentId(event.target.value);
  };

  const handlePublish = () => {
    if (stagedAgent.IsPublished === false || stagedAgent.IsPublished === null) {
      publishAgent(stagedAgent);
    } else {
      unPublishAgent(stagedAgent);
    }
  };

  const handleDelete = () => {
    deleteAgent(stagedAgent.UserName);
    hideDeleteModal();
    setTimeout(() => {
      history.push('/profile');
    }, 0);
  };

  const {
    FirstName,
    LastName,
    AvatarImageReference,
    Email,
    IsPublished,
  } = stagedAgent;

  const handleChange = event => {
    if (event.target.id === 'IsSpanishSpeaker') {
      updateStagedAgent(event.target.id, event.target.checked);
    } else {
      updateStagedAgent(event.target.id, event.target.value);
    }
  };

  if (isError) {
    setTimeout(() => {
      history.push('/homes/on-display');
    }, 0);
  }

  const publishButtonText = () => {
    if (IsPublished) {
      return 'Unpublish Agent';
    }
    return 'Publish Agent';
  };

  const editImage = async file => {
    // we need to convert the form input file into image data (base64 string)
    // and pass it to the image editor.
    // if file is null (user has not selected a new image)
    // use the current avatar image url (only works when CORS is fixed on server and will need testing)
    const imageData = file
      ? await fileToBlob(file)
      : await imageUrlToBlob(AvatarImageReference);

    const image = { imageData: imageData, file: file };

    setImageToEdit(image);
  };

  const cancelEditingImage = useCallback(() => {
    // close the dialog
    setImageToEdit(null);
  }, []);

  const doneEditingImage = useCallback(
    editedImage => {
      uploadImage(editedImage.file);

      // close the dialog
      setImageToEdit(null);
    },
    [imageToEdit]
  );

  if (loading || isLoading) return <LoadingDots />;

  const uploadImage = async file => {
    const formData = new FormData();

    formData.append('ImageSource', 'RETAIL');
    formData.append('ImageGroup', '');
    formData.append('file', file);

    try {
      const response = await uploadSiteItemImage(formData).then();

      if (response === false) {
        toast.error(
          `${file.name} could not be uploaded. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }

      // image upload success... add the new image to the user profile
      // and update...
      const newImage = await response.json();

      if (newImage) {
        updateStagedAgent('AvatarImageReference', newImage);
      }
    } catch (error) {
      toast.error(
        `${file.name} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }
  };

  const SalesAgentName = `${stagedAgent.FirstName} ${stagedAgent.LastName}`;

  return (
    <StyledSalesAgents>
      <div className="sales-agent-container">
        {imageToEdit && (
          <ImageEditor
            imageData={imageToEdit.imageData}
            imageFileNameOverride={imageToEdit.file.name}
            onDoneEditing={doneEditingImage}
            onCancelEditing={cancelEditingImage}
          />
        )}
        {IsCorporateOfficeUser && stagedAgent.SalesAgentId && (
          <SelectSalesAgent
            activeSalesAgentId={stagedAgent.SalesAgentId}
            handleSalesAgentChange={handleSalesAgentChange}
            salesAgents={availableSalesAgents}
          />
        )}

        <div className="button-container">
          {stagedAgent.SalesAgentId !== 0 && (
            <PublishAgent
              IsCorporateOfficeUser={IsCorporateOfficeUser}
              handlePublish={handlePublish}
              publishButtonText={publishButtonText}
              disabled={isLoading || !validateStagedAgent()}
            />
          )}
          <DeleteAgent
            IsCorporateOfficeUser={IsCorporateOfficeUser}
            showDeleteModal={showDeleteModal}
          />
        </div>
        <p className="section-header">Basic information</p>
        <BasicInfo
          firstName={FirstName}
          lastName={LastName}
          avatar={AvatarImageReference}
          handleChange={handleChange}
          handleImageUpload={uploadImage}
          handleEditImage={editImage}
        />
        {Email !== null && (
          <>
            <p className="section-header">Contact information</p>
            <ContactInfo email={Email} />
          </>
        )}
        <Modal
          modalHeadline="Are you sure?"
          modalBody={`Are you sure you want to delete "${SalesAgentName}"?`}
          saveCopy="Delete"
          closeCopy="Cancel"
          saveCallback={handleDelete}
          show={isDeleteModalVisible}
          discardCallback={hideDeleteModal}
          closeCallback={hideDeleteModal}
        />
      </div>
    </StyledSalesAgents>
  );
};

export default SalesAgents;
