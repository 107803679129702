import React from 'react';

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path fill="#FFF" fillRule="nonzero" d="M8 6h6v2H8v6H6V8H0V6h6V0h2v6z" />
  </svg>
);

export default PlusIcon;
