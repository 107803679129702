export const siteItemsReducer = (state, action) => {
  if (!action) return { ...state };

  switch (action.type) {
    case 'FETCH_SITE_ITEMS_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_SITE_ITEMS_SUCCESS':
      return {
        ...state,
        siteItems: action.payload.siteItems,
        stockImages: action.payload.stockImages,
        isLoading: false,
        isError: false,
      };
    case 'FETCH_SITE_ITEMS_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'FETCH_CONTENT_SITE_ITEMS_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_CONTENT_SITE_ITEMS_SUCCESS':
      return {
        ...state,
        contentSiteItems: action.payload.contentSiteItems,
        stockImages: action.payload.stockImages,
        isLoading: false,
        isError: false,
      };
    case 'FETCH_CONTENT_SITE_ITEMS_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'SET_IS_DELETE_MODAL_VISIBLE':
      return {
        ...state,
        isDeleteModalVisible: action.payload,
      };
    case 'SET_IS_NAVIGATION_MODAL_VISIBLE':
      return {
        ...state,
        isNavigationModalVisible: action.payload,
      };
    case 'SET_IS_CALENDAR_MODAL_VISIBLE':
      return {
        ...state,
        isCalendarModalVisible: action.payload,
      };
    case 'SET_IS_LIBRARY_IMAGES_MODAL_VISIBLE':
      return {
        ...state,
        isLibraryImagesModalVisible: action.payload,
      };
    case 'SET_TARGET_LOCATION':
      return {
        ...state,
        targetLocation: action.payload,
      };
    case 'SET_CONFIRMED_NAVIGATION': {
      return {
        ...state,
        confirmedNavigation: action.payload,
      };
    }
    case 'SET_TARGET_ITEM':
      return {
        ...state,
        targetItem: action.payload,
      };
    case 'SET_ACTIVE_ITEM_ID':
      return {
        ...state,
        activeItemId: action.payload,
      };
    case 'ADD_ITEM_INIT':
      return {
        ...state,
        siteItems: [...state.siteItems, action.payload],
        isLoading: true,
        isError: false,
      };
    case 'ADD_ITEM_SUCCESS':
      return {
        ...state,
        siteItems: [...state.siteItems, action.payload],
        isLoading: false,
        isError: false,
      };
    case 'ADD_CONTENT_ITEM_INIT':
      return {
        ...state,
        contentSiteItems: [...state.contentSiteItems, action.payload],
        isLoading: true,
        isError: false,
      };
    case 'ADD_CONTENT_ITEM_SUCCESS':
      return {
        ...state,
        contentSiteItems: [...state.contentSiteItems, action.payload],
        isLoading: false,
        isError: false,
      };
    case 'UPDATE_ITEM_INIT':
      return {
        ...state,
        siteItems: [
          ...state.siteItems.filter(
            item => item.RetailSiteItemId !== action.payload.RetailSiteItemId
          ),
          action.payload,
        ],
        stagedItem: action.payload,
        isLoading: true,
        isError: false,
      };
    case 'UPDATE_ITEM_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case 'UPDATE_ITEM_FAILURE':
      return {
        ...state,
        siteItems: [
          ...state.siteItems.filter(
            item =>
              item.RetailSiteItemId !== action.payload.item.RetailSiteItemId
          ),
          action.payload.item,
        ],
        stagedItem: action.payload.item,
        isLoading: false,
        isError: true,
        error: action.payload.error,
      };
    case 'UPDATE_CONTENT_ITEM_INIT':
      return {
        ...state,
        contentSiteItems: [
          ...state.contentSiteItems.filter(
            item =>
              item.HomeCenterWebsiteContentId !==
              action.payload.HomeCenterWebsiteContentId
          ),
          action.payload,
        ],
        stagedContentItem: action.payload,
        isLoading: true,
        isError: false,
      };
    case 'UPDATE_CONTENT_ITEM_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case 'UPDATE_CONTENT_ITEM_FAILURE':
      return {
        ...state,
        contentSiteItems: [
          ...state.contentSiteItems.filter(
            item =>
              item.HomeCenterWebsiteContentId !==
              action.payload.item.HomeCenterWebsiteContentId
          ),
          action.payload.item,
        ],
        stagedContentItem: action.payload.item,
        isLoading: false,
        isError: true,
        error: action.payload.error,
      };
    case 'UPDATE_STAGED_ITEM':
      return {
        ...state,
        stagedItem: { ...action.payload },
      };

    case 'UPDATE_STAGED_CONTENT_ITEM':
      return {
        ...state,
        stagedContentItem: { ...action.payload },
      };
    case 'DELETE_ITEM_INIT':
      return {
        ...state,
        siteItems: state.siteItems.filter(
          item =>
            parseInt(item.RetailSiteItemId, 10) !== parseInt(action.payload, 10)
        ),
        isLoading: true,
        isError: false,
      };
    case 'DELETE_ITEM_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case 'DELETE_ITEM_FAILURE':
      return {
        ...state,
        siteItems: action.payload.item
          ? [...state.siteItems, action.payload.item]
          : state.siteItems,
        isLoading: false,
        isError: true,
        error: action.payload.error,
      };
    case 'DELETE_CONTENT_ITEM_INIT':
      return {
        ...state,
        contentSiteItems: state.contentSiteItems.filter(
          item =>
            parseInt(item.HomeCenterWebsiteContentId, 10) !==
            parseInt(action.payload, 10)
        ),
        isLoading: true,
        isError: false,
      };
    case 'DELETE_CONTENT_ITEM_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case 'DELETE_CONTENT_ITEM_FAILURE':
      return {
        ...state,
        contentSiteItems: action.payload.item
          ? [...state.contentSiteItems, action.payload.item]
          : state.contentSiteItems,
        isLoading: false,
        isError: true,
        error: action.payload.error,
      };
    case 'ADD_IMAGE_INIT':
      return { ...state, imageIsLoading: true };
    case 'ADD_IMAGE_COMPLETED':
      return { ...state, imageIsLoading: false };
    default:
      return { ...state };
  }
};

export default siteItemsReducer;
