import React from 'react';
import PropTypes from 'prop-types';
import StyledProgressBar from './ProgressBar.Style';

const defaultLabelFormat = (current, max) => `${current} of ${max} added`;

export default function ProgressBar({ title, current, max, labelFormat }) {
  if (current > max || current < 0 || max <= 0) {
    return <></>;
  }

  return (
    <StyledProgressBar percentage={(current / max) * 100}>
      {title && <div className="title">{title}</div>}
      <div className="bar-wrapper">
        <div className="bar-outer">
          <div className="bar-inner" />
        </div>
        <div className="label">{labelFormat(current, max)}</div>
      </div>
    </StyledProgressBar>
  );
}

ProgressBar.propTypes = {
  title: PropTypes.string,
  current: PropTypes.number,
  max: PropTypes.number.isRequired,
  labelFormat: PropTypes.func,
};

ProgressBar.defaultProps = {
  title: undefined,
  current: 0,
  labelFormat: defaultLabelFormat,
};
