import React from 'react';
import PropTypes from 'prop-types';
import StyledDownloads from './Downloads.Style';
import PdfIcon from '../../icons/Pdf';
import NewTab from '../../icons/NewTab';
import { HOPE_SHEET_BASE_URL } from '../../constants';

const Downloads = ({ lotBaseUrl, SerialNumber, ModelNumber, lotNumber }) => (
  <StyledDownloads>
    <h2>Downloads</h2>
    <div className="card">
      <a
        href={`https://${lotBaseUrl}/Homes/${SerialNumber ||
          `model/${ModelNumber}`}/Brochure`}
        className="download-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="pdf-icon">
          <PdfIcon />
        </div>
        <div className="link-name">Brochure</div>
        <div className="view">View</div>
        <div className="view-icon">
          <NewTab />
        </div>
      </a>
    </div>
  </StyledDownloads>
);

Downloads.propTypes = {
  lotBaseUrl: PropTypes.string.isRequired,
  lotNumber: PropTypes.number.isRequired,
  SerialNumber: PropTypes.string,
  ModelNumber: PropTypes.string.isRequired,
};

Downloads.defaultProps = {
  SerialNumber: undefined,
};

export default Downloads;
