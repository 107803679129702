import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledLandListingCard from './LandListingCard.Style';
import Chevron from '../icons/Chevron';
import AlertIcon from '../icons/AlertIcon';
import LoadingDots from './LoadingDots';
import Toggle from './Toggle';
import { canEditSaleHome, canEditSticker, canEditVisibility } from '../utils';

function LandListingCard({
  labels,
  to,
  land,
  updateAttribute,
  getErrors,
  ...props
}) {
  const errors = getErrors(land);

  return useMemo(() => {
    if (land.isUpdating) {
      return (
        <StyledLandListingCard {...props}>
          <div className="card updating">
            <div className="card-body">
              <LoadingDots />
            </div>
          </div>
        </StyledLandListingCard>
      );
    }

    const fullAddress = `${land.Address1 && land.Address1} ${land.City &&
      land.City}, ${land.StateProvince && land.StateProvince} 
      ${land.PostalCode && land.PostalCode}`;

    const displayName = () => {
      if (land.Name && land.Name !== land.StockNumber) {
        return land.Name;
      } else if (land.Name === land.StockNumber && land.Address1 !== null) {
        return fullAddress;
      }
      return land.StockNumber;
    };

    return (
      <StyledLandListingCard {...props}>
        <div className="card">
          <div className="card-body">
            <Link to={to}>
              <div className="land-info">
                <h2>
                  {displayName()}
                  <div className="tags">
                    {land.IsSold && <div className="tag">Sold</div>}
                    {land.IsLand && <div className="tag">Land</div>}
                    {land.IsOnLand && <div className="tag">On Land</div>}
                  </div>
                </h2>
                <div className="land-info-container">
                  {labels &&
                    labels.map(label => (
                      <div className="land-label" key={label.value}>
                        <p>
                          <span>{label.text}</span>
                        </p>
                        <p>{label.value}</p>
                      </div>
                    ))}
                </div>
                <div className="land-errors">
                  {errors &&
                    errors.map(error => (
                      <div key={error.message} className="error">
                        <AlertIcon color="#ffffff" backgroundColor="#b21f28" />
                        <span>{error.message}</span>
                      </div>
                    ))}
                </div>
                <div className="arrow-position">
                  <Chevron />
                </div>
              </div>
            </Link>
            <div className="card-options">
              <Toggle
                label="Visible"
                onToggle={event => {
                  updateAttribute('IsPublished', event.currentTarget.checked);
                }}
                inputProps={{
                  id: `${land.StockNumber}-visible`,
                  checked: land.IsPublished && canEditVisibility(land),
                  disabled: !canEditVisibility(land),
                }}
              />
            </div>
          </div>
        </div>
      </StyledLandListingCard>
    );
  }, [land, errors, labels, props, to]);
}

LandListingCard.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()),
  to: PropTypes.shape().isRequired,
  land: PropTypes.shape({
    IsPublished: PropTypes.bool,
    Address1: PropTypes.string,
    City: PropTypes.string,
    StateProvince: PropTypes.string,
    PostalCode: PropTypes.string,
    Price: PropTypes.number,
    Acres: PropTypes.number,
    StockNumber: PropTypes.string,
  }).isRequired,
};

LandListingCard.defaultProps = {
  labels: [],
};

export default LandListingCard;
