import React from 'react';
import PropTypes from 'prop-types';
import StyledOptions from './Options.Style';
import Toggle from '../Toggle';
import StickerSelect from '../StickerSelect/StickerSelect';
import {
  canEditSaleHome,
  canEditVisibility,
  canEditSticker,
  analyticsSendEvent,
} from '../../utils';

const Options = ({
  IsPublished,
  IsSaleHome,
  IsSold,
  IsAvailableFloorPlan,
  IsLand,
  IsOnLand,
  IsOnThirdPartyLand,
  Id,
  StickerOptions,
  StickerTypeId,
  SerialNumber,
  ModelNumber,
  toggle,
  OptionsText,
  home,
}) => (
  <StyledOptions>
    <h2>{OptionsText} Options</h2>
    <div className="card">
      {!IsLand && !IsOnLand && !IsOnThirdPartyLand && (
        <Toggle
          onToggle={event => {
            analyticsSendEvent('home_info_visible', {
              status: event.currentTarget.checked ? 'on' : 'off',
            });
            toggle('IsPublished', event.currentTarget.checked);
          }}
          size="large"
          label="Visible"
          inputProps={{
            id: 'is-visible-toggle',
            checked: canEditVisibility(home) && IsPublished,
            disabled: !canEditVisibility(home),
          }}
          title={
            IsPublished
              ? 'Turn off to remove this home from the website.'
              : 'Turn on to show this home on the website.'
          }
        />
      )}
      {(IsLand || IsOnLand || IsOnThirdPartyLand) && (
        <Toggle
          size="large"
          label="Visible"
          onToggle={event => {
            analyticsSendEvent('home_info_visible', {
              status: event.currentTarget.checked ? 'on' : 'off',
            });
            toggle('IsPublished', event.currentTarget.checked);
          }}
          inputProps={{
            id: `${Id}-visible`,
            checked: canEditVisibility(home) && IsPublished,
            disabled: !canEditVisibility(home),
          }}
          title={
            IsPublished
              ? 'Turn off to remove this land from the website.'
              : 'Turn on to show this land on the website.'
          }
        />
      )}
      {!IsAvailableFloorPlan && !IsLand && (
        <>
          <Toggle
            onToggle={event => {
              analyticsSendEvent('home_info_sale', {
                status: event.currentTarget.checked ? 'on' : 'off',
              });
              toggle('IsSaleHome', event.currentTarget.checked);
            }}
            size="large"
            label="Sale Home"
            inputProps={{
              id: 'is-sale-home-toggle',
              disabled: !canEditSaleHome(home),
              checked: IsSaleHome && canEditSaleHome(home),
            }}
          />
          <StickerSelect
            stickerOptions={StickerOptions}
            selectedStickerTypeId={StickerTypeId}
            updateStickerType={event => {
              analyticsSendEvent('home_info_sticker', {
                stickerId: event.currentTarget.value,
                stickerName:
                  event.currentTarget.options[event.currentTarget.selectedIndex]
                    .text,
              });
              toggle('StickerTypeId', parseInt(event.currentTarget.value, 10));
            }}
            selectProps={{
              id: `${SerialNumber || ModelNumber}-sticker`,
              disabled: !canEditSticker(home),
            }}
          />
        </>
      )}
    </div>
  </StyledOptions>
);

Options.propTypes = {
  IsPublished: PropTypes.bool.isRequired,
  IsSaleHome: PropTypes.bool,
  IsSold: PropTypes.bool,
  IsAvailableFloorPlan: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  StickerOptions: PropTypes.arrayOf(PropTypes.shape),
  StickerTypeId: PropTypes.number,
  SerialNumber: PropTypes.string,
  ModelNumber: PropTypes.string,
};

Options.defaultProps = {
  IsSaleHome: false,
  IsSold: false,
  IsAvailableFloorPlan: true,
  StickerOptions: undefined,
  StickerTypeId: undefined,
  SerialNumber: undefined,
  ModelNumber: undefined,
};

export default Options;
