import { findHomeByWildCardId, getUniqueHomeLotId } from '../utils';

export const homesReducer = (state, action) => {
  if (!action) return { ...state };

  switch (action.type) {
    case 'FETCH_LIST_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_LIST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        homes: action.payload.reduce((prev, next) => {
          return {
            ...prev,
            [getUniqueHomeLotId(next)]: {
              ...next,
              detailsFetched: false,
              isUpdating: false,
            },
          }; // action.payload to object
        }, {}),
      };
    case 'FETCH_LIST_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'FETCH_HOME_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_HOME_SUCCESS':
      const home = findHomeByWildCardId(state.homes, action.payload.Id);

      const merged = {
        ...home,
        ...action.payload,
        detailsFetched: true,
      };

      return {
        ...state,
        homes: {
          ...state.homes,
          [getUniqueHomeLotId(action.payload)]: merged,
        },
        isLoading: false,
      };
    case 'FETCH_HOME_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'CLEAR_HOMES':
      return {
        ...state,
        homes: {},
        isLoading: false,
        isError: false,
        error: null,
        query: '',
      };
    case 'SEARCH':
      return {
        ...state,
        query: action.payload,
      };
    case 'SET_ACTIVE_HOME_ID':
      return {
        ...state,
        activeHomeId: action.payload,
      };
    case 'UPDATE_HOME_INIT':
      return {
        ...state,
        isUpdating: true,
        homes: {
          ...state.homes,
          [getUniqueHomeLotId(action.payload)]: {
            ...findHomeByWildCardId(state.homes, action.payload.Id),
            ...action.payload,
            isUpdating: true,
          },
        },
      };
    case 'UPDATE_HOME_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        homes: {
          ...state.homes,
          [getUniqueHomeLotId(action.payload)]: {
            ...action.payload,
            isUpdating: false,
          },
        },
        stagedHome: action.payload,
      };
    case 'UPDATE_HOME_FAILURE':
      return {
        ...state,
        isUpdating: false,
        homes: {
          ...state.homes,
          [getUniqueHomeLotId(action.payload)]: {
            ...findHomeByWildCardId(state.homes, action.payload.Id),
            ...action.payload,
          },
        },
      };
    case 'INIT_STAGED_HOME':
      return state.activeHomeId
        ? {
            ...state,
            stagedHome: {
              ...findHomeByWildCardId(state.homes, state.activeHomeId),
            },
          }
        : { ...state, stagedHome: null };
    case 'UPDATE_STAGED_HOME':
      return {
        ...state,
        stagedHome: action.payload,
      };
    case 'FETCH_ROOM_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_ROOM_SUCCESS':
      return {
        ...state,
        rooms: action.payload.reduce((prev, next) => {
          const { Id, Description } = next;
          return { ...prev, [Id]: Description }; // action.payload to object
        }, {}),
        didFetchRooms: true,
        isLoading: false,
      };
    case 'FETCH_ROOM_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'FETCH_ANNOUNCEMENT_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_ANNOUNCEMENT_SUCCESS':
      return {
        ...state,
        announcements: { ...action.payload },
        didFetchAnnouncements: true,
        isLoading: false,
      };
    case 'FETCH_ANNOUNCEMENT_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'ADD_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.payload,
      };
    case 'RESET_ERROR':
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default homesReducer;
