import React from 'react';
import PropTypes from 'prop-types';

export default function AlertIcon({ color, backgroundColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={backgroundColor}
          d="M17.887 9.099a8.785 8.785 0 1 1-17.57 0 8.785 8.785 0 0 1 17.57 0"
        />
        <path
          fill={color}
          d="M7.877 3.439h2.575l-.334 7.524H8.244L7.877 3.44zm1.304 8.628c.735 0 1.337.618 1.337 1.354 0 .719-.602 1.338-1.337 1.338a1.353 1.353 0 0 1-1.338-1.338c0-.736.602-1.354 1.338-1.354z"
        />
      </g>
    </svg>
  );
}

AlertIcon.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

AlertIcon.defaultProps = {
  color: '#b21f28',
  backgroundColor: '#fff',
};
