import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import isequal from 'lodash.isequal';
import { UserContext } from '../User';
import ToastUndo from '../ToastUndo';
import { siteItemsReducer } from '../../reducers/siteItems';
import {
  deleteSiteItem,
  getSiteItems,
  updateSiteItem,
  uploadSiteItemImage,
  getStockImages,
  getContentSiteItems,
  deleteContentSiteItem,
  updateContentSiteItem,
  createContentSiteItem,
  batchCreateContentSiteItem,
} from '../../Api';
import { qualifyUrl, sortByKey } from '../../utils';
import { ERROR_TOAST_OPTIONS, UNDO_TOAST_OPTIONS } from '../../constants';

export const SiteItemsContext = React.createContext({
  siteItems: null,
  contentSiteItems: null,
  stockImages: null,
  targetItem: null, // used on listing page
  activeItemId: null, // used on details page
  stagedItem: null, // used on details page
  stagedContentItem: null,
  isLoading: true,
  imageIsLoading: false,
  isError: false,
  isDeleteModalVisible: false,
  isNavigationModalVisible: false,
  isCalendarModalVisible: false,
  isLibraryModalVisible: false,
  confirmedNavigation: false,
});

export const SiteItemsProvider = ({ children }) => {
  const context = useContext(SiteItemsContext);
  const { activeLot, user } = useContext(UserContext);
  const [state, dispatch] = useReducer(siteItemsReducer, context);

  const siteItems = useMemo(() => {
    return state.siteItems ? state.siteItems : [];
  }, [state.siteItems]);

  const contentSiteItems = useMemo(() => {
    return state.contentSiteItems ? state.contentSiteItems : [];
  }, [state.contentSiteItems]);

  const activeItem = useMemo(() => {
    if (state.activeItemId === null) {
      return null;
    }
    if (
      siteItems === null ||
      siteItems === undefined ||
      siteItems.length === 0
    ) {
      return null;
    }
    return (
      siteItems.filter(
        item =>
          parseInt(item.RetailSiteItemId, 10) ===
          parseInt(state.activeItemId, 10)
      )[0] || null
    );
  }, [state, siteItems]);

  const activeContentItem = useMemo(() => {
    if (state.activeItemId === null) {
      return null;
    }
    if (
      contentSiteItems === null ||
      contentSiteItems === undefined ||
      contentSiteItems.length === 0
    ) {
      return null;
    }
    return (
      contentSiteItems.filter(
        item =>
          parseInt(item.HomeCenterWebsiteContentId, 10) ===
          parseInt(state.activeItemId, 10)
      )[0] || null
    );
  }, [state, contentSiteItems]);

  const stagedItem = useMemo(() => {
    if (state.stagedItem === null || !state.stagedItem.RetailSiteItemType) {
      return activeItem || {};
    }
    return state.stagedItem;
  }, [activeItem, state.stagedItem]);

  const stagedContentItem = useMemo(() => {
    if (
      state.stagedContentItem === null ||
      !state.stagedContentItem.HomeCenterWebsiteContentType
    ) {
      return activeContentItem || {};
    }
    return state.stagedContentItem;
  }, [activeContentItem, state.stagedContentItem]);

  const isStagedItemValid = useMemo(() => {
    if (stagedItem.RetailSiteItemType === 'SlideShow') {
      return (
        !!stagedItem.ImageReference &&
        !!stagedItem.RetailSiteItemId &&
        !!stagedItem.Headline
      );
    }

    return false;
  }, [stagedItem]);

  const isStagedContentItemValid = useMemo(() => {
    if (
      stagedContentItem.HomeCenterWebsiteContentType === 'News' ||
      stagedContentItem.HomeCenterWebsiteContentType === 2
    ) {
      return (
        !!(
          !stagedContentItem.DealerIds || stagedContentItem.DealerIds.length > 0
        ) &&
        !!stagedContentItem.MainImage &&
        !!stagedContentItem.HomeCenterWebsiteContentId &&
        !!stagedContentItem.Title &&
        !!stagedContentItem.Summary &&
        !!stagedContentItem.Content
      );
    }

    if (
      stagedContentItem.HomeCenterWebsiteContentType === 'Events' ||
      stagedContentItem.HomeCenterWebsiteContentType === 1
    ) {
      return (
        !!(
          !stagedContentItem.DealerIds || stagedContentItem.DealerIds.length > 0
        ) &&
        !!stagedContentItem.MainImage &&
        !!stagedContentItem.HomeCenterWebsiteContentId &&
        !!stagedContentItem.Title &&
        !!stagedContentItem.Summary &&
        !!stagedContentItem.Content
      );
    }

    return false;
  }, [stagedContentItem]);

  const isItemOnline = item => {
    return (
      item &&
      ((item.IsPublished && item.EndDate === null) ||
        (item.IsPublished && new Date(item.EndDate) > new Date()))
    );
  };

  const validateLinkUrl = useMemo(() => {
    if (
      stagedContentItem.LinkUrl &&
      !stagedContentItem.LinkUrl.match(
        /(\/\w+)+(\.)?\w+(\?(\w+=[\w\d]+(&\w+=[\w\d]+)*)+){0,1}/
      )
    ) {
      return false;
    }

    return true;
  }, [stagedContentItem.LinkUrl]);

  const stagedItemUpdated = useMemo(() => {
    return !isequal(activeItem, stagedItem);
  }, [activeItem, stagedItem]);

  const stagedContentItemUpdated = useMemo(() => {
    return !isequal(activeContentItem, stagedContentItem);
  }, [activeContentItem, stagedContentItem]);

  const news = useMemo(() => {
    if (contentSiteItems === null || contentSiteItems === undefined) return [];
    const filteredItems = contentSiteItems.filter(
      item =>
        item.HomeCenterWebsiteContentType === 2 ||
        item.HomeCenterWebsiteContentType === 'News'
    );

    const sortedItems = [
      ...filteredItems.sort((itemA, itemB) => {
        return (
          new Date(itemA.EndDate || '12-25-9999') -
          new Date(itemB.EndDate || '12-25-9999')
        );
      }),
    ];

    return [
      ...sortedItems.filter(item => item.isOnline),
      ...sortedItems.filter(item => !item.isOnline),
    ];
  }, [contentSiteItems]);

  const publishedNewsCount = useMemo(() => {
    const publishedNews = news.filter(news => news.IsPublished);
    return publishedNews.length;
  }, [news]);

  const promotions = useMemo(() => {
    if (siteItems === null) return [];
    const filteredItems = siteItems.filter(
      item => item.RetailSiteItemType === 'SlideShow'
    );

    const sortedItems = [
      ...filteredItems.sort((itemA, itemB) => {
        return (
          new Date(itemA.EndDate || '12-25-9999') -
          new Date(itemB.EndDate || '12-25-9999')
        );
      }),
    ];

    return [
      ...sortedItems.filter(item => item.isOnline),
      ...sortedItems.filter(item => !item.isOnline),
    ];
  }, [siteItems]);

  const events = useMemo(() => {
    if (contentSiteItems === null) return [];
    const filteredItems = contentSiteItems.filter(
      item =>
        item.HomeCenterWebsiteContentType === 1 ||
        item.HomeCenterWebsiteContentType === 'Events'
    );

    const sortedItems = [
      ...filteredItems.sort((itemA, itemB) => {
        return (
          new Date(itemA.EndDate || '12-25-9999') -
          new Date(itemB.EndDate || '12-25-9999')
        );
      }),
    ];

    return [
      ...sortedItems.filter(item => item.isOnline),
      ...sortedItems.filter(item => !item.isOnline),
    ];
  }, [contentSiteItems]);

  const libraryImages = useMemo(() => {
    return state.stockImages;
  }, [state.stockImages]);

  const getItemById = useCallback(
    id => {
      if (siteItems === null) return {};
      return siteItems.filter(item => item.RetailSiteItemId === id)[0];
    },
    [siteItems]
  );

  const getContentItemById = useCallback(
    id => {
      if (contentSiteItems === null) return {};
      return contentSiteItems.filter(
        item => item.HomeCenterWebsiteContentId === id
      )[0];
    },
    [contentSiteItems]
  );

  const setActiveItemId = useCallback(id => {
    dispatch({ type: 'SET_ACTIVE_ITEM_ID', payload: id });
  }, []);

  const setIsNavigationModalVisible = visible => {
    dispatch({ type: 'SET_IS_NAVIGATION_MODAL_VISIBLE', payload: visible });
  };

  const showNavigationModal = useCallback(location => {
    dispatch({ type: 'SET_TARGET_LOCATION', payload: location });
    setIsNavigationModalVisible(true);
  }, []);

  const setConfirmNavigation = useCallback(confirm => {
    dispatch({ type: 'SET_CONFIRMED_NAVIGATION', payload: confirm });
  }, []);

  const hideNavigationModal = useCallback(() => {
    setIsNavigationModalVisible(false);
    setConfirmNavigation(false);
  }, [setConfirmNavigation]);

  const setIsCalendarModalVisible = visible => {
    dispatch({ type: 'SET_IS_CALENDAR_MODAL_VISIBLE', payload: visible });
  };

  const showCalendarModal = () => {
    setIsCalendarModalVisible(true);
  };

  const hideCalendarModal = () => {
    setIsCalendarModalVisible(false);
  };

  const setIsDeleteModalVisible = visible => {
    dispatch({ type: 'SET_IS_DELETE_MODAL_VISIBLE', payload: visible });
  };

  const showDeleteModal = item => {
    dispatch({ type: 'SET_TARGET_ITEM', payload: item });
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
    dispatch({ type: 'SET_TARGET_ITEM', payload: null });
  };

  const showLibraryImagesModal = () => {
    dispatch({ type: 'SET_IS_LIBRARY_IMAGES_MODAL_VISIBLE', payload: true });
  };

  const hideLibraryImagesModal = () => {
    dispatch({ type: 'SET_IS_LIBRARY_IMAGES_MODAL_VISIBLE', payload: false });
  };

  const handleLibraryImageSelect = url => {
    dispatch({
      type: 'UPDATE_STAGED_ITEM',
      payload: { ...stagedItem, ImageReference: url },
    });
  };

  const handleContentItemLibraryImageSelect = url => {
    dispatch({
      type: 'UPDATE_STAGED_CONTENT_ITEM',
      payload: { ...stagedContentItem, MainImage: url },
    });
  };

  const addItem = useCallback(
    async item => {
      const initialItem = { ...item };
      try {
        dispatch({
          type: 'ADD_ITEM_INIT',
          payload: { ...item, isOnline: true },
        });

        const response = await updateSiteItem(
          activeLot.LotNumber,
          item.RetailSiteItemId,
          JSON.stringify(item)
        );

        const newItem = await response.json();
        dispatch({
          type: 'ADD_ITEM_SUCCESS',
          payload: { ...newItem, isOnline: true },
        });
        return newItem;
      } catch (error) {
        dispatch({
          type: 'UPDATE_ITEM_FAILURE',
          payload: {
            error: 'Failed to update item: invalid request',
            item: initialItem,
          },
        });
        return false;
      }
    },
    [activeLot]
  );

  const addContentItem = useCallback(
    async item => {
      const initialItem = { ...item };

      try {
        dispatch({
          type: 'ADD_CONTENT_ITEM_INIT',
          payload: { ...item, isOnline: true },
        });
        // if more than one dealer we call the batch version of this method
        let response = null;
        if (item.DealerIds.length > 1) {
          // // temp fix to allow saving without pub start/end dates
          // let batchContentItem = {
          //   ...item,
          //   EndDate: '2023-12-22T16:12:37.556Z',
          //   StartDate: '2023-12-22T16:12:37.556Z',
          // };
          response = await batchCreateContentSiteItem(JSON.stringify(item));
        } else {
          response = await createContentSiteItem(
            activeLot.LotNumber,
            JSON.stringify(item)
          );
        }

        const newItem = await response.json();

        dispatch({
          type: 'ADD_CONTENT_ITEM_SUCCESS',
          payload: { ...newItem, isOnline: true },
        });

        // batch event api doesnt return update item (with full link urls and other changes the BE makes to the uploaded item)
        // so we force re-download all items
        fetchContentSiteItems();
        return newItem;
      } catch (error) {
        dispatch({
          type: 'UPDATE_CONTENT_ITEM_FAILURE',
          payload: {
            error: 'Failed to update item: invalid request',
            item: initialItem,
          },
        });

        // right now batch events api can fail half way through, so we still want to re-download our site items
        fetchContentSiteItems();

        return false;
      }
    },
    [activeLot]
  );

  const updateItem = useCallback(
    async (item, undo = true) => {
      if (item.RetailSiteItemId === 'new') {
        return addItem(item);
      }
      const initialItem = getItemById(item.RetailSiteItemId);
      dispatch({
        type: 'UPDATE_ITEM_INIT',
        payload: {
          ...item,
          isOnline: isItemOnline(item),
        },
      });

      if (!activeLot) {
        dispatch({
          type: 'UPDATE_ITEM_FAILURE',
          payload: {
            error: 'Update failed: No active lot found.',
            item: initialItem,
          },
        });
        return false;
      }
      if (!item) {
        dispatch({
          type: 'UPDATE_ITEM_FAILURE',
          payload: {
            error: 'Update failed: Invalid argument',
            item: initialItem,
          },
        });
        return false;
      }

      try {
        const undoFunction = () => {
          updateItem(initialItem, false).then();
        };
        const response = await updateSiteItem(
          activeLot.LotNumber,
          item.RetailSiteItemId,
          JSON.stringify(item)
        );
        const parsedResponse = await response.json();
        if (!response.ok) {
          const { Message } = parsedResponse;
          dispatch({
            type: 'UPDATE_ITEM_FAILURE',
            payload: {
              error:
                typeof Message === 'string' ? Message : 'No message available',
              item: initialItem,
            },
          });
          return false;
        }
        dispatch({ type: 'UPDATE_ITEM_SUCCESS' });
        if (undo) {
          toast.info(
            <ToastUndo undo={undoFunction} message={'Promotion Updated'} />,
            UNDO_TOAST_OPTIONS
          );
        }
        return parsedResponse;
      } catch (error) {
        dispatch({
          type: 'UPDATE_ITEM_FAILURE',
          payload: {
            error: 'Failed to update item: invalid request',
            item: initialItem,
          },
        });
        return false;
      }
    },
    [activeLot, addItem, getItemById]
  );

  const updateContentItem = useCallback(
    async (item, undo = true) => {
      if (item.HomeCenterWebsiteContentId === 'new') {
        return addContentItem(item);
      }
      const initialItem = getContentItemById(item.HomeCenterWebsiteContentId);
      dispatch({
        type: 'UPDATE_CONTENT_ITEM_INIT',
        payload: {
          ...item,
          isOnline: isItemOnline(item),
        },
      });

      if (!activeLot) {
        dispatch({
          type: 'UPDATE_CONTENT_ITEM_FAILURE',
          payload: {
            error: 'Update failed: No active lot found.',
            item: initialItem,
          },
        });
        return false;
      }
      if (!item) {
        dispatch({
          type: 'UPDATE_CONTENT_ITEM_FAILURE',
          payload: {
            error: 'Update failed: Invalid argument',
            item: initialItem,
          },
        });
        return false;
      }

      try {
        const undoFunction = () => {
          updateContentItem(initialItem, false).then();
        };

        const response = await updateContentSiteItem(
          activeLot.LotNumber,
          item.HomeCenterWebsiteContentId,
          item
        );
        const parsedResponse = await response.json();
        if (!response.ok) {
          const { Message } = parsedResponse;
          dispatch({
            type: 'UPDATE_CONTENT_ITEM_FAILURE',
            payload: {
              error:
                typeof Message === 'string' ? Message : 'No message available',
              item: initialItem,
            },
          });
          return false;
        }
        dispatch({ type: 'UPDATE_CONTENT_ITEM_SUCCESS' });
        if (undo) {
          toast.info(
            <ToastUndo
              undo={undoFunction}
              message={
                item.HomeCenterWebsiteContentType === 1
                  ? 'Event Updated'
                  : 'News Updated'
              }
            />,
            UNDO_TOAST_OPTIONS
          );
        }
        return parsedResponse;
      } catch (error) {
        dispatch({
          type: 'UPDATE_CONTENT_ITEM_FAILURE',
          payload: {
            error: 'Failed to update item: invalid request',
            item: initialItem,
          },
        });
        return false;
      }
    },
    [activeLot, addContentItem, getContentItemById]
  );

  const deleteItemById = async id => {
    dispatch({ type: 'DELETE_ITEM_INIT', payload: id });

    if (!activeLot) {
      dispatch({
        type: 'DELETE_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: no active lot number' },
      });
      return false;
    }
    if (!id) {
      dispatch({
        type: 'DELETE_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: invalid parameters' },
      });
      return false;
    }

    const deletedItem = getItemById(id);

    try {
      await deleteSiteItem(activeLot.LotNumber, id);
      dispatch({ type: 'DELETE_ITEM_SUCCESS' });
      return true;
    } catch (error) {
      dispatch({
        type: 'DELETE_ITEM_FAILURE',
        payload: {
          error: 'Failed to delete item: invalid request',
          item: deletedItem,
        },
      });
      return false;
    }
  };

  const deleteContentItemById = async id => {
    dispatch({ type: 'DELETE_CONTENT_ITEM_INIT', payload: id });

    if (!activeLot) {
      dispatch({
        type: 'DELETE_CONTENT_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: no active lot number' },
      });
      return false;
    }
    if (!id) {
      dispatch({
        type: 'DELETE_CONTENT_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: invalid parameters' },
      });
      return false;
    }

    const deletedItem = getContentItemById(id);

    try {
      await deleteContentSiteItem(activeLot.LotNumber, id);
      dispatch({ type: 'DELETE_CONTENT_ITEM_SUCCESS' });
      return true;
    } catch (error) {
      dispatch({
        type: 'DELETE_CONTENT_ITEM_FAILURE',
        payload: {
          error: 'Failed to delete item: invalid request',
          item: deletedItem,
        },
      });
      return false;
    }
  };

  const publishItem = async item => {
    return updateItem({ ...item, IsPublished: true });
  };

  const publishContentItem = async item => {
    // make sure we don't have 3 published news
    if (publishedNewsCount && publishedNewsCount > 2) {
      dispatch({
        type: 'UPDATE_CONTENT_ITEM_FAILURE',
        payload: {
          error:
            'Failed to publish item: You already have three published news',
          item,
        },
      });
      return false;
    }

    return updateContentItem({ ...item, IsPublished: true });
  };

  const updateStagedItem = useCallback(item => {
    dispatch({
      type: 'UPDATE_STAGED_ITEM',
      payload: {
        ...item,
        isOnline: isItemOnline(item),
      },
    });
  }, []);

  const updateStagedContentItem = useCallback(item => {
    dispatch({
      type: 'UPDATE_STAGED_CONTENT_ITEM',
      payload: {
        ...item,
        isOnline: isItemOnline(item),
      },
    });
  }, []);

  /**
   * This function creates a FormData objects and sends it in a POST request to the API.
   * After a successful request, the image is added to the site item's state
   *
   * @param {object} data the object containing the image data
   * @param {object} item the staged site item being updated
   * @returns {Promise<void>}
   */
  const uploadItemImage = async (data, item) => {
    const { file } = data;
    const formData = new FormData();
    formData.append('ImageSource', 'RETAIL');
    formData.append('ImageGroup', '');
    formData.append('file', file);

    dispatch({
      type: 'ADD_IMAGE_INIT',
    });

    try {
      const response = await uploadSiteItemImage(formData).then();
      if (response === false) {
        toast.error(
          `${file.name} could not be uploaded. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }
      const newImage = await response.json();

      if (newImage && stagedItem.RetailSiteItemType === 'SlideShow') {
        updateStagedItem({ ...item, ImageReference: newImage });
      }
      if (
        newImage &&
        (stagedContentItem.HomeCenterWebsiteContentType === 'Events' ||
          stagedContentItem.HomeCenterWebsiteContentType === 1 ||
          stagedContentItem.HomeCenterWebsiteContentType === 'News' ||
          stagedContentItem.HomeCenterWebsiteContentType === 2)
      ) {
        updateStagedContentItem({ ...item, MainImage: newImage });
      }
    } catch (error) {
      toast.error(
        `${file.name} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }

    dispatch({
      type: 'ADD_IMAGE_COMPLETED',
    });
  };

  const uploadItemFeaturedImage = async (data, item) => {
    const { file } = data;
    const formData = new FormData();
    formData.append('ImageSource', 'RETAIL');
    formData.append('ImageGroup', '');
    formData.append('file', file);

    dispatch({
      type: 'ADD_IMAGE_INIT',
    });

    try {
      const response = await uploadSiteItemImage(formData).then();
      if (response === false) {
        toast.error(
          `${file.name} could not be uploaded. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }
      const newFeaturedImage = await response.json();
      if (newFeaturedImage) {
        updateStagedContentItem({ ...item, FeaturedImage: newFeaturedImage });
      }
    } catch (error) {
      toast.error(
        `${file.name} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }

    dispatch({
      type: 'ADD_IMAGE_COMPLETED',
    });
  };

  const fetchSiteItems = async () => {
    dispatch({ type: 'FETCH_SITE_ITEMS_INIT' });
    try {
      const items = await getSiteItems(activeLot.LotNumber);
      const images = await getStockImages();
      if (items === '[]') {
        dispatch({
          type: 'FETCH_SITE_ITEMS_SUCCESS',
          payload: {
            items: [],
            stockImages: Array.isArray(images) ? images : [],
          },
        });
      } else {
        // We have to do this becuase our data is so great :D
        const sortedItems = [
          ...sortByKey(
            items.filter(
              siteItem => siteItem.RetailSiteItemType === 'SlideShow'
            ),
            'EndDate'
          ),
        ];
        dispatch({
          type: 'FETCH_SITE_ITEMS_SUCCESS',
          payload: {
            siteItems: sortedItems.map(item => ({
              ...item,
              ImageReference: qualifyUrl(item.ImageReference),
              StartDate: new Date(item.StartDate),
              EndDate: (date => {
                const endDate = new Date(date);
                const today = new Date();
                if (endDate.getFullYear() > today.getFullYear() + 50) {
                  return null;
                }
                return endDate;
              })(item.EndDate),
              isOnline: isItemOnline(item),
            })),
            stockImages: Array.isArray(images) ? images : [],
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_SITE_ITEMS_FAILURE',
        payload: 'Failed to fetch from API',
      });
    }
  };

  const fetchContentSiteItems = async () => {
    dispatch({ type: 'FETCH_CONTENT_SITE_ITEMS_INIT' });
    try {
      const items = await getContentSiteItems(activeLot.LotNumber);
      const images = await getStockImages();
      if (items === '[]') {
        dispatch({
          type: 'FETCH_CONTENT_SITE_ITEMS_SUCCESS',
          payload: {
            items: [],
            stockImages: Array.isArray(images) ? images : [],
          },
        });
      } else {
        // We have to do this becuase our data is so great :D
        const sortedItems = [
          ...sortByKey(
            items.filter(
              siteItem =>
                siteItem.HomeCenterWebsiteContentType === 2 ||
                siteItem.HomeCenterWebsiteContentType === 'News'
            ),
            'EndDate'
          ),
          ...sortByKey(
            items.filter(
              siteItem =>
                siteItem.HomeCenterWebsiteContentType === 1 ||
                siteItem.HomeCenterWebsiteContentType === 'Events'
            ),
            'EndDate'
          ),
        ];
        dispatch({
          type: 'FETCH_CONTENT_SITE_ITEMS_SUCCESS',
          payload: {
            contentSiteItems: sortedItems.map(item => ({
              ...item,
              MainImage: qualifyUrl(item.MainImage),
              StartDate: new Date(item.StartDate),
              EndDate: (date => {
                const endDate = new Date(date);
                const today = new Date();
                if (endDate.getFullYear() > today.getFullYear() + 50) {
                  return null;
                }
                return endDate;
              })(item.EndDate),
              isOnline: isItemOnline(item),
            })),
            stockImages: Array.isArray(images) ? images : [],
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_CONTENT_SITE_ITEMS_FAILURE',
        payload: 'Failed to fetch from API',
      });
    }
  };

  useEffect(() => {
    if (activeLot) {
      fetchSiteItems();
    }
  }, [activeLot]);

  useEffect(() => {
    if (activeLot) {
      fetchContentSiteItems();
    }
  }, [activeLot]);

  useEffect(() => {
    if (state.isError) {
      toast.error(state.error, ERROR_TOAST_OPTIONS);
    }
  }, [state.isError, state.error]);

  return (
    <SiteItemsContext.Provider
      value={{
        ...state,
        news,
        promotions,
        events,
        showNavigationModal,
        hideNavigationModal,
        showDeleteModal,
        hideDeleteModal,
        showCalendarModal,
        hideCalendarModal,
        showLibraryImagesModal,
        hideLibraryImagesModal,
        handleLibraryImageSelect,
        handleContentItemLibraryImageSelect,
        deleteItemById,
        deleteContentItemById,
        publishItem,
        publishContentItem,
        updateItem,
        updateContentItem,
        updateStagedItem,
        updateStagedContentItem,
        stagedItemUpdated,
        stagedContentItemUpdated,
        activeItem,
        activeContentItem,
        stagedItem,
        stagedContentItem,
        setActiveItemId,
        setConfirmNavigation,
        isStagedItemValid,
        isStagedContentItemValid,
        validateLinkUrl,
        uploadItemImage,
        uploadItemFeaturedImage,
        libraryImages,
        addContentItem,
      }}
    >
      {children}
    </SiteItemsContext.Provider>
  );
};

SiteItemsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
