import styled from 'styled-components';

const BasicInfoWrapper = styled.div`
  max-width: 690px;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  display: flex;
  padding: 29px 35px;
  box-sizing: border-box;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    display: block;
  }

  .profile-avatar {
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-bottom: 37px;
    }

    .avatar {
      background-size: cover;
      width: 220px;
      height: 220px;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 768px) {
        margin: 0px;
      }
    }

    img {
      max-height: 220px;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0;
      font: 600 12px/100% source-sans-pro;
      color: #9b9b9b;
      letter-spacing: 0.5px;
      text-align: center;
      margin-top: 12px;
    }

    .upload-container {
      position: relative;
      overflow: hidden;

      button {
        margin: 12px auto 0;
        padding: 0;
        font: 600 12px/100% source-sans-pro;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        text-align: center;
        text-decoration: none;
        display: block;
        border: none;
        background: none;
        -webkit-appearance: none;

        &:hover {
          cursor: pointer;
        }
      }

      .adjust-button {
        color: #0075c9;
      }

      .upload-image {
        position: absolute;
        left: 0;
        top: 5px;
        opacity: 0;
        max-width: 190px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .form-wrap {
    max-width: 378px;
    padding-left: 0px;

    @media screen and (min-width: 768px) {
      padding-left: 52px;
    }

    @media screen and (min-width: 1025px) {
      width: 378px;
    }
  }

  #basicInfoForm {
    display: flex;
    flex-flow: row wrap;
    flex: 1 100%;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      display: block;
    }

    .label {
      display: block;
      font: 600 14px/121% source-sans-pro;
      color: #4e5255;
      margin-bottom: 6px;
    }

    input {
      border: 1px solid #e4e5e7;
      width: 181px;
      font: 400 15px/100% source-sans-pro;
      color: #a1a3a6;
      padding: 15px 5px;
      box-sizing: border-box;
      margin-bottom: 16px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .field-value-container {
      width: 100%;

      .field-value {
        width: 100%;
        font: 400 15px/100% source-sans-pro;
        color: #a1a3a6;
        padding: 15px 5px;
        box-sizing: border-box;
        margin-bottom: 16px;
      }
    }

    .summary {
      width: 100%;
      max-width: 378px;

      @media only screen and (min-width: 1025px) {
        width: 295px;
      }

      textarea {
        width: 100%;
        border: 1px solid #e4e5e7;
        font: 400 15px/100% source-sans-pro;
        color: #a1a3a6;
        padding: 15px 5px;
        box-sizing: border-box;
        min-height: 88px;
        resize: none;

        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font: 400 15px/100% source-sans-pro;
          color: #a1a3a6;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          font: 400 15px/100% source-sans-pro;
          color: #a1a3a6;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          font: 400 15px/100% source-sans-pro;
          color: #a1a3a6;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          font: 400 15px/100% source-sans-pro;
          color: #a1a3a6;
        }
      }
    }
  }
`;

export default BasicInfoWrapper;
