import styled from 'styled-components';

const StyledSalesAgents = styled.div`
  
  .sales-agent-container {
    margin: 0 23px;
    width: 90%;

    @media screen and (min-width: 768px) {
      width: 88%;
      margin: 0 auto 55px;
    }
  }

  .button-container{
    max-width: 690px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
  
  .basic-info-container {
    position: relative;
  }

  .section-header {
    padding: 0px;
    padding-top: 51px;
    padding-bottom: 1em;
    font-size: 15px;
    color: #4e5255;
    max-width: 690px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  p {
    margin 0px;
    padding: 0px;
    font-size: 15px;
    color: #777b7e;
  }
  
  .sub-heading {
    color: #4e5255;
  }

  label {
    
  }

  textarea, input {
    display: block;
    margin-top: 6px;
  }

  .favorite-home {
    margin-bottom: 150px;
  }
`;

export default StyledSalesAgents;
