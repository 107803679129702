import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import StyledDatePicker from './DatePicker.Style';
import { formatShortWeekday } from '../../utils';

const DatePicker = ({ value, onChange }) => {
  return (
    <StyledDatePicker>
      <Calendar
        value={value}
        onChange={onChange}
        formatShortWeekday={(locale, date) => formatShortWeekday(date)}
        maxDetail="month"
        minDetail="year"
        showNeighboringMonth={false}
      />
    </StyledDatePicker>
  );
};

DatePicker.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
