import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13"
    viewBox="0 0 16 13"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M14.113.614l1.462 1.364L5.511 12.762.113 6.978l1.462-1.364L5.511 9.83z"
    />
  </svg>
);

Check.propTypes = {
  color: PropTypes.string,
};

Check.defaultProps = {
  color: '#ffffff',
};

export default Check;
