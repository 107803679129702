import React from 'react';

const NewTab = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="a"
        d="M5.553 4.99v14.052h14.405v-4.587h-1.535v3.052H7.087V6.524h3.293V4.99z"
      />
      <path
        id="c"
        d="M12.755 4.99v1.534h4.583l-8.441 8.412 1.085 1.085 8.441-8.412v4.582h1.535V4.99z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="#777B7E" mask="url(#b)">
        <path d="M0 0h24v24H0z" />
      </g>
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <g fill="#777B7E" mask="url(#d)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default NewTab;
