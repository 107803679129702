import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { HomesContext } from './Homes';
import Details from './Details/Details';
import Images from './Images/Images';
import Features from './Features/Features';
import LoadingDots from './LoadingDots';
import Error from './Error';
import { canViewHome } from '../permissions';
import { UserContext } from '../components/User';
import { Redirect } from 'react-router-dom';

const Home = ({
  match: {
    params: { homeId },
  },
  location,
}) => {
  const {
    updateHomeAttribute,
    updateHome,
    updateHomeFeatures,
    updateStagedHome,
    setActiveHomeId,
    activeHome,
    stagedHome,
    isError,
    isLoading,
    isUpdating,
    error,
    rooms,
  } = useContext(HomesContext);

  const { activeLot } = useContext(UserContext);

  useEffect(() => {
    setActiveHomeId(homeId);
    return () => {
      // Clear active home on unmount to avoid refresh flash
      setActiveHomeId(null);
    };
  }, [homeId, setActiveHomeId]);

  if (!canViewHome(activeHome, activeLot)) {
    return <Redirect to="/homes/on-display/" />;
  }

  if (isError) {
    return <Error message="Details could not be loaded." error={error} />;
  }

  if (
    isUpdating ||
    isLoading ||
    !activeHome ||
    !activeHome.detailsFetched ||
    !stagedHome
  ) {
    return <LoadingDots />;
  }

  return (
    <Switch>
      <Route
        exact
        path={['/home/:homeId/info', '/land/:homeId/info']}
        render={props => (
          <Details
            home={activeHome}
            updateAttribute={updateHomeAttribute}
            updateHome={updateHome}
            stagedHome={stagedHome}
            updateStagedHome={updateStagedHome}
            isUpdating={isUpdating}
            pathname={location.pathname}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={['/home/:homeId/images', '/land/:homeId/images']}
        render={props => (
          <Images
            home={activeHome}
            rooms={rooms}
            updateHome={updateHome}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={['/home/:homeId/features', '/land/:homeId/features']}
        render={props => (
          <Features
            home={activeHome}
            updateHomeFeatures={updateHomeFeatures}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

Home.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default Home;
