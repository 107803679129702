import posed from 'react-pose';

const PosedTabBarLink = posed.p({
  active: { color: '#0075c9', transition: { duration: '150' } },
  inactive: { color: '#9b9b9b', transition: { duration: '150' } },
  pressable: true,
  press: {
    scale: 0.85,
    transition: { duration: 75 },
  },
  init: {
    scale: 1,
    transition: { duration: 75 },
  },
});

export default PosedTabBarLink;
