import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Prompt, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { toast } from 'react-toastify';
import StyledDetails from './Details.Style';
import Errors from './Errors';
import Specs from './Specs';
import Pricing, { DEALER_PRICE_ID, DEALER_RETAIL_PRICE_ID } from './Pricing';
import Options from './Options';
import DetailsLandSpecs from './DetailsLandSpecs';
import Downloads from './Downloads';
import { UserContext } from '../User';
import { pick } from '../../utils';
import Modal from '../Modal';
import ToastSave from '../ToastSave';
import { history } from '../../Router';
import { SAVE_TOAST_OPTIONS, STICKER_TYPES } from '../../constants';
import { canViewLandRecords } from '../../permissions';
import useFocus from '../../hooks/useFocus';
import { analyticsSendEvent } from '../../utils';

/**
 * Determines applicable tags for a given home
 *
 * @param home
 * @returns {Array<string>}
 */
const getHomeTags = home => {
  const availableTags = [
    { attribute: 'IsOnLand', label: 'On Land' },
    { attribute: 'IsSold', label: 'Sold' },
    { attribute: 'IsUsed', label: 'Used' },
  ];

  if (home.IsAvailableFloorPlan) {
    return [];
  }
  return availableTags.reduce((acc, tag) => {
    return home[tag.attribute] ? [...acc, tag.label] : [...acc];
  }, []);
};

const specKeys = [
  'Baths',
  'Beds',
  'SquareFeet',
  'ModelNumber',
  'Name',
  'StockNumber',
  'SerialNumber',
  'IsAvailableFloorPlan',
  'IsLand',
];
const optionKeys = [
  'IsPublished',
  'IsSaleHome',
  'IsSold',
  'IsLand',
  'IsOnLand',
  'IsOnThirdPartyLand',
  'Id',
  'IsAvailableFloorPlan',
  'IsLand',
  'StickerOptions',
  'StickerTypeId',
  'ErrorUnpublishedByDealer',
  'ErrorRequiresFloorPlanImage',
  'ErrorRequiresTwoImages',
  'ErrorRequiresAcres',
  'ErrorRequiresAddress',
  'ErrorRequiresLandImage',
  'ErrorRequiresBrokerageName',
  'ErrorRequiresBrokerageContact',
];
const landKeys = [
  'StockNumber',
  'Address1',
  'Address2',
  'Acres',
  'StateProvince',
  'City',
  'PostalCode',
  'BrokerageEmail',
  'BrokerageName',
  'BrokeragePhone',
];
const pricingKeys = [
  'PriceOptions',
  'HomePriceOptions',
  'IsAvailableFloorPlan',
  'IsLand',
];
const downloadKeys = ['ModelNumber', 'SerialNumber'];

const analyticsInventoryPriceTextOverrides = {
  '6': 'Before Options Shift Down',
  '7': 'Before Options Default',
  '8': 'Before Options Shift Up',
};

const analyticsModelPriceTextOverrides = {
  '-1': 'Before Options Shift Down',
  '0': 'Before Options Default',
  '1': 'Before Options Shift Up',
};
/**
 * The top level component for the home details page
 */
const Details = ({
  home,
  stagedHome,
  updateAttribute,
  updateHome,
  updateStagedHome,
  isUpdating,
  pathname,
  history,
}) => {
  const { activeLot } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [focusRef, setHasFocus, hasFocus] = useFocus();
  const acresRef = useRef();
  const addrRef = useRef();
  const brokerageNameRef = useRef();
  const brokerageContactRef = useRef();

  const acresFocus = () => {
    acresRef.current.focus();
  };

  const addressFocus = () => {
    addrRef.current.focus();
  };

  const brokerageNameFocus = () => {
    brokerageNameRef.current.focus();
  };

  const brokerageContactFocus = () => {
    brokerageContactRef.current.focus();
  };

  const getErrors = (
    {
      ErrorRequiresFloorPlanImage,
      ErrorRequiresTwoImages,
      ErrorUnpublishedByDealer,
      ErrorRequiresAcres,
      ErrorRequiresAddress,
      ErrorRequiresLandImage,
      ErrorRequiresBrokerageName,
      ErrorRequiresBrokerageContact,
      Id,
      InventoryId,
      IsLand,
    },
    ErrorUnpublishedByDealerClickHandler
  ) => {
    const errors = [
      {
        message:
          'For your home to be visible, you must upload at least a floor plan.',
        error: ErrorRequiresFloorPlanImage,
        buttonText: 'Add Image',
        targetUrl: `/home/${Id}/images`,
      },
      {
        message:
          'For your home to be visible, you must upload at least two images.',
        error: ErrorRequiresTwoImages,
        buttonText: 'Add Image',
        targetUrl: `/home/${Id}/images`,
      },
      {
        message: 'This home was unpublished by the dealer.',
        error: ErrorUnpublishedByDealer,
        buttonText: 'Publish Home',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/info`
            : `/home/${Id}/info`,
        onClickHandler: ErrorUnpublishedByDealerClickHandler,
      },
      {
        message: 'This listing needs an acres value.',
        error: ErrorRequiresAcres,
        buttonText: 'Add Acres',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/info`
            : `/home/${Id}/info`,
        onClickHandler: acresFocus,
      },
      {
        message: 'This listing needs an address value.',
        error: ErrorRequiresAddress,
        buttonText: 'Add Address',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/info`
            : `/home/${Id}/info`,
        onClickHandler: addressFocus,
      },
      {
        message:
          'For this listing to be visible, you must upload at least one land image.',
        error: ErrorRequiresLandImage,
        buttonText: 'Add Image',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/images`
            : `/home/${Id}/images`,
      },
      {
        message: 'This listing needs a brokerage name.',
        error: ErrorRequiresBrokerageName,
        buttonText: 'Add Brokerage Name',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/info`
            : `/home/${Id}/info`,
        onClickHandler: brokerageNameFocus,
      },
      {
        message: 'This listing needs a a brokerage email or phone.',
        error: ErrorRequiresBrokerageContact,
        buttonText: 'Add Brokerage Contact',
        targetUrl:
          (pathname && pathname.startsWith('/land/')) || IsLand
            ? `/land/${InventoryId}/info`
            : `/home/${Id}/info`,
        onClickHandler: brokerageContactFocus,
      },
    ];

    return errors.filter(error => error.error);
  };

  /**
   * Updates temporary home object with a new Name
   *
   * @param newName
   */
  const setHomeName = newName => {
    updateStagedHome({
      ...stagedHome,
      Name: newName,
    });
  };

  /**
   * Updates temporary home object with a new Name
   *
   * @param newInventoryDescription
   */
  const setInventoryDescription = newInventoryDescription => {
    updateStagedHome({
      ...stagedHome,
      InventoryDescription: newInventoryDescription,
    });
  };

  /**
   * Updates temporary home object with a new ModelPriceAdjustmentTypeId
   *
   * @param newModelPriceAdjustmentTypeId
   */
  const setModelPriceAdjustmentTypeId = newModelPriceAdjustmentTypeId => {
    updateStagedHome({
      ...stagedHome,
      ModelPriceAdjustmentTypeId: newModelPriceAdjustmentTypeId,
    });
  };

  /**
   * Updates temporary home object with a new PublishedPriceStateId

   * Updates temporary home object with a new PublishedPriceStateId
   *
   * @param newPublishedPriceStateId
   */
  const setPublishedPriceStateId = newPublishedPriceStateId => {
    updateStagedHome({
      ...stagedHome,
      PublishedPriceStateId: newPublishedPriceStateId,
    });
  };

  /**
   * Updates temporary home object with a new PublishedPriceStateValue
   *
   * @param newPublishedPriceStateValue
   */
  const setPublishedPriceStateValue = newPublishedPriceStateValue => {
    updateStagedHome({
      ...stagedHome,
      PublishedPriceStateValue: newPublishedPriceStateValue,
    });
  };

  const setAddress = newAddress => {
    updateStagedHome({
      ...stagedHome,
      Address1: newAddress,
    });
  };

  const setCity = newCity => {
    updateStagedHome({
      ...stagedHome,
      City: newCity,
    });
  };

  const setState = newState => {
    updateStagedHome({
      ...stagedHome,
      StateProvince: newState,
    });
  };

  const setZip = newZip => {
    updateStagedHome({
      ...stagedHome,
      PostalCode: newZip.replace(/\D/g, ''),
    });
  };

  const setAcres = newAcres => {
    updateStagedHome({
      ...stagedHome,
      Acres: newAcres,
    });
  };

  const setPrice = newPrice => {
    updateStagedHome({
      ...stagedHome,
      Price: newPrice,
    });
  };

  const setBrokerageName = newName => {
    updateStagedHome({
      ...stagedHome,
      BrokerageName: newName,
    });
  };

  const setBrokeragePhone = newPhone => {
    updateStagedHome({
      ...stagedHome,
      BrokeragePhone: newPhone.replace(/\D/g, ''),
    });
  };

  const setBrokerageEmail = newEmail => {
    updateStagedHome({
      ...stagedHome,
      BrokerageEmail: newEmail,
    });
  };

  const isDealerPriceMinLength = () => {
    if (
      (stagedHome.PublishedPriceStateId === DEALER_PRICE_ID ||
        stagedHome.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      (stagedHome.PublishedPriceStateValue &&
        hasFocus &&
        stagedHome.PublishedPriceStateValue.length >= 3)
    ) {
      return true;
    }
    return false;
  };

  //Stopped using lodash.omit due to needing to be able to use more comparison
  const omitValues = (keys, obj) =>
    Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

  const stagedHomeOmit = () => {
    if (
      (stagedHome.PublishedPriceStateId === DEALER_PRICE_ID ||
        stagedHome.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      stagedHome.PublishedPriceStateValue === ''
    ) {
      return omitValues(
        ['ErrorUnpublishedByDealer', 'PublishedPriceStateId'],
        stagedHome
      );
    } else {
      return omitValues(['ErrorUnpublishedByDealer'], stagedHome);
    }
  };

  const homeOmit = () => {
    return omitValues(['ErrorUnpublishedByDealer'], home);
  };

  const getDifference = (a, b) =>
    Object.fromEntries(
      Object.entries(b).filter(([key, val]) => key in a && a[key] !== val)
    );
  //Moved this out it be a function instead of lodash because of the need
  //of being able to only mark homeHasUpdates under certain conditions.
  //It fired off at times it shouldn't have under lodash.isEqual
  const homeHasUpdates = () => {
    const diff = getDifference(homeOmit(), stagedHomeOmit());

    const isDeepEqual = (object1, object2) => {
      const objKeys1 = Object.keys(object1);
      const objKeys2 = Object.keys(object2);

      if (objKeys1.length !== objKeys2.length) return false;

      for (let key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if (
          (isObjects && !isDeepEqual(value1, value2)) ||
          (!isObjects && value1 !== value2)
        ) {
          return false;
        }
      }
      return true;
    };

    const isObject = object => {
      return object != null && typeof object === 'object';
    };

    const dealerPriceHome =
      home &&
      !home.IsAvailableFloorPlan &&
      home.HomePriceOptions.find(hm => hm.Id === DEALER_PRICE_ID);

    const isPriceEqual =
      stagedHomeOmit().PublishedPriceStateValue &&
      stagedHomeOmit()
        .PublishedPriceStateValue.replace('$', '')
        .replace(',', '') === dealerPriceHome.Value.toString();

    //Sets to true if you switch to Dealer Price or Dealer Retail Price
    //and there is already a value in the input field from
    //being added before
    if (
      (diff.PublishedPriceStateId === DEALER_PRICE_ID ||
        diff.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      dealerPriceHome.Value.toString().length >= 3
    ) {
      return true;
    }

    if (
      stagedHomeOmit().PublishedPriceStateValue !== undefined &&
      stagedHomeOmit().PublishedPriceStateValue.length >= 3 &&
      !isPriceEqual
    ) {
      return true;
    }

    //Sets to false if you switch to Dealer Price or Dealer Retail Price
    //and there is no PublishedPriceStateValue and you have not met
    //the min length of the input field which is 3 characters
    if (
      (diff.PublishedPriceStateId === DEALER_PRICE_ID ||
        diff.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      stagedHomeOmit().PublishedPriceStateValue === undefined &&
      !isDealerPriceMinLength()
    ) {
      return false;
    }

    //Sets to true if there is a difference in the 2 objects
    //and the difference is not and empty object
    //Note: The diff automatically sets to an empty object
    //and that is why that is necessary
    if (
      !isDeepEqual(stagedHomeOmit(), homeOmit()) &&
      JSON.stringify(diff) !== '{}'
    ) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    const dealerPriceHome =
      home &&
      !home.IsAvailableFloorPlan &&
      home.HomePriceOptions.find(hm => hm.Id === DEALER_PRICE_ID);

    if (isDealerPriceMinLength()) {
      setShowToast(true);
    }
    if (
      (stagedHome.PublishedPriceStateId === DEALER_PRICE_ID ||
        stagedHome.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      dealerPriceHome.Value.toString().length >= 3
    ) {
      setShowToast(true);
    }

    if (
      (stagedHome.PublishedPriceStateId === DEALER_PRICE_ID ||
        stagedHome.PublishedPriceStateId === DEALER_RETAIL_PRICE_ID) &&
      (!isDealerPriceMinLength() && hasFocus) &&
      stagedHome.PublishedPriceStateValue === undefined
    ) {
      setShowToast(false);
    }

    if (homeHasUpdates() && !hasFocus) {
      setShowToast(true);
    }
  }, [stagedHome.PublishedPriceStateId, stagedHome.PublishedPriceStateValue]);

  const saveFunction = useCallback(() => {
    const diff = getDifference(homeOmit(), stagedHomeOmit());

    if (diff.hasOwnProperty('Name')) {
      analyticsSendEvent('home_info_name');
    }
    if (diff.hasOwnProperty('InventoryDescription')) {
      analyticsSendEvent('home_info_description');
    }
    if (diff.hasOwnProperty('PublishedPriceStateId')) {
      analyticsSendEvent('home_info_inventory_price_display', {
        publishedPriceStateId: diff.PublishedPriceStateId,
        publishedPriceStateText: analyticsInventoryPriceTextOverrides.hasOwnProperty(
          diff.PublishedPriceStateId + ''
        )
          ? analyticsInventoryPriceTextOverrides[
              diff.PublishedPriceStateId + ''
            ]
          : stagedHome.HomePriceOptions.find(
              option => option.Id === diff.PublishedPriceStateId
            ).Label,
      });
    }
    if (stagedHome.hasOwnProperty('PublishedPriceStateValue')) {
      analyticsSendEvent('home_info_dealer_price', {
        price: stagedHome.PublishedPriceStateValue,
      });
    }
    if (stagedHome.hasOwnProperty('ModelPriceAdjustmentTypeId')) {
      analyticsSendEvent('home_info_model_price_display', {
        publishedPriceStateId: stagedHome.ModelPriceAdjustmentTypeId,
        publishedPriceStateText:
          analyticsModelPriceTextOverrides[
            stagedHome.ModelPriceAdjustmentTypeId + ''
          ],
      });
    }

    if (
      stagedHome.PublishedPriceStateId !== DEALER_PRICE_ID &&
      stagedHome.PublishedPriceStateId !== DEALER_RETAIL_PRICE_ID
    ) {
      updateHome(omitValues(['PublishedPriceStateValue'], stagedHome));
    } else {
      updateHome(stagedHome);
    }
    setShowToast(false);
    toast.dismiss('saveToast');
  }, [stagedHome, updateHome]);

  // check to see if we need to display the save button
  // (if the staged home is different than the home from the api/db)
  useEffect(() => {
    if (!isUpdating && !isComingSoon) {
      // if home is not already updating...

      if (homeHasUpdates() && showToast) {
        // stagedHome contains changes from the original/saved home in the db
        if (!toast.isActive('saveToast')) {
          // no existing toast is being displayed... display one
          // and have it callback to our saveFunction
          toast.info(
            <ToastSave saveFunction={saveFunction} />,
            SAVE_TOAST_OPTIONS
          );
        } else {
          // update toast with correct saveFunction (func ref changes on re-render)
          toast.update('saveToast', {
            render: <ToastSave saveFunction={saveFunction} />,
            SAVE_TOAST_OPTIONS,
          });
        }
      } else {
        toast.dismiss('saveToast');
      }
    }
  }, [stagedHome, home, isUpdating, showToast]);

  // called by react router '<Prompt>' component when user attempts to nav and there are unsaved changes
  const handleBlockedNavigation = nextLocation => {
    // show user save/discard
    setShowModal(true);
    setShowToast(false);
    toast.dismiss('saveToast');
    setNextLocation(nextLocation);
    // returning false prevents nav
    return false;
  };

  const handleSaveClick = () => {
    setShowModal(false);
    saveFunction(stagedHome);

    if (nextLocation) {
      history.push(nextLocation);
    }
  };

  const handleDiscardClick = () => {
    setShowModal(false);

    if (nextLocation) {
      history.push(nextLocation);
    }
  };

  const fixKeys = obj => {
    // TODO: model homes have 'PriceOptions' key instead of 'HomeOptions'
    // for now we transform here - though this should probably be changed in api
    const res = { ...obj };
    if (
      Object.prototype.hasOwnProperty.call(res, 'PriceOptions') &&
      res.PriceOptions !== undefined
    ) {
      res.HomePriceOptions = res.PriceOptions;
    }
    delete res.PriceOptions;
    return res;
  };

  const isComingSoon =
    home &&
    (home.StickerTypeId === STICKER_TYPES.COMING_SOON ||
      (home.SerialNumber !== null && home.SerialNumber.includes('-')));

  if (isComingSoon) {
    return <Redirect to="/homes/on-display/" />;
  }

  return (
    <StyledDetails>
      {home && (
        <>
          <Errors
            {...{
              error: getErrors(home, () => {
                updateAttribute(home, 'IsPublished', true);
              }),
              errorAction: '',
            }}
          />
          {!home.IsLand && (
            <Specs
              {...{
                ...pick(specKeys)(home),
                tags: getHomeTags(home),
                Name: stagedHome.Name,
                setHomeName,
                InventoryDescription: stagedHome.InventoryDescription,
                LineDescription: stagedHome.LineDescription,
                setInventoryDescription,
              }}
              SpecsText="Home"
              isUpdating={isUpdating}
            />
          )}
          {(home.IsOnLand || home.IsLand) &&
            activeLot &&
            canViewLandRecords(activeLot.LotNumber) && (
              <DetailsLandSpecs
                {...{
                  ...pick(landKeys)(stagedHome),
                  InventoryDescription: stagedHome.InventoryDescription,
                  setAcres,
                  setAddress,
                  setBrokerageEmail,
                  setBrokerageName,
                  setBrokeragePhone,
                  setCity,
                  setState,
                  setZip,
                  setInventoryDescription,
                }}
                acresRef={acresRef}
                addrRef={addrRef}
                brokerageContactRef={brokerageContactRef}
                brokerageNameRef={brokerageNameRef}
                isUpdating={isUpdating}
              />
            )}
          <Pricing
            {...{
              ...fixKeys(pick(pricingKeys)(home)),
              PublishedPriceStateId: stagedHome.PublishedPriceStateId,
              PublishedPriceStateValue: stagedHome.PublishedPriceStateValue,
              setPublishedPriceStateId,
              setPublishedPriceStateValue,
              ModelPriceAdjustmentTypeId: stagedHome.ModelPriceAdjustmentTypeId,
              setModelPriceAdjustmentTypeId,
            }}
            focusRef={focusRef}
            setHasFocus={setHasFocus}
            setShowToast={setShowToast}
          />
          {!home.IsAvailableFloorPlan && (
            <Options
              {...{
                ...pick(optionKeys)(home),
                home,
                toggle: (attr, value) => {
                  // the update attribute is special and updates the staged home at the same time
                  updateAttribute(home, attr, value);
                },
              }}
              OptionsText={home.IsLand ? 'Land' : 'Home'}
            />
          )}

          <Downloads
            {...{
              ...pick(downloadKeys)(home),
              lotBaseUrl: activeLot.Url,
              lotNumber: activeLot.LotNumber,
            }}
          />
          <Prompt
            when={homeHasUpdates() && !nextLocation}
            message={handleBlockedNavigation}
          />
          <Modal
            modalHeadline="Save changes?"
            modalBody="If you don’t save your changes, any changes you’ve made will be undone."
            closeCopy="DISCARD"
            saveCopy="SAVE"
            show={showModal}
            closeCallback={() => {
              setShowModal(false);
            }}
            discardCallback={handleDiscardClick}
            saveCallback={handleSaveClick}
          />
        </>
      )}
    </StyledDetails>
  );
};

Details.propTypes = {
  /**
   * The home object that contains all the details data
   */
  home: PropTypes.shape(),

  /**
   * A clone of the home object used for storing edits
   */
  stagedHome: PropTypes.shape(),

  /**
   * Makes an API call to update a single home attribute, updates state optimistically
   */
  updateAttribute: PropTypes.func.isRequired,

  /**
   * Makes an API call to update a home object, updates state optimistically
   */
  updateHome: PropTypes.func.isRequired,

  /**
   * Dispatches an update for the staged home object
   */
  updateStagedHome: PropTypes.func.isRequired,

  /**
   * This returns true if an update is in progress, otherwise false
   */
  isUpdating: PropTypes.bool,
};

Details.defaultProps = {
  home: undefined,
  stagedHome: undefined,
  isUpdating: false,
};

export default Details;
