import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledSiteItemCard from './SiteItemCard.Style';
import LinkIcon from '../../icons/LinkIcon';
import { SiteItemsContext } from './SiteItemsProvider';
import { formatDate } from '../../utils';

const SiteItemCard = ({ item, type }) => {
  const { showDeleteModal } = useContext(SiteItemsContext);
  return (
    <StyledSiteItemCard>
      <div className="img-container">
        {(item.HomeCenterWebsiteContentType !== 2 ||
          item.HomeCenterWebsiteContentType !== 1) && (
          <img src={item.ImageReference} alt="" />
        )}
        {(item.HomeCenterWebsiteContentType === 2 ||
          item.HomeCenterWebsiteContentType === 1) && (
          <img src={item.MainImage} alt="" />
        )}
      </div>

      <div className="publish-date publish-date-mobile">
        {item.isOnline ? (
          <p className="published">
            <span className="green-bullet">&bull;</span>ONLINE
          </p>
        ) : (
          <p className="unpublished">
            <span className="gray-bullet">&bull;</span>OFFLINE
          </p>
        )}
        <p className="auto-publish">
          Auto-publish on {formatDate(item.StartDate)}
        </p>
        <p className="expiration">
          {`Expires ${
            item.EndDate ? `on ${formatDate(item.EndDate)}` : 'never'
          }`}
        </p>
      </div>

      <div className="card-content">
        <h4 className="headline">
          {item.HomeCenterWebsiteContentType === 2 ||
          item.HomeCenterWebsiteContentType === 1
            ? item.Title
            : item.Headline}
        </h4>
        {(item.HomeCenterWebsiteContentType === 2 ||
          item.HomeCenterWebsiteContentType === 1) && (
          <>
            {item.IsFeatured &&
              (item.EndDate == null ||
                Date.parse(item.EndDate) >= Date.now()) && (
                <div className="featured">featured</div>
              )}
            <p className="copy">{item.Content.replace(/(<([^>]+)>)/gi, '')}</p>

            {item.LinkUrl && (
              <a href={item.LinkUrl} rel="noopener noreferrer" target="_blank">
                <LinkIcon className="linkIcon" />
                {item.LinkUrl}
              </a>
            )}
          </>
        )}
      </div>

      <div className="card-caboose">
        <div className="publish-date publish-date-desktop">
          {item.isOnline ? (
            <p className="published">
              <span className="green-bullet">&bull;</span>ONLINE
            </p>
          ) : (
            <p className="unpublished">
              <span className="gray-bullet">&bull;</span>OFFLINE
            </p>
          )}
          <p className="auto-publish">
            Auto-publish on {formatDate(item.StartDate)}
          </p>
          <p className="expiration">
            {`Expires ${
              item.EndDate ? `on ${formatDate(item.EndDate)}` : 'never'
            }`}
          </p>
        </div>
        <div className="controls-area">
          <button
            type="button"
            className="delete"
            onClick={event => {
              showDeleteModal(item);
              event.preventDefault();
            }}
          >
            DELETE
          </button>
          {item.IsEditable &&
            (item.HomeCenterWebsiteContentType !== 2 ||
              item.HomeCenterWebsiteContentType !== 1) && (
              <Link
                className="edit"
                to={`/content/${type}/${item.RetailSiteItemId}`}
              >
                EDIT
              </Link>
            )}

          {(item.HomeCenterWebsiteContentType === 2 ||
            item.HomeCenterWebsiteContentType === 1) && (
            <Link
              className="edit"
              to={`/content/${type}/${item.HomeCenterWebsiteContentId}`}
            >
              EDIT
            </Link>
          )}
        </div>
      </div>
    </StyledSiteItemCard>
  );
};

SiteItemCard.propTypes = {
  item: PropTypes.shape({
    RetailSiteItemId: PropTypes.number.isRequired,
    RetailSiteItemType: PropTypes.string.isRequired,
    HomeCenterWebsiteContentType: PropTypes.string,
    ImageReference: PropTypes.string.isRequired,
    Headline: PropTypes.string.isRequired,
    FullCopy: PropTypes.string,
    LinkUrl: PropTypes.string,
    StartDate: PropTypes.shape().isRequired,
    EndDate: PropTypes.shape(),
    IsPublished: PropTypes.bool.isRequired,
    IsEditable: PropTypes.bool.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default SiteItemCard;
