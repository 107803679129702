import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledSiteItemList from './SiteItemList.Style';
import SearchBar from './../SearchBar';
import { UserContext } from '../User';
import { SiteItemsContext } from './SiteItemsProvider';
import { WelcomeHomeContext } from '../WelcomeHome/WelcomeHomeProvider';
import LoadingDots from '../LoadingDots';
import SiteItemCard from './SiteItemCard';
import PlusIcon from '../../icons/PlusIcon';
import AddButton from '../AddButton';
import Modal from '../Modal';
import Error from '../Error';
import AlertIcon from '../../icons/AlertIcon';
import ActiveImages from '../WelcomeHome/ActiveImages';
import WelcomeHomeListingCard from '../WelcomeHome/WelcomeHomeListingCard';
import SearchIcon from '../../icons/SearchIcon';

const availableSiteItemTypes = ['news', 'promotions', 'events', 'welcome-home'];

const SiteItemList = ({
  match: {
    params: { type },
  },
  history: { push },
}) => {
  if (availableSiteItemTypes.indexOf(type) < 0) {
    // 404?
    push('/');
  }

  const {
    welcomeHomeItems,
    activeImages,
    originalOrder,
    currentHistory,
    toggleActiveImage,
    deleteWelcomeHomeImage,
    isLoading: isLoadingWelcomeHomeItems,
  } = useContext(WelcomeHomeContext);

  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [filterKey, setFilterKey] = useState('');

  // page specific states & functions
  const [filteredData, setFilteredData] = useState([]);
  const [galleryPageItems, setGalleryPageItems] = useState();

  // handle search of data
  const handleSearch = value => {
    if (value === '') {
      return welcomeHomeItems;
    }
    return welcomeHomeItems.filter(
      data =>
        data.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        data.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        data.Email.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  };

  // handle sorting of data
  const handleSort = (obj, value) => {
    if (value == 'NewToOld' || value == '') {
      return obj.sort((a, b) => {
        if (a['CreationDate'] > b['CreationDate']) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    if (value == 'OldToNew') {
      return obj.sort((a, b) => {
        if (a['CreationDate'] < b['CreationDate']) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    if (value == 'Alphabetical') {
      return obj.sort((a, b) => {
        if (a['LastName'] < b['LastName']) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    return obj;
  };

  // handle filtering of data
  const handleFilter = (arr, value) => {
    if (value == 'All') {
      return arr;
    }
    if (value == 'Home') {
      return arr.filter(home => home.SortOrder !== null);
    }
    if (value == 'Gallery') {
      return arr.filter(home => home.IsShownInGallery === true);
    }
    if (value == 'NotPosted') {
      return arr.filter(
        home => home.IsShownInGallery === false && home.SortOrder === null
      );
    }
    return arr;
  };

  // handle filtering
  useEffect(() => {
    let dataSearch = handleSearch(searchKey);
    let dataSort = handleSort(dataSearch, sortKey);
    let finalData = handleFilter(dataSort, filterKey);

    setFilteredData([...finalData]);
  }, [searchKey, sortKey, filterKey]);

  useEffect(() => {
    setFilteredData(
      Array.from(new Set(welcomeHomeItems.map(a => a.WelcomeHomeItemId))).map(
        id => {
          return welcomeHomeItems.find(a => a.WelcomeHomeItemId === id);
        }
      )
    );
  }, [welcomeHomeItems]);

  useEffect(() => {
    let galleryArr = welcomeHomeItems.filter(
      home => home.IsShownInGallery === true
    );
    setGalleryPageItems(galleryArr);
  }, [welcomeHomeItems]);

  const {
    news,
    promotions,
    events,
    isLoading,
    targetItem,
    isDeleteModalVisible,
    hideDeleteModal,
    deleteItemById,
    deleteContentItemById,
  } = useContext(SiteItemsContext);

  const {
    user: {
      isError: isUserProviderError,
      error: userProviderError,
      IsCorporateOfficeUser: isCorporateOfficeUser,
    },
  } = useContext(UserContext);

  const handleDelete = () => {
    if (type === 'promotions') {
      deleteItemById(targetItem.RetailSiteItemId);
    } else {
      deleteContentItemById(targetItem.HomeCenterWebsiteContentId);
    }
    hideDeleteModal();
  };

  const deleteFunction = async imageId => {
    deleteWelcomeHomeImage(imageId);
  };

  if (!isLoading && !isCorporateOfficeUser && type === 'promotions') {
    push('/');
  }

  if ((isLoading || isLoadingWelcomeHomeItems) && !isUserProviderError) {
    return <LoadingDots />;
  }

  if (isUserProviderError) {
    return (
      <StyledSiteItemList>
        <Error
          message={`${
            type === 'news'
              ? 'News'
              : type === 'events'
              ? 'Events'
              : 'Promotions'
          } could not be loaded.`}
          error={userProviderError}
        />
      </StyledSiteItemList>
    );
  }

  return (
    <StyledSiteItemList>
      {type === 'welcome-home' && (
        <>
          <AddButton
            buttonText="ADD WELCOME HOME IMAGE"
            path="/content/welcome-home/new"
            icon={<PlusIcon />}
          />
          {activeImages.length < 8 && (
            <div className="error-wrap">
              <AlertIcon color="#ffffff" backgroundColor="#b21f28" />
              <span>
                Not enough images have been selected to be displayed on the home
                page.
              </span>
            </div>
          )}
          {galleryPageItems && galleryPageItems.length < 12 && (
            <div className="error-wrap">
              <AlertIcon color="#ffffff" backgroundColor="#b21f28" />
              <span>
                Not enough images have been selected to activate the gallery
                page.
              </span>
            </div>
          )}
          <ActiveImages
            aImages={activeImages}
            orgOrder={originalOrder}
            history={currentHistory}
          />

          {welcomeHomeItems.length > 0 && (
            <>
              <div className="archived-images-intro">
                <div className="content">
                  <h2>Image Library</h2>
                  <div className="intro-p">
                    <p>
                      Configure where images are displayed on your website (Home
                      Page, Gallery Page, or both). You are encouraged to add as
                      many images to the Gallery Page as you see fit, but at
                      least 12 images are needed to activate your Gallery Page
                      on your website.
                    </p>
                    <p>Click "Edit" to toggle each available option.</p>
                  </div>
                </div>

                <div className="filter-section">
                  <label htmlFor="search" className="search-wrap">
                    <SearchIcon className="search-icon" />
                    <input
                      type="text"
                      name="search"
                      placeholder={'Search for Customer'}
                      value={searchKey}
                      className="styled-input search-input"
                      onChange={e => setSearchKey(e.target.value)}
                    />
                  </label>

                  <select
                    name="sort-select"
                    value={sortKey}
                    placeholder="Sort By"
                    onChange={e => setSortKey(e.target.value)}
                    className="styled-select sort-select"
                  >
                    <option value="">Sort By</option>
                    <option value="NewToOld">New to Old</option>
                    <option value="OldToNew">Old to New</option>
                    <option value="Alphabetical">Alphabetical</option>
                  </select>

                  <select
                    name="filter-select"
                    value={filterKey}
                    onChange={e => setFilterKey(e.target.value)}
                    className="styled-select filter-select"
                  >
                    <option value="">Filter By</option>
                    <option value="All">All</option>
                    <option value="Home">Home Page</option>
                    <option value="Gallery">Gallery Page</option>
                    <option value="NotPosted">Not Posted</option>
                  </select>
                </div>
              </div>
              {filteredData.length > 0 ? (
                <>
                  <div className="image-library-list">
                    {filteredData.map(item => (
                      <WelcomeHomeListingCard
                        key={item && item.WelcomeHomeItemId}
                        item={item}
                        toggleActiveImage={toggleActiveImage}
                        deleteFunction={
                          isCorporateOfficeUser ? deleteFunction : null
                        }
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="no-results">
                  <p>No Results</p>
                </div>
              )}
            </>
          )}
        </>
      )}

      {type === 'news' && (
        <>
          <AddButton
            buttonText="ADD NEWS ITEM"
            path="/content/news/new"
            icon={<PlusIcon />}
          />

          {news.map(item => (
            <SiteItemCard
              key={item.HomeCenterWebsiteContentId}
              item={item}
              type={type}
            />
          ))}
        </>
      )}

      {type === 'promotions' && (
        <>
          <AddButton
            buttonText="ADD PROMOTION"
            path="/content/promotions/new"
            icon={<PlusIcon />}
          />
          {promotions.map(item => (
            <SiteItemCard key={item.RetailSiteItemId} item={item} type={type} />
          ))}
        </>
      )}

      {type === 'events' && (
        <>
          <AddButton
            buttonText="ADD EVENT"
            path="/content/events/new"
            icon={<PlusIcon />}
          />
          {events.map(item => (
            <SiteItemCard
              key={item.HomeCenterWebsiteContentId}
              item={item}
              type={type}
            />
          ))}
        </>
      )}
      {targetItem && (
        <Modal
          modalHeadline="Are you sure?"
          modalBody={`Are you sure you want to delete "${
            type === 'promotions' ? targetItem.Headline : targetItem.Title
          }"`}
          saveCopy="Delete"
          closeCopy="Cancel"
          saveCallback={handleDelete}
          show={isDeleteModalVisible}
          discardCallback={hideDeleteModal}
          closeCallback={hideDeleteModal}
        />
      )}
    </StyledSiteItemList>
  );
};

SiteItemList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SiteItemList;
