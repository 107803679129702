import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledTabs from './TabBar.Style';
import PosedTabBarLinkBg from './TabBarLinkBg.Pose';

const TabBar = ({ links, pathname, backLink, viewLink, header }) => {
  const pathIndex = links.findIndex(link =>
    pathname.includes(link.to.pathname)
  );

  return (
    <StyledTabs links={links} pathIndex={pathIndex}>
      {(backLink || viewLink) && (
        <div className="top-links">
          {backLink && (
            <Link className="back-button" to={backLink.path}>
              {backLink.icon}
              {backLink.text}
            </Link>
          )}
          {viewLink && (
            <a
              className="view-link"
              href={viewLink.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {viewLink.text}
              {viewLink.icon}
            </a>
          )}
        </div>
      )}
      {header && <h1>{header}</h1>}

      {links && links.length > 0 && (
        <div className="links">
          {links.map(link => (
            <Link key={link.to.pathname} to={{ ...link.to }}>
              <PosedTabBarLinkBg
                pose={
                  pathname.includes(link.to.pathname) ? 'active' : 'inactive'
                }
              >
                {link.text}
              </PosedTabBarLinkBg>
            </Link>
          ))}
          <div className="nav-select-bar-container">
            <div className="selected-bar" />
          </div>
        </div>
      )}
    </StyledTabs>
  );
};

TabBar.propTypes = {
  pathname: PropTypes.string.isRequired,
  header: PropTypes.string,
  links: PropTypes.instanceOf(Array),
  backLink: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  viewLink: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

TabBar.defaultProps = {
  header: '',
  links: [],
  backLink: undefined,
  viewLink: undefined,
};

export default TabBar;
