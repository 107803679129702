import styled from 'styled-components';

const StyledDealerPicker = styled.div`
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 20px;
    display: block;
  }

  .heading {
    margin-bottom: 5px;
  }

  .dealers {
    box-sizing: border-box;
    padding: 1em;
    .tag-container {
      display: flex;
      flex-wrap: wrap;
    }
  }
  a {
    color: #0075c9;
    background: none;
    border: none;
    cursor: pointer;
  }

  .search {
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 250px;
  }

  .suggestions {
    border-radius: 1px;
    border: 1px solid #e4e5e7;
    color: #777b7e;
    font-size: 14px;
    line-height: 140%;
    font-family: source-sans-pro, sans-serif;
    -moz-appearance: none;
    background-color: white;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
    width: 250px;
    padding: 0;
    min-height: 140px;
    div {
      color: #777b7e;
      padding: 8px 15px;
      font-size: 15px;
    }
    .suggestion {
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: #0075c9;
        color: white;
      }
    }
  }

  .dealer-filter-sub-area {
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

export default StyledDealerPicker;
