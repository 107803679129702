import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StyledAddButton from './AddButton.Style';

const AddButton = ({ buttonText, icon, path }) => {
  return (
    <StyledAddButton>
      <Link className="add-button" to={path}>
        {icon}
        {buttonText}
      </Link>
    </StyledAddButton>
  );
};

AddButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  path: PropTypes.string,
};

AddButton.defaultProps = {
  path: '',
};

export default AddButton;
