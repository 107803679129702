import styled from 'styled-components';

const StyledInstallBannerIos = styled.div.attrs({
  className: 'install-banner',
})`
  margin: 0 auto;
  .share-icon {
    width: 20px;
    height: 20px;
  }
`;

export default StyledInstallBannerIos;
