import styled from 'styled-components';

const StyledModelImagesModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .modal-content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 781px;
    background: #fff;
    box-sizing: border-box;
    /* padding: 20px; */
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    width: 40%;
    max-width: 578px;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
      left: 61%;
    }

    @media screen and (max-width: 768px) {
      width: 75%;
    }

    .images {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0 0;
      box-sizing: border-box;
      overflow-y: auto;
      height: 100%;
      max-height: 680px;

      .image {
        max-width: 259px;
        margin-bottom: 17px;
        margin-right: 17px;
        margin-left: 17px;

        &:nth-child(even) {
          margin-right: 0;
          margin-left: 0;
        }

        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
  }

  .btns-container {
    display: flex;
    justify-content: center;
    border-top: 2px solid #e4e5e7;
    margin: 17px 0;
    padding-top: 17px;

    button {
      font: 600 12px/100% source-sans-pro;
      color: #fff;
      background: #00b0ac !important;
      letter-spacing: 0.5px;
      margin-block-start: 0;
      text-align: center;
      margin-block-end: 0;
      border: none;
      background: none;
      border-radius: 2px;
      padding: 16px 0;
      width: 195px;
      transition: color 0.3s;
      &:hover,
      &:focus {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export default StyledModelImagesModal;
