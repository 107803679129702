import styled from 'styled-components';

const StyledNoImagesAvailable = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex: 1 1 100%;
  margin-bottom: 100px;
  .image-wrapper {
    flex: 1 1 100%;
    background: #ffffff;
    padding: 30px;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    text-align: center;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  p.message {
    flex: 1 0;
    max-width: 370px;
    min-width: 200px;
    padding-top: 50px;
    strong {
      display: block;
      margin-bottom: 5px;
    }
  }
  @media screen and (min-width: 700px) {
    .image-wrapper {
      flex: 1 0;
      margin-right: 80px;
      max-width: 320px;
      svg {
        height: auto;
        width: auto;
      }
    }
    p.message {
    }
  }
`;

export default StyledNoImagesAvailable;
