import React, { useContext, useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { UserContext } from '../User';
import salesAgentsReducer from '../../reducers/salesAgents';
import {
  getProfileData,
  getSalesAgentData,
  getSpecialtyOptions,
  updateSalesAgent,
  deleteSalesAgent,
} from '../../Api';
import { ERROR_TOAST_OPTIONS, SAVE_TOAST_OPTIONS } from '../../constants';
import ToastSave from '../ToastSave';
import Modal from '../Modal';
import { analyticsSendEvent } from '../../utils';

export const SalesAgentsContext = React.createContext({
  agentDataLoading: true,
  specialtiesLoading: true,
  showModal: false,
  lastLocation: null,
  isError: false,
  isDeleteModalVisible: false,
});

export const SalesAgentsProvider = ({ children, history }) => {
  const context = useContext(SalesAgentsContext);
  const {
    activeLot,
    user: { UserName, IsCorporateOfficeUser },
  } = useContext(UserContext);
  const [state, dispatch] = useReducer(salesAgentsReducer, context);

  const showModal = useMemo(() => {
    return state.showModal;
  }, [state.showModal]);

  const setShowModal = value => {
    if (value) {
      toast.dismiss();
    }
    dispatch({ type: 'SET_SHOW_MODAL', payload: value });
  };

  const setIsDeleteModalVisible = visible => {
    dispatch({ type: 'SET_IS_DELETE_MODAL_VISIBLE', payload: visible });
  };

  const showDeleteModal = agent => {
    dispatch({ type: 'SET_TARGET_AGENT', payload: agent });
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
    dispatch({ type: 'SET_TARGET_AGENT', payload: null });
  };

  const lastLocation = useMemo(() => {
    return state.lastLocation;
  }, [state.lastLocation]);

  const setLastLocation = value => {
    dispatch({ type: 'SET_LAST_LOCATION', payload: value });
  };

  const toggleModal = location => {
    toast.dismiss();
    setShowModal(!showModal);
    setLastLocation(location);
  };

  const agentDataLoading = useMemo(() => state.agentDataLoading, [
    state.agentDataLoading,
  ]);

  const specialtiesLoading = useMemo(() => state.specialtiesLoading, [
    state.specialtiesLoading,
  ]);

  const isLoading = useMemo(() => agentDataLoading || specialtiesLoading, [
    agentDataLoading,
    specialtiesLoading,
  ]);

  const availableSalesAgents = useMemo(() => {
    if (!state.salesAgents) {
      return [];
    }
    return state.salesAgents.sort((first, second) => {
      if (first.LastName.toLowerCase() < second.LastName.toLowerCase()) {
        return -1;
      }
      if (first.LastName.toLowerCase() > second.LastName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }, [state.salesAgents]);

  const activeSalesAgent = useMemo(() => {
    if (state.activeSalesAgentId !== 0 && !state.activeSalesAgentId) {
      return {};
    }

    return (
      availableSalesAgents.find(
        agent =>
          parseInt(agent.SalesAgentId, 10) ===
          parseInt(state.activeSalesAgentId, 10)
      ) || {}
    );
  }, [availableSalesAgents, state.activeSalesAgentId]);

  const stagedAgent = useMemo(() => {
    return state.stagedAgent || {};
  }, [state.stagedAgent]);

  const agentHasUpdates = useMemo(() => {
    if (isLoading) {
      return false;
    }
    return !isEqual(
      omit(stagedAgent, 'profileLoaded'),
      omit(activeSalesAgent, 'profileLoaded')
    );
  }, [stagedAgent, activeSalesAgent, isLoading]);

  const setActiveSalesAgentId = id => {
    dispatch({ type: 'SET_ACTIVE_SALES_AGENT_ID', payload: parseInt(id, 10) });
  };

  const updateStagedAgent = (attribute, value) => {
    dispatch({
      type: 'UPDATE_STAGED_AGENT',
      payload: { ...stagedAgent, [attribute]: value },
    });
  };

  const saveSalesAgent = React.useCallback(async () => {
    dispatch({ type: 'UPDATE_SALES_AGENT_INIT' });
    try {
      const response = await updateSalesAgent(activeLot.LotNumber, {
        ...stagedAgent,
        IsPublished: true,
      });
      const updatedAgent = await response.json();
      if (updatedAgent) {
        dispatch({
          type: 'UPDATE_SALES_AGENT_SUCCESS',
          payload: updatedAgent,
        });
      } else {
        dispatch({
          type: 'UPDATE_SALES_AGENT_FAILURE',
          payload: 'Profile could not be saved.',
        });
      }
    } catch (error) {
      dispatch({
        type: 'UPDATE_SALES_AGENT_FAILURE',
        payload: error.message,
      });
    }
  }, [activeLot, stagedAgent]);

  const publishAgent = async () => {
    dispatch({ type: 'PUBLISH_SALES_AGENT_INIT' });
    try {
      await updateSalesAgent(activeLot.LotNumber, {
        ...stagedAgent,
        IsPublished: true,
      });
      analyticsSendEvent('profiles_add_new');
      dispatch({ type: 'PUBLISH_SALES_AGENT_SUCCESS' });
      return true;
    } catch (error) {
      dispatch({
        type: 'PUBLISH_SALES_AGENT_FAILURE',
        payload: {
          error: error.message,
        },
      });
      return false;
    }
  };

  const unPublishAgent = async () => {
    dispatch({ type: 'UNPUBLISH_SALES_AGENT_INIT' });
    try {
      await updateSalesAgent(activeLot.LotNumber, {
        ...stagedAgent,
        IsPublished: false,
      });
      dispatch({ type: 'UNPUBLISH_SALES_AGENT_SUCCESS' });
      return true;
    } catch (error) {
      dispatch({
        type: 'UNPUBLISH_SALES_AGENT_FAILURE',
        payload: {
          error: error.message,
        },
      });
      return false;
    }
  };

  const deleteAgent = async userName => {
    dispatch({ type: 'DELETE_SALES_AGENT_INIT', payload: userName });

    if (!activeLot) {
      dispatch({
        type: 'DELETE_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: no active lot number' },
      });
      return false;
    }
    if (!userName) {
      dispatch({
        type: 'DELETE_ITEM_FAILURE',
        payload: { error: 'Failed to delete item: invalid parameters' },
      });
      return false;
    }

    try {
      await deleteSalesAgent(activeLot.LotNumber, userName);
      dispatch({ type: 'DELETE_SALES_AGENT_SUCCESS' });
      return true;
    } catch (error) {
      dispatch({
        type: 'DELETE_SALES_AGENT_FAILURE',
        payload: {
          error: error.message,
        },
      });
      return false;
    }
  };

  const availableSpecialties = useMemo(() => state.specialties, [
    state.specialties,
  ]);

  const handleConfirmNavigationClick = () => {
    saveSalesAgent().then();
    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 0);
  };

  const handleDiscardNavigationClick = () => {
    dispatch({
      type: 'UPDATE_STAGED_AGENT',
      payload: { ...activeSalesAgent },
    });
    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 0);
  };

  /**
   *
   */
  useEffect(() => {
    if (activeLot && !IsCorporateOfficeUser) {
      dispatch({ type: 'FETCH_SALES_AGENTS_SUCCESS', payload: [] });

      (async () => {
        dispatch({ type: 'FETCH_PROFILE_INIT' });
        try {
          const profile = await getProfileData(activeLot.LotNumber, UserName);
          dispatch({
            type: 'FETCH_PROFILE_SUCCESS',
            payload: profile,
          });
          setActiveSalesAgentId(profile.SalesAgentId);
        } catch (error) {
          dispatch({
            type: 'FETCH_PROFILE_FAILURE',
            payload: error.message,
          });
        }
      })();
    }
    if (activeLot && IsCorporateOfficeUser) {
      (async () => {
        dispatch({ type: 'FETCH_SALES_AGENTS_INIT' });
        try {
          const agents = await getSalesAgentData(activeLot.LotNumber);
          if (!Array.isArray(agents)) {
            dispatch({
              type: 'FETCH_SALES_AGENTS_FAILURE',
              payload: 'API request failed.',
            });
            return;
          }

          const availableAgents = agents.filter(agent => {
            const { UserName: agentUserName, SalesAgentId } = agent;
            return SalesAgentId;
          });

          if (availableAgents) {
            dispatch({
              type: 'FETCH_SALES_AGENTS_SUCCESS',
              payload: availableAgents,
            });
            const currentUserAgent = availableAgents.find(agent => {
              const { UserName: agentUserName } = agent;
              return agentUserName === UserName;
            });
            if (currentUserAgent) {
              setActiveSalesAgentId(currentUserAgent.SalesAgentId);
            }
            if (availableAgents[0]) {
              setActiveSalesAgentId(availableAgents[0].SalesAgentId);
            }
          }
        } catch (error) {
          dispatch({
            type: 'FETCH_SALES_AGENTS_FAILURE',
            payload: error.message,
          });
        }
      })();
    }
  }, [activeLot, IsCorporateOfficeUser, UserName]);

  useEffect(() => {
    if (activeLot) {
      if (!availableSpecialties || availableSpecialties.length === 0) {
        (async () => {
          try {
            const fetchedSpecialties = await getSpecialtyOptions(activeLot);
            dispatch({
              type: 'FETCH_SPECIALTIES_SUCCESS',
              payload: fetchedSpecialties,
            });
          } catch (error) {
            dispatch({
              type: 'FETCH_SPECIALTIES_FAILURE',
              payload: error.message,
            });
          }
        })();
      }
    }
  }, [activeLot, availableSpecialties]);

  useEffect(() => {
    if (!isLoading && agentHasUpdates && !showModal && validateStagedAgent()) {
      if (!toast.isActive('saveToast')) {
        toast.info(
          <ToastSave saveFunction={saveSalesAgent} />,
          SAVE_TOAST_OPTIONS
        );
      } else {
        // rewrite save function with updated home object
        toast.update('saveToast', {
          render: <ToastSave saveFunction={saveSalesAgent} />,
        });
      }
    } else {
      toast.dismiss('saveToast');
    }
    return () => {
      if (!agentHasUpdates && toast.isActive('saveToast')) {
        toast.dismiss('saveToast');
      }
    };
  }, [
    agentHasUpdates,
    isLoading,
    stagedAgent,
    showModal,
    saveSalesAgent,
    IsCorporateOfficeUser,
  ]);

  const validateStagedAgent = () => {
    if (!stagedAgent) return false;

    // first last and avatar required
    if (
      !stagedAgent.FirstName ||
      !stagedAgent.LastName ||
      !stagedAgent.AvatarImageReference
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      setShowModal(true);
      setLastLocation(nextLocation);
      return false;
    });
    if (!agentHasUpdates) {
      unblock();
    }
    return () => {
      unblock();
    };
  }, [agentHasUpdates, history]);

  useEffect(() => {
    if (state.isError) {
      toast.error(state.error, ERROR_TOAST_OPTIONS);
    }
  }, [state.isError, state.error]);

  return (
    <SalesAgentsContext.Provider
      value={{
        ...state,
        isLoading,
        activeSalesAgent,
        availableSalesAgents,
        availableSpecialties,
        stagedAgent,
        updateStagedAgent,
        setActiveSalesAgentId,
        agentHasUpdates,
        publishAgent,
        unPublishAgent,
        deleteAgent,
        hideDeleteModal,
        showDeleteModal,
        validateStagedAgent,
      }}
    >
      {children}
      <Modal
        modalHeadline="Save changes?"
        modalBody="If you don’t save, any changes you’ve made to your profile will be undone."
        closeCopy="DISCARD"
        saveCopy="SAVE"
        show={showModal}
        closeCallback={toggleModal}
        discardCallback={handleDiscardNavigationClick}
        saveCallback={handleConfirmNavigationClick}
      />
    </SalesAgentsContext.Provider>
  );
};

SalesAgentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape().isRequired,
};
