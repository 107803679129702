import styled from 'styled-components';

const StyledProgressBar = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 40px;
  .title {
    font-size: 16px;
    color: #25292d;
  }
  .bar-wrapper {
    display: flex;
    align-items: center;
    .bar-outer {
      width: 230px;
      height: 5px;
      position: relative;
      background: rgba(151, 212, 202, 0.34);
      margin-right: 20px;
      .bar-inner {
        width: ${({ percentage }) => `${percentage}%`};
        height: 5px;
        transition: all 0.3s ease-out;
        background: #00b0ac;
      }
    }
    .label {
      flex: 0;
      min-width: fit-content;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.3px;
      color: #6d6e71;
    }
  }
`;

export default StyledProgressBar;
