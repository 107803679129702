import React from 'react';
import PropTypes from 'prop-types';
import StyledModal from './Modal.Style';
import CloseIcon from '../icons/CloseIcon';

const Modal = ({
  modalHeadline,
  modalBody,
  closeCopy,
  saveCopy,
  show,
  closeCallback,
  saveCallback,
  discardCallback,
  useCloseIcon,
  useOkBtn,
  imageReference,
}) => (
  <StyledModal
    useCloseIcon={useCloseIcon}
    useOkBtn={useOkBtn}
    className="modal"
    style={{ display: show ? 'block' : 'none' }}
  >
    <div role="presentation" className="overlay" onClick={closeCallback} />
    <div className="modal-content">
      {useCloseIcon && (
        <button
          type="button"
          className="close-modal-icon"
          onClick={discardCallback}
        >
          <CloseIcon width="28px" height="28px" />
        </button>
      )}
      <h2>{modalHeadline}</h2>
      <div className="modal-text">
        {imageReference && <img src={imageReference} alt="" />}
        <p>{modalBody}</p>
      </div>
      <div className="btns-container">
        <button
          type="button"
          className={`${useOkBtn && 'ok-btn'} close-modal`}
          onClick={discardCallback}
        >
          {closeCopy}
        </button>
        <button type="button" onClick={saveCallback}>
          {saveCopy}
        </button>
      </div>
    </div>
  </StyledModal>
);

Modal.propTypes = {
  modalHeadline: PropTypes.string,
  modalBody: PropTypes.string,
  closeCopy: PropTypes.string,
  saveCopy: PropTypes.string,
  show: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  discardCallback: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  modalHeadline: '',
  modalBody: '',
  closeCopy: '',
  saveCopy: '',
  show: false,
};

export default Modal;
