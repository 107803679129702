import styled from 'styled-components';

const StyledCalendarModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  .overlay {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(12, 30, 52, 0.75);
  }
  .modal {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    width: 300px;
  }
  p.header {
    font-size: 14px;
    line-height: 1.21;
    font-weight: 600;
    margin: 25px;
    padding: 0;
    text-align: center;
  }
  .radioButtonButtons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin-bottom: 25px;

    button {
      border: 1px solid #e4e5e7;
      padding: 0 20px;
      background: #f3f5f7;
      color: #a1a3a6;
      border-radius: 15px;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;

      &.active {
        border: 1px solid #0075c9;
        background: #0075c9;
        color: white;
      }
    }
  }
  button.done {
    background: #00b0ac;
    border: none;
    color: #ffffff;
    width: 100%;
    display: block;
    height: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

export default StyledCalendarModal;
