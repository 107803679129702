import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledWelcomeHomeListingCard from './WelcomeHomeListingCard.Style';
import Checkmark from '../../icons/Checkmark';
import LoadingDots from './../LoadingDots';
import GrayCloseIcon from '../../icons/GrayCloseIcon';
import EditModal from './EditModal';
import { toast } from 'react-toastify';
import Modal from '../Modal';

function WelcomeHomeListingCard({
  to,
  item,
  toggleActiveImage,
  deleteFunction,
  ...props
}) {
  const [showModal, setShowModal] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return useMemo(() => {
    if (item === null || item === undefined) {
      return null;
    }

    if (item.isUpdating) {
      return (
        <StyledWelcomeHomeListingCard className="library-card" {...props}>
          <div className="card updating">
            <div className="card-body">
              <LoadingDots />
            </div>
          </div>
        </StyledWelcomeHomeListingCard>
      );
    }

    const fullName =
      item && item.FirstName === null
        ? item.Name
        : `${item.FirstName} ${item.LastName}`;

    return (
      <StyledWelcomeHomeListingCard className="library-card" {...props}>
        <div className="library-card-image">
          {item.ImageReference && (
            <img
              src={item.ImageReference}
              alt={'Welcome Home Image for ' + fullName ? `${fullName} ` : ''}
            />
          )}
        </div>
        <div className="library-card-content">
          <div className="library-card-name">
            {fullName && <p className="name">{fullName}</p>}
            {item.Email && <p className="email">{item.Email}</p>}
          </div>
          <div className="library-card-options">
            <div className="checkmarks">
              <p>
                {item.SortOrder ? <Checkmark /> : <GrayCloseIcon />}
                Home Page
              </p>
              <p>
                {item.IsShownInGallery ? <Checkmark /> : <GrayCloseIcon />}
                Gallery Page
              </p>
            </div>
            {deleteFunction && (
              <span
                className="edit"
                onClick={() => {
                  setShowDeletePrompt(true);
                }}
              >
                DELETE
              </span>
            )}
            <span
              className="edit"
              onClick={() => {
                if (!item.HasConsentedToMediaRelease) {
                  toast(
                    "Images without a signed image release form will not be displayed on your website and can't be edited.",
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'info-toast',
                      progressClassName: 'info-progress-bar',
                      closeButton: false,
                    }
                  );
                  return;
                }
                setShowModal(true);
              }}
            >
              EDIT
            </span>
          </div>
        </div>
        {showModal && (
          <EditModal
            fullName={fullName}
            galleryCheck={item.IsShownInGallery}
            homeCheck={item.SortOrder ? true : false}
            currentItem={item}
            show={showModal}
            useCloseIcon={true}
            closeCallback={toggleModal}
          />
        )}
        <Modal
          modalHeadline="Warning: This action cannot be undone."
          modalBody="Are you sure you want to permanantly delete this image?"
          closeCopy="CANCEL"
          saveCopy="DELETE IMAGE"
          show={showDeletePrompt}
          closeCallback={() => {
            document.documentElement.classList.remove('modal-lock');
            setShowDeletePrompt(false);
          }}
          saveCallback={() => {
            deleteFunction(item.WelcomeHomeItemId);
            setShowDeletePrompt(false);
          }}
          discardCallback={() => {
            document.documentElement.classList.remove('modal-lock');
            setShowDeletePrompt(false);
          }}
        />
      </StyledWelcomeHomeListingCard>
    );
  }, [item, props, to, toggleActiveImage]);
}

WelcomeHomeListingCard.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()),
  to: PropTypes.shape().isRequired,
  item: PropTypes.shape({
    Active: PropTypes.bool,
    Name: PropTypes.string,
  }).isRequired,
  toggleActiveImage: PropTypes.func.isRequired,
};

export default WelcomeHomeListingCard;
