import styled from 'styled-components';

const StyledMultiCheckBox = styled.div`
  background: white;
  display: block;
  padding: 0px;

  h2 {
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: 600;
    color: #4e5255;
  }

  label {
    color: #777b7e;
    font-size: 14px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(260px, 1fr));
    @media screen and (max-width: 550px) {
      grid-template-columns: minmax(260px, 1fr);
    }
  }

  .itemContainer {
    padding-bottom: 19px;
  }

  span {
    margin-left: 8px;
    vertical-align: top;
  }
`;

export default StyledMultiCheckBox;
