import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  overflow-y: scroll;
}
html.modal-lock {
  overflow: hidden !important;
}
body {
  padding: 0;
  margin: 0;
  font-family: source-sans-pro, sans-serif;
  background-color: #f3f5f7;
  touch-action: manipulation;
}
.app-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;

  @media screen and (min-width: 1025px) {
    max-height: none;
  }
  
  &.nav-expanded {
    max-height: 100vh;
    overflow: hidden;
  }
}
.side-bar {
  overflow: auto;
}
main {
  z-index: 1;
  position: relative;
  background-color: #f3f5f7;
  flex:2;
  display: flex;
  flex-direction: column;
  max-width: 100%;
 p {
     color: #25292d;
     padding: 50px;
     &.error {
         width: 88%;
         margin: 1em auto;
         padding: 0;
     }
  }
  .listing-container {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      padding-top: 40px;
      
  }
}
img {
  max-width: 100%;
  image-orientation: from-image;
}

.sale-homes-container {
  width: 88%;
  margin: auto;
  &:not(:first-of-type) {
    margin-top: 50px;
  }
}

.sale-homes-text {
  padding: 0;
  margin: 0 0 40px;
  color: #7f8083;
  width: 88%;
  margin: auto;
}

.Toastify__toast {
  border-radius: 2px;
  padding: 0;
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }

  .Toastify__toast-body {
      font-size: 14px;
      line-height: 1.4;
      display: flex;
      align-items: center;
      padding: 0;
      cursor: default;
  }
  button {
    cursor: pointer;
  }
  .info-progress-bar {
    background: white;
  }
  &.info-toast {
    color: #fff;
    background: #00b0ac;
    .Toastify__toast-body {
    padding: 1rem; 
    }
  }
  &.save-toast {
      cursor: default;
      background: #00b0ac;
      color: white;
      min-height: 50px;
      display: flex;
      .Toastify__toast-body {
      margin: 0;
      display: flex;
      align-items: stretch;
          span {
              flex: 0 auto;
              text-transform: uppercase;
          }
          .save {
          transition: all 0.3s;
          flex: 1 auto;
            margin: 0 auto;
            color: white;
            background: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 13px;
            font-weight: 600;
            padding: 8px 5px;
            outline: none;
            &.closing {
              background: #00b0ac;
            }
          }
      }
  }
  &.undo-toast {
      color: #fff;
      background: #0c1e34;
      .Toastify__toast-body {
      padding: 0 10px 0 15px;
          span {
              flex: 0 auto;
              margin-right: auto;
          }
          .undo {
              border: none;
              background: none;
              color: #00b0ac;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              padding: 20px 7px;
              &:hover {
                color: #e4e5e7;
              }
          }
      }
  }
  &.error-toast {
    color: #fff;
    background: #b21f28;
    .Toastify__toast-body {
    padding: 1rem; 
    }
  }

  

  &.install-banner {
    cursor: default;
    background: #0c1e34;
    color: white;
    min-height: 50px;
    display: flex;
    

    .Toastify__close-button {
      margin-right: 5px;
      font-size: 26px;
    }

    .Toastify__toast-body {
      margin: 10px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      
        span {
            flex: 0 auto;
            text-transform: uppercase;
        }

        button {
          border: none;
          font-family: source-sans-pro,sans-serif;
          padding: 0;
          
        }

        .arrow {
          font-size: 26px;
        }
        
        .save {
          transition: all 0.3s;
          flex: 1 auto;
          margin: 0 auto;
          color: white;
          background: none;
          letter-spacing: 1px;
          font-size: 13px;
          padding: 0;
          outline: none;
          &.closing {
            background: #00b0ac;
          }
        }
    }
  }
}
`;

export default GlobalStyles;
