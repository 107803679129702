import React from 'react';
import { rgba } from 'style-value-types';

const Chevron = props => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.5581 12.9419C14.8021 13.186 15.1979 13.186 15.4419 12.9419C15.686 12.6979 15.686 12.3021 15.4419 12.0581L10.4419 7.05806C10.1979 6.81398 9.80214 6.81398 9.55806 7.05806L4.55806 12.0581C4.31398 12.3021 4.31398 12.6979 4.55806 12.9419C4.80214 13.186 5.19786 13.186 5.44194 12.9419L10 8.38388L14.5581 12.9419Z"
        fill="#25292D"
      />
    </svg>
  );
};

export default Chevron;
