import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledHomeListingCard from './HomeListingCard.Style';
import Chevron from '../icons/Chevron';
import Toggle from './Toggle';
import AlertIcon from '../icons/AlertIcon';
import LoadingDots from './LoadingDots';
import StickerSelect from './StickerSelect/StickerSelect';
import { canEditVisibility, canEditSticker, canEditSaleHome } from '../utils';
import { STICKER_TYPES } from '../constants';

function HomeListingCard({
  labels,
  to,
  home,
  activeRooms,
  updateAttribute,
  getErrors,
  ...props
}) {
  const errors = getErrors(home);

  return useMemo(() => {
    if (home.isUpdating) {
      return (
        <StyledHomeListingCard {...props}>
          <div className="card updating">
            <div className="card-body">
              <LoadingDots />
            </div>
          </div>
        </StyledHomeListingCard>
      );
    }

    return (
      <StyledHomeListingCard {...props}>
        <div className="card">
          <div
            className={`${home &&
              (home.StickerTypeId === STICKER_TYPES.COMING_SOON ||
                (home.SerialNumber !== null &&
                  home.SerialNumber.includes('-'))) &&
              'disabled'} card-body`}
          >
            <Link
              to={
                home &&
                (home.StickerTypeId === STICKER_TYPES.COMING_SOON ||
                  (home.SerialNumber !== null &&
                    home.SerialNumber.includes('-')))
                  ? ''
                  : to
              }
            >
              <div className="home-info">
                <h2>
                  <span>{home.Name}</span>
                  <div className="tags">
                    {home.IsUsed && <div className="tag">used</div>}
                    {home.IsSold && <div className="tag">Sold</div>}
                    {home.IsOnLand && <div className="tag">On Land</div>}
                  </div>
                </h2>
                <div className="home-info-container">
                  {labels &&
                    labels.map(label => (
                      <div
                        className={
                          home.IsAvailableFloorPlan ? 'available' : 'display'
                        }
                        key={label.value}
                      >
                        <p>
                          <span>{label.text}</span>
                        </p>
                        <p>{label.value}</p>
                      </div>
                    ))}
                </div>
                {home &&
                  (home.SerialNumber !== null &&
                    home.SerialNumber.indexOf('-') < 0) &&
                  home.StickerTypeId !== STICKER_TYPES.COMING_SOON && (
                    <div className="home-errors">
                      {errors &&
                        errors.map(error => (
                          <div key={error.message} className="error">
                            <AlertIcon
                              color="#ffffff"
                              backgroundColor="#b21f28"
                            />
                            <span>{error.message}</span>
                          </div>
                        ))}
                    </div>
                  )}
                {home &&
                  (home.StickerTypeId === STICKER_TYPES.COMING_SOON ||
                    (home.SerialNumber !== null &&
                      home.SerialNumber.indexOf('-') > 0)) && (
                    <div className="home-errors">
                      <div className="error">
                        <AlertIcon color="#ffffff" backgroundColor="#b21f28" />
                        <span>This home can't be edited</span>
                      </div>
                    </div>
                  )}
                <div className="arrow-position">
                  <Chevron />
                </div>
              </div>
            </Link>
            {!home.IsAvailableFloorPlan && !home.IsLand && (
              <div className="card-options">
                <Toggle
                  label="Visible"
                  onToggle={event => {
                    updateAttribute('IsPublished', event.currentTarget.checked);
                  }}
                  inputProps={{
                    id: `${home.SerialNumber || home.ModelNumber}-visible`,
                    disabled: !canEditVisibility(home),
                    checked: home.IsPublished && canEditVisibility(home),
                  }}
                />
                <Toggle
                  label="Sale Home"
                  onToggle={event => {
                    updateAttribute('IsSaleHome', event.currentTarget.checked);
                  }}
                  inputProps={{
                    id: `${home.SerialNumber || home.ModelNumber}-sale`,
                    disabled: !canEditSaleHome(home),
                    checked: home.IsSaleHome && canEditSaleHome(home),
                  }}
                />
                <StickerSelect
                  stickerOptions={home.StickerOptions}
                  selectedStickerTypeId={home.StickerTypeId}
                  updateStickerType={event => {
                    event.preventDefault();
                    updateAttribute(
                      'StickerTypeId',
                      parseInt(event.currentTarget.value, 10),
                      event.currentTarget.options[
                        event.currentTarget.selectedIndex
                      ].text
                    );
                  }}
                  selectProps={{
                    id: `${home.SerialNumber || home.ModelNumber}-sticker`,
                    disabled: !canEditSticker(home),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </StyledHomeListingCard>
    );
  }, [home, errors, labels, props, to, updateAttribute]);
}

HomeListingCard.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()),
  to: PropTypes.shape().isRequired,
  home: PropTypes.shape({
    IsUsed: PropTypes.bool,
    IsSold: PropTypes.bool,
    IsOnLand: PropTypes.bool,
    IsSaleHome: PropTypes.bool,
    IsSalePending: PropTypes.bool,
    IsPublished: PropTypes.bool,
    IsAvailableFloorPlan: PropTypes.bool,
    SerialNumber: PropTypes.string,
    ModelNumber: PropTypes.string,
  }).isRequired,
  activeRooms: PropTypes.shape(),
  updateAttribute: PropTypes.func.isRequired,
  availableClass: PropTypes.string,
};

HomeListingCard.defaultProps = {
  labels: [],
  availableClass: '',
  activeRooms: {},
};

export default HomeListingCard;
