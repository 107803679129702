import styled from 'styled-components';

const StyledActiveImageCard = styled.div`
  display: flex;
  width: 253px;
  /* padding: 0 12px; */
  box-sizing: border-box;
  .spacer {
    flex-grow: 1;
  }
  @media screen and (min-width: 375px) {
    width: 100%;
  }
  @media screen and (min-width: 550px) {
    display: block;
  }
  .card-name {
    margin: 10px 0px;
  }
  .label {
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #777b7e;
    flex: 1;
    padding: 0 12px;
    @media screen and (max-width: 549px) {
      align-self: center;
      /* flex-wrap: wrap; */
      flex-direction: column;
      padding: 10px 0px 10px 10px;
      .label-right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    @media screen and (min-width: 550px) {
      margin: 1rem 0;
      align-items: center;
    }
    .bold {
      padding-right: 8px;
      padding-left: 5px;
      color: #25292d;
      font-weight: 600;
      line-height: 1;
      @media screen and (min-width: 550px) {
        padding-left: 0px;
      }
    }
    .label-left {
      position: relative;
      display: flex;
      align-items: center;
      .number {
        margin-right: 7px;
      }
    }
    button.edit {
      margin-top: auto;
      margin-left: auto;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.57;
      letter-spacing: 1px;
      color: #0075c9;
      background: none;
      border: none;
      cursor: pointer;
      @media screen and (min-width: 550px) {
        margin-top: 0;
      }
    }
  }
  .number {
    position: relative;
    background: #c2c2c2;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 1rem;
      margin: 0px;
      line-height: 0;
      color: #4e5255;
    }
  }
`;

export default StyledActiveImageCard;
