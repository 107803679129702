import React from 'react';
import { rgba } from 'style-value-types';

const Chevron = props => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="7.41"
      height="12"
      viewBox="0 0 7.41 12"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M0,10.59,4.58,6,0,1.41,1.41,0l6,6-6,6Z"
            transform="translate(8.59 6.75)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g transform="translate(-8.59 -6.75)" clipPath="url(#clip-path)">
        <g id="_Icon_Color" data-name="↳ Icon Color">
          <rect
            id="_Icon_Color_background"
            data-name="↳ Icon Color background"
            width="24"
            height="24"
            fill={props && props.fill ? props.fill : 'rgba(0, 0, 0, 0)'}
          />
          <rect
            id="Rectangle"
            width="24"
            height="24"
            fill={props && props.fill ? props.fill : 'rgba(0, 0, 0, 0)'}
          />
        </g>
      </g>
    </svg>
  );
};

export default Chevron;
