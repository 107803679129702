import styled from 'styled-components';

const StyledAuth = styled.div`
  aside {
    width: 274px;
    background: #0c1e34;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    .upper {
      background: #1b3453;
      width: 100%;
      height: 168px;
    }
    .user-image {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(76, 117, 167);
      border-image: initial;
      overflow: hidden;
      margin: 25px 0 0 28px;
      img {
        max-width: 100%;
      }
    }
    .boxes {
      padding: 0 15%;
      width: auto;
    }
    .sm-box {
      background: #0075c9;
      width: 70%;
      height: 20px;
      margin-bottom: 20px;
      border-radius: 3px;
      margin-top: 40px;
    }
    .md-box {
      background: #4c75a7;
      width: 100%;
      height: 35px;
      margin-bottom: 20px;
      border-radius: 3px;
    }
  }
  main {
    background: #f3f5f7;
    .header {
      height: 108.5px;
      box-shadow: rgba(58, 67, 77, 0.12) 0 1px 4px 0;
      background-color: #ffffff;

      align-items: center;
      h1 {
        font-size: 25px;
        color: rgb(37, 41, 45);
        margin-left: 16px;
      }
    }
    .error {
      padding: 50px;
      span {
        font-weight: normal;
        display: block;
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }
`;

export default StyledAuth;
