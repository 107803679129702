import { MAX_FEATURE_COUNT } from '../components/Features/Features';
import { sortByKey } from '../utils';

export const featuresReducer = (state, action) => {
  if (!action) return { ...state };
  switch (action.type) {
    case 'INIT_FEATURES':
      return {
        ...state,
        features: [
          ...sortByKey([...action.payload].splice(0, 10), 'SortOrder').map(
            (feat, index) => ({ ...feat, SortOrder: index + 1 })
          ),
        ],
      }; // ensure we have no more than 10 features
    case 'ADD_FEATURE':
      if (state.features.length === MAX_FEATURE_COUNT) {
        return {
          ...state,
          isError: true,
          // TODO find a wordsmith
          error: `You may not exceed ${MAX_FEATURE_COUNT} features.`,
        };
      }
      return {
        ...state,
        features: sortByKey(
          [action.payload, ...state.features],
          'SortOrder'
        ).map((feat, index) => {
          return { ...feat, SortOrder: index + 1 };
        }),
      };
    case 'REMOVE_FEATURE':
      return {
        ...state,
        features: state.features.filter(
          feat => feat.InventoryFeatureId !== action.payload
        ),
      };
    case 'EDIT_FEATURE':
      return {
        ...state,
        features: sortByKey(
          [
            ...state.features.filter(
              feat =>
                feat.InventoryFeatureId !== action.payload.InventoryFeatureId
            ),
            action.payload,
          ],
          'SortOrder'
        ),
      };
    case 'REORDER_FEATURES': {
      const orderedFeatures = sortByKey(state.features, 'SortOrder');

      const [removed] = orderedFeatures.splice(action.payload.startIndex, 1);
      orderedFeatures.splice(action.payload.endIndex, 0, removed);

      return {
        ...state,
        features: [
          ...orderedFeatures.map((item, index) => ({
            ...item,
            SortOrder: index + 1,
          })),
        ],
      };
    }
    case 'POST_FEATURES_INIT':
      return { ...state };
    case 'POST_FEATURES_SUCCESS':
      return { ...state };
    case 'POST_FEATURES_FAILURE':
      return { ...state };
    default:
      return { ...state };
  }
};

export default featuresReducer;
