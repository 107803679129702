import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from './components/User';
import Modal from './components/Modal';
import StyledAppError from './AppError.Style';
import LoadingDots from './components/LoadingDots';

const AppError = ({ children }) => {
  const { isLoading, isError, error, loadUserData } = useContext(UserContext);

  return (
    <StyledAppError>
      {isLoading && (
        <div className="loading-container">
          <LoadingDots />
        </div>
      )}
      {isError && (
        <Modal
          modalHeadline="Error"
          modalBody={error}
          saveCopy="RETRY"
          show={isError}
          saveCallback={() => window.location.reload()}
        />
      )}
    </StyledAppError>
  );

  return [children];
};

export default AppError;
