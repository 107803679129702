import styled from 'styled-components';

const StyledAddButton = styled.div`
  a {
    width: 232px;
    border-radius: 2px;
    border: none;
    outline: none;
    background: #00b0ac;
    color: #fff;
    position: relative;
    display: block;
    text-decoration: none;
    font: 600 14px/100% source-sans-pro;
    letter-spacing: 1px;
    text-align: center;
    padding: 20px 0;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      margin-right: 13px;
      margin-right: 13px;
      position: relative;
      top: 1px;
    }
  }
`;

export default StyledAddButton;
