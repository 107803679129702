import React from 'react';
import PropTypes from 'prop-types';

const ToastUndo = ({ undo, closeToast, message }) => {
  function handleClick() {
    undo();
    closeToast();
  }

  return (
    <>
      <span>{message}</span>
      <button type="button" className="undo" onClick={handleClick}>
        Undo
      </button>
    </>
  );
};

ToastUndo.propTypes = {
  undo: PropTypes.func.isRequired,
  closeToast: PropTypes.func,
  message: PropTypes.string.isRequired,
};

ToastUndo.defaultProps = {
  closeToast: undefined,
};

export default ToastUndo;
