import React from 'react';

const PdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="30"
    viewBox="0 0 25 30"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#0075C9"
        d="M24.887 7.029v19.41a3.374 3.374 0 0 1-3.375 3.374H3.98a3.372 3.372 0 0 1-3.375-3.375V3.606A3.374 3.374 0 0 1 3.98.231H18.09"
      />
      <path
        fill="#FFF"
        d="M7.345 13.164H6.018v1.617h1.327c.493 0 .86-.314.86-.807 0-.486-.367-.81-.86-.81zM4.77 12.091h2.74c1.276 0 1.971.864 1.971 1.89 0 1.02-.705 1.873-1.97 1.873H6.017v2.1H4.77V12.09zM12.569 16.856c1.161 0 1.836-.834 1.836-1.83 0-1.036-.631-1.836-1.836-1.836h-1.063v3.666h1.063zm-2.312-4.766h2.312c1.836 0 3.112 1.171 3.112 2.936 0 1.769-1.276 2.93-3.112 2.93h-2.312V12.09zM16.572 12.091h4.148v1.073h-2.9v1.276h2.839v1.07H17.82v2.444h-1.248z"
      />
      <path
        fill="#003F8C"
        d="M18.09.231l6.797 6.797h-4.51A2.29 2.29 0 0 1 18.09 4.74V.231z"
      />
    </g>
  </svg>
);

export default PdfIcon;
