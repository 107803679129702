import React, {
  useReducer,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  sortByKey,
  qualifyImageRefs,
  findHomeByWildCardId,
  isValidEmail,
  isValidZip,
  isValidPhone,
  canEditVisibility,
} from '../utils';
import { homesReducer } from '../reducers/homes';
import {
  getHomeList,
  updateModel,
  updateInventory,
  updateFeatures,
  getRoomsData,
  getHomeByInventoryId,
  getHomeByModelNumber,
} from '../Api';
import { UserContext } from './User';
import ToastUndo from './ToastUndo';
import {
  ERROR_TOAST_OPTIONS,
  MAX_SALE_HOMES_COUNT,
  STICKER_TYPES,
} from '../constants';
import { canViewHome, canViewLandRecords } from '../permissions';

const searchHomes = (query, homes) => {
  if (query === '') {
    return homes;
  }
  if (homes.length > 0) {
    return homes.filter(home => {
      if (home.IsAvailableFloorPlan) {
        return (
          (home.Name &&
            home.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
          (home.ModelNumber &&
            home.ModelNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0)
        );
      }
      if (home.IsLand || home.IsOnLand) {
        return (
          (home.Name &&
            home.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
          (home.Address1 &&
            home.Address1.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
          (home.StockNumber &&
            home.StockNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
          (home.SerialNumber &&
            home.SerialNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0)
        );
      }
      return (
        (home.Name &&
          home.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (home.ModelNumber &&
          home.ModelNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (home.SerialNumber &&
          home.SerialNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (home.StockNumber &&
          home.StockNumber.toLowerCase().indexOf(query.toLowerCase()) >= 0)
      );
    });
  }
  return [];
};

const validateHome = home => {
  const {
    BrokerageName,
    BrokerageEmail,
    BrokeragePhone,
    IsPublished,
    IsUsed,
    InventoryImages,
    InventoryImageCount,
    ModelImages,
    ModelImageCount,
    IsInBrokerageRequiredState,
    IsSaleHome,
    IsSalePending,
    IsLand,
    IsOnLand,
    IsOnThirdPartyLand,
    ErrorRequiresFloorPlanImage,
    ErrorRequiresTwoImages,
    ErrorRequiresAcres,
    ErrorRequiresAddress,
    ErrorRequiresLandImage,
    ErrorRequiresBrokerageName,
    ErrorRequiresBrokerageContact,
  } = home;

  const hasImages = (targetHome =>
    (targetHome.ModelImages && Array.isArray(targetHome.ModelImages)) ||
    (targetHome.InventoryImages && Array.isArray(targetHome.InventoryImages)))(
    home
  );

  const hasInventoryFloorPlanImage =
    InventoryImages &&
    InventoryImages.some(image => image.ImageTypeAcronym === 'FLP');

  const hasModelFloorPlanImage =
    ModelImages && ModelImages.some(image => image.ImageTypeAcronym === 'FLP');

  const requiresFloorPlanImage =
    !IsUsed && hasImages && !IsLand
      ? !hasInventoryFloorPlanImage && !hasModelFloorPlanImage
      : ErrorRequiresFloorPlanImage;

  const requiresTwoImages = IsUsed
    ? InventoryImageCount + ModelImageCount < 2
    : ErrorRequiresTwoImages;

  const requiresAcres =
    (IsLand || IsOnLand || IsOnThirdPartyLand) && ErrorRequiresAcres;

  const requiresAddress =
    (IsLand || IsOnLand || IsOnThirdPartyLand) && ErrorRequiresAddress;

  const requiresLandImage =
    (IsLand || IsOnLand || IsOnThirdPartyLand) && ErrorRequiresLandImage;

  const requiresBrokerageName =
    (IsLand || IsOnLand || IsOnThirdPartyLand) &&
    (ErrorRequiresBrokerageName ||
      (IsInBrokerageRequiredState && !BrokerageName));

  const requiresBrokerageContact =
    (IsLand || IsOnLand || IsOnThirdPartyLand) &&
    (ErrorRequiresBrokerageContact ||
      (IsInBrokerageRequiredState && !BrokerageEmail && !BrokeragePhone));

  const newIsPublished = IsPublished && canEditVisibility(home);

  const newStickerTypeId =
    home.StickerTypeId === STICKER_TYPES.SALE_HOME && !IsSaleHome
      ? STICKER_TYPES.BLANK
      : home.StickerTypeId;

  return {
    ...home,
    ErrorUnpublishedByDealer: !newIsPublished,
    ErrorRequiresFloorPlanImage: requiresFloorPlanImage,
    ErrorRequiresTwoImages: requiresTwoImages,
    ErrorRequiresAcres: requiresAcres,
    ErrorRequiresAddress: requiresAddress,
    ErrorRequiresLandImage: requiresLandImage,
    ErrorRequiresBrokerageName: requiresBrokerageName,
    ErrorRequiresBrokerageContact: requiresBrokerageContact,
    IsPublished: newIsPublished,
    IsSaleHome: newIsPublished && IsSaleHome,
    IsSalePending: newIsPublished && IsSalePending,
    ModelImageCount,
    InventoryImageCount,
    StickerTypeId: IsPublished ? newStickerTypeId : STICKER_TYPES.BLANK,
  };
};

export const HomesContext = React.createContext({
  isLoading: true,
  isUpdating: false,
  isError: false,
  homes: {},
  rooms: {},
  didFetchRooms: false,
  activeHomeId: null,
  query: '',
});

export const HomesProvider = ({ children, push, history }) => {
  const context = useContext(HomesContext);
  const { activeLot, user } = useContext(UserContext);
  const [state, dispatch] = useReducer(homesReducer, { ...context });

  const { location } = history;

  const filterHomes = query => {
    dispatch({ type: 'SEARCH', payload: query });
  };

  const homes = useMemo(() => {
    return Object.keys(state.homes)
      .map(id => {
        return state.homes[id];
      })
      .sort((a, b) => a.InventoryId > b.InventoryId)
      .filter(home => {
        return canViewHome(home, activeLot);
      });
  }, [state.homes]);

  const filteredHomes = useMemo(() => searchHomes(state.query, homes), [
    state.query,
    homes,
    activeLot,
  ]);

  const inventoryHomes = useMemo(
    () =>
      filteredHomes
        .filter(home => !home.IsAvailableFloorPlan)
        .filter(home => !home.IsLand),
    [filteredHomes]
  );

  const modelHomes = useMemo(
    () => filteredHomes.filter(home => home.IsAvailableFloorPlan),
    [filteredHomes]
  );

  const saleHomes = useMemo(
    () => filteredHomes.filter(home => home.IsSaleHome),
    [filteredHomes]
  );

  const homesWithLand = useMemo(
    () =>
      filteredHomes.filter(
        home => home.IsLand || home.IsOnLand || home.IsOnThirdPartyLand
      ),
    [filteredHomes]
  );

  const suggestedSaleHomes = useMemo(
    () =>
      filteredHomes.filter(({ IsSuggestedSaleHome }) => IsSuggestedSaleHome),
    [filteredHomes]
  );

  const notSaleHomes = useMemo(() => {
    return filteredHomes.filter(
      ({ IsAvailableFloorPlan, IsSaleHome, IsSuggestedSaleHome }) => {
        return !IsAvailableFloorPlan && !IsSaleHome && !IsSuggestedSaleHome;
      }
    );
  }, [filteredHomes]);

  const filterLand = query => {
    dispatch({ type: 'SEARCH', payload: query });
  };

  const fetchHomeByInventoryId = async (lotNumber, inventoryId) => {
    dispatch({ type: 'FETCH_HOME_INIT' });
    try {
      const home = await getHomeByInventoryId(lotNumber, inventoryId);
      // TODO Should reorder happen here or in reducer action?

      dispatch({
        type: 'FETCH_HOME_SUCCESS',
        payload: {
          ...home,
          InventoryImages: (
            sortByKey(qualifyImageRefs(home.InventoryImages), 'SortOrder') || []
          ).map(image => ({ ...image, ImageId: image.InventoryImagesId })),
          ModelImages: sortByKey(
            qualifyImageRefs(home.ModelImages),
            'SortOrder'
          ),
          Features: (sortByKey(home.Features, 'SortOrder') || []).map(
            (item, index) => ({ ...item, SortOrder: index + 1 })
          ),
        },
      });

      dispatch({ type: 'INIT_STAGED_HOME' });
      return home;
    } catch (error) {
      dispatch({ type: 'FETCH_HOME_FAILURE', payload: error });
      return error;
    }
  };

  const fetchHomeByModelNumber = async (lotNumber, modelNumber) => {
    dispatch({ type: 'FETCH_HOME_INIT' });
    try {
      const home = await getHomeByModelNumber(lotNumber, modelNumber);
      dispatch({ type: 'FETCH_HOME_SUCCESS', payload: home });

      dispatch({ type: 'INIT_STAGED_HOME' });
      return home;
    } catch (error) {
      dispatch({ type: 'FETCH_HOME_FAILURE', payload: error });
      return error;
    }
  };

  const activeHome = useMemo(() => {
    // reset error only if active home changes
    dispatch({ type: 'RESET_ERROR' });
    if (!state.activeHomeId || homes.length < 1) return null;

    return findHomeByWildCardId(state.homes, state.activeHomeId);
  }, [state.activeHomeId, homes, state.homes]);

  const setActiveHomeId = useCallback(
    id => {
      if (id === null || id === state.activeHomeId) {
        dispatch({ type: 'SET_ACTIVE_HOME_ID', payload: id });
        // staged home is cleared on unmount (i.e. switching details tabs)
        // make sure to re-init if clearing active home or loading current home

        dispatch({ type: 'INIT_STAGED_HOME' });
      } else {
        dispatch({ type: 'SET_ACTIVE_HOME_ID', payload: id });
      }
    },
    [state.activeHomeId]
  );

  const updateHome = async (home, stateOnly = false, undo = false) => {
    const IsAvailableFloorPlan = Object.prototype.hasOwnProperty.call(
      home,
      'IsAvailableFloorPlan'
    )
      ? home.IsAvailableFloorPlan
      : true;

    const validHome = {
      ...validateHome(home),
    };

    // user initiated update, update state optimistically
    dispatch({
      type: 'UPDATE_HOME_INIT',
      payload: validHome,
    });

    const getPriceStateValue = targetHome => {
      if (targetHome.PublishedPriceStateValue) {
        return targetHome.PublishedPriceStateValue.split(',')
          .join('')
          .split('$')
          .join('')
          .split('.00')
          .join('');
      }

      if (
        targetHome.PublishedPriceStateId === 2 ||
        targetHome.PublishedPriceStateId === 3
      ) {
        return targetHome.PublishedPriceStateValue === '' ||
          targetHome.PublishedPriceStateValue === undefined
          ? targetHome.HomePriceOptions.find(
              option => option.Id === targetHome.PublishedPriceStateId
            ).Value
          : targetHome.HomePriceOptions.find(
              option => option.Id === targetHome.PublishedPriceStateId
            )
              .Value.split(',')
              .join('')
              .split('$')
              .join('')
              .split('.00')
              .join('');
      }
      return null;
    };

    try {
      // send api request unless state only update
      let response;

      if (stateOnly) {
        response = true;
      } else {
        if (IsAvailableFloorPlan) {
          response = await updateModel(
            activeLot.LotNumber,
            home.ModelId,
            validHome
          );
        } else {
          response = await updateInventory(
            activeLot.LotNumber,
            home.InventoryId,
            {
              ...validHome,
              PublishedPriceStateValue: getPriceStateValue(home),
            }
          );
        }
      }

      // TODO Need to update API so PUT requests for models return updated objects
      const updatedHome =
        stateOnly || IsAvailableFloorPlan ? validHome : await response.json();

      if (response === true || response.ok) {
        setTimeout(() => {
          dispatch({ type: 'UPDATE_HOME_SUCCESS', payload: updatedHome });
        }, 0);
        if (undo) {
          // make undo function and show toast
          const undoFunction = async () => {
            await updateHome(findHomeByWildCardId(state.homes, home.Id), false);
          };
          toast.dismiss('undoToast'); // TODO determine if this is desired functionality
          toast.info(
            <ToastUndo undo={undoFunction} message="Changes saved." />,
            {
              className: 'undo-toast',
              progressClassName: 'undo-progress-bar',
              closeButton: false,
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: 'undoToast',
            }
          );
        }
      } else {
        setTimeout(() => {
          // api failure, show error toast and dispatch failed action to update state
          toast.error(<div>Unable to save updates.</div>, ERROR_TOAST_OPTIONS);
          dispatch({
            type: 'UPDATE_HOME_FAILURE',
            payload: findHomeByWildCardId(state.homes, home.Id),
          });
        }, 600); // Allow toggle animation to complete
      }
    } catch (error) {
      toast.error(<div>{`${error}`}</div>, ERROR_TOAST_OPTIONS);
      dispatch({
        type: 'UPDATE_HOME_FAILURE',
        payload: findHomeByWildCardId(state.homes, home.Id),
      });
    }
  };

  const updateStagedHome = home => {
    dispatch({
      type: 'UPDATE_STAGED_HOME',
      payload: home,
    });
  };

  const updateHomeAttribute = async (home, attribute, value, undo = true) => {
    const updatedHome = { ...home, [attribute]: value };

    if (attribute === 'IsSaleHome') {
      if (value) {
        if (saleHomes.length >= MAX_SALE_HOMES_COUNT) {
          toast.error(
            `You may not exceed ${MAX_SALE_HOMES_COUNT} sale homes.`,
            ERROR_TOAST_OPTIONS
          );
          return false;
        }
        if (home.StickerTypeId === STICKER_TYPES.BLANK) {
          updatedHome.StickerTypeId = STICKER_TYPES.SALE_HOME;
        }
      } else if (home.StickerTypeId === STICKER_TYPES.SALE_HOME) {
        updatedHome.StickerTypeId = STICKER_TYPES.BLANK;
      }
    }

    if (
      attribute === 'StickerTypeId' &&
      value === STICKER_TYPES.BLANK &&
      home.IsSaleHome
    ) {
      updatedHome.IsSaleHome = false;
    }

    if (
      attribute === 'StickerTypeId' &&
      value === STICKER_TYPES.SALE_HOME &&
      saleHomes.length >= MAX_SALE_HOMES_COUNT
    ) {
      toast.error(
        `You may not exceed ${MAX_SALE_HOMES_COUNT} sale homes.`,
        ERROR_TOAST_OPTIONS
      );
      return false;
    }

    if (
      attribute === 'StickerTypeId' &&
      value === STICKER_TYPES.SALE_HOME &&
      saleHomes.length < MAX_SALE_HOMES_COUNT
    ) {
      updatedHome.IsSaleHome = true;
    }

    if (attribute === 'IsPublished' && !value) {
      updatedHome.StickerTypeId = STICKER_TYPES.BLANK;
    }

    if (home.IsSold) {
      updatedHome.StickerTypeId = STICKER_TYPES.SOLD;
    }

    await updateHome(updatedHome, false, undo);
    return true;
  };

  const updateHomeFeatures = async (home, features) => {
    // user initiated update, update state optimistically
    dispatch({
      type: 'UPDATE_HOME_INIT',
      payload: { ...home, Features: features },
    });
    try {
      // send api request
      const newFeatures = await updateFeatures(
        activeLot.LotNumber,
        home.InventoryId,
        features
      );

      if (newFeatures) {
        dispatch({
          type: 'UPDATE_HOME_SUCCESS',
          payload: { ...home, Features: features },
        });
        return newFeatures;
      }
      setTimeout(() => {
        // api failure, show error toast and dispatch failed action to update state
        toast.error(<div>Unable to save updates.</div>, ERROR_TOAST_OPTIONS);
        dispatch({
          type: 'UPDATE_HOME_FAILURE',
          payload: findHomeByWildCardId(state.homes, home.Id),
        });
      }, 600); // Allow toggle animation to complete
      return false;
    } catch (error) {
      toast.error(<div>{`${error}`}</div>, ERROR_TOAST_OPTIONS);
      dispatch({
        type: 'UPDATE_HOME_FAILURE',
        payload: findHomeByWildCardId(state.homes, home.Id),
      });
      return false;
    }
  };

  const getErrors = ({
    ErrorRequiresFloorPlanImage,
    ErrorRequiresTwoImages,
    ErrorUnpublishedByDealer,
    ErrorMaxSaleHomes,
    ErrorRequiresAcres,
    ErrorRequiresAddress,
    ErrorRequiresLandImage,
    ErrorRequiresBrokerageName,
    ErrorRequiresBrokerageContact,
    IsLand,
  }) => {
    const errors = [
      {
        message:
          location && location.pathname.startsWith('/homes/land')
            ? 'This land needs a floor plan image.'
            : 'This home needs a floor plan image.',
        error: ErrorRequiresFloorPlanImage,
      },
      {
        message:
          location && location.pathname.startsWith('/homes/land')
            ? 'For this land to be visible, you must upload at least two images.'
            : 'For this home to be visible, you must upload at least two images.',
        error: ErrorRequiresTwoImages,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'This land was unpublished by the dealer.'
            : 'This home was unpublished by the dealer.',
        error: ErrorUnpublishedByDealer,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'This land needs an acres value.'
            : 'This listing needs an acres value.',
        error: ErrorRequiresAcres,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'This land needs an address value.'
            : 'This listing needs an address value.',
        error: ErrorRequiresAddress,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'For this land to be visible, you must upload at least one image.'
            : 'For this listing to be visible, you must upload at least one image.',
        error: ErrorRequiresLandImage,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'This land needs a brokerage name.'
            : 'This listing needs a brokerage name.',
        error: ErrorRequiresBrokerageName,
      },
      {
        message:
          (location && location.pathname.startsWith('/homes/land')) || IsLand
            ? 'This land needs a brokerage email or phone.'
            : 'This listing needs a brokerage email or phone.',
        error: ErrorRequiresBrokerageContact,
      },
    ];

    return errors.filter(error => error.error);
  };

  useEffect(() => {
    let didCancel = false;
    if (user.error && !state.isError) {
      dispatch({ type: 'ADD_ERROR', payload: user.error });
    } else if (activeLot && !state.isError) {
      (async () => {
        dispatch({ type: 'CLEAR_HOMES' });
        dispatch({ type: 'FETCH_LIST_INIT' });
        try {
          const homeList = await getHomeList(activeLot.LotNumber);
          if (!didCancel) {
            dispatch({ type: 'FETCH_LIST_SUCCESS', payload: homeList });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_LIST_FAILURE', payload: error });
          }
        }
      })();
    }

    return () => {
      didCancel = true;
    };
  }, [activeLot, user.error, state.isError]);

  const resetError = () => {
    dispatch({ type: 'RESET_ERROR' });
  };

  // make sure homes is populated and an active home id is set, then fetch details
  useEffect(() => {
    if (Object.keys(state.homes).length && state.activeHomeId) {
      const home = findHomeByWildCardId(state.homes, state.activeHomeId);

      if (!home) {
        // if ID is missing from home list, redirect to listings with error message
        push('/homes/on-display');
        toast.error(
          <div>{`${state.activeHomeId} is not a valid ID for ${
            activeLot.Name
          }`}</div>,
          ERROR_TOAST_OPTIONS
        );
      } else if (!home.detailsFetched) {
        if (home.IsAvailableFloorPlan) {
          fetchHomeByModelNumber(activeLot.LotNumber, home.ModelNumber).then();
        } else {
          fetchHomeByInventoryId(activeLot.LotNumber, home.InventoryId).then();
        }
      } else {
        dispatch({ type: 'INIT_STAGED_HOME' });
      }
    }
  }, [state.homes, state.activeHomeId, activeLot, push]);

  useEffect(() => {
    if (!state.didFetchRooms) {
      (async () => {
        dispatch({ type: 'FETCH_ROOM_INIT' });
        try {
          const roomData = await getRoomsData();
          dispatch({ type: 'FETCH_ROOM_SUCCESS', payload: roomData });
        } catch (error) {
          dispatch({ type: 'FETCH_ROOM_FAILURE', payload: error });
        }
      })();
    }
  }, [state.didFetchRooms]);

  return (
    <HomesContext.Provider
      value={{
        ...state,
        inventoryHomes,
        modelHomes,
        saleHomes,
        homesWithLand,
        notSaleHomes,
        suggestedSaleHomes,
        filterHomes,
        filterLand,
        activeHome,
        setActiveHomeId,
        updateHomeAttribute,
        updateHome,
        updateStagedHome,
        updateHomeFeatures,
        resetError,
        getErrors,
      }}
    >
      {children}
    </HomesContext.Provider>
  );
};

HomesProvider.propTypes = {
  children: PropTypes.element,
  push: PropTypes.func.isRequired,
};

HomesProvider.defaultProps = {
  children: <></>,
};
