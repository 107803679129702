import styled from 'styled-components';

const StyledWelcomeHomeListingCard = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
  display: flex;
  flex-flow: row wrap;
  flex-wrap: nowrap;
  flex-direction: column;
  /* width: 250px; */

  .card {
    width: 88%;
    box-sizing: border-box;
    position: relative;
    color: #25292d;
    margin: 50% auto 0;
    &.updating {
      .card-body {
        align-items: center;
      }
    }

    .loader {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .library-card-image {
    position: relative;
    aspect-ratio: 2/1.35;
    overflow: hidden;
    img {
      object-fit: contain;
      object-position: center;
    }
  }

  .library-card-content {
    position: relative;
    padding: 10px;
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .library-card-name {
    position: relative;
    margin-bottom: 10px;
    .name {
      font-size: 20px;
      font-weight: 600;
      color: #25292d;
      line-height: 1;
      margin-bottom: 1px;
      overflow-wrap: break-word;
    }
    .email {
      position: relative;
      color: #25292d;
      @media screen and (max-width: 600px) {
        font-size: 11px;
        word-break: break-word;
        /* text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 120px; */
      }
    }
  }

  .library-card-options {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checkmarks {
      position: relative;
      display: flex;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      svg {
        width: 15px;
        margin-right: 2px;
      }
      p {
        margin-right: 8px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        color: #9b9b9b;
        font-size: 11px;
      }
    }
    .edit {
      color: rgb(0, 117, 201);
      text-transform: uppercase;
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
    .edit-disabled {
      color: rgb(128, 128, 128);
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.available {
    .card-body {
      min-height: 100px;
    }
    .card-options {
      min-height: 0;
    }
  }

  .error {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    span {
      color: #b21f28;
      margin-left: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  h2 {
    padding: 0;
    margin: 0 0 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 600;
    color: #25292d;
    span {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      display: block;
      margin-right: 8px;
    }
  }
  .tag {
    font: 600 12px/100% source-sans-pro, sans-serif;
    text-align: center;
    width: 48px;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    flex: 0 auto;
  }
  a {
    text-decoration: none;
    display: flex;
    flex: 1;
    flex-flow: row wrap;
  }

  .arrow-position {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  p {
    color: #777b7e;
    font-size: 14px;
    padding: 0;
    margin: 0 0 5px;
  }
  span {
    margin-right: 7px;
    font-size: 12px;
    color: #4e5255;
    font-weight: 600;
  }
  .toggle-placeholder {
    height: 57px;
    width: 74px;
  }
  @media screen and (min-width: 1024px) {
  }
  @media screen and (min-width: 1200px) {
  }
`;

export default StyledWelcomeHomeListingCard;
