import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  createRef,
} from 'react';
import StyledSettings from './Descriptions.Style';
import { FormPrompt } from '../../FormPrompt';
import { getDealer, updateDealer } from '../../../Api';
import LoadingDots from '../../LoadingDots';
import { UserContext } from '../../User';
import { characterCount } from '../../../utils';
import PropTypes from 'prop-types';

const Descriptions = ({ history }) => {
  const [dealer, setDealer] = useState(null);
  const [dealerSnapshot, setDealerSnapshot] = useState(null);
  const [dealerHasChanged, setDealerHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageError, setIsPageError] = useState(false);
  const { activeLot, user } = useContext(UserContext);

  const settingsFormRef = useRef(null);
  const dealerDescriptionRef = useRef(null);
  const cityDescriptionRef = useRef(null);
  const metaRefs = useRef([]);

  // corp users only
  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser) {
      history.push('/');
    }
  }, [user]);

  // scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // first time load dealer info and whatever you need...
  useEffect(() => {
    if (!activeLot) return;
    (async () => {
      try {
        const dealer = await getDealer(activeLot.LotNumber);
        setDealer(dealer);
        setDealerSnapshot(dealer);
        setIsLoading(false);
        setDealerHasChanged(false);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
    })();
  }, [activeLot, isLoading]);

  // on dealer change, validate
  useEffect(() => {
    if (!dealer) return;

    // enable save button on change
    if (dealerSnapshot != null) {
      setDealerHasChanged(true);
    }
  }, [dealer]);

  const handleSave = () => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await updateDealer(activeLot.LotNumber, dealer);
      } catch (e) {
        setIsPageError(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    })();
  };

  const handleMetaDescriptionChange = (propName, newValue, path) => {
    const index =
      dealer.WebsiteMetaDescriptions &&
      dealer.WebsiteMetaDescriptions.findIndex(
        pth => pth.WebsitePagePath === path
      );

    const newMetaDescriptions = JSON.parse(
      JSON.stringify(dealer && dealer.WebsiteMetaDescriptions)
    );

    if (dealer && dealer.WebsiteMetaDescriptions.length > 0 && index > -1) {
      newMetaDescriptions[index].MetaDescription = newValue;
    }

    const newDescription = {
      WebsitePagePath: path,
      MetaDescription: newValue,
    };

    setDealer({
      ...dealer,
      [propName]:
        dealer && dealer.WebsiteMetaDescriptions.length > 0 && index > -1
          ? newMetaDescriptions
          : [...dealer.WebsiteMetaDescriptions, newDescription],
    });
  };

  const handleChange = (propName, newValue) => {
    setDealer({ ...dealer, [propName]: newValue });
  };

  const formatDescription = str => {
    let formattedDescription;
    formattedDescription = str
      .replace('<br>', '')
      .replace('<br/>', '')
      .replace('<b>', '')
      .replace('</b>', '');

    return formattedDescription;
  };

  const formatPath = str => {
    let formattedPath;
    let isHomes = str.startsWith('homes');
    let homes = isHomes && 'homes';
    formattedPath = str
      .replace('-', ' ')
      .toLowerCase()
      .replace(homes, 'Available Homes')
      .replace('index', 'homepage')
      .replace('housesmart', 'HouseSmart')
      .replace('energysmart', 'EnergySmart Zero');
    return formattedPath;
  };

  const metaDescriptionsCopy = [
    { WebsitePagePath: 'about-us' },
    { WebsitePagePath: 'energysmart' },
    { WebsitePagePath: 'homes' },
    { WebsitePagePath: 'housesmart' },
    { WebsitePagePath: 'index' },
    { WebsitePagePath: 'sale-homes' },
    { WebsitePagePath: 'special-offers' },
  ];

  const metaDescriptionValue = path => {
    const index =
      dealer.WebsiteMetaDescriptions &&
      dealer.WebsiteMetaDescriptions.findIndex(
        pth => pth.WebsitePagePath === path
      );

    if (
      dealer.WebsiteMetaDescriptions.length > 0 &&
      dealer.WebsiteMetaDescriptions[index]
    ) {
      return dealer.WebsiteMetaDescriptions[index].MetaDescription.trimStart();
    }

    return '';
  };

  metaRefs.current = metaDescriptionsCopy.map(
    (description, index) => (metaRefs && metaRefs.current[index]) || createRef()
  );

  return (
    <StyledSettings>
      <FormPrompt hasUnsavedChanges={dealerHasChanged} />
      <div>
        {isLoading && <LoadingDots />}
        {isPageError && (
          <div className="error">
            There was an error loading setttings, please try again later.
          </div>
        )}
        {!isPageError && !isLoading && (
          <>
            <h4 className="page-header">
              All descriptions are optional. Default descriptions are used if
              custom descriptions are not provided below.
            </h4>
            <p className="description-blurb">
              For Dealer and City descriptions, use &lt;p&gt; and &lt;/p&gt;
              tags to ensure proper formatting.
            </p>
            <form id="settings" ref={settingsFormRef}>
              <div className="settings-item">
                <label className="label" htmlFor="DealerDescription">
                  <span className="label-text">Dealer Description</span>
                  <p className="character-count">
                    {characterCount(1000, dealerDescriptionRef)}
                  </p>
                  <textarea
                    id="DealerDescription"
                    className="large-textarea"
                    value={
                      dealer.ContactUsDealerDescription
                        ? dealer.ContactUsDealerDescription.trimStart()
                        : ''
                    }
                    onChange={e => {
                      handleChange(
                        'ContactUsDealerDescription',
                        e.target.value
                      );
                    }}
                    onBlur={e => {
                      handleChange(
                        'ContactUsDealerDescription',
                        formatDescription(e.target.value)
                      );
                    }}
                    maxLength="1000"
                    ref={dealerDescriptionRef}
                    disabled={isLoading}
                  />
                </label>
              </div>

              <div className="settings-item">
                <label className="label" htmlFor="CityDescription">
                  <span className="label-text">City Description</span>
                  <p className="character-count">
                    {characterCount(
                      1000,
                      cityDescriptionRef && cityDescriptionRef
                    )}
                  </p>
                  <textarea
                    id="CityDescription"
                    className="large-textarea"
                    value={
                      dealer.ContactUsCityDescription
                        ? dealer.ContactUsCityDescription.trimStart()
                        : ''
                    }
                    onChange={e => {
                      handleChange('ContactUsCityDescription', e.target.value);
                    }}
                    onBlur={e => {
                      handleChange(
                        'ContactUsCityDescription',
                        formatDescription(e.target.value)
                      );
                    }}
                    maxLength="1000"
                    ref={cityDescriptionRef}
                    disabled={isLoading}
                  />
                </label>
              </div>
              <div className="meta-descriptions-container">
                {metaDescriptionsCopy.map((description, index) => (
                  <div
                    className={`settings-item ${description.WebsitePagePath}`}
                    key={index}
                  >
                    <label className="label" htmlFor={`MetaDescription`}>
                      <span className="label-text">
                        {`${formatPath(
                          description.WebsitePagePath
                        )} Meta Description`}
                      </span>
                      <p className="character-count">
                        {characterCount(160, metaRefs.current[index])}
                      </p>
                      <textarea
                        id={`MetaDescription`}
                        className="small-textarea"
                        value={metaDescriptionValue(
                          description.WebsitePagePath
                        )}
                        onChange={e => {
                          handleMetaDescriptionChange(
                            'WebsiteMetaDescriptions',
                            e.target.value,
                            description.WebsitePagePath
                          );
                        }}
                        maxLength="160"
                        ref={metaRefs.current[index]}
                      />
                    </label>
                  </div>
                ))}
              </div>

              <div className="settings-item">
                <button
                  type="button"
                  className="button"
                  disabled={isLoading || !dealerHasChanged}
                  onClick={handleSave}
                >
                  SAVE CHANGES
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </StyledSettings>
  );
};

export default Descriptions;
