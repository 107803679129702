import React from 'react';

const NoImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="190"
    height="191"
    viewBox="0 0 190 191"
  >
    <g fill="none" fillRule="nonzero">
      <path fill="#D6F4FF" d="M0 .317h190v190H0z" />
      <g fill="#00A0DD" transform="translate(55 69.432)">
        <circle cx="8.695" cy="8.849" r="8.5" />
        <circle cx="71.488" cy="8.849" r="8.5" />
        <path d="M69.74 35.387a4.5 4.5 0 1 1 7.133 5.488C68.127 52.242 54.622 59.041 40 59.041c-14.622 0-28.127-6.8-36.873-18.166a4.5 4.5 0 1 1 7.133-5.488C17.32 44.562 28.202 50.04 40 50.04c11.798 0 22.68-5.479 29.74-14.654z" />
      </g>
    </g>
  </svg>
);

export default NoImage;
