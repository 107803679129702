import styled from 'styled-components';

const PublishAgentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  button {
    background: #00b0ac;
    height: 45px;
    box-shadow: 0 9px 18px -12px #10a29f;
    border: solid 1px var(--kiddie-pool);
    color: white;
    border-radius: 2px;
    width: 300px;
    margin: 50px auto 0;
    font: 600 14px/100% source-sans-pro;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background: #e4e5e7;
      box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
      cursor: not-allowed;
    }
  }
`;

export default PublishAgentWrapper;
