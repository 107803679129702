import styled from 'styled-components';

const StyledDownloads = styled.div.attrs({ className: 'downloads' })`
  .card {
    padding: 0;
    .download-link {
      font-size: 15px;
      text-decoration: none;
      color: #4e5255;
      padding: 14px 25px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid #e4e5e7;
      }
      .pdf-icon {
        display: inline-block;
        height: 30px;
        width: 25px;
        margin-right: 12px;
      }
      .link-name {
        flex: 1;
      }
      .view {
        color: #a1a3a6;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .view-icon {
        display: block;
        margin-left: 12px;
        height: 24px;
      }
    }
  }
`;

export default StyledDownloads;
