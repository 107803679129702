import styled from 'styled-components';

const StyledAutoComplete = styled.div.attrs({ className: 'autocomplete' })`
  position: relative;
  z-index: 1;
  .suggestions {
    background-color: #29476c;
    position: absolute;
    top: 75px;
    left: 5px;
    right: 15px;
    padding: 10px 0;
    min-height: 40px;
    color: #fff;
    max-width: 310px;
    .no-customers-found {
      padding: 8px 15px;
    }
    .suggestion {
      cursor: pointer;
      color: #fff;
      padding: 8px 15px;
      font-size: 15px;
      &:hover,
      &:focus {
        background-color: #0075c9;
        color: #fff;
      }
    }
    .selected {
      background-color: #0075c9;
      color: #fff;
    }
  }
  label {
    display: flex;
    flex-flow: row wrap;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 0;
    display: block;
    flex: 1 1 100%;
  }
  .search-input-container {
    flex: 1 1 200px;
    color: #e4e5e7;
    padding: 1em;
    border: solid 1px #e4e5e7;
    margin-bottom: 20px;
    background: #fff;

    input {
      border: 0;
      width: 80%;
      margin-left: 10px;
      outline: 0;
      font-size: 15px;
      background: #fff;
    }
  }
  #searchButton {
    flex: 1 1 40px;
    border: none;
    background: #0075c9;
    color: #fff;
    height: 49px;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

export default StyledAutoComplete;
