import styled from 'styled-components';

const StyledWelcomeHomeItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 25px;
  padding-bottom: 100px;
  flex: 1;
  @media screen and (min-width: 1024px) {
    margin: 0 0 0 100px;
  }
  .main-column {
    flex: 1 1 100%;
    .image-uploader {
      margin-bottom: 20px;
    }
    @media screen and (min-width: 1024px) {
      flex: 0;
      min-width: 460px;
      margin-right: 100px;
    }
  }

  .card {
    background: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    border: solid 1px #e4e5e7;
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .image-field {
    text-align: center;
    margin-bottom: 45px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 0 1rem 0;
    .image-wrapper {
      padding: 1rem;
      min-height: 200px;
    }

    img {
      border: solid 1px #e4e5e7;
    }
    button.clean {
      margin: 0;
    }
  }
  button.clean {
    cursor: pointer;
    margin: 1rem 0 0 0;
    background: none;
    border: none;
    text-transform: uppercase;
    color: #9b9b9b;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    &:disabled {
      cursor: not-allowed;
      color: #cbcbcb;
    }
  }
  .input-field {
    label {
      display: flex;
      flex-flow: row wrap;
    }

    input {
      color: #4e5255;
      flex: 1 1 100%;
      padding: 5px;
      border: solid 1px #e4e5e7;
      font-size: 15px;
      box-sizing: border-box;
      height: 49px;
      margin-bottom: 2px;

      &.has-error {
        border: 1px solid #ff0000;
      }
    }

    .error {
      color: #ff0000;
      margin-bottom: 18px;
    }
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 0;
    display: block;
    flex: 1 1 100%;
  }
  .sub-label {
    font-size: 13px;
    color: #4e5255;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
  }
  #search {
    flex: 1 1 200px;
  }
  .checkbox-display {
    display: initial !important;
    margin-right: 15px;
  }
  .customer-entry-form {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ededed;
    background: #fff;
    margin-bottom: 20px;

    .form-headline {
      font-size: 14px;
      font-weight: 600;
      color: #4e5255;
      margin-bottom: 0;
    }

    .required-text {
      padding: 0;
      margin: 0 0 20px;
      font-size: 12px;
      color: #4e5255;
      width: 100%;
    }

    .input-field {
      flex: 0 0 100%;
      @media only screen and (min-width: 600px) {
        flex: 0 0 45%;
      }

      &:nth-of-type(3n) {
        flex: 0 0 100%;
      }
    }
  }

  .submit-container {
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ededed;
    background: #fff;

    .signed-release-container {
      flex: 0 0 100%;

      label {
        display: flex;
        flex-flow: row wrap;
      }

      .signed-release-label {
        flex: 0 0 70%;
        margin-right: 20px;
      }

      .signed-release-sub-label {
        font-size: small;
        font-weight: bold;
      }

      .is-consented-checkbox {
        margin-top: 9px;
      }
    }

    #submitBtn {
      border: none;
      background: #0075c9;
      color: #fff;
      height: 49px;
      padding: 0 80px;
      font-weight: 700;
      margin: 30px 0 0;

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }

      &:disabled {
        cursor: default;
        opacity: 1;
        background: #cbcbcb;
      }
    }

    .form-submit-loading {
      display: block;
      margin: 20% 0 0;
    }
  }

  .current-image-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    background: white;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 650px) {
      padding: 15px;
    }
    p {
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      margin: 0px;
      color: rgb(0, 117, 201);
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 0;
      @media screen and (max-width: 650px) {
        font-size: 13px;
      }
    }
  }
`;

export default StyledWelcomeHomeItem;
