/**
 * Retrieves the base URL for API requests from appSettings file
 *
 * @returns {Promise} Resolves to the base url
 */
const getBaseUrl = () => {
  try {
    return fetch('/appSettings.json')
      .then(data => data.json())
      .then(response => {
        const { baseApiUrl } = response;
        if (!baseApiUrl) {
          throw new Error('baseApiUrl is not set in appSettings.json');
        }
        return baseApiUrl;
      });
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves the auth URL from appSettings file
 *
 * returnContext needs to have 'https://' removed because of ETO WAF rules
 *
 * @param {string} returnContext Destination to redirect back to once logged in
 * @returns {Promise} Resolves to the auth url
 */
const getAuthUrl = (returnContext = '') => {
  try {
    return fetch('/appSettings.json')
      .then(data => data.json())
      .then(response => {
        const { authUrl } = response;
        if (!authUrl) {
          throw new Error('authUrl is not set in appSettings.json');
        }
        const sanitizedReturnContext = escape(
          returnContext.replace('https://', '').replace('http://', '')
        );
        return `${authUrl}${
          sanitizedReturnContext
            ? `&returnContext=${sanitizedReturnContext}`
            : ''
        }`;
      });
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves the signout URL from the appSettings file
 *
 * @returns {Promise<string>} Resolves to the signout url
 */
export const getSignoutUrl = () => {
  try {
    return fetch('/appSettings.json')
      .then(data => data.json())
      .then(response => {
        const { signoutUrl } = response;
        return signoutUrl;
      });
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves the no fap auth URL from appSettings file
 *
 * @deprecated since version 0.1.0 - Use getAuthUrl() instead
 * @returns {Promise} Resolves to the no fap auth url
 */
export const getNoFapAuthUrl = () => {
  try {
    return fetch('/appSettings.json')
      .then(data => data.json())
      .then(response => {
        const { noFapAuthUrl } = response;
        if (!noFapAuthUrl) {
          throw new Error('noFapAuthUrl is not set in appSettings.json');
        }
        return noFapAuthUrl;
      });
  } catch (error) {
    throw error;
  }
};

/**
 * Decodes JWT and parses pertinent data
 *
 * @param {string} token
 * @returns {object}
 * @throws Will throw error if unable to decode or parse token
 */
export const parseJwt = token => {
  try {
    const [, payload] = token.split('.');
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    throw error;
  }
};

/**
 * Attempts to return token from local storage.
 *
 * @returns {(String|null)} The valid token from local storage or null if unset or expired
 */
export const getCachedToken = () => {
  const cachedToken = localStorage.getItem('token');
  if (!cachedToken) return null;
  return parseJwt(cachedToken).exp > Math.floor(Date.now() / 1000)
    ? cachedToken
    : null;
};

export const redirectToAuthUrl = returnUrl => {
  getAuthUrl(returnUrl).then(url => {
    window.location.replace(url);
  });
};

/**
 * Return a cached token if available, if not redirect to FAP SAML page
 *
 * @returns {String} the user's JWT
 */
export const getAuthToken = async () => {
  const token = getCachedToken();

  if (!token) {
    redirectToAuthUrl();
  }

  return token;
};

/**
 * Generic method to perform GET requests.
 * This method also updates the user's JWT based on the response header.
 *
 * @param {string} endpoint
 * @param {object} [headers={}]
 * @returns {Promise<any | Promise<any | never>>}
 */
const get = async (endpoint, headers = {}) => {
  const baseUrl = await getBaseUrl();
  const token = await getAuthToken();
  return fetch(baseUrl + endpoint, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  })
    .then(response => {
      if (response.headers.get('authorization')) {
        localStorage.setItem('token', response.headers.get('authorization'));
      }
      if (!response.ok) {
        switch (response.status) {
          case 401:
            throw new Error('You are not authorized to make this request.');
          default:
            return response;
        }
      }
      return response.json();
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Generic method to perform POST requests.
 * This method also updates the user's JWT based on the response header.
 *
 * @param {string} endpoint
 * @param {object} body
 * @param {object} [headers={}]
 * @returns {boolean} True if success otherwise false
 */
const post = async (endpoint, body, headers = {}) => {
  const baseUrl = await getBaseUrl();
  const token = await getAuthToken();
  return fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body,
  })
    .then(response => {
      if (response.headers.get('authorization')) {
        localStorage.setItem('token', response.headers.get('authorization'));
      }
      return response;
    })
    .catch(error => {
      // Network Error
      throw error;
    });
};

/**
 * Generic method to perform PUT requests.
 * This method also updates the user's JWT based on the response header.
 *
 * @param {string} endpoint
 * @param {object} body
 * @param {object} [headers={}]
 * @returns {boolean} True if success otherwise false
 */
const put = async (endpoint, body, headers = {}) => {
  const baseUrl = await getBaseUrl();
  const token = await getAuthToken();
  return fetch(baseUrl + endpoint, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body,
  })
    .then(response => {
      if (response.headers.get('authorization')) {
        localStorage.setItem('token', response.headers.get('authorization'));
      }
      return response;
    })
    .catch(error => {
      // Network Error
      throw error;
    });
};

/**
 * Generic method to perform DELETE requests.
 * This method also updates the user's JWT based on the response header.todo does it though?
 *
 * @param {string} endpoint
 * @param {object} [headers={}]
 * @returns {boolean} True if success otherwise false
 */
const del = async (endpoint, headers = {}) => {
  const baseUrl = await getBaseUrl();
  const token = await getAuthToken();
  return fetch(baseUrl + endpoint, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  })
    .then(response => {
      if (response.headers.get('authorization')) {
        localStorage.setItem('token', response.headers.get('authorization'));
      }
      return response.ok;
    })
    .catch(error => {
      // Network Error
      throw error;
    });
};

/**
 * Api request to retrieve user data
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getUserData = async () => {
  try {
    return get('userdata');
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to simulate failed retrieve user data
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getUserDataFailTest = async () => {
  try {
    throw 'simulated api fail';
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve homes for a given lot number
 *
 * @param {number} lotNumber
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getHomeList = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/homes`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve available inventory for a given lot number
 *
 * @deprecated since version 0.5.0
 *
 * @param {number} lotNumber
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getOnDisplayList = async lotNumber => {
  const homes = await getHomeList(lotNumber);
  return homes.filter(home => !home.IsAvailableFloorPlan);
};

/**
 * Api request to retrieve available models for a given lot number
 *
 * @deprecated since version 0.5.0
 *
 * @param {number} lotNumber
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getAvailableList = async lotNumber => {
  const homes = await getHomeList(lotNumber);
  return homes.filter(home => home.IsAvailableFloorPlan);
};

/**
 * Api request to retrieve home for a given serial number
 *
 * @param {number} lotNumber
 * @param {string} serialNumber
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getHomeBySerialNumber = async (lotNumber, serialNumber) => {
  try {
    return get(
      `retailadmin/${lotNumber}/inventory/serialnumber/${serialNumber}`
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve home for a given inventory ID
 *
 * @param {number} lotNumber
 * @param {string} inventoryId
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getHomeByInventoryId = async (lotNumber, inventoryId) => {
  try {
    return get(`retailadmin/${lotNumber}/inventory/${inventoryId}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve retail site items
 *
 * @param {number} lotNumber
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const getSiteItems = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/retailSiteItems`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve retail content site items
 *
 * @param {number} lotNumber
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const getContentSiteItems = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/homecenterwebsitecontent`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve rooms data
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getRoomsData = async () => {
  try {
    return get('rooms');
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve profile data
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getProfileData = (lotNumber, userName) => {
  try {
    return get(`retailadmin/${lotNumber}/userprofiles/${userName}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve sales agent data
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getSalesAgentData = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/userprofiles`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update a sales agent
 *
 * @param {number} lotNumber
 * @param {object} body
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateSalesAgent = async (lotNumber, body) => {
  try {
    return post(`retailadmin/${lotNumber}/userprofiles`, JSON.stringify(body), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to delete a sales agent
 *
 * @param lotNumber
 * @param userName
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const deleteSalesAgent = async (lotNumber, userName) => {
  try {
    return del(`retailadmin/${lotNumber}/userProfiles/${userName}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve home for a given model number
 *
 * @param {number} lotNumber
 * @param {string} modelNumber
 * @returns {Promise<any|Promise<any|never>>}
 * @throws Will throw error if network request fails
 */
export const getHomeByModelNumber = async (lotNumber, modelNumber) => {
  try {
    return get(`retailadmin/${lotNumber}/model/modelnumber/${modelNumber}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update model
 *
 * @param {number} lotNumber
 * @param {string} modelId
 * @param {object} body
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateModel = async (lotNumber, modelId, body) => {
  try {
    return put(
      `retailadmin/${lotNumber}/model/${modelId}`,
      JSON.stringify(body),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update inventory
 *
 * @param {number} lotNumber
 * @param {string} inventoryId
 * @param {object} body
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateInventory = async (lotNumber, inventoryId, body) => {
  try {
    return put(
      `retailadmin/${lotNumber}/inventory/${inventoryId}`,
      JSON.stringify(body),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update features for inventory item
 *
 * @param {number} lotNumber
 * @param {string} inventoryId
 * @param {array} features
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateFeatures = async (lotNumber, inventoryId, features) => {
  try {
    return put(
      `retailadmin/${lotNumber}/inventory/${inventoryId}/features`,
      JSON.stringify(features),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update content site items
 *
 * @param {number} lotNumber
 * @param {string} contentItemId
 * @param {object} contentItem
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateContentSiteItem = async (
  lotNumber,
  contentItemId,
  contentItem
) => {
  try {
    return put(
      `retailadmin/${lotNumber}/homecenterwebsitecontent/${contentItemId}`,
      JSON.stringify(contentItem),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to upload a home image
 *
 * @param {number} lotNumber
 * @param {string} inventoryId
 * @param {FormData} data
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const uploadHomeImage = async (lotNumber, inventoryId, data) => {
  try {
    return post(
      `retailadmin/${lotNumber}/inventory/${inventoryId}/images`,
      data
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to upload a site item image
 *
 * @param {FormData} data
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const uploadSiteItemImage = async data => {
  try {
    return post('images/', data);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to delete an image
 *
 * @param {number} lotNumber
 * @param {string} inventoryId
 * @param {number} inventoryImageId
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const deleteImage = async (lotNumber, inventoryId, inventoryImageId) => {
  try {
    return del(
      `retailadmin/${lotNumber}/inventory/${inventoryId}/images/${inventoryImageId}`
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update a given inventory image
 *
 * @param {number} lotNumber
 * @param {number} inventoryId
 * @param {{InventoryImagesId: {number}}} image
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const updateImage = async (lotNumber, inventoryId, image) => {
  try {
    return put(
      `retailadmin/${lotNumber}/inventory/${inventoryId}/images/${
        image.InventoryImagesId
      }`,
      JSON.stringify(image),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to delete a welcome home image
 *
 * @param {number} lotNumber
 * @param {number} welcomeHomeItemId
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const deleteWelcomeHomeItem = async (lotNumber, welcomeHomeImageId) => {
  try {
    return del(`/retailadmin/${lotNumber}/welcomehome/${welcomeHomeImageId}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update the sort order of images
 *
 * @param lotNumber
 * @param inventoryId
 * @param images
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const reorderImages = async (lotNumber, inventoryId, images) => {
  try {
    return put(
      `retailadmin/${lotNumber}/inventory/${inventoryId}/images`,
      JSON.stringify(images),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to delete a retail site item (announcement or promotion)
 *
 * @param lotNumber
 * @param retailSiteItemId
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const deleteSiteItem = async (lotNumber, retailSiteItemId) => {
  try {
    return del(`retailadmin/${lotNumber}/retailsiteitems/${retailSiteItemId}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to delete a retail site item (announcement or promotion)
 *
 * @param lotNumber
 * @param contentItemId
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const deleteContentSiteItem = async (lotNumber, contentItemId) => {
  try {
    return del(
      `retailadmin/${lotNumber}/homecenterwebsitecontent/${contentItemId}`
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update a retail site item (promotion)
 *
 * @param lotNumber
 * @param retailSiteItemId
 * @param siteItem
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const updateSiteItem = async (lotNumber, retailSiteItemId, siteItem) => {
  try {
    return post(
      `retailadmin/${lotNumber}/retailsiteitems/${retailSiteItemId}`,
      siteItem,
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to create a new retail site item (promotion)
 *
 * @param lotNumber
 * @param siteItem
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const createSiteItem = async (lotNumber, siteItem) => {
  try {
    return post(`retailadmin/${lotNumber}/retailsiteitems/`, siteItem);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to create a new retail site item (promotion)
 *
 * @param lotNumber
 * @param contentSiteItem
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const createContentSiteItem = async (lotNumber, contentSiteItem) => {
  try {
    return post(
      `retailadmin/${lotNumber}/homecenterwebsitecontent/`,
      contentSiteItem,
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to add content site items in bulk (for multiple dealers at once)
 *
 * @param {object} contentItem
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const batchCreateContentSiteItem = async contentItem => {
  try {
    return post(`homecenterwebsitecontent`, contentItem, {
      'Content-Type': 'application/json',
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to get stock images for retail site items
 *
 * @returns {Promise<[] | any>}
 * @throws Will throw error if network request fails
 */
export const getStockImages = async () => {
  try {
    return get('stockimages');
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to get list of specialties for sales agents
 * @param lotNumber
 * @returns {Promise<[] | any>}
 * @throws Will throw error if network request fails
 */
export const getSpecialtyOptions = async lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/specialtyoptions`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to get hope sheet details
 * @param lotNumber
 * @param serialNumber
 * @returns {Promise<{} | any}
 * @throws Will throw error if network request fails
 */
export const getHopeSheetDetails = async (lotNumber, serialNumber) => {
  try {
    return get(
      `retailadmin/${lotNumber}/inventory/serialnumber/${serialNumber}/hopesheet`
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve Welcome Home items
 *
 * @param {number} lotNumber
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const getWelcomeHomeItems = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/welcomehome`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to create a new Welcome Home item
 *
 * @param lotNumber
 * @param data
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const createWelcomeHomeItem = async (lotNumber, data) => {
  try {
    return post(`retailadmin/${lotNumber}/welcomehome`, data);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update a Welcome Home item
 *
 * @param lotNumber
 * @param id
 * @param data
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const updateWelcomeHomeItem = async (lotNumber, id, data) => {
  try {
    return put(`v2/retailadmin/${lotNumber}/welcomehome/${id}`, data);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update the sort order of active images for Welcome Home
 *
 * @param lotNumber
 * @param welcomeHomeItemIds
 * @returns {Promise<boolean>}
 * @throws Will throw error if network request fails
 */
export const reorderActiveImage = async (lotNumber, welcomeHomeItemIds) => {
  try {
    return put(
      `retailadmin/${lotNumber}/welcomehome`,
      JSON.stringify(welcomeHomeItemIds),
      { 'Content-Type': 'application/json' }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve booked opportunities from vantage
 *
 * @param {number} lotNumber
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const getBookedOpportunities = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}/bookedopportunities`);
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve web site template types
 *
 * @returns {Promise<any|Promise<any|never>>}
 */
export const getWebSiteTemplates = async () => {
  try {
    return get('websitetemplates');
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to update a dealer
 *
 * @param {number} lotNumber
 * @param data
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const updateDealer = async (lotNumber, data) => {
  try {
    return put(`retailadmin/${lotNumber}`, JSON.stringify(data), {
      'Content-Type': 'application/json',
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Api request to retrieve dealer
 *
 * @param {number} lotNumber
 * @returns {Promise<{}>}
 * @throws Will throw error if network request fails
 */
export const getDealer = lotNumber => {
  try {
    return get(`retailadmin/${lotNumber}`);
  } catch (error) {
    throw error;
  }
};
