import React from 'react';
import PropTypes from 'prop-types';

const HomesDisplayIcon = () => (
  <svg
    width="192"
    height="96"
    viewBox="0 0 192 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.46133 77.5C0.819699 72.1438 0.0718653 63.6514 2.37021 58.0042C4.17569 53.5673 8.21755 53.6997 12.4546 54.2867C16.0029 54.7782 20.4386 55.2615 23.8594 54.069C32.0335 51.2193 30.8527 41.8324 32.2209 35.6816C34.5562 25.1799 39.3666 11.8164 49.9246 5.70703C54.7513 2.91464 60.9177 0.133705 66.7743 0.00318919C74.3189 -0.164252 81.8051 6.30039 87.4181 10.2649C98.2168 17.8914 105.125 28.2811 117.678 33.7474C126.851 37.7431 138.431 39.266 148.493 37.7329C152.981 37.0485 157.115 35.737 161.404 34.4745C163.577 33.8347 166.307 32.7453 168.656 32.9281C174.61 33.3909 180.099 39.627 183.24 43.6348C187.093 48.5479 189.022 53.4419 189.77 59.3526C190.287 63.4413 190.797 67.3886 190.292 71.495C190.135 72.7779 189.766 73.9793 190.03 75.3678"
      fill="#F1FAFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.306 53.3113C170.306 58.9519 165.734 63.5239 160.094 63.5239C154.453 63.5239 149.881 58.9519 149.881 53.3113C149.881 47.6706 154.453 43.0986 160.094 43.0986C165.734 43.0986 170.306 47.6706 170.306 53.3113Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M160.196 49.0381V66.6318"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.56 52.2498L160.031 54.7212L162.627 52.1248"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.56 56.3032L160.031 58.774L162.627 56.1775"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.269 77.8015C173.992 67.1935 137.81 59.8137 95.7877 59.8137C60.0044 59.8137 28.4562 65.1643 9.8264 73.3035C6.57911 74.722 3.73944 76.2274 1.323 77.8015"
      fill="white"
    />
    <path
      d="M190.269 77.8015C173.992 67.1935 137.81 59.8137 95.7877 59.8137C60.0044 59.8137 28.4562 65.1643 9.8264 73.3035C6.57911 74.722 3.73944 76.2274 1.323 77.8015"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.15 66.6638C191.15 72.3038 186.578 76.8764 180.937 76.8764C175.296 76.8764 170.724 72.3038 170.724 66.6638C170.724 61.0232 175.296 56.4512 180.937 56.4512C186.578 56.4512 191.15 61.0232 191.15 66.6638"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.15 66.6638C191.15 72.3038 186.578 76.8764 180.937 76.8764C175.296 76.8764 170.724 72.3038 170.724 66.6638C170.724 61.0232 175.296 56.4512 180.937 56.4512C186.578 56.4512 191.15 61.0232 191.15 66.6638Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M181.04 62.3909V79.9839"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M178.403 65.6025L180.875 68.074L183.471 65.4768"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M178.403 69.6553L180.875 72.1267L183.471 69.5303"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.937 76.876C184.59 76.876 187.786 74.9521 189.591 72.0683C189.364 72.2341 181.04 77.4175 175.874 72.8611C169.273 67.0377 173.194 60.3618 173.355 59.8394C173.328 59.8699 173.3 59.8991 173.273 59.9297L172.44 60.9997C171.357 62.6206 170.724 64.5676 170.724 66.6641C170.724 72.304 175.296 76.876 180.937 76.876"
      fill="#1B478D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.937 76.876C184.59 76.876 187.786 74.9521 189.591 72.0683C189.364 72.2341 181.04 77.4175 175.874 72.8611C169.273 67.0377 173.194 60.3618 173.355 59.8394C173.328 59.8699 173.3 59.8991 173.273 59.9297L172.44 60.9997C171.357 62.6206 170.724 64.5676 170.724 66.6641C170.724 72.304 175.296 76.876 180.937 76.876Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5711 66.6638C26.5711 72.3038 21.9991 76.8764 16.3584 76.8764C10.7185 76.8764 6.14648 72.3038 6.14648 66.6638C6.14648 61.0232 10.7185 56.4512 16.3584 56.4512C21.9991 56.4512 26.5711 61.0232 26.5711 66.6638"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5711 66.6638C26.5711 72.3038 21.9991 76.8764 16.3584 76.8764C10.7185 76.8764 6.14648 72.3038 6.14648 66.6638C6.14648 61.0232 10.7185 56.4512 16.3584 56.4512C21.9991 56.4512 26.5711 61.0232 26.5711 66.6638Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4617 62.3909V79.9839"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8246 65.6025L16.2961 68.074L18.8918 65.4768"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8246 69.6553L16.2961 72.1267L18.8918 69.5303"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3582 76.876C20.0117 76.876 23.2081 74.9521 25.0124 72.0683C24.7855 72.2341 16.4615 77.4175 11.2957 72.8611C4.69379 67.0377 8.61499 60.3618 8.776 59.8394C8.74882 59.8699 8.72097 59.8991 8.69447 59.9297L7.86227 60.9997C6.77871 62.6206 6.14624 64.5676 6.14624 66.6641C6.14624 72.304 10.7183 76.876 16.3582 76.876"
      fill="#1B478D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3582 76.876C20.0117 76.876 23.2081 74.9521 25.0124 72.0683C24.7855 72.2341 16.4615 77.4175 11.2957 72.8611C4.69379 67.0377 8.61499 60.3618 8.776 59.8394C8.74882 59.8699 8.72097 59.8991 8.69447 59.9297L7.86227 60.9997C6.77871 62.6206 6.14624 64.5676 6.14624 66.6641C6.14624 72.304 10.7183 76.876 16.3582 76.876Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.3097 79.7912L33.7289 81.2103L35.2397 79.6995"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7745 78.3257V81.2102"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.9374 23.5539H56.6126V19.5566H53.9374V23.5539Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5833 34.8897L51.338 23.5535H138.591"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8966 37.0484H122.38V34.8894H29.8966V37.0484Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3667 77.1525H120.03V37.0486H31.3667V77.1525Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3667 77.1525H120.03V37.0486H31.3667V77.1525Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.3545 77.1524H104.794V42.4744H89.3545V77.1524Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.907 62.2974C102.907 62.8585 102.452 63.3137 101.891 63.3137C101.33 63.3137 100.875 62.8585 100.875 62.2974C100.875 61.7369 101.33 61.2817 101.891 61.2817C102.452 61.2817 102.907 61.7369 102.907 62.2974Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.468 77.1525H74.1195V37.0486H70.468V77.1525Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3667 77.1525H35.0182V37.0486H31.3667V77.1525Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3226 63.3134H64.4089V42.4744H41.3226V63.3134Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.8653 42.4749V63.3138"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.3223 52.8939H64.4085"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3851 58.1375H101.891V45.2781H92.3851V58.1375Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8375 58.7741H35.5474V57.1008H30.8375V58.7741Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8375 58.7741H35.5474V57.1008H30.8375V58.7741Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.9388 58.7741H74.648V57.1008H69.9388V58.7741Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.9388 58.7741H74.648V57.1008H69.9388V58.7741Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.093 36.4568L140.061 22.5322L120.03 36.4568H117.564V38.6396H120.03V76.1104H160.093V38.6396H162.559V36.4568H160.093Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.093 36.4568L140.061 22.5322L120.03 36.4568H117.564V38.6396H120.03V76.1104H160.093V38.6396H162.559V36.4568H160.093Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.093 77.1527H120.03V38.7057L140.062 25.1072L160.093 38.7057V77.1527Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.093 77.1527H120.03V38.7057L140.062 25.1072L160.093 38.7057V77.1527Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.523 46.4167C141.813 46.2761 140.242 45.9378 139.765 46.6552C139.112 47.6396 140.9 49.1531 141.615 49.6001C144.013 51.0988 144.5 47.1973 142.523 46.4167"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.519 63.3134H151.605V42.4744H128.519V63.3134Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140.061 42.4749V63.3138"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.518 52.8939H151.605"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.294 64.8462H109.944V45.1506C109.944 43.673 111.141 42.4746 112.619 42.4746C114.097 42.4746 115.294 43.673 115.294 45.1506V64.8462Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.944 62.2972H115.294"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.2055 64.8462H78.8549V45.1506C78.8549 43.673 80.0526 42.4746 81.5302 42.4746C83.0078 42.4746 84.2055 43.673 84.2055 45.1506V64.8462Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8549 62.2972H84.2055"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2051 63.3136H65.5257"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.401 63.3136H152.722"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.03 69.8438H160.094"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.2434 69.857H69.88"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0331 77.1527H31.3673"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M159.836 77.1527H162.17"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.2676 42.4747H105.882"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.5305 77.4161V77.4161C81.5305 75.1077 79.6583 73.2361 77.3498 73.2361H77.1487"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.8035 75.3257C77.8035 75.3257 80.7872 75.3257 81.5304 77.416"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.5305 77.4161V77.4161C81.5305 75.1077 83.4021 73.2361 85.7105 73.2361H85.9116"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.5305 72.0974V77.416C82.2737 75.3257 85.2567 75.3257 85.2567 75.3257"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.619 77.4161V77.4161C112.619 75.1077 110.747 73.2361 108.439 73.2361H108.238"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.892 75.3257C108.892 75.3257 111.876 75.3257 112.618 77.416"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.619 77.4161V77.4161C112.619 75.1077 114.491 73.2361 116.799 73.2361H117"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.619 72.0974V77.416C113.363 75.3257 116.346 75.3257 116.346 75.3257"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.0646 70.6844C82.0646 70.9799 81.8248 71.219 81.5307 71.219C81.2352 71.219 80.996 70.9799 80.996 70.6844C80.996 70.3888 81.2352 70.1504 81.5307 70.1504C81.8248 70.1504 82.0646 70.3888 82.0646 70.6844Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.153 70.6844C113.153 70.9799 112.914 71.219 112.619 71.219C112.324 71.219 112.085 70.9799 112.085 70.6844C112.085 70.3888 112.324 70.1504 112.619 70.1504C112.914 70.1504 113.153 70.3888 113.153 70.6844Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.581 34.4799C143.581 36.4235 142.005 37.9996 140.061 37.9996C138.118 37.9996 136.543 36.4235 136.543 34.4799C136.543 32.5363 138.118 30.9602 140.061 30.9602C142.005 30.9602 143.581 32.5363 143.581 34.4799"
      fill="#1B478D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.581 34.4799C143.581 36.4235 142.005 37.9996 140.061 37.9996C138.118 37.9996 136.543 36.4235 136.543 34.4799C136.543 32.5363 138.118 30.9602 140.061 30.9602C142.005 30.9602 143.581 32.5363 143.581 34.4799Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3849 45.2783C92.3849 45.2783 92.8577 58.1554 101.891 58.1377H92.3849V45.2783Z"
      fill="#1B478D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3849 45.2783C92.3849 45.2783 92.8577 58.1554 101.891 58.1377H92.3849V45.2783Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.016 77.6544C154.496 76.9682 153.96 76.2386 153.413 75.4641C153.293 75.2943 153.444 74.9866 153.68 74.9003C153.917 74.8133 154.063 74.4913 153.946 74.3296C153.327 73.475 152.691 72.562 152.046 71.5912C152.315 71.5375 152.583 71.473 152.849 71.3996C151.969 70.1224 151.066 68.7345 150.16 67.2359C150.036 67.0314 149.725 67.0314 149.6 67.2359C148.695 68.7345 147.792 70.1224 146.912 71.3996C147.178 71.473 147.445 71.5375 147.715 71.5912C147.071 72.562 146.434 73.475 145.815 74.3296C145.698 74.4913 145.844 74.8133 146.081 74.9003C146.318 74.9866 146.468 75.2943 146.348 75.4641C145.802 76.2386 145.265 76.9682 144.745 77.6544C144.632 77.8025 144.772 78.1415 145.001 78.2542C148.077 79.7665 151.684 79.7665 154.76 78.2542C154.989 78.1408 155.13 77.8025 155.016 77.6544"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.016 77.6544C154.496 76.9682 153.96 76.2386 153.413 75.4641C153.293 75.2943 153.444 74.9866 153.68 74.9003C153.917 74.8133 154.063 74.4913 153.946 74.3296C153.327 73.475 152.691 72.562 152.046 71.5912C152.315 71.5375 152.583 71.473 152.849 71.3996C151.969 70.1224 151.066 68.7345 150.16 67.2359C150.036 67.0314 149.725 67.0314 149.6 67.2359C148.695 68.7345 147.792 70.1224 146.912 71.3996C147.178 71.473 147.445 71.5375 147.715 71.5912C147.071 72.562 146.434 73.475 145.815 74.3296C145.698 74.4913 145.844 74.8133 146.081 74.9003C146.318 74.9866 146.468 75.2943 146.348 75.4641C145.802 76.2386 145.265 76.9682 144.745 77.6544C144.632 77.8025 144.772 78.1415 145.001 78.2542C148.077 79.7665 151.684 79.7665 154.76 78.2542C154.989 78.1408 155.13 77.8025 155.016 77.6544Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.537 78.3483C154.486 78.3333 154.432 78.3204 154.397 78.2946C153.396 78.5745 151.905 78.1893 150.905 77.9617C150.283 77.8204 148.839 77.4665 148.579 76.7892C148.276 75.9964 149.668 75.8456 150.144 75.656C151.378 75.1642 149.715 75.0514 149.191 74.9828C148.643 74.9108 146.749 74.7219 147.202 73.8374C147.739 72.7892 149.459 72.8225 150.339 72.1859C150.419 72.1282 150.421 72.0072 150.352 71.9366C149.966 71.55 149.091 71.8829 148.815 71.1594C148.64 70.7022 148.757 69.9604 148.841 69.4984C148.922 69.05 149.047 68.1363 149.621 67.5032C149.595 67.5045 149.074 68.1343 149.047 68.1363C148.328 69.2933 147.613 70.3829 146.912 71.3992C147.178 71.4733 147.445 71.5378 147.715 71.5915C147.07 72.5616 146.434 73.4746 145.815 74.3299C145.698 74.4909 145.844 74.8136 146.081 74.9006C146.318 74.9869 146.468 75.2946 146.348 75.4645C145.801 76.2382 145.265 76.9685 144.744 77.654C144.632 77.8021 144.772 78.1404 145 78.2539C148.005 79.7308 151.509 79.7545 154.537 78.3483"
      fill="#1B468D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.18 77.6544C134.659 76.9682 134.123 76.2386 133.576 75.4641C133.456 75.2943 133.606 74.9866 133.843 74.9003C134.08 74.8133 134.226 74.4913 134.108 74.3296C133.49 73.475 132.854 72.562 132.209 71.5912C132.478 71.5375 132.746 71.473 133.013 71.3996C132.132 70.1224 131.229 68.7345 130.323 67.2359C130.199 67.0314 129.888 67.0314 129.764 67.2359C128.858 68.7345 127.955 70.1224 127.074 71.3996C127.341 71.473 127.608 71.5375 127.878 71.5912C127.233 72.562 126.598 73.475 125.979 74.3296C125.861 74.4913 126.007 74.8133 126.244 74.9003C126.481 74.9866 126.631 75.2943 126.511 75.4641C125.964 76.2386 125.428 76.9682 124.907 77.6544C124.795 77.8025 124.935 78.1415 125.163 78.2542C128.24 79.7665 131.847 79.7665 134.923 78.2542C135.152 78.1408 135.293 77.8025 135.18 77.6544"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.18 77.6544C134.659 76.9682 134.123 76.2386 133.576 75.4641C133.456 75.2943 133.606 74.9866 133.843 74.9003C134.08 74.8133 134.226 74.4913 134.108 74.3296C133.49 73.475 132.854 72.562 132.209 71.5912C132.478 71.5375 132.746 71.473 133.013 71.3996C132.132 70.1224 131.229 68.7345 130.323 67.2359C130.199 67.0314 129.888 67.0314 129.764 67.2359C128.858 68.7345 127.955 70.1224 127.074 71.3996C127.341 71.473 127.608 71.5375 127.878 71.5912C127.233 72.562 126.598 73.475 125.979 74.3296C125.861 74.4913 126.007 74.8133 126.244 74.9003C126.481 74.9866 126.631 75.2943 126.511 75.4641C125.964 76.2386 125.428 76.9682 124.907 77.6544C124.795 77.8025 124.935 78.1415 125.163 78.2542C128.24 79.7665 131.847 79.7665 134.923 78.2542C135.152 78.1408 135.293 77.8025 135.18 77.6544Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.7 78.3483C134.649 78.3333 134.595 78.3204 134.56 78.2946C133.559 78.5745 132.068 78.1893 131.068 77.9617C130.446 77.8204 129.002 77.4665 128.742 76.7892C128.44 75.9964 129.831 75.8456 130.307 75.656C131.541 75.1642 129.878 75.0514 129.354 74.9828C128.807 74.9108 126.912 74.7219 127.365 73.8374C127.902 72.7892 129.622 72.8225 130.502 72.1859C130.583 72.1282 130.584 72.0072 130.515 71.9366C130.129 71.55 129.254 71.8829 128.978 71.1594C128.803 70.7022 128.92 69.9604 129.004 69.4984C129.085 69.05 129.21 68.1363 129.784 67.5032C129.758 67.5045 129.237 68.1343 129.21 68.1363C128.491 69.2933 127.776 70.3829 127.074 71.3992C127.341 71.4733 127.609 71.5378 127.878 71.5915C127.233 72.5616 126.598 73.4746 125.979 74.3299C125.861 74.4909 126.007 74.8136 126.244 74.9006C126.481 74.9869 126.631 75.2946 126.511 75.4645C125.964 76.2382 125.428 76.9685 124.907 77.654C124.795 77.8021 124.935 78.1404 125.163 78.2539C128.168 79.7308 131.672 79.7545 134.7 78.3483"
      fill="#1B468D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.055 77.6544C67.5347 76.9682 66.998 76.2386 66.4511 75.4641C66.3315 75.2943 66.4817 74.9866 66.7181 74.9003C66.9552 74.8133 67.1012 74.4913 66.9837 74.3296C66.3648 73.475 65.7289 72.562 65.0842 71.5912C65.3533 71.5375 65.6216 71.473 65.8879 71.3996C65.0075 70.1224 64.1039 68.7345 63.1984 67.2359C63.0741 67.0314 62.7636 67.0314 62.6393 67.2359C61.7337 68.7345 60.8302 70.1224 59.9497 71.3996C60.216 71.473 60.4837 71.5375 60.7534 71.5912C60.1087 72.562 59.4728 73.475 58.8539 74.3296C58.7364 74.4913 58.8825 74.8133 59.1196 74.9003C59.356 74.9866 59.5061 75.2943 59.3866 75.4641C58.8397 76.2386 58.303 76.9682 57.7826 77.6544C57.6698 77.8025 57.8105 78.1415 58.0394 78.2542C61.1155 79.7665 64.7222 79.7665 67.7989 78.2542C68.0272 78.1408 68.1678 77.8025 68.055 77.6544"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.055 77.6544C67.5347 76.9682 66.998 76.2386 66.4511 75.4641C66.3315 75.2943 66.4817 74.9866 66.7181 74.9003C66.9552 74.8133 67.1012 74.4913 66.9837 74.3296C66.3648 73.475 65.7289 72.562 65.0842 71.5912C65.3533 71.5375 65.6216 71.473 65.8879 71.3996C65.0075 70.1224 64.1039 68.7345 63.1984 67.2359C63.0741 67.0314 62.7636 67.0314 62.6393 67.2359C61.7337 68.7345 60.8302 70.1224 59.9497 71.3996C60.216 71.473 60.4837 71.5375 60.7534 71.5912C60.1087 72.562 59.4728 73.475 58.8539 74.3296C58.7364 74.4913 58.8825 74.8133 59.1196 74.9003C59.356 74.9866 59.5061 75.2943 59.3866 75.4641C58.8397 76.2386 58.303 76.9682 57.7826 77.6544C57.6698 77.8025 57.8105 78.1415 58.0394 78.2542C61.1155 79.7665 64.7222 79.7665 67.7989 78.2542C68.0272 78.1408 68.1678 77.8025 68.055 77.6544Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.5754 78.3483C67.5245 78.3333 67.4701 78.3204 67.4355 78.2946C66.4348 78.5745 64.9436 78.1893 63.9436 77.9617C63.3213 77.8204 61.877 77.4665 61.6182 76.7892C61.3152 75.9964 62.7065 75.8456 63.1821 75.656C64.4164 75.1642 62.7541 75.0514 62.2296 74.9828C61.6821 74.9108 59.7874 74.7219 60.2405 73.8374C60.7772 72.7892 62.498 72.8225 63.3777 72.1859C63.4579 72.1282 63.4599 72.0072 63.3906 71.9366C63.0048 71.55 62.1298 71.8829 61.8533 71.1594C61.6787 70.7022 61.7948 69.9604 61.8791 69.4984C61.9613 69.05 62.0863 68.1363 62.6597 67.5032C62.6332 67.5045 62.1121 68.1343 62.0863 68.1363C61.3662 69.2933 60.6515 70.3829 59.9497 71.3992C60.216 71.4733 60.4844 71.5378 60.7534 71.5915C60.1087 72.5616 59.4728 73.4746 58.8539 74.3299C58.7364 74.4909 58.8825 74.8136 59.1196 74.9006C59.356 74.9869 59.5061 75.2946 59.3866 75.4645C58.8397 76.2382 58.303 76.9685 57.7826 77.654C57.6698 77.8021 57.8105 78.1404 58.0394 78.2539C61.0428 79.7308 64.5476 79.7545 67.5754 78.3483"
      fill="#1B468D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.2179 77.6544C47.6976 76.9682 47.1609 76.2386 46.614 75.4641C46.4944 75.2943 46.6446 74.9866 46.881 74.9003C47.1181 74.8133 47.2641 74.4913 47.1473 74.3296C46.5277 73.475 45.8919 72.562 45.2472 71.5912C45.5162 71.5375 45.7845 71.473 46.0508 71.3996C45.1704 70.1224 44.2675 68.7345 43.362 67.2359C43.237 67.0314 42.9265 67.0314 42.8022 67.2359C41.8966 68.7345 40.9931 70.1224 40.1133 71.3996C40.3789 71.473 40.6466 71.5375 40.9163 71.5912C40.2716 72.562 39.6357 73.475 39.0169 74.3296C38.8993 74.4913 39.0454 74.8133 39.2825 74.9003C39.5189 74.9866 39.669 75.2943 39.5495 75.4641C39.0026 76.2386 38.4666 76.9682 37.9462 77.6544C37.8328 77.8025 37.9734 78.1415 38.2023 78.2542C41.2784 79.7665 44.8851 79.7665 47.9618 78.2542C48.1908 78.1408 48.3307 77.8025 48.2179 77.6544"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.2179 77.6544C47.6976 76.9682 47.1609 76.2386 46.614 75.4641C46.4944 75.2943 46.6446 74.9866 46.881 74.9003C47.1181 74.8133 47.2641 74.4913 47.1473 74.3296C46.5277 73.475 45.8919 72.562 45.2472 71.5912C45.5162 71.5375 45.7845 71.473 46.0508 71.3996C45.1704 70.1224 44.2675 68.7345 43.362 67.2359C43.237 67.0314 42.9265 67.0314 42.8022 67.2359C41.8966 68.7345 40.9931 70.1224 40.1133 71.3996C40.3789 71.473 40.6466 71.5375 40.9163 71.5912C40.2716 72.562 39.6357 73.475 39.0169 74.3296C38.8993 74.4913 39.0454 74.8133 39.2825 74.9003C39.5189 74.9866 39.669 75.2943 39.5495 75.4641C39.0026 76.2386 38.4666 76.9682 37.9462 77.6544C37.8328 77.8025 37.9734 78.1415 38.2023 78.2542C41.2784 79.7665 44.8851 79.7665 47.9618 78.2542C48.1908 78.1408 48.3307 77.8025 48.2179 77.6544Z"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.7383 78.3483C47.6874 78.3333 47.633 78.3204 47.5984 78.2946C46.5977 78.5745 45.1065 78.1893 44.1065 77.9617C43.4842 77.8204 42.04 77.4665 41.7811 76.7892C41.4781 75.9964 42.8694 75.8456 43.345 75.656C44.5794 75.1642 42.917 75.0514 42.3925 74.9828C41.845 74.9108 39.9503 74.7219 40.4034 73.8374C40.9408 72.7892 42.6609 72.8225 43.5413 72.1859C43.6208 72.1282 43.6228 72.0072 43.5535 71.9366C43.1677 71.55 42.2927 71.8829 42.0162 71.1594C41.8416 70.7022 41.9578 69.9604 42.042 69.4984C42.1242 69.05 42.2492 68.1363 42.8226 67.5032C42.7961 67.5045 42.275 68.1343 42.2492 68.1363C41.5291 69.2933 40.8144 70.3829 40.1133 71.3992C40.3789 71.4733 40.6473 71.5378 40.9163 71.5915C40.2716 72.5616 39.6357 73.4746 39.0169 74.3299C38.8993 74.4909 39.0454 74.8136 39.2825 74.9006C39.5189 74.9869 39.669 75.2946 39.5495 75.4645C39.0026 76.2382 38.4666 76.9685 37.9462 77.654C37.8328 77.8021 37.9734 78.1404 38.2023 78.2539C41.2057 79.7308 44.7105 79.7545 47.7383 78.3483"
      fill="#1B468D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.333 31.4512C140.99 31.4559 141.773 31.9247 142.221 32.3737C142.549 32.7019 142.807 33.0979 142.979 33.5286C143.043 33.691 143.425 34.6489 143.055 34.6149C142.373 34.5531 141.726 34.2005 141.29 33.674C141.03 33.3615 140.839 32.9967 140.684 32.6224C140.56 32.3214 140.278 31.7745 140.333 31.4512"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.3722 58.996C73.4517 59.6883 71.1894 58.6991 70.7608 62.3819L70.468 58.7739C70.468 58.7739 75.2927 58.3038 74.3722 58.996"
      fill="#1B468D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.2713 58.996C34.3508 59.6883 32.0885 58.6991 31.6592 62.3819L31.3671 58.7739C31.3671 58.7739 36.1918 58.3038 35.2713 58.996"
      fill="#1B468D"
    />
    <path
      d="M104.494 77.5007C104.494 77.5007 103.984 83.1746 101.984 86.5535C99.9836 89.9324 98.9836 95.0007 98.9836 95.0007"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.3338 77.5007C89.3338 77.5007 87.9836 82.3299 85.9836 85.7088C83.9836 89.0877 81.9836 95.0007 81.9836 95.0007"
      stroke="#1B468D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.794 19.2175C123.832 19.2175 126.294 16.7551 126.294 13.7175C126.294 10.68 123.832 8.21753 120.794 8.21753C117.757 8.21753 115.294 10.68 115.294 13.7175C115.294 16.7551 117.757 19.2175 120.794 19.2175Z"
      fill="#F1FAFF"
    />
  </svg>
);

// HomeSvg.propTypes = {
//   height: PropTypes.number,
//   width: PropTypes.number,
// };

// HomeSvg.defaultProps = {
//   height: 142,
//   width: 251,
// };

export default HomesDisplayIcon;
