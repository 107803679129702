import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BasicInfoWrapper from './BasicInfo.Style';
import NoImage from '../../icons/NoImage';

const BasicInfo = ({
  firstName,
  lastName,
  avatar,
  handleChange,
  handleImageUpload,
  handleEditImage,
}) => {
  const avatarStyle = {
    backgroundImage: `url(${avatar}?width=220)`,
  };

  const [imageFile, setImageFile] = useState(null);

  const imageSelected = e => {
    const files = Array.from(e.target.files);
    const file = files[0];
    setImageFile(file);
    handleImageUpload(file);
  };

  return (
    <BasicInfoWrapper>
      <div className="profile-avatar">
        {!avatar ? <NoImage /> : <div className="avatar" style={avatarStyle} />}
        <div className="upload-container">
          <button type="button">UPLOAD PHOTO</button>
          <input
            type="file"
            className="upload-image"
            name="upload-image"
            onChange={imageSelected}
          />
          {imageFile && (
            <button
              className="adjust-button"
              onClick={() => handleEditImage(imageFile)}
            >
              ADJUST
            </button>
          )}
        </div>
      </div>
      <div className="form-wrap">
        <form id="basicInfoForm">
          <div>
            <label className="label" htmlFor="FirstName">
              First Name
              <input
                type="text"
                name="FirstName"
                id="FirstName"
                value={firstName}
                onChange={handleChange}
              />
            </label>
          </div>
          <div>
            <label className="label" htmlFor="LastName">
              Last Name
              <input
                type="text"
                name="LastName"
                id="LastName"
                value={lastName}
                onChange={handleChange}
              />
            </label>
          </div>
        </form>
      </div>
    </BasicInfoWrapper>
  );
};

BasicInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  handleEditImage: PropTypes.func.isRequired,
};

BasicInfo.defaultProps = {
  firstName: '',
  lastName: '',
  avatar: '',
};

export default BasicInfo;
