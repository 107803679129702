import styled from 'styled-components';

const StyledAccordianToggle = styled.div`
  .accordian-icon-open {
    transform: rotate(-180deg);
  }

  .accordian-icon {
    margin-left: 10px;
  }

  .accordian-label {
    display: flex;
    justify-content: space-between;
  }
`;

export default StyledAccordianToggle;
