import styled from 'styled-components';

const StyledSettings = styled.div`
  padding: 0 20px 20px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    padding: 0 100px 50px;
  }

  select {
    border-radius: 1px;
    border: 1px solid #e4e5e7;
    font-size: 15px;
    line-height: 140%;
    font-family: source-sans-pro, sans-serif;
    flex: 0 1 396px;
    padding: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-top: 5px;
    border-radius: 1px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-color: transparent;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    min-width: 245px;
  }

  .button-container {
    max-width: 690px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      display: flex;
    }
  }

  .button {
    background: #00b0ac;
    height: 45px;
    box-shadow: 0 9px 18px -12px #10a29f;
    border: solid 1px var(--kiddie-pool);
    color: white;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 25px;
    &:hover {
      opacity: 0.9;
    }
    &:disabled {
      background: #e4e5e7;
      box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
      cursor: not-allowed;
    }
    min-width: 246px;
  }

  input,
  textarea {
    color: #4e5255;
    padding: 1em;
    border: solid 1px #e4e5e7;
    display: block;
    font-size: 15px;
    min-width: 215px;
    width: 90%;
  }

  .large-textarea {
    min-height: 150px;
  }

  .small-textarea {
    min-height: 100px;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 0;
    display: block;
  }

  h4 {
    line-height: 1.4;
    color: #4e5255;
    margin: 0 0 40px;
    padding: 0;
    max-width: 460px;
  }

  .form-submit-loading {
    display: block;
    margin: 20% 0 0;
  }

  .save-btn {
    margin-top: 20px;
  }

  .basic-info-container {
    position: relative;
  }

  .section-header {
    padding: 0px;
    padding-top: 51px;
    padding-bottom: 1em;
    font-size: 15px;
    color: #4e5255;
    max-width: 690px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .row {
    display: flex;
    width: 90%;
  }

  p {
    margin: 0;
    padding: 0px;
    font-size: 15px;
    color: #777b7e;
  }

  .sub-heading {
    color: #4e5255;
  }

  label {
  }

  .loader {
    margin-top: 0;
  }

  .character-count {
    font-size: 13px;
  }

  .settings-item {
    margin-bottom: 16px;
  }

  .error {
    color: #ff0000;
    font-size: 12px;
    height: 16px;
  }

  .toggle-label {
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    margin-bottom: 0;
    display: block;
  }

  .short-toggle {
    justify-content: start;
  }
`;

export default StyledSettings;
