import styled from 'styled-components';

const StyledSiteItemCard = styled.div`
  width: 74%;
  /* max-width: 860px; */
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
  display: block;
  flex-wrap: row wrap;
  flex: 1;
  justify-content: space-between;
  padding: 22px;
  margin-bottom: 26px;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    display: flex;
  }

  .featured {
    background-color: #00408c;
    color: white;
    font: 400 11px/100% source-sans-pro;
    letter-spacing: 1px;
    text-align: center;
    padding: 4px;
    width: 57px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .publish-date {
    flex: 0 1 45%;
    max-width: 283px;
    margin: 18px auto 0;

    p {
      font: 400 11px/100% source-sans-pro;
      color: #414042;
      letter-spacing: 1px;

      .bullet {
        margin-right: 8px;
        font: 400 24px/100% arial, helvetica, sans-serif;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .publish-date-mobile {
    text-align: left;

    @media screen and (min-width: 767px) {
      display: none;
    }
  }

  .publish-date-desktop {
    text-align: right;
    display: none;

    @media screen and (min-width: 767px) {
      display: block;
    }
  }

  .published {
    color: #25292d !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .unpublished {
    color: #a1a3a6;
    font-size: 12px;
  }

  .gray-bullet {
    color: #cacccf;
  }

  .green-bullet {
    color: #00b0ac;
  }

  .img-container {
    flex: 0 1 25%;
    max-width: 283px;
    margin: 0 auto 26px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .card-content {
    flex: 0 1 45%;
    max-width: 283px;
    margin: 18px auto 0;

    .headline {
      font: 600 16px/100% source-sans-pro;
      color: #25292d;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 6px;
    }

    .copy {
      font: 400 14px/143% source-sans-pro;
      color: #777b7e;
      margin-bottom: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    a {
      font: normal 11px/100% source-sans-pro;
      color: #777b7e;
    }

    svg {
      padding-right: 10px;
    }
  }

  .card-caboose {
    max-width: 283px;
    margin: 0 auto;
    flex: 0 1 20%;

    .controls-area {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin-top: 72px;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        margin-top: 36px;
      }

      a,
      button {
        font: 600 14px/100% source-sans-pro;
        letter-spacing: 1px;
        color: #0075c9;
        border: none;
        padding: 0;
        background-color: transparent;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default StyledSiteItemCard;
