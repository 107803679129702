let PDFJS;

(async function() {
  PDFJS = await import('pdfjs-dist/webpack');
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker');
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
})();

const readFileData = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result);
    };
    reader.onerror = err => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64
export const convertPdfToImages = async file => {
  console.log('convertPdfToImages');
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement('canvas');
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext('2d', { preserveDrawingBuffer: true });
    canvas.height =
      viewport.height && viewport.height >= 600 ? viewport.height : 600;
    canvas.width =
      viewport.width && viewport.width >= 900 ? viewport.width : 900;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    const res = canvas.toDataURL('image/jpeg');
    images.push(res);
  }

  canvas.remove();
  return images;
};

export default {};
