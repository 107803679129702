import React from 'react';

const Checkmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <path
        fill="#00B0AC"
        fillRule="nonzero"
        d="M9.582.864c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm-1.8 13.5l-4.5-4.5 1.269-1.269 3.231 3.222 6.831-6.83 1.269 1.277-8.1 8.1z"
      />
    </svg>
  );
};

export default Checkmark;
