import React, { useContext, useEffect } from 'react';
import { HomesContext } from './Homes';
import SearchBar from './SearchBar';
import HomeListingCard from './HomeListingCard';
import SaleHomeListingCard from './SaleHomeListingCard';
import LandListingCard from './LandListingCard';
import LoadingDots from './LoadingDots';
import Error from './Error';
import ProgressBar from './ProgressBar';
import { MAX_SALE_HOMES_COUNT } from '../constants';
import { canViewLandRecords } from '../permissions';
import { UserContext } from '../components/User';
import { Redirect } from 'react-router-dom';
import { analyticsSendEvent } from '../utils';

export const OnDisplayList = () => {
  const {
    isLoading,
    isError,
    error,
    inventoryHomes,
    filterHomes,
    query,
    rooms,
    updateHomeAttribute,
    getErrors,
  } = useContext(HomesContext);

  return (
    <>
      {(query || inventoryHomes.length > 0) && (
        <SearchBar
          searchQuery={query}
          searchAction={filterHomes}
          searchText="a home"
        />
      )}
      {/*  TODO: better error handling */}
      {isError && (
        <Error message="Inventory List could not be loaded." error={error} />
      )}
      {isLoading && <LoadingDots />}
      {query && inventoryHomes.length === 0 && (
        // TODO: could improve language here
        <p>No homes were found for this search.</p>
      )}
      {inventoryHomes.length > 0 &&
        inventoryHomes.map(home => {
          return (
            <HomeListingCard
              title={home.Name}
              to={{
                pathname: `/home/${home.Id ? home.Id : home.InventoryId}/info`,
                state: {
                  backLink: { path: '/homes/on-display', text: 'LISTINGS' },
                },
              }}
              labels={[
                { text: 'Stock #', value: home.StockNumber },
                { text: 'Model #', value: home.ModelNumber },
                { text: 'Serial #', value: home.SerialNumber },
              ]}
              updateAttribute={(attribute, value, info) => {
                if (attribute === 'IsPublished') {
                  analyticsSendEvent('on_display_visible', {
                    status: value ? 'on' : 'off',
                  });
                }
                if (attribute === 'IsSaleHome') {
                  analyticsSendEvent('on_display_sale', {
                    status: value ? 'on' : 'off',
                  });
                }
                if (attribute == 'StickerTypeId') {
                  analyticsSendEvent('on_display_sticker', {
                    stickerId: value,
                    stickerName: info,
                  });
                }
                updateHomeAttribute(home, attribute, value);
              }}
              key={home.Id}
              home={home}
              rooms={rooms}
              getErrors={getErrors}
            />
          );
        })}
    </>
  );
};

export const AvailableList = () => {
  const {
    isLoading,
    isError,
    error,
    modelHomes,
    filterHomes,
    query,
    updateHomeAttribute,
    getErrors,
  } = useContext(HomesContext);

  return (
    <>
      {(query || modelHomes.length > 0) && (
        <SearchBar
          searchQuery={query}
          searchAction={filterHomes}
          searchText="a home"
        />
      )}
      {/* TODO: maybe add a loading icon */}
      {/*  TODO: better error handling */}
      {isError && (
        <Error message="Model List could not be loaded." error={error} />
      )}
      {isLoading && <LoadingDots />}
      {query && modelHomes.length === 0 && (
        // TODO: could improve language here
        <p>No homes were found for this search.</p>
      )}
      {modelHomes.length > 0 &&
        modelHomes.map(home => (
          <HomeListingCard
            title={home.Name}
            to={{
              pathname: `/home/${home.Id}/info`,
              state: {
                backLink: {
                  path: '/homes/available-floor-plans',
                  text: 'LISTINGS',
                },
              },
            }}
            labels={[{ text: 'Model #', value: home.ModelNumber }]}
            updateAttribute={(attribute, value) => {
              updateHomeAttribute(home, attribute, value);
            }}
            key={home.Id}
            home={home}
            className="available"
            getErrors={getErrors}
          />
        ))}
    </>
  );
};

export const SaleList = () => {
  const {
    isLoading,
    isError,
    error,
    saleHomes,
    suggestedSaleHomes,
    notSaleHomes,
    updateHomeAttribute,
    getErrors,
  } = useContext(HomesContext);

  const saleHomeList = saleHomes.map(home => (
    <SaleHomeListingCard
      title={home.Name}
      to={{
        pathname: `/home/${home.Id}/info`,
        state: { backLink: { path: '/homes/sale', text: 'LISTINGS' } },
      }}
      labels={[
        { text: 'Stock #', value: home.StockNumber },
        { text: 'Model #', value: home.ModelNumber },
        { text: 'Serial #', value: home.SerialNumber },
      ]}
      updateAttribute={(attribute, value) => {
        if (attribute === 'IsSaleHome') {
          analyticsSendEvent('sale_homes_sale', {
            status: value ? 'on' : 'off',
          });
        }
        updateHomeAttribute(home, attribute, value);
      }}
      key={`sale-${home.Id}`}
      home={home}
      getErrors={getErrors}
    />
  ));

  const suggestedSaleHomeList = suggestedSaleHomes.map(home => (
    <SaleHomeListingCard
      title={home.Name}
      to={{
        pathname: `/home/${home.Id}/info`,
        state: { backLink: { path: '/homes/sale', text: 'LISTINGS' } },
      }}
      labels={[
        { text: 'Stock #', value: home.StockNumber },
        { text: 'Model #', value: home.ModelNumber },
        { text: 'Serial #', value: home.SerialNumber },
      ]}
      updateAttribute={(attribute, value) => {
        if (attribute === 'IsSaleHome') {
          analyticsSendEvent('sale_homes_sale', {
            status: value ? 'on' : 'off',
          });
        }
        updateHomeAttribute(home, attribute, value);
      }}
      key={`suggested-${home.Id}`}
      home={home}
      getErrors={getErrors}
    />
  ));

  const otherHomeList = notSaleHomes.map(home => (
    <SaleHomeListingCard
      title={home.Name}
      to={{
        pathname: `/home/${home.Id}/info`,
        state: { backLink: { path: '/homes/sale', text: 'LISTINGS' } },
      }}
      labels={[
        { text: 'Stock #', value: home.StockNumber },
        { text: 'Model #', value: home.ModelNumber },
        { text: 'Serial #', value: home.SerialNumber },
      ]}
      updateAttribute={(attribute, value) => {
        if (attribute === 'IsSaleHome') {
          analyticsSendEvent('sale_homes_sale', {
            status: value ? 'on' : 'off',
          });
        }
        updateHomeAttribute(home, attribute, value);
      }}
      key={`other-${home.Id}`}
      home={home}
      getErrors={getErrors}
    />
  ));

  return (
    <>
      {/*  TODO: better error handling */}
      {isError && (
        <Error message="Sale Homes List could not be loaded." error={error} />
      )}
      {isLoading && <LoadingDots />}
      <>
        <p className="sale-homes-text">
          Your website will always display at least 5 sale homes. If 5 are not
          selected, your website will automatically add your oldest stock units
          to the sale homes page. To remove the auto-display homes you must
          manually select other homes to include on the sale homes page.
        </p>
        {saleHomeList.length > 0 && (
          <div className="sale-homes-container">
            <h3>Current sale homes</h3>
            <ProgressBar
              max={MAX_SALE_HOMES_COUNT}
              current={saleHomeList.length}
            />
            {/* <p className="sale-homes-text">
              Your website will always display at least 5 sale homes. If 5 are
              not selected, your website will automatically add your oldest
              stock units to the sale homes page. To remove the auto-display
              homes you must manually select other homes to include on the sale
              homes page.
            </p> */}
            {saleHomeList}
          </div>
        )}
        {suggestedSaleHomeList.length > 0 && (
          <div className="sale-homes-container">
            <h3>Suggested sale homes</h3>
            {suggestedSaleHomeList}
          </div>
        )}
        {otherHomeList.length > 0 && (
          <div className="sale-homes-container">
            <h3>All other homes</h3>
            {otherHomeList}
          </div>
        )}
      </>
    </>
  );
};

export const LandList = () => {
  const {
    isLoading,
    isError,
    error,
    homesWithLand,
    filterLand,
    updateHomeAttribute,
    getErrors,
    query,
  } = useContext(HomesContext);

  const { activeLot } = useContext(UserContext);

  if (activeLot && !canViewLandRecords(activeLot.LotNumber))
    return <Redirect to="/homes/on-display/" />;

  return (
    <>
      {(query || homesWithLand.length > 0) && (
        <SearchBar
          searchQuery={query}
          searchAction={filterLand}
          searchText="land"
        />
      )}
      {/* TODO: maybe add a loading icon */}
      {/*  TODO: better error handling */}
      {isError && (
        <Error message="Land List could not be loaded." error={error} />
      )}
      {isLoading && <LoadingDots />}
      {query && homesWithLand.length === 0 && (
        // TODO: could improve language here
        <p>No land records were found for this search.</p>
      )}
      {homesWithLand.length === 0 && (
        <p>There are no land records to display at this time.</p>
      )}
      {homesWithLand.length > 0 &&
        homesWithLand.map(land => {
          return (
            <LandListingCard
              key={land.InventoryId}
              title={land.StockNumber}
              to={{
                pathname: `/land/${land.InventoryId}/info`,
                state: {
                  backLink: {
                    path: '/homes/land',
                    text: 'LISTINGS',
                  },
                },
              }}
              labels={[
                {
                  text: 'Stock #',
                  value: land.StockNumber ? land.StockNumber : '',
                },
                { text: 'Acres', value: land.Acres === 0 ? '' : land.Acres },
              ]}
              land={land}
              className="land"
              updateAttribute={(attribute, value) => {
                if (attribute === 'IsPublished') {
                  analyticsSendEvent('land_inventory_visible', {
                    status: value ? 'on' : 'off',
                  });
                }
                updateHomeAttribute(land, attribute, value);
              }}
              getErrors={getErrors}
            />
          );
        })}
    </>
  );
};
