import React from 'react';

const SearchIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeWidth="2"
      transform="rotate(45 4.445 7.738)"
    >
      <path
        stroke="#AFAFAF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.562 8.215v3.448"
      />
      <circle cx="3.562" cy="3.651" r="3.154" stroke="#B3B3B3" />
    </g>
  </svg>
);

export default SearchIcon;
