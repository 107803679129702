import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import StyledSiteItem from './SiteItem.Style';
import ImageUploader from '../ImageUploader/ImageUploader';
import Modal from '../Modal';
import ToastSave from '../ToastSave';
import LoadingDots from '../LoadingDots';
import Checkmark from '../../icons/Checkmark';
import { SiteItemsContext } from './SiteItemsProvider';
import { UserContext } from '../User';
import { SAVE_TOAST_OPTIONS } from '../../constants';
import CalendarModal from './CalendarModal';
import LibraryImagesModal from './LibraryImagesModal';
import { formatDate, insertTextAtCaret, characterCount } from '../../utils';
import ZoneSelector from '../ZoneSelector/ZoneSelector';
import MultiCheckBox from '../MultiCheckBox/MultiCheckBox';
import { analyticsSendEvent } from '../../utils';

const ContentSiteItem = ({
  match: {
    params: { type, id: contentSiteItemId, bulkMode },
  },
  history,
}) => {
  const {
    activeContentItem,
    stagedContentItem,
    isLoading,
    setActiveItemId,
    updateStagedContentItem,
    updateContentItem,
    deleteContentItemById,
    stagedContentItemUpdated,
    isNavigationModalVisible,
    showNavigationModal,
    hideNavigationModal,
    isDeleteModalVisible,
    showDeleteModal,
    hideDeleteModal,
    isLibraryImagesModalVisible,
    showLibraryImagesModal,
    hideLibraryImagesModal,
    setConfirmNavigation,
    confirmedNavigation,
    targetLocation,
    isStagedContentItemValid,
    validateLinkUrl,
    uploadItemImage,
    uploadItemFeaturedImage,
    libraryImages,
    imageIsLoading,
    events,
  } = useContext(SiteItemsContext);

  const { activeLot, user } = useContext(UserContext);
  const [toolTip, setShowToolTip] = useState(false);
  const [showIsFeatured, setShowIsFeatured] = useState(false);
  const [linkUrlError, setLinkUrlError] = useState('');
  const [isFeaturedError, setIsFeaturedError] = useState('');
  const [showIsFeaturedError, setShowIsFeaturedError] = useState(false);
  const [linkOptions, setLinkOptions] = useState([
    {
      id: 'Sale Homes',
      label: 'Sale Homes',
      checked: false,
      linkText: 'View Sale Homes',
      linkUrl: '/sale-homes',
    },
    {
      id: 'Special Offers',
      label: 'Special Offers',
      checked: false,
      linkText: 'View Special Offers',
      linkUrl: '/special-offers',
    },
    {
      id: 'Contact Us',
      label: 'Contact Us',
      checked: false,
      linkText: 'Contact Us',
      linkUrl: '/contact-us',
    },
    {
      id: 'Available Homes',
      label: 'Available Homes',
      checked: false,
      linkText: 'View Available Homes',
      linkUrl: '/homes',
    },
    {
      id: 'Homes On Display',
      label: 'Homes On Display',
      checked: false,
      linkText: 'View Homes On Display',
      linkUrl: '/mobile-manufactured-homes-on-display',
    },
    {
      id: 'Homes Under $150k',
      label: 'Homes Under $150k',
      checked: false,
      linkText: 'View Homes Under $150k',
      linkUrl: '/mobile-manufactured-homes-under-150k',
    },
    {
      id: 'Move-In Ready Homes',
      label: 'Move-In Ready Homes',
      checked: false,
      linkText: 'View Move-In Ready Homes',
      linkUrl: '/mobile-manufactured-homes-with-land',
    },
    {
      id: 'Other',
      label: 'Other',
      checked: false,
      linkText: null,
      linkUrl: null,
    },
    {
      id: 'None',
      label: 'None',
      checked: true,
      linkText: '',
      linkUrl: '',
    },
  ]);
  const [calendarModalMode, setCalendarModalMode] = useState('hide');
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);

  // after stagedContentItem changes, update the link options to set the correct one as checked
  useEffect(() => {
    if (!stagedContentItem.LinkUrl) return;

    // remove domain from url (server adds domain, but our link options just have paths)
    const curLinkPath = stagedContentItem.LinkUrl.replace(/^.*\/\/[^\/]+/, '');

    // if no items selected, and linkurl is not blank, select other
    const pathIsInLinkOptions =
      linkOptions.find(function(linkOption) {
        return (
          linkOption &&
          linkOption.linkUrl !== '' &&
          linkOption.linkUrl === curLinkPath
        );
      }) != undefined;

    const otherSelected =
      !pathIsInLinkOptions && stagedContentItem.LinkUrl !== '';

    // update the linkOptions
    let newLinkOptions = [...linkOptions];
    newLinkOptions.forEach(linkOption => {
      // if this item matches cur link url, check it
      linkOption.checked = curLinkPath === linkOption.linkUrl;

      // if other should be selected, select it
      if (otherSelected && linkOption.id === 'Other') {
        linkOption.checked = true;
      }

      // if the link path is empty, select none
      if (linkOption.id === 'None') {
        linkOption.checked =
          !otherSelected && (!curLinkPath || curLinkPath === '');
      }
    });

    // save new option state
    setLinkOptions(newLinkOptions);
  }, [stagedContentItem]);

  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser && bulkMode) {
      history.push('/');
    }
  }, [user]);

  const validateLinkOptions = () => {
    if (isLinkRequired()) {
      if (!stagedContentItem.LinkText || stagedContentItem.LinkText === '') {
        return false;
      }
      if (!stagedContentItem.LinkUrl || stagedContentItem.LinkUrl === '') {
        return false;
      }
    }
    return true;
  };

  const isLinkRequired = () => {
    return (
      linkOptions.length > 0 && linkOptions.find(o => o.id === 'Other').checked
    );
  };

  const showLinkInputs =
    linkOptions.length > 0 &&
    linkOptions.find(option => option.id === 'Other').checked;

  // if bulk mode, check that user has perm
  useEffect(() => {
    if (!user.loading && !user.IsCorporateOfficeUser && bulkMode) {
      history.push('/');
    }
  }, [user]);

  useEffect(() => {
    if (
      !bulkMode &&
      events &&
      events.filter(event => event.IsFeatured === true).length > 0
    ) {
      setIsFeaturedError('You may not exceed 1 featured event.');
    }
  }, [events]);

  useEffect(() => {
    setShowIsFeatured(stagedContentItem && stagedContentItem.IsFeatured);
  }, [stagedContentItem]);

  useEffect(() => {
    if (!validateLinkUrl) {
      setLinkUrlError('Please enter a full URL');
    }
    if (validateLinkUrl) {
      setLinkUrlError('');
    }
  }, [validateLinkUrl]);

  const summaryRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const contentRef = useRef(null);
  const disclaimereRef = useRef(null);
  const linkTextRef = useRef(null);

  const showTooltip = () => {
    setShowToolTip(!toolTip);
  };

  const handleChange = event => {
    updateStagedContentItem({
      ...stagedContentItem,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  const handleBulkZoneChange = (zones, dealerIds) => {
    updateStagedContentItem({
      ...stagedContentItem,
      DealerIds: dealerIds,
    });
  };

  const hasOtherFeaturedEvent = excludeItem => {
    const res =
      events &&
      events.filter(item => {
        // skip item to exclude
        if (
          excludeItem.HomeCenterWebsiteContentId ===
            item.HomeCenterWebsiteContentId ||
          bulkMode
        )
          return false;

        return (
          (item.EndDate == null || Date.parse(item.EndDate) >= Date.now()) &&
          item.IsFeatured === true
        );
      });

    return res.length > 0;
  };

  const handleCheckboxChange = event => {
    if (hasOtherFeaturedEvent(stagedContentItem)) {
      setShowIsFeaturedError(true);
    } else {
      updateStagedContentItem({
        ...stagedContentItem,
        [event.currentTarget.id]: event.currentTarget.checked,
      });
      setShowIsFeatured(!showIsFeatured);
    }
  };

  const updatePublishDate = newDate => {
    updateStagedContentItem({
      ...stagedContentItem,
      StartDate: newDate,
    });
  };

  const updateExpirationDate = newDate => {
    updateStagedContentItem({
      ...stagedContentItem,
      EndDate: newDate,
    });
  };

  const updateStatus = newStatus => {
    updateStagedContentItem(
      {
        ...stagedContentItem,
        IsPublished: newStatus,
      },
      setShowToolTip(!toolTip)
    );
  };

  const handleSave = useCallback(() => {
    updateContentItem({
      ...stagedContentItem,
      IsPublished: true,
      LinkText:
        stagedContentItem.LinkText === null ||
        stagedContentItem.LinkText === undefined
          ? null
          : stagedContentItem.LinkText,
      LinkUrl:
        stagedContentItem.LinkUrl === null ||
        stagedContentItem.LinkUrl === undefined
          ? null
          : stagedContentItem.LinkUrl,
      Subtitle:
        stagedContentItem.Subtitle === null ||
        stagedContentItem.Subtitle === undefined
          ? null
          : stagedContentItem.Subtitle,
      FeaturedImage:
        stagedContentItem.FeaturedImage === null ||
        stagedContentItem.FeaturedImage === undefined ||
        !showIsFeatured
          ? null
          : stagedContentItem.FeaturedImage,
      Disclaimer:
        stagedContentItem.Disclaimer === null ||
        stagedContentItem.Disclaimer === undefined
          ? null
          : stagedContentItem.Disclaimer,
      Content: stagedContentItem.Content.replace(/\n/g, '<br>'),
      HomeCenterWebsiteContentType: type === 'events' ? 1 : 2,
    }).then(response => {
      // might add check for success

      setIsSaveModalVisible(false);

      if (stagedContentItem.HomeCenterWebsiteContentId === 'new') {
        if (
          stagedContentItem.HomeCenterWebsiteContentType === 'News' ||
          stagedContentItem.HomeCenterWebsiteContentType === 2
        ) {
          analyticsSendEvent('news_add_new');
        }
        if (
          stagedContentItem.HomeCenterWebsiteContentType === 'Events' ||
          stagedContentItem.HomeCenterWebsiteContentType === 1
        ) {
          analyticsSendEvent('events_add_new');
        }
        setConfirmNavigation(true);
        setTimeout(() => {
          hideNavigationModal();
          history.push(`/content/${type}`);
        }, 0);
      } else {
        if (
          stagedContentItem.HomeCenterWebsiteContentType === 'News' ||
          stagedContentItem.HomeCenterWebsiteContentType === 2
        ) {
          analyticsSendEvent('news_edit');
        }
        if (
          stagedContentItem.HomeCenterWebsiteContentType === 'Events' ||
          stagedContentItem.HomeCenterWebsiteContentType === 1
        ) {
          analyticsSendEvent('events_edit');
        }
      }
      if (targetLocation) {
        setConfirmNavigation(true);
        setTimeout(() => {
          hideNavigationModal();
          history.push(targetLocation);
        }, 0);
      }
    });
  }, [
    hideNavigationModal,
    history,
    setConfirmNavigation,
    stagedContentItem,
    targetLocation,
    updateContentItem,
  ]);

  const handleDiscard = () => {
    updateStagedContentItem({ ...activeContentItem });
    setConfirmNavigation(true);
    setTimeout(() => {
      hideNavigationModal();
      history.push(targetLocation);
    }, 0);
  };

  const handleDelete = () => {
    deleteContentItemById(contentSiteItemId);
    hideDeleteModal();
    setTimeout(() => {
      history.push(`/content/${type}`);
    }, 0);
  };

  const handleRemoveImage = () => {
    updateStagedContentItem({
      ...stagedContentItem,
      MainImage: null,
    });
  };

  const handleRemoveFeaturedImage = () => {
    updateStagedContentItem({
      ...stagedContentItem,
      FeaturedImage: null,
    });
  };

  const handleUpload = imageData => {
    uploadItemImage(imageData, stagedContentItem);
  };

  const handleFeaturedUpload = imageData => {
    uploadItemFeaturedImage(imageData, stagedContentItem);
  };

  const handleLibraryImageSelect = url => {
    updateStagedContentItem({
      ...stagedContentItem,
      MainImage: url,
    });
    hideLibraryImagesModal();
  };

  const {
    MainImage,
    FeaturedImage,
    IsFeatured,
    Title,
    Content,
    Subtitle,
    Summary,
    Disclaimer,
    Headline,
    LinkUrl,
    LinkText,
    IsPublished,
    StartDate,
    EndDate,
  } = stagedContentItem;

  const isPublishButtonDisabled = () => {
    if (type !== 'news' && !EndDate) return true;
    return (
      !isStagedContentItemValid ||
      !validateLinkUrl ||
      !validateLinkOptions() ||
      (IsPublished && !stagedContentItemUpdated)
    );
  };

  const publishButtonText = () => {
    if (
      stagedContentItemUpdated &&
      !isDeleteModalVisible &&
      !isSaveModalVisible &&
      !isNavigationModalVisible &&
      calendarModalMode === 'hide' &&
      isStagedContentItemValid
    ) {
      return bulkMode ? 'Save' : 'Save & Publish';
    }

    if (IsPublished) {
      return 'Published';
    }
    return bulkMode ? 'Save' : 'Save & Publish';
  };

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      if (!confirmedNavigation && stagedContentItemUpdated) {
        showNavigationModal(nextLocation);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [
    stagedContentItemUpdated,
    confirmedNavigation,
    history,
    showNavigationModal,
  ]);

  useEffect(() => {
    if (contentSiteItemId && activeLot) {
      if (contentSiteItemId !== 'new') {
        setActiveItemId(contentSiteItemId);
      } else {
        const dealerIds = bulkMode ? [] : [activeLot.LotNumber];
        const newItem = {
          HomeCenterWebsiteContentId: 'new',
          DealerIds: dealerIds,
          HomeCenterWebsiteContentType: type === 'events' ? 1 : 2,
        };
        if (type === 'news') {
          newItem.HomeCenterWebsiteContentType = 'News';
        }
        if (type === 'events') {
          newItem.HomeCenterWebsiteContentType = 'Events';
        }
        updateStagedContentItem(newItem);
      }
    }
    return () => {
      setActiveItemId(null);
      updateStagedContentItem(null);
    };
  }, [
    contentSiteItemId,
    activeLot,
    setActiveItemId,
    type,
    updateStagedContentItem,
  ]);

  // show loading dots until we have an actual object
  if (
    isLoading ||
    (stagedContentItem.HomeCenterWebsiteContentId !== 'new' &&
      !activeContentItem)
  ) {
    return <LoadingDots />;
  }

  const tooltipStyle = {
    display: toolTip ? 'block' : 'none',
  };

  const copyTag = tag => {
    insertTextAtCaret(contentRef.current, tag);
    updateStagedContentItem({
      ...stagedContentItem,
      ['Content']: contentRef.current.value,
    });
  };

  const typeDescription = `${type}`.toLowerCase() === 'news' ? 'news' : 'event';
  return (
    <StyledSiteItem>
      <div className="main-column">
        {!MainImage && (
          <ImageUploader
            uploadFunction={handleUpload}
            stageImages={false}
            allowMultiple={false}
            headerText={`Upload ${typeDescription} image (required)`}
            showLibraryImagesModal={showLibraryImagesModal}
            minHeight={600}
            minWidth={900}
            isLoading={imageIsLoading}
          />
        )}

        {MainImage && (
          <div className="image-field card">
            <div className="image-wrapper">
              <img src={MainImage} alt={Headline} />
            </div>
            <button type="button" className="clean" onClick={handleRemoveImage}>
              Remove Image
            </button>
          </div>
        )}
        {type === 'events' && (
          <div className="input-field">
            <span className="is-featured">
              <label
                htmlFor="IsFeatured"
                onClick={e => e.preventDefault()}
                className="label checkbox-display"
              >
                Mark as Featured
              </label>
              <input
                type="checkbox"
                id="IsFeatured"
                value={IsFeatured || showIsFeatured || FeaturedImage}
                checked={
                  IsFeatured !== undefined && IsFeatured && !showIsFeaturedError
                }
                onChange={handleCheckboxChange}
              />
            </span>
            {showIsFeaturedError && (
              <div className="error featured-error">{isFeaturedError}</div>
            )}
            <p className="is-featured-text">
              Featured Events show on the Special Offers page and the About Us
              Page.
            </p>
          </div>
        )}
        {!FeaturedImage &&
          !showIsFeaturedError &&
          showIsFeatured &&
          type === 'events' && (
            <ImageUploader
              uploadFunction={handleFeaturedUpload}
              stagedFeaturedImage={true}
              stageImages={false}
              allowMultiple={false}
              headerText="Upload featured image (optional)"
              showLibraryImagesModal={showLibraryImagesModal}
              minHeight={600}
              minWidth={900}
              isFeaturedImage={true}
              isLoading={imageIsLoading}
            />
          )}
        {FeaturedImage &&
          !showIsFeaturedError &&
          showIsFeatured &&
          type === 'events' && (
            <div className="image-field card">
              <div className="image-wrapper">
                <img src={FeaturedImage} alt={Headline} />
              </div>
              <button
                type="button"
                className="clean"
                onClick={handleRemoveFeaturedImage}
              >
                Remove Image
              </button>
            </div>
          )}
        {showIsFeatured && !showIsFeaturedError && type === 'events' && (
          <div className="input-field">
            <label htmlFor="Subtitle">
              <span className="label">Displayed Event Time (optional)</span>
              <p className="character-count">
                {characterCount(30, subtitleRef)}
              </p>
              <input
                type="text"
                id="Subtitle"
                value={Subtitle || ''}
                onChange={handleChange}
                maxLength="30"
                ref={subtitleRef}
              />
            </label>
          </div>
        )}
        <div className="input-field">
          <label htmlFor="Title">
            <span className="label">Title (required)</span>
            <p className="character-count">{characterCount(50, titleRef)}</p>
            <input
              type="text"
              id="Title"
              value={Title || ''}
              onChange={handleChange}
              maxLength="50"
              ref={titleRef}
            />
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="Summary">
            <span className="label">Summary (required)</span>
            <p className="character-count">{characterCount(300, summaryRef)}</p>
            <textarea
              id="Summary"
              onChange={handleChange}
              value={Summary || ''}
              maxLength="300"
              ref={summaryRef}
            />
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="Content">
            <div className="content-field-header">
              <div className="label">
                Content (required)
                <p className="character-count">
                  {type === 'events'
                    ? characterCount(1000, contentRef)
                    : characterCount(2000, contentRef)}
                </p>
              </div>
              {bulkMode && (
                <div className="label">
                  Insert Tag:{' '}
                  <button
                    type="button"
                    className="publish tag"
                    onClick={e => copyTag('<HOME CENTER NAME>')}
                  >
                    {'<HOME CENTER NAME>'}
                  </button>
                </div>
              )}
            </div>

            <textarea
              id="Content"
              onChange={handleChange}
              value={(Content && Content.replace(/<br>/g, '\n')) || ''}
              maxLength={type === 'events' ? 1000 : 2000}
              ref={contentRef}
              // onBlur={() => setUpdatedContent(Content.replace(/\n/g, '<br />'))}
            />
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="Disclaimer">
            <span className="label">Disclaimer (optional)</span>
            <p className="character-count">
              {characterCount(8000, disclaimereRef)}
            </p>
            <textarea
              id="Disclaimer"
              onChange={handleChange}
              value={Disclaimer || ''}
              maxLength="8000"
              ref={disclaimereRef}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="LinkText">
            <span className="label">
              Link ({isLinkRequired() ? 'required' : 'optional'})
            </span>
            <p className="character-count">What would you like to link to?</p>
          </label>
          <div className="input-area">
            <MultiCheckBox
              items={linkOptions}
              changedCallBack={(foundItem, newItems) => {
                setLinkOptions(newItems);

                updateStagedContentItem({
                  ...stagedContentItem,
                  LinkText: foundItem.linkText,
                  LinkUrl: foundItem.linkUrl,
                });
              }}
              asRadioGroup={true}
              width="16px"
              height="16px"
            />
            {showLinkInputs && (
              <>
                <div className="">
                  <label htmlFor="LinkText">
                    <span className="label">
                      Link Text ({isLinkRequired() ? 'required' : 'optional'})
                    </span>
                    <p className="character-count">
                      {characterCount(25, linkTextRef)}
                    </p>
                    <input
                      type="text"
                      id="LinkText"
                      onChange={handleChange}
                      value={LinkText ? LinkText.trimStart() : ''}
                      maxLength="25"
                      ref={linkTextRef}
                    />
                  </label>
                </div>
                <div className="">
                  <label htmlFor="LinkUrl">
                    <span className="label">
                      Link{' '}
                      <span className="optional">
                        ({isLinkRequired() ? 'required' : 'optional'})
                      </span>
                      <br />
                      <span className="sub-label">
                        (example: https://www.google.com)
                      </span>
                    </span>
                    <input
                      type="text"
                      id="LinkUrl"
                      value={LinkUrl || ''}
                      onChange={handleChange}
                      onBlur={validateLinkUrl}
                    />
                  </label>
                  <div className="error">{linkUrlError}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="side-column">
        {bulkMode && (
          <div className="">
            <ZoneSelector className="" onChange={handleBulkZoneChange} />
          </div>
        )}
        <div className="status-box card">
          <button
            type="button"
            className="publish"
            disabled={isPublishButtonDisabled()}
            onClick={() =>
              bulkMode ? setIsSaveModalVisible(true) : handleSave()
            }
          >
            {publishButtonText()}
          </button>
          <div className="save-grid">
            <div className="status-line">
              <strong>Publish Date</strong>
            </div>
            <div className="status-line">
              <span>{!StartDate ? 'Immediately' : formatDate(StartDate)}</span>
            </div>
            <div className="status-line last-col">
              <button
                type="button"
                className="clean"
                onClick={() => setCalendarModalMode('startDate')}
              >
                Edit
              </button>
            </div>

            <div className="status-line">
              <strong>Status</strong>
            </div>
            <div className="status-line">
              <span>{IsPublished ? 'Published' : 'Unpublished'}</span>
            </div>

            <div className="status-line  last-col">
              <div className="tooltip" style={tooltipStyle}>
                <div
                  role="presentation"
                  className="overlay"
                  onClick={showTooltip}
                />
                <button
                  type="button"
                  onClick={() => {
                    updateStatus(true);
                  }}
                  className={IsPublished ? 'active' : ''}
                >
                  Published
                </button>
                <button
                  type="button"
                  onClick={() => {
                    updateStatus(false);
                  }}
                  className={IsPublished ? '' : 'active'}
                >
                  Unpublished
                </button>
              </div>
            </div>

            <div className="status-line">
              <strong>Expires {type !== 'news' && '(required)'}</strong>
            </div>
            <div className="status-line">
              {type !== 'news' && (
                <span>{!EndDate ? '' : formatDate(EndDate)}</span>
              )}
              {type === 'news' && (
                <span>{!EndDate ? 'Never' : formatDate(EndDate)}</span>
              )}
            </div>
            <div className="status-line last-col">
              <button
                type="button"
                className="clean"
                onClick={() => {
                  setCalendarModalMode('endDate');
                }}
              >
                {EndDate ? 'Edit' : 'Enter'}
              </button>
            </div>
          </div>

          {!bulkMode && (
            <div className="bottom-button">
              <button
                type="button"
                className="clean"
                disabled={!IsPublished}
                onClick={() => {
                  showDeleteModal();
                }}
              >
                {`Delete ${type === 'news' ? 'News Item' : 'Event'}`}
              </button>
            </div>
          )}
        </div>

        <div className="message">
          <div className="checkmark" style={{ background: 'transparent' }}>
            <Checkmark />
          </div>
          Please note: changes won’t be visible on your site until published.
        </div>
      </div>
      <Modal
        modalHeadline={isStagedContentItemValid ? 'Save Changes?' : 'Hold On!'}
        modalBody={
          isStagedContentItemValid
            ? `If you do not save your changes, any changes you have made to your ${typeDescription} will be undone.`
            : 'This item has been changed, but is currently invalid and cannot be saved.  Would you like to continue editing?'
        }
        closeCopy="DISCARD"
        saveCopy={isStagedContentItemValid ? 'SAVE' : 'KEEP EDITING'}
        show={isNavigationModalVisible}
        closeCallback={hideNavigationModal}
        discardCallback={handleDiscard}
        saveCallback={
          isStagedContentItemValid ? handleSave : hideNavigationModal
        }
      />
      <Modal
        modalHeadline="Are you sure?"
        modalBody={`Are you sure you want to delete "${Title}"?`}
        saveCopy="Delete"
        closeCopy="Cancel"
        saveCallback={handleDelete}
        show={isDeleteModalVisible}
        discardCallback={hideDeleteModal}
        closeCallback={hideDeleteModal}
      />
      <Modal
        modalHeadline="Are you sure?"
        modalBody={`Once this event is bulk uploaded, you won't have the ability to mass edit the event across all home centers.`}
        saveCopy="Save"
        closeCopy="Cancel"
        saveCallback={handleSave}
        show={isSaveModalVisible}
        discardCallback={() => setIsSaveModalVisible(false)}
        closeCallback={() => setIsSaveModalVisible(false)}
      />
      {libraryImages && (
        <LibraryImagesModal
          closeCallback={hideLibraryImagesModal}
          images={
            type === 'news'
              ? libraryImages
                  .filter(image => image.ItemTypeId === 2)
                  .map(image => image.Reference)
              : libraryImages
                  .filter(image => image.ItemTypeId === 4)
                  .map(image => image.Reference)
          }
          selectCallback={handleLibraryImageSelect}
          show={isLibraryImagesModalVisible}
          user={user}
        />
      )}
      {calendarModalMode === 'endDate' && (
        <CalendarModal
          date={
            stagedContentItem.EndDate === '9999-12-31T00:00:00'
              ? null
              : stagedContentItem.EndDate
          }
          setDate={date => {
            updateExpirationDate(date);
          }}
          closeCallback={() => setCalendarModalMode('hide')}
          mode="endDate"
          allowNeverExpires={type === 'news'}
        />
      )}
      {calendarModalMode === 'startDate' && (
        <CalendarModal
          date={
            stagedContentItem.StartDate === '9999-12-31T00:00:00'
              ? null
              : stagedContentItem.StartDate
          }
          setDate={date => {
            updatePublishDate(date);
          }}
          closeCallback={() => setCalendarModalMode('hide')}
          mode="startDate"
        />
      )}
    </StyledSiteItem>
  );
};

ContentSiteItem.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default ContentSiteItem;
