import styled from 'styled-components';

const StyledInventoryImageCard = styled.div`
  display: flex;
  width: 253px;
  padding: 0 12px;
  box-sizing: border-box;
  .spacer {
    flex-grow: 1;
  }
  @media screen and (min-width: 375px) {
    width: 268px;
  }
  @media screen and (min-width: 550px) {
    display: block;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #777b7e;
    flex: 1;
    @media screen and (min-width: 550px) {
      margin: 1rem 0;
      align-items: center;
    }
    .bold {
      padding-right: 8px;
      padding-left: 13px;
      color: #25292d;
      font-weight: 600;
      @media screen and (min-width: 550px) {
        padding-left: 0px;
      }
    }
    button.edit {
      margin-top: auto;
      margin-left: auto;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.57;
      letter-spacing: 1px;
      color: #0075c9;
      background: none;
      border: none;
      cursor: pointer;
      @media screen and (min-width: 550px) {
        margin-top: 0;
      }
    }
  }
`;

export default StyledInventoryImageCard;
