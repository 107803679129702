import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chevron from '../icons/Chevron';
import NewTab from '../icons/NewTab';
import { UserContext } from './User';
import { HomesContext } from './Homes';
import { SiteItemsContext } from './SiteItems/SiteItemsProvider';
import TabBar from './TabBar';
import { SalesAgentsContext } from './SalesAgents/SalesAgentsProvider';
import { canViewLandRecords } from '../permissions';

const PageHeader = ({ header, history, pathname }) => {
  const {
    activeLot,
    user: { IsCorporateOfficeUser },
  } = useContext(UserContext);
  const { activeHome, activeLand } = useContext(HomesContext);
  const { stagedItem, stagedContentItem } = useContext(SiteItemsContext);
  const { stagedAgent } = useContext(SalesAgentsContext);

  const {
    location: { state: navState },
  } = history;

  if (!activeLot) {
    return <></>;
  }

  const getBackLink = () => {
    if (navState && navState.backLink) {
      const { backLink } = navState;

      return {
        ...backLink,
        icon: <Chevron />,
      };
    }

    if (activeHome) {
      return {
        path: activeHome.IsAvailableFloorPlan
          ? '/homes/available-floor-plans'
          : '/homes/on-display',
        text: 'Listings',
        icon: <Chevron />,
      };
    }
    if (
      (stagedItem && stagedItem.RetailSiteItemType) ||
      (stagedContentItem && stagedContentItem.HomeCenterWebsiteContentType)
    ) {
      return {
        path:
          stagedContentItem.HomeCenterWebsiteContentType === 2 ||
          stagedContentItem.HomeCenterWebsiteContentType === 'News'
            ? '/content/news'
            : stagedContentItem.HomeCenterWebsiteContentType === 1 ||
              stagedContentItem.HomeCenterWebsiteContentType === 'Events'
            ? '/content/events'
            : '/content/promotions',
        text:
          stagedContentItem.HomeCenterWebsiteContentType === 2 ||
          stagedContentItem.HomeCenterWebsiteContentType === 'News'
            ? 'News'
            : stagedContentItem.HomeCenterWebsiteContentType === 1 ||
              stagedContentItem.HomeCenterWebsiteContentType === 'Events'
            ? 'Events'
            : 'Promotions',
        icon: <Chevron />,
      };
    }
    if (pathname.startsWith('/content/welcome-home/new')) {
      return {
        path: '/content/welcome-home',
        text: 'Welcome Home',
        icon: <Chevron />,
      };
    }
    return false;
  };

  const getHomeLink = () => {
    const url = `https://${activeLot.Url}/Homes`;

    if (activeHome.IsAvailableFloorPlan) {
      return activeHome.ModelNumber
        ? `${url}/Model/${activeHome.ModelNumber}`
        : url;
    } else if (activeHome.IsLand) {
      return activeHome.InventoryId
        ? `${url}/Land/${activeHome.InventoryId}`
        : url;
    } else {
      return `${url}/${activeHome.SerialNumber}`;
    }
  };

  const getViewLink = () => {
    if (activeHome) {
      return {
        customClass: 'view-home',
        url: getHomeLink(),
        text: 'VIEW HOME',
        icon: <NewTab />,
      };
    }

    const viewLink = {
      customClass: 'view-home',
      url: getViewLinkUrl(),
      text: 'VIEW SITE',
      icon: <NewTab />,
    };

    return viewLink.url === false ? false : viewLink;
  };

  const getViewLinkUrl = () => {
    if (
      pathname.startsWith('/homes/on-display') ||
      pathname.startsWith('/homes/available-floor-plans')
    ) {
      const availabilityType = pathname.startsWith('/homes/on-display') ? 1 : 3;
      return `https://${activeLot.Url}/Homes?availability=${availabilityType}`;
    }

    if (pathname.startsWith('/content/welcome-home')) {
      return `https://${activeLot.Url}/our-homeowners`;
    }

    if (
      pathname.startsWith('/content/promotions') ||
      pathname.startsWith('/content/news') ||
      pathname.startsWith('/content/events') ||
      (stagedItem.RetailSiteItemType &&
        stagedItem.RetailSiteItemId !== 'new') ||
      (stagedContentItem.HomeCenterWebsiteContentType &&
        stagedContentItem.HomeCenterWebsiteContentId !== 'new')
    ) {
      return `https://${activeLot.Url}/news-events`;
    }

    if (
      pathname.startsWith('/profile') &&
      Object.keys(stagedAgent).length > 0
    ) {
      return `https://${activeLot.Url}/about-us`;
    }

    if (pathname.startsWith('/homes/sale')) {
      return `https://${activeLot.Url}/Sale-Homes`;
    }

    if (pathname.startsWith('/homes/land')) {
      return `https://${activeLot.Url}/land`;
    }

    if (pathname.startsWith('/settings')) {
      return `https://${activeLot.Url}`;
    }

    return false;
  };

  const getLinks = () => {
    if (pathname.startsWith('/home/AFP_')) {
      if (!activeHome) {
        return [];
      }
      return [
        {
          to: {
            pathname: `/home/${activeHome.Id}/info`,
            state: navState,
          },
          text: 'INFO',
        },
      ];
    }
    if (pathname.startsWith('/home/')) {
      if (!activeHome) {
        return [];
      }
      return [
        {
          to: {
            pathname: `/home/${activeHome.Id}/info`,
            state: navState,
          },
          text: 'INFO',
        },
        {
          to: {
            pathname: `/home/${activeHome.Id}/images`,
            state: navState,
          },
          text: 'IMAGES',
        },
        {
          to: {
            pathname: `/home/${activeHome.Id}/features`,
            state: navState,
          },
          text: 'FEATURES',
        },
      ];
    }
    if (pathname.startsWith('/homes/')) {
      return [
        {
          to: {
            pathname: '/homes/on-display',
          },
          text: 'HOMES ON DISPLAY',
        },
        {
          to: {
            pathname: '/homes/available-floor-plans',
          },
          text: 'AVAILABLE',
        },
        {
          to: {
            pathname: '/homes/sale',
          },
          text: 'SALE',
        },
        ...(canViewLandRecords(activeLot.LotNumber)
          ? [
              {
                to: {
                  pathname: '/homes/land',
                },
                text: 'LAND',
              },
            ]
          : []),
      ];
    }
    if (pathname.startsWith('/land/')) {
      if (!activeHome) {
        return [];
      }
      return [
        {
          to: {
            pathname: `/land/${activeHome.InventoryId}/info`,
            state: navState,
          },
          text: 'INFO',
        },
        {
          to: {
            pathname: `/land/${activeHome.InventoryId}/images`,
            state: navState,
          },
          text: 'IMAGES',
        },
        {
          to: {
            pathname: `/land/${activeHome.InventoryId}/features`,
            state: navState,
          },
          text: 'FEATURES',
        },
      ];
    }
    if (
      (pathname === '/content/promotions' ||
        pathname === '/content/news' ||
        pathname === '/content/events' ||
        pathname === '/content/welcome-home') &&
      IsCorporateOfficeUser
    ) {
      return [
        {
          to: {
            pathname: '/content/welcome-home',
          },
          text: 'WELCOME HOME',
        },
        {
          to: {
            pathname: '/content/news',
          },
          text: 'NEWS',
        },
        {
          to: {
            pathname: '/content/events',
          },
          text: 'EVENTS',
        },
      ];
    }

    if (pathname.startsWith('/settings/')) {
      return [
        {
          to: {
            pathname: `/settings/descriptions`,
            state: navState,
          },
          text: 'DESCRIPTIONS',
        },
        {
          to: {
            pathname: `/settings/urls`,
            state: navState,
          },
          text: 'URLS',
        },
        {
          to: {
            pathname: `/settings/hours`,
            state: navState,
          },
          text: 'HOURS',
        },
        {
          to: {
            pathname: `/settings/other`,
            state: navState,
          },
          text: 'OTHER',
        },
      ];
    }
    return [];
  };

  const updatedHeader = () => {
    if (pathname === '/homes/land') {
      return 'Land Inventory';
    }
  };

  return (
    <TabBar
      header={updatedHeader() ? updatedHeader() : header}
      pathname={pathname}
      links={getLinks()}
      viewLink={getViewLink()}
      backLink={getBackLink()}
    />
  );
};

PageHeader.propTypes = {
  header: PropTypes.string,
  history: PropTypes.shape(),
  pathname: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  header: '',
  history: { location: { state: null } },
};

export default PageHeader;
