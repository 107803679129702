import styled from 'styled-components';

const StyledAppError = styled.div`
  width: 100%;
  height: 100%;

  .loading-container {
    width: 100%;
    height: 100%;
  }

  .modal-content {
    @media screen and (min-width: 1024px) {
      left: 50%;
    }
  }
`;

export default StyledAppError;
