import styled from 'styled-components';

const StyledLibraryImagesModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .modal-content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 570px;
    background: #fff;
    box-sizing: border-box;
    /* padding: 20px; */
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    width: 40%;
    max-width: 578px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: 1024px) {
      left: 61%;
    }

    @media screen and (max-width: 768px) {
      width: 75%;
    }

    .images {
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      overflow-y: auto;
      max-height: 475px;

      .image {
        flex: 1 1 30%;
        padding: 3px;
        button {
          display: block;
          padding: 0;
          border: 0;
          max-width: 200px;
          border: 3px solid transparent;
          &:hover,
          &:focus {
            border: 3px solid #e4e5e7;
          }
          img {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .btns-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e4e5e7;
    button {
      font: 600 12px/100% source-sans-pro;
      color: #fff;
      background: #00b0ac !important;
      letter-spacing: 0.5px;
      margin-block-start: 0;
      text-align: center;
      margin-block-end: 0;
      border: none;
      background: none;
      border-radius: 2px;
      padding: 16px 0;
      width: 195px;
      transition: color 0.3s;
      &:hover,
      &:focus {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export default StyledLibraryImagesModal;
