import React, {
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import StyledActiveImageCard from './ActiveImageCard.Style';
import Toggle from './../Toggle';

const cardSource = {
  beginDrag: props => ({
    id: props.id,
    index: props.index,
  }),
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const ActiveImageCard = React.forwardRef(
  (
    {
      image,
      firstName,
      lastName,
      name,
      number,
      welcomeHomeItemId,
      active,
      toggleActiveImage,
      isDragging,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      style,
    },
    ref
  ) => {
    const elementRef = useRef(null);
    const previewRef = useRef(null);

    connectDragSource(elementRef);
    connectDropTarget(previewRef);
    connectDragPreview(previewRef);
    const opacity = isDragging ? 0.2 : 1;
    const transition = isDragging ? 'none' : 'inherit';
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    const fullName =
      (firstName || name) && firstName === null
        ? name
        : `${firstName} ${lastName}`;

    return (
      <div
        ref={previewRef}
        style={{ ...style, opacity, transition }}
        className="card"
      >
        <div ref={elementRef} className="draggable-bars">
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
          <div className="dots-container">
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
        <StyledActiveImageCard>
          {image && <img src={`${image}?width=300`} alt="image for " />}

          <div className="label">
            <div className="label-left">
              {number && (
                <div className="number">
                  <span>{number}</span>
                </div>
              )}
              {fullName && <span className="bold">{fullName}</span>}
            </div>
            <div className="label-right">
              <Toggle
                label=""
                onToggle={event => {
                  toggleActiveImage(welcomeHomeItemId, false);
                }}
                inputProps={{
                  id: `${fullName}-visible`,
                  checked: active,
                }}
              />
            </div>
          </div>
        </StyledActiveImageCard>
      </div>
    );
  }
);

ActiveImageCard.propTypes = {
  image: PropTypes.shape({
    Reference: PropTypes.string.isRequired,
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  style: PropTypes.shape(),
};

ActiveImageCard.defaultProps = {
  style: {},
};

export default DropTarget(
  'image-card',
  {
    hover(props, monitor, component) {
      const { index, moveCard } = props;
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return null;
      }
      moveCard(dragIndex, hoverIndex);
      // (Mutating this object for performance enhancement)
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
      return null;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  })
)(DragSource('image-card', cardSource, collect)(ActiveImageCard));
