import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Redirect } from 'react-router-dom';
import GlobalStyles from './App.GlobalStyles';
import StyledAuth from './Auth.Style';
import face from './images/face.png';

export const AuthLanding = ({ children }) => (
  <StyledAuth>
    <GlobalStyles />
    <div className="app-container">
      <aside>
        <div className="upper">
          <div className="user-image">
            <img src={face} alt="user portrait placeholder" />
          </div>
        </div>
        <div className="boxes">
          <div className="sm-box" />
          <div className="md-box" />
          <div className="md-box" />
          <div className="md-box" />
          <div className="sm-box" />
          <div className="md-box" />
          <div className="md-box" />
          <div className="md-box" />
          <div className="md-box" />
        </div>
      </aside>
      <main>
        <>
          <section className="header">
            <h1>CMH Web Admin</h1>
          </section>
          <section>{children}</section>
        </>
      </main>
    </div>
  </StyledAuth>
);

AuthLanding.propTypes = {
  children: PropTypes.element,
};

AuthLanding.defaultProps = {
  children: <></>,
};

const Auth = props => {
  const {
    location: { search },
  } = props;
  const { returnContext, error, accessToken } = parse(search);

  if ((!accessToken || !returnContext) && !error) {
    return (
      <Redirect
        to={{
          pathname: '/auth/error',
          search: '?error=Unable to fetch access token',
        }}
      />
    );
  }

  if (accessToken) {
    // Save new token
    localStorage.setItem('token', accessToken.toString());
  }

  // redirect to original url
  if (returnContext) {
    // add https if not running on localhost
    if (returnContext.indexOf('localhost') < 0) {
      window.location.replace(`https://${returnContext.toString()}`);
    }
    window.location.replace(`http://${returnContext.toString()}`);
  }

  return (
    <AuthLanding>
      {error && (
        <h3 className="error">
          An error occurred when logging in:
          <span>{error}</span>
          <span>
            <a href="/">Click Here</a> to try logging in again.
          </span>
        </h3>
      )}
    </AuthLanding>
  );
};

Auth.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default Auth;
