import styled from 'styled-components';

import StyledSettings from '../Settings.Style';

const StyledUrls = styled(StyledSettings)`
  .tool-column {
    padding-left: 20px;
    align-self: center;
    @media screen and (max-width: 339px) {
      padding-left: 6px;
    }
  }

  .add-btn {
    margin-top: 20px;
  }

  .delete-btn {
    font: 600 14px/100% source-sans-pro;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    color: #0075c9;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .delete-btn-content {
    display: none;
    @media screen and (min-width: 340px) {
      display: inline;
    }
  }
  .delete-btn-content-mobile {
    display: none;
    @media screen and (max-width: 339px) {
      display: inline;
    }
  }

  .hc-url {
    width: 95%;
  }
`;

export default StyledUrls;
