import styled from 'styled-components';

export const StyledZoneSelector = styled.div`

.hidden {
  display:none;
}

.card {
  width: auto;
}

.zone {
  margin-top: 16px;
  border-bottom: 1px solid #F7F9FB;

}

.region:not(:first-child) {
  margin-top: 16px;
}


.zone-selector-box {
  flex: 1 1 100%;
  
  margin-top: 20px;
  @media screen and (min-width: 1024px) {
    flex: 0;
    
  }

  .title {
    
    background-color: #F3F5F7
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #4e5255;
    display: block;
  }

  .content {
    padding: 1em;

    .section {
      padding: 1em;
    }

    .zone-checkbox-label {
      margin-left: 15px;
    }
  }

}
`;
