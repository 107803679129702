import React from 'react';
import PropTypes from 'prop-types';
import PublishAgentWrapper from './PublishAgent.style';

const PublishAgent = ({
  IsCorporateOfficeUser,
  handlePublish,
  publishButtonText,
  disabled = false,
}) => {
  return (
    <PublishAgentWrapper>
      <div>
        <button disabled={disabled} onClick={handlePublish}>
          {publishButtonText()}
        </button>
      </div>
    </PublishAgentWrapper>
  );
};

PublishAgent.propTypes = {
  IsCorporateOfficeUser: PropTypes.bool.isRequired,
  handlePublish: PropTypes.func.isRequired,
  publishButtonText: PropTypes.string.isRequired,
};

PublishAgent.defaultProps = {};

export default PublishAgent;
