import styled from 'styled-components';

const StyledFeatureCard = styled.div`
  border: 2px solid transparent;
  min-height: 45px;
  user-select: none;
  padding: 8px 32px 8px 10px;
  position: relative;
  .card {
    box-shadow: 0 1px 4px 0 rgba(58, 67, 77, 0.12);
    border: solid 1px #e4e5e7;
    background-color: #ffffff;
    display: flex;
    min-height: 45px;
  }
  .handle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f5f7;
    border-right: 1px solid #ebeced;
    svg {
      padding: 0 5px;
    }
  }
  .name {
    padding: 10px 20px;
    flex: 1;
    font-size: 15px;
    line-height: 1.4;
    color: #7f8083;
    border: none;
  }
  .removeFeature {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    border: 0;
    background: #e4e5e7;
    color: #777b7e;
    font-size: 18px;
    padding: 0;
    margin: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    line-height: 1;
  }
`;

export default StyledFeatureCard;
