import React from 'react';

const DraggableDots = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="18"
    viewBox="0 0 12 18"
  >
    <g fill="#777B7E" fillRule="nonzero" transform="translate(.929 .577)">
      <circle cx="1.734" cy="1.734" r="1.734" />
      <circle cx="8.587" cy="1.734" r="1.734" />
      <circle cx="1.734" cy="8.349" r="1.734" />
      <circle cx="8.587" cy="8.349" r="1.734" />
      <circle cx="1.734" cy="15.436" r="1.734" />
      <circle cx="8.587" cy="15.436" r="1.734" />
    </g>
  </svg>
);

export default DraggableDots;
