import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import StyledSpecs from './Specs.Style';
import { formatPhoneNumber } from '../../utils';
import CheckBox from '../CheckBox/CheckBox';

const Specs = ({
  Name,
  tags,
  Beds,
  Baths,
  SquareFeet,
  StockNumber,
  SerialNumber,
  ModelNumber,
  InventoryDescription,
  LineDescription,
  IsAvailableFloorPlan,
  IsLand,
  setHomeName,
  setInventoryDescription,
  SpecsText,
  isUpdating,
}) => {
  const descriptionRef = useRef(null);
  const descriptionMaxLength = 1000;

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckBoxChange = event => {
    if (InventoryDescription === null || InventoryDescription === '') {
      setIsChecked(!isChecked);
    } else {
      setInventoryDescription('');
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    if (InventoryDescription !== null) {
      setIsChecked(false);
    }
  }, []);

  const characterCount =
    descriptionRef.current &&
    `${descriptionMaxLength -
      (InventoryDescription?.length ||
        0)}/${descriptionMaxLength} characters remaining`;

  return (
    <StyledSpecs>
      <h2>{SpecsText} Specs</h2>
      {!IsLand && (
        <div className="card">
          <div className="info">
            <div className="name-wrapper">
              {!IsAvailableFloorPlan && (
                <label htmlFor="name">
                  <span className="label">Name</span>
                  <input
                    id="name"
                    type="text"
                    value={Name}
                    onChange={event => setHomeName(event.currentTarget.value)}
                  />
                </label>
              )}
              {IsAvailableFloorPlan && (
                <>
                  <span className="label">Name</span>
                  <span className="name">{Name}</span>
                </>
              )}
            </div>
            {!IsLand && tags.length > 0 && (
              <div className="tags">
                {tags.map(tag => (
                  <div className="tag" key={tag}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
            <div className="details">
              <>
                {`${Beds} beds`}
                <span>&middot;</span>
                {`${Baths} baths`}
                <span>&middot;</span>
                {`${Number(SquareFeet).toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })}
          square feet`}
              </>
            </div>
            <div className="description-wrapper">
              {!IsAvailableFloorPlan && (
                <>
                  <label htmlFor="InventoryDescription">
                    <span className="label">Inventory Description</span>
                    <span className="sub-label">
                      If you enter your own description, it will display on the
                      detail page in place of the default description.
                    </span>
                    <textarea
                      name="InventoryDescription"
                      maxLength={descriptionMaxLength}
                      ref={descriptionRef}
                      value={InventoryDescription}
                      disabled={isUpdating || isChecked ? true : false}
                      onChange={event =>
                        setInventoryDescription(event.currentTarget.value)
                      }
                    />
                  </label>
                  <p className="character-count">{characterCount}</p>
                  <div className="description-box-container">
                    <label htmlFor="descriptionBox">
                      <CheckBox
                        name="descriptionBox"
                        id="descriptionBox"
                        checked={isChecked}
                        onChange={handleCheckBoxChange}
                      />
                      <span className="description-label">
                        Use default description
                      </span>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="numbers">
            {!IsAvailableFloorPlan && (
              <>
                <div className="number">
                  <span className="label">Serial Number</span>
                  <span>{SerialNumber}</span>
                </div>
              </>
            )}
            <div className="number">
              <span className="label">Model Number</span>
              {ModelNumber}
            </div>
            <div className="number">
              <span className="label">Stock Number</span>
              {StockNumber}
            </div>
          </div>
        </div>
      )}
    </StyledSpecs>
  );
};
Specs.propTypes = {
  Name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  Beds: PropTypes.number.isRequired,
  Baths: PropTypes.number.isRequired,
  SquareFeet: PropTypes.number.isRequired,
  StockNumber: PropTypes.string,
  SerialNumber: PropTypes.string,
  ModelNumber: PropTypes.string.isRequired,
  IsAvailableFloorPlan: PropTypes.bool,
  setHomeName: PropTypes.func.isRequired,
};

Specs.defaultProps = {
  tags: [],
  StockNumber: '',
  SerialNumber: '',
  IsAvailableFloorPlan: true,
};

export default Specs;
