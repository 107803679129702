const imagesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IMAGES':
      return { ...state, images: [...action.payload], isLoading: false };
    case 'ADD_IMAGE_INIT':
      return {
        ...state,
        images: [...state.images, action.payload],
        isLoading: true,
      };
    case 'ADD_IMAGE_SUCCESS':
      return { ...state, isLoading: false };
    case 'EDIT_IMAGE':
      return { ...state };
    case 'DELETE_INVENTORY_IMAGE_INIT':
      return { ...state, isLoading: true };
    case 'DELETE_INVENTORY_IMAGE_COMPLETED':
      return { ...state, isLoading: false };
    case 'DELETE_IMAGE_INIT':
      return {
        ...state,
        images: [...state.images].filter(
          image => image.InventoryImagesId !== action.payload
        ),
        isLoading: true,
      };
    case 'DELETE_IMAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
      };
    case 'SET_IMAGES_INIT': {
      return { ...state, isLoading: true };
    }
    case 'SET_IMAGES_SUCCESS': {
      return { ...state, images: action.payload, isLoading: false };
    }
    case 'UPDATE_IMAGE_INIT': {
      return { ...state, isUpdating: true };
    }
    case 'UPDATE_IMAGE_SUCCESS': {
      return { ...state, isUpdating: false };
    }
    default:
      return { ...state };
  }
};

export default imagesReducer;
