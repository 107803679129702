import React from 'react';
import PropTypes from 'prop-types';
import StyledModelImagesModal from './ModelImagesModal.Style';

const ModelImagesModal = ({
  closeCopy,
  show,
  closeCallback,
  discardCallback,
  modelImages,
}) => (
  <StyledModelImagesModal
    className="modal"
    style={{ display: show ? 'block' : 'none' }}
  >
    <div role="presentation" className="overlay" onClick={closeCallback} />
    <div className="modal-content">
      <div className="images">
        {modelImages.map(modelImage => (
          <div className="image" key={modelImage}>
            <img src={`${modelImage}?width=259`} alt="" />
          </div>
        ))}
      </div>
      <div className="btns-container">
        <button type="button" className="close-modal" onClick={discardCallback}>
          {closeCopy}
        </button>
      </div>
    </div>
  </StyledModelImagesModal>
);

ModelImagesModal.propTypes = {
  closeCopy: PropTypes.string,
  show: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  discardCallback: PropTypes.func.isRequired,
  modelImages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ModelImagesModal.defaultProps = {
  closeCopy: '',
  show: false,
};

export default ModelImagesModal;
