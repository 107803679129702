import React from 'react';
import PropTypes from 'prop-types';
import StyledLibraryImagesModal from './LibraryImagesModal.Style';

const LibraryImagesModal = ({
  show,
  closeCallback,
  selectCallback,
  images,
  user,
}) => (
  <StyledLibraryImagesModal
    className="modal"
    style={{ display: show ? 'block' : 'none' }}
  >
    <div role="presentation" className="overlay" onClick={closeCallback} />
    <div className="modal-content">
      <div className="images">
        {images.length === 0 && user.IsCorporateOfficeUser && (
          <p>
            No images are available in your library. Upload images to see them
            here!
          </p>
        )}
        {images.length === 0 && !user.IsCorporateOfficeUser && (
          <p>No images are available in this library.</p>
        )}
        {images.map(image => (
          <div className="image" key={image}>
            <button
              type="button"
              onClick={() => {
                selectCallback(image);
              }}
            >
              {show && <img src={`${image}?width=200`} alt="" />}
            </button>
          </div>
        ))}
      </div>
      <div className="btns-container">
        <button type="button" className="close-modal" onClick={closeCallback}>
          Close
        </button>
      </div>
    </div>
  </StyledLibraryImagesModal>
);

LibraryImagesModal.propTypes = {
  show: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  selectCallback: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LibraryImagesModal.defaultProps = {
  show: false,
};

export default LibraryImagesModal;
