import styled from 'styled-components';

const StyledFeatures = styled.div`
  max-width: 90%;
  margin: 50px auto 0;
  padding-bottom: 60px;
  @media screen and (min-width: 500px) {
    max-width: 380px;
  }
  h1 {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    color: #25292d;
    margin-bottom: 10px;
  }
  .handle {
    cursor: grab;
  }
  .description {
    padding: 0;
    margin: 0 0 20px 0;
    color: #7f8083;
  }
  .bar-wrapper {
    margin-bottom: 40px;
  }
`;

export default StyledFeatures;
