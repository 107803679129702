import React from 'react';

const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <path
      fill="#777B7E"
      fillRule="evenodd"
      d="M4.092 6.761a.584.584 0 1 0-.827-.827L1.343 7.855a2.988 2.988 0 0 0-.886 2.097c-.007.793.3 1.54.863 2.106a2.931 2.931 0 0 0 2.081.858 2.99 2.99 0 0 0 2.119-.879l1.13-1.13a2.974 2.974 0 0 0 .293-3.868L9.23 4.752a.585.585 0 1 0-.827-.827L7.25 5.08a1.747 1.747 0 0 1-.278-.917c-.005-.465.18-.907.522-1.246l1.132-1.13a1.817 1.817 0 0 1 1.277-.542c.47.007.928.18 1.269.521.34.34.524.791.522 1.266a1.828 1.828 0 0 1-.542 1.277L9.3 6.158a.585.585 0 0 0 .827.827l1.85-1.85a2.987 2.987 0 0 0 .886-2.096A2.926 2.926 0 0 0 12 .933C11.436.37 10.709.052 9.895.07a2.998 2.998 0 0 0-2.097.883l-1.132 1.13a2.891 2.891 0 0 0-.257 3.835L3.712 8.616a.584.584 0 1 0 .827.827l1.564-1.565a1.777 1.777 0 0 1-.277 2.197l-1.13 1.13c-.72.72-1.837.73-2.546.02a1.767 1.767 0 0 1-.522-1.266 1.83 1.83 0 0 1 .543-1.277l1.92-1.92z"
    />
  </svg>
);

export default LinkIcon;
