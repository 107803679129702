import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledDealerPicker from './DealerPicker.Style';
import DealerTag from './DealerTag';
import Toggle from '../Toggle';
import Hideable from '../Hideable';
import AutoComplete from '../AutoComplete';
import AccordianToggle from '../AccordianToggle';

const DealerPicker = ({
  authorizedDealers,
  selectedDealers,
  updateDealers,
}) => {
  const [showDealers, setShowDealers] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [showZones, setShowZones] = useState(false);
  const [showRegions, setShowRegions] = useState(false);

  const dealerBrands = [
    'BUS',
    'CLA',
    'CSL',
    'ECO',
    'FRE',
    'INT',
    'LUV',
    'OAK',
    'SYN',
    'TRU',
    'GNI',
  ];
  const dealerRegions = [
    'SER',
    'GUL',
    'SCA',
    'LOU',
    'TEX',
    'VIR',
    'NCA',
    'COL',
    'KEN',
    'WVA',
    'OKL',
    'TEN',
    'ECR',
    'NER',
  ];
  const dealerZones = [0, 1, 2, 4];

  // we need to track the toggle status for all available dealer brands
  // create dict with dealer brand as key and checked state
  // from array of dealer brand strings
  const [brandToggleStates, setBrandToggleStates] = useState(
    dealerBrands.reduce((o, k) => ({ ...o, [k]: false }), {})
  );

  // do the same for zones and regions
  const [zoneToggleStates, setZoneToggleStates] = useState(
    dealerZones.reduce((o, k) => ({ ...o, [k]: false }), {})
  );
  const [regionToggleStates, setRegionToggleStates] = useState(
    dealerRegions.reduce((o, k) => ({ ...o, [k]: false }), {})
  );

  const toggleDealers = () => {
    setShowDealers(!showDealers);
  };

  const toggleBrands = () => {
    setShowBrands(!showBrands);
  };

  const toggleZones = () => {
    setShowZones(!showZones);
  };

  const toggleRegions = () => {
    setShowRegions(!showRegions);
  };

  const handleAddDealer = id => {
    if (selectedDealers.includes(id) || selectedDealers.includes(id.toString()))
      return;

    updateDealers([...selectedDealers, id]);
  };

  const handleRemoveDealer = dealerIdToDelete => {
    updateDealers(
      [...selectedDealers].filter(dealerId => dealerId !== dealerIdToDelete)
    );
  };

  const handleBrandToggle = brand => {
    const newBrandToggleStates = {
      ...brandToggleStates,
      [brand]: !brandToggleStates[brand],
    };

    setBrandToggleStates(newBrandToggleStates);

    updateDealers(
      getDealerIdsFromFilter(
        newBrandToggleStates,
        zoneToggleStates,
        regionToggleStates
      )
    );
  };

  const handleZoneToggle = zone => {
    const newZoneToggleStates = {
      ...zoneToggleStates,
      [zone]: !zoneToggleStates[zone],
    };

    setZoneToggleStates(newZoneToggleStates);

    updateDealers(
      getDealerIdsFromFilter(
        brandToggleStates,
        newZoneToggleStates,
        regionToggleStates
      )
    );
  };

  const handleRegionToggle = region => {
    const newRegionToggleStates = {
      ...regionToggleStates,
      [region]: !regionToggleStates[region],
    };

    setRegionToggleStates(newRegionToggleStates);

    updateDealers(
      getDealerIdsFromFilter(
        regionToggleStates,
        zoneToggleStates,
        newRegionToggleStates
      )
    );
  };

  const getDealerIdsFromFilter = (
    brandToggleStates,
    zoneToggleStates,
    regionToggleStates
  ) => {
    // update the promotion dealer ids to reflect the new filter
    if (!brandToggleStates) return selectedDealers;
    if (!authorizedDealers || authorizedDealers.length == 0)
      return selectedDealers;

    // get all delearIds who's brand matches those that are currently checked
    const dealerIdsToSelect = Object.keys(authorizedDealers).reduce(
      (filtered, key) => {
        return brandToggleStates[authorizedDealers[key].Brand] ||
          zoneToggleStates[authorizedDealers[key].Zone] ||
          regionToggleStates[authorizedDealers[key].Region]
          ? [...filtered, key]
          : filtered;
      },
      []
    );

    return dealerIdsToSelect;
  };

  const getDealerFromId = dealerId => {
    if (!authorizedDealers) return undefined;

    return Object.values(authorizedDealers).find(
      dealer => dealer.LotNumber == dealerId
    );
  };

  return (
    <StyledDealerPicker>
      <div className="input-field dealer-toggle">
        <span className="heading label">
          Dealers{' '}
          <a onClick={toggleDealers}>{showDealers ? '(hide)' : '(show)'}</a>
        </span>
      </div>
      <Hideable hidden={showDealers}>
        <div className="card dealers">
          <div className="label">
            <AccordianToggle title="Toggle Brands" onToggle={toggleBrands} />
          </div>
          <Hideable hidden={showBrands} className="dealer-filter-sub-area">
            <div>
              {dealerBrands.map(dealerBrand => (
                <Toggle
                  key={dealerBrand}
                  label={dealerBrand}
                  onToggle={event => {
                    handleBrandToggle(dealerBrand);
                  }}
                  inputProps={{
                    id: dealerBrand.toString(),
                    checked: brandToggleStates[dealerBrand],
                  }}
                />
              ))}
            </div>
          </Hideable>
          <div className="label">
            <AccordianToggle title="Toggle Zones" onToggle={toggleZones} />
          </div>
          <Hideable hidden={showZones} className="dealer-filter-sub-area">
            <div>
              {dealerZones.map(dealerZone => (
                <Toggle
                  key={dealerZone}
                  label={dealerZone.toString()}
                  onToggle={event => {
                    handleZoneToggle(dealerZone);
                  }}
                  inputProps={{
                    id: dealerZone.toString(),
                    checked: zoneToggleStates[dealerZone],
                  }}
                />
              ))}
            </div>
          </Hideable>
          <div className="label">
            <AccordianToggle title="Toggle Regions" onToggle={toggleRegions} />
          </div>
          <Hideable hidden={showRegions} className="dealer-filter-sub-area">
            <div>
              {dealerRegions.map(dealerRegion => (
                <Toggle
                  key={dealerRegion}
                  label={dealerRegion}
                  onToggle={event => {
                    handleRegionToggle(dealerRegion);
                  }}
                  inputProps={{
                    id: dealerRegion.toString(),
                    checked: regionToggleStates[dealerRegion],
                  }}
                />
              ))}
            </div>
          </Hideable>

          {Object.keys(authorizedDealers).length && (
            <AutoComplete
              defaultValue={''}
              clearOnSelect={true}
              placeHolder={'Search and Add Dealer'}
              entries={Object.values(authorizedDealers)}
              onSelect={id => {
                handleAddDealer(id);
              }}
            />
          )}

          <div className="tag-container">
            {selectedDealers.map(dealerId => {
              const dealer = getDealerFromId(dealerId) || {
                LotNumber: dealerId,
                Name: '',
              };

              return (
                <DealerTag
                  key={dealer.LotNumber}
                  dealer={dealer}
                  removeDealer={() => {
                    handleRemoveDealer(dealerId);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Hideable>
    </StyledDealerPicker>
  );
};

DealerPicker.propTypes = {};

DealerTag.defaultProps = {};

export default React.memo(DealerPicker);
