import React from 'react';
import {
  StyledCheckBox,
  HiddenCheckBox,
  CheckBoxContainer,
  Icon,
} from './CheckBox.Style';

const Checkbox = ({
  className,
  checked,
  width,
  height,
  borderRadius,
  ...props
}) => (
  <CheckBoxContainer className={className}>
    <HiddenCheckBox checked={checked} {...props} />
    <StyledCheckBox
      checked={checked}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckBox>
  </CheckBoxContainer>
);

export default Checkbox;
