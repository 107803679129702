import styled from 'styled-components';

const StyledImageUploader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  .edit-image-container {
    position: absolute;
    z-index: 5;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: white;

    .cropper-container {
      position: relative;
      width: 100%;
      height: 90%;
    }
  }

  .loader {
    margin-top: 0px !important;

    .bubble {
      background-color: white !important;
    }
  }

  .btns-container {
    border-top: 1px solid #e4e5e7;
    border-bottom: 1px solid grey;
    padding-top: 17px;
    background-color: white;
    position: sticky;
    bottom: 0;
    width: 100%;

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      padding: 5px;

      & > * {
        margin: 5px;
      }

      label {
        display: block;
      }

      .MuiSlider-thumb {
        color: #00b0ac !important;
      }

      .MuiSlider-track {
        color: #00b0ac !important;
      }

      .MuiSlider-root {
        width: 105px;
        @media screen and (max-width: 767px) {
          width: 142px;
        }
      }
      .cancel-button {
        background: #e4e5e7;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-left: 83px;

        @media screen and (max-width: 767px) {
          width: 142px;
          margin-left: 20px;
        }
      }

      .save-button {
        background: #00b0ac;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #fff;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-right: 83px;

        @media screen and (max-width: 767px) {
          width: 142px;
          margin-right: 20px;
        }
      }

      button {
        margin-block-start: 0;
        margin-block-end: 0;
        border: none;
        border-radius: 0;
        &:hover,
        &:focus {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
`;

export default StyledImageUploader;
