const salesAgentsReducer = (state, action) => {
  if (!action) return { ...state };
  switch (action.type) {
    case 'FETCH_SALES_AGENTS_INIT':
      return {
        ...state,
        agentDataLoading: true,
        isError: false,
      };
    case 'FETCH_SALES_AGENTS_SUCCESS':
      return {
        ...state,
        salesAgents: action.payload,
        agentDataLoading: false,
        isError: false,
      };
    case 'FETCH_SALES_AGENTS_FAILURE':
      return {
        ...state,
        agentDataLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'FETCH_PROFILE_INIT':
      return {
        ...state,
        agentDataLoading: true,
        isError: false,
      };
    case 'FETCH_PROFILE_SUCCESS':
      return {
        ...state,
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.SalesAgentId !== action.payload.SalesAgentId
          ),
          { ...action.payload, profileLoaded: true },
        ],
        agentDataLoading: false,
        isError: false,
      };
    case 'FETCH_PROFILE_FAILURE':
      return {
        ...state,
        agentDataLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'FETCH_SPECIALTIES_INIT':
      return {
        ...state,
        specialtiesLoading: true,
        isError: false,
      };
    case 'FETCH_SPECIALTIES_SUCCESS':
      return {
        ...state,
        specialties: action.payload,
        specialtiesLoading: false,
        isError: false,
      };
    case 'FETCH_SPECIALTIES_FAILURE':
      return {
        ...state,
        specialtiesLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'SET_ACTIVE_SALES_AGENT_ID':
      return {
        ...state,
        activeSalesAgentId: action.payload,
        stagedAgent: {
          ...state.salesAgents.find(
            agent => agent.SalesAgentId === action.payload
          ),
        },
      };
    case 'UPDATE_STAGED_AGENT':
      return {
        ...state,
        stagedAgent: action.payload,
      };
    case 'UPDATE_SALES_AGENT_INIT':
      if (!state.salesAgents) {
        return { ...state, salesAgents: [state.stagedAgent] };
      }

      return {
        ...state,
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.SalesAgentId !== state.stagedAgent.SalesAgentId
          ),
          state.stagedAgent,
        ],
      };
    case 'UPDATE_SALES_AGENT_SUCCESS':
      return {
        ...state,
        stagedAgent: action.payload,
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.SalesAgentId !== state.stagedAgent.SalesAgentId
          ),
          action.payload,
        ],
      };
    case 'UPDATE_SALES_AGENT_FAILURE':
      return {
        ...state,
        isError: true,
        error: action.payload,
      };
    case 'PUBLISH_SALES_AGENT_INIT':
      return {
        ...state,
        stagedAgent: {
          ...state.stagedAgent,
          IsPublished: true,
        },
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.SalesAgentId !== state.stagedAgent.SalesAgentId
          ),
          { ...state.stagedAgent, IsPublished: true },
        ],
      };
    case 'PUBLISH_SALES_AGENT_SUCCESS':
      return {
        ...state,
      };
    case 'PUBLISH_SALES_AGENT_FAILURE':
      return {
        ...state,
        isError: true,
        error: action.payload,
      };
    case 'UNPUBLISH_SALES_AGENT_INIT':
      return {
        ...state,
        stagedAgent: {
          ...state.stagedAgent,
          IsPublished: false,
        },
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.SalesAgentId !== state.stagedAgent.SalesAgentId
          ),
          { ...state.stagedAgent, IsPublished: false },
        ],
      };
    case 'UNPUBLISH_SALES_AGENT_SUCCESS':
      return {
        ...state,
      };
    case 'UNPUBLISH_SALES_AGENT_FAILURE':
      return {
        ...state,
        isError: true,
        error: action.payload,
      };
    case 'DELETE_SALES_AGENT_INIT':
      return {
        ...state,
        stagedAgent: [
          ...state.salesAgents.filter(
            agent => agent.UserName !== action.payload
          ),
        ][0],
        activeSalesAgentId: [
          ...state.salesAgents.filter(
            agent => agent.UserName !== action.payload
          ),
        ][0].SalesAgentId,
        salesAgents: [
          ...state.salesAgents.filter(
            agent => agent.UserName !== action.payload
          ),
        ],
      };
    case 'DELETE_SALES_AGENT_SUCCESS':
      return {
        ...state,
      };
    case 'DELETE_SALES_AGENT_FAILURE':
      return {
        ...state,
        isError: true,
        error: action.payload,
      };
    case 'SET_SHOW_MODAL':
      return {
        ...state,
        showModal: action.payload,
      };
    case 'SET_IS_DELETE_MODAL_VISIBLE':
      return {
        ...state,
        isDeleteModalVisible: action.payload,
      };
    case 'SET_LAST_LOCATION':
      return {
        ...state,
        lastLocation: action.payload,
      };
    case 'SET_TARGET_LOCATION':
      return {
        ...state,
        targetLocation: action.payload,
      };
    case 'SET_TARGET_AGENT':
      return {
        ...state,
        targetAgent: state.stagedAgent,
      };
    default:
      return { ...state };
  }
};

export default salesAgentsReducer;
