import React from 'react';
import PropTypes from 'prop-types';
import StyledUploadCard from './UploadCard.Style';
import useDragging from './useDragging';
import LoadingDots from '../LoadingDots';
/**
 * Component for individual staged image cards
 */
const UploadCard = ({
  handleChange,
  handleDrop,
  id,
  headerText,
  footerText,
  allowMultiple,
  showLibraryImagesModal,
  isUploading,
}) => {
  const [draggingRef, isDragging] = useDragging();
  if (isUploading) {
    return (
      <StyledUploadCard>
        <div className="header">{headerText}</div>
        <LoadingDots />
      </StyledUploadCard>
    );
  } else
    return (
      <StyledUploadCard>
        <div className="header">{headerText}</div>
        <form
          ref={draggingRef}
          className={`${isDragging ? 'dropBox dragging' : 'dropBox'}${
            showLibraryImagesModal ? ' withLibrary' : ''
          }`}
          method="post"
          action=""
          encType="multipart/form-data"
          onChange={handleChange}
          onDrop={handleDrop}
        >
          {isUploading && <LoadingDots />}
          {!isUploading && (
            <>
              <div className="label drag-image">
                {isDragging
                  ? 'Drop to add'
                  : `Drag image${allowMultiple ? 's' : ''} here or`}
              </div>
              <div className="buttons">
                <label htmlFor={id}>
                  <div className="button">
                    {`Select Image${allowMultiple ? 's' : ''}`}
                  </div>
                  <input
                    type="file"
                    name="files[]"
                    multiple={allowMultiple}
                    id={id}
                  />
                </label>

                {showLibraryImagesModal && (
                  <button
                    type="button"
                    className="button"
                    onClick={showLibraryImagesModal}
                  >
                    Choose From Library
                  </button>
                )}
              </div>
            </>
          )}
        </form>
        <div className="footer">{footerText}</div>
      </StyledUploadCard>
    );
};

UploadCard.propTypes = {
  /**
   * function to call when form changes
   */
  handleChange: PropTypes.func.isRequired,
  /**
   * function to call when file is dropped
   */
  handleDrop: PropTypes.func.isRequired,
  /**
   * the id of the file input element
   */
  id: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  allowMultiple: PropTypes.bool,
  showLibraryImagesModal: PropTypes.func,
};

UploadCard.defaultProps = {
  id: 'image-upload',
  allowMultiple: true,
  showLibraryImagesModal: null,
};

export default UploadCard;
