import styled from 'styled-components';

const StyledDatePicker = styled.div`
  .react-calendar {
    font-family: source-sans-pro, sans-serif;
    width: auto;
    border: none;
    &__navigation {
      background: #f3f5f7;
      margin-bottom: 5px;
      &__label {
        color: #25292d;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1.08;
        text-transform: uppercase;
      }
    }
    &__month-view {
      padding: 5px 10px;
      margin-bottom: 25px;
      &__weekdays {
        margin-bottom: 10px;
        text-transform: capitalize;
        color: #25292d;
        abbr {
          text-decoration: none;
        }
      }
      &__days {
        .react-calendar__tile {
          color: #777b7e;
          position: relative;
          z-index: 1;
          height: 26px;
          padding: 0;
          margin-bottom: 7px;
          &:after {
            position: absolute;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            z-index: -1;
          }
        }

        .react-calendar__tile--active,
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
          background: none;
          color: #fff;
          &:after {
            background: #0075c9;
          }
        }
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
          background: none;
          color: #fff;
          &:after {
            background: #0075c9;
          }
        }
        &__day {
        }
      }
    }
  }
`;

export default StyledDatePicker;
