import styled from 'styled-components';

const StyledModelImagesCard = styled.div`
  width: 253px;
  padding: 0 12px;
  box-sizing: border-box;
  @media screen and (min-width: 375px) {
    width: 268px;
  }
  /* .model-image-count {
    position: absolute;
    background: #ccc;
    box-sizing: border-box;
    padding: 3px 5px;
    border-radius: 11px;
    right: 5px;
    top: -10px;
    color: #777;
  } */
  .model-image-count {
    font: 600 12px/100% source-sans-pro, sans-serif;
    text-align: center;
    width: 48px;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 2px;
    background: #003f8c;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #777b7e;
    .bold {
      padding-right: 8px;
      color: #25292d;
      font-weight: 600;
    }
  }
`;

export default StyledModelImagesCard;
