import styled from 'styled-components';

const SideBarStyled = styled.div`
  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .side-bar {
    z-index: 10;
    background-color: #0c1e34;
    width: 324px;
    transform: ${props =>
      props.collapsed ? 'translateX(-324px)' : 'translateX(0)'};
    padding-bottom: ${props => (props.collapsed ? '0' : '225px')};
    transition: transform 0.2s linear, width 0.1s linear 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media only screen and (min-width: 1024px) {
      transform: translateX(0);
      width: 274px;
      z-index: 1;
    }
    .side-bar-header {
      color: #4c75a7;
      font-size: 14px;
      margin-left: 28px;
      margin-top: 40px;
    }
    a {
      color: white;
      text-decoration: none;
      font-size: 16px;
      .bg-animate {
        height: 45px;
        border-radius: 3px;
        margin-left: 16px;
        width: 89%;
      }
      .link-text {
        position: absolute;
        padding: 12px;
        margin: 0;
        width: 200px;
      }
    }
    @media only screen and (min-width: 1024px) {
      position: relative;
      flex: 1;
    }
  }

  .push-it-down {
    flex-grow: 1;
  }

  .bottom-links {
    border-top: 2px solid #29476c;
    margin-top: 65px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    button {
      font-family: source-sans-pro, sans-serif;
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 0;
      text-align: left;
      background: transparent;
      color: white;
      text-decoration: none;
      font-size: 16px;
      flex: 1;
      .bg-animate {
        height: 45px;
        border-radius: 3px;
        margin-left: 16px;
        width: 89%;
      }
      .link-text {
        position: absolute;
        padding: 12px;
        margin: 0;
        width: 200px;
      }
    }
  }

  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: ${props => (props.collapsed ? '0' : '0.8')};
    z-index: ${props => (props.collapsed ? '-1' : '9')};
    transition: all 0.2s, z-index 0s;
    background-color: #000;
  }

  .side-bar-button {
    z-index: 15;
    position: fixed;
    bottom: 7px;
    left: 7px;
    background-color: #0075c9;
    width: 55px;
    height: 55px;
    border-radius: 50px;
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    &:focus {
      outline: none;
    }

    @media only screen and (min-width: 1024px) {
      display: none;
    }
    &-top {
      bottom: 62px;
    }

    svg {
      margin-top: 8px;
    }
  }
  .side-bar-button {
    z-index: 15;
    position: fixed;
    bottom: 7px;
    left: 7px;
    background-color: #0075c9;
    width: 55px;
    height: 55px;
    border-radius: 50px;
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    &:focus {
      outline: none;
    }

    @media only screen and (min-width: 1024px) {
      display: none;
    }
    &-top {
      font-size: 12px;
      font-weight: 500;
      background: #0c1e34;
      bottom: 69px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      opacity: 1;
      transition: opacity 0.2s;
      &.hidden {
        opacity: 0;
        clip-path: inset(0 0 0 0);
        height: 0;
        width: 0;
        padding: 0;
      }
      @media only screen and (min-width: 1024px) {
        display: none;
      }
      svg,
      span {
        flex: 1;
        max-height: 20px;
      }
    }
  }
`;

export default SideBarStyled;
